import { SubscriptionDto } from "@neolime-gmbh/api-gateway-client";
import FanItem from "components/atoms/lists/FanItem";
import { generateTimeTagForFans } from "helper/dateAndTimeHelper";
import useSubscribers from "hooks/useSubscribers.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  className?: string;
  "data-testid"?: string;
};

const NoSubscribers = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  return (
    <section className={className} data-testid={dataTestId}>
      <h2 className="text-xl font-semibold text-gray-800">{`0 ${t("fans.subscribers")}`}</h2>
      <p className="text-gray-500">{t("fans.noSubscribers")}</p>
    </section>
  );
};

const Subscribers = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView(); // ref for loading trigger div
  const { subscribers, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage, subscribersCount } = useSubscribers();

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading]);

  if (!subscribers || subscribers.pages[0]?.data?.length === 0) {
    return <NoSubscribers className={className} data-testid={dataTestId} />;
  }

  return (
    <section className={className} data-testid={dataTestId}>
      <div className="relative flex flex-col gap-y-4">
        <h2 className="text-xl font-semibold text-gray-800" data-testid="subscribersHeadline">{`${subscribersCount} ${t(
          "fans.subscribers",
        )}`}</h2>
        {subscribers.pages.map((page) =>
          page.data.map((subscription: SubscriptionDto) => (
            <FanItem
              key={subscription?.subscriber?.username}
              userId={subscription?.subscriber?._id}
              username={subscription.subscriber?.username}
              userProfilePictureUrl={subscription.subscriber?.profilePictureThumbnail?.url}
              userProfilePictureUploadStatus={subscription.subscriber?.profilePictureThumbnail?.uploadStatus}
              additionalInfo={
                <span className="text-xs text-gray-500">{`${t("fans.subscribedSince")} ${generateTimeTagForFans(
                  new Date(subscription.subscribedAt),
                )}`}</span>
              }
              actions={
                <StatefulLink
                  to={`/chat/with/${subscription.subscriber?._id}`}
                  className="flex select-none items-center justify-center rounded-md border border-beige-400 bg-beige-400 p-2 font-medium text-gray-700 transition-all hover:border-beige-600 hover:bg-beige-600 hover:text-gray-800 active:border-beige-500 active:bg-beige-500 active:text-gray-800"
                  data-testid={`message-with-${subscription?.subscriber?.username}`}
                >
                  <span>{t("message.title")}</span>
                </StatefulLink>
              }
              isUserVerified={subscription.subscriber?.isVerified}
            />
          )),
        )}
        <div className="absolute bottom-[40rem]" ref={ref} />
      </div>
    </section>
  );
};

export default Subscribers;
