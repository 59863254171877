import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "react-query";

const useFolder = (id: string, enabled = true) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchFolder = async () => {
    return await maloumClient.vaultFolders.findFolderById(id);
  };

  const { data: folder, isLoading, isError } = useQuery(["folder", id], fetchFolder, { enabled });

  return { folder, isLoading, isError };
};

export default useFolder;
