import classNames from "classnames";
import { type KeyboardEvent, useRef } from "react";

type Props = {
  placeholder?: string;
  prefix?: string;
  submitOnEnter?: boolean;
  className?: string;
  textSizeClassName?: "text-xs" | "text-sm" | "text-base" | "text-lg" | "text-xl" | "text-2xl";
  [key: string]: any;
};

type AllowedKeys = {
  numbers: string[];
  separators: string[];
  special: string[];
  conditional: string[];
};

const PriceInput = ({
  placeholder = "0.00",
  prefix = "€",
  submitOnEnter = true,
  className,
  textSizeClassName = "text-base",
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const allowedKeys: AllowedKeys = {
    numbers: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    separators: ["."],
    special: ["Delete", "Backspace", "Tab", "Escape", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"],
    conditional: submitOnEnter ? ["Enter"] : [],
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ",") {
      triggerPeriodKey(e);
      return;
    }

    const { key } = e;
    const target = e.target as HTMLInputElement;
    const { start, isAValidCharacter, newValue } = getSelectionDetails(target, key);
    const isExceedingDecimalPlaces = newValue.includes(".") && newValue.split(".")[1]?.length > 2;

    if (
      !isKeyAllowed(e) ||
      (newValue.match(/\./g) || []).length >= 2 ||
      newValue.startsWith(".") ||
      (isAValidCharacter && isExceedingDecimalPlaces)
    ) {
      e.preventDefault();
      return;
    }

    //necessary to render . in the input field if , is pressed
    if (key === ".") {
      setTimeout(() => {
        target.value = newValue;
        target.selectionStart = target.selectionEnd = start + 1;
      }, 10);
    }

    props?.onChange?.(e as any);
    props?.onKeyDown?.(e as any);
  };

  const triggerPeriodKey = (e: KeyboardEvent<HTMLInputElement>): void => {
    const keyRelatedEvents = ["keydown", "keypress", "keyup", "input", "change"];
    keyRelatedEvents.forEach((event) =>
      e.target.dispatchEvent(
        new KeyboardEvent(event, {
          key: ".",
          code: "Period",
          keyCode: 190,
          which: 190,
          bubbles: true,
          cancelable: true,
        }),
      ),
    );
    e.preventDefault();
  };

  const getSelectionDetails = (target: HTMLInputElement, key: string) => {
    const start = target.selectionStart || 0;
    const end = target.selectionEnd || 0;
    return {
      start,
      isAValidCharacter: isCharacterValid(key),
      newValue: computeNewValue(target, key, start, end),
    };
  };

  const isCharacterValid = (key: string): boolean =>
    allowedKeys.numbers.includes(key) || allowedKeys.separators.includes(key);

  const computeNewValue = (target: HTMLInputElement, key: string, start: number, end: number): string => {
    const calculatedStart = key === "Backspace" && start > 0 ? start - 1 : start;
    return `${target.value.slice(0, calculatedStart)}${isCharacterValid(key) ? key : ""}${target.value.slice(end)}`;
  };

  const isKeyAllowed = (e: KeyboardEvent<HTMLInputElement>): boolean =>
    [...Object.values(allowedKeys).flat()].includes(e.key) || e.metaKey || e.ctrlKey;

  return (
    <div className="relative flex border-b border-b-gray-400 focus-within:border-b-red-900">
      {prefix && (
        <span
          className={classNames("input-underline", textSizeClassName, " !w-[unset] !pr-1.5")}
          style={{ borderBottomStyle: "none" }}
        >
          {prefix}
        </span>
      )}
      <input
        id={props.name}
        className={classNames("input-underline", className, textSizeClassName)}
        style={{ borderBottomStyle: "none" }}
        placeholder={placeholder}
        inputMode="decimal"
        autoComplete="off"
        //@ts-ignore
        ref={inputRef}
        //@ts-ignore
        onKeyDown={onKeyDown}
        {...props}
      />
    </div>
  );
};

export default PriceInput;
