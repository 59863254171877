import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TransactionItem from "../atoms/lists/TransactionItem";
import useTransactions from "hooks/useTransactions.hook";
import { useInView } from "react-intersection-observer";

const TransactionList = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { transactions, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useTransactions();

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading, isFetchingNextPage]);

  return (
    <>
      <div className="my-4 flex justify-between">
        <h2 className={"text-2xl font-semibold text-gray-900"}>{t("transactions.title")}</h2>

        <div className="flex flex-col items-end">
          <span className="text-sm font-semibold text-gray-700">{t("earnings.yours")}</span>
          <span className="text-xs text-gray-500">{t("earnings.total")}</span>
        </div>
      </div>
      <div className="flex flex-col gap-2 pb-16">
        {transactions.map((t) => (
          <TransactionItem
            transaction={t}
            className="[&:not(:first-child)]:pt-2 pb-4 border-b border-b-gray-200"
            key={t._id}
          />
        ))}
        {(isLoading || isFetchingNextPage) && (
          <>
            {Array(5)
              .fill(null)
              .map((_, i) => (
                <TransactionItem isLoading key={i} />
              ))}
          </>
        )}
        {!isLoading && !isFetchingNextPage && transactions.length === 0 && (
          <div className={"py-4 text-center"}>{t("transactions.empty")}</div>
        )}
        <div className="absolute bottom-[10rem]" ref={ref} />
      </div>
    </>
  );
};

export default TransactionList;
