import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Toggle from "components/atoms/Toggle";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import UserContext from "contexts/UserContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const NotificationSettings = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const { updateProfile } = useContext(UserContext);

  const [isChatMessageEmailEnabled, setIsChatMessageEmailEnabled] = useState(
    user.emailSettings.isChatMessageEmailEnabled,
  );

  const handleSave = () => updateProfile({ emailSettings: { isChatMessageEmailEnabled } }).then(() => navigate(-1));

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <h1 className="text-center text-sm">{t("notifications.title")}</h1>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        <p className="mt-6 text-3xl font-semibold">{t("notifications.title")}</p>
        <p className="mt-2">{t("notifications.description")}</p>

        <p className="mb-2 mt-6 font-bold">{t("notifications.emails")}</p>
        <div className="flex justify-between">
          <span>{t("notifications.unreadMessages")}</span>
          <Toggle
            name=""
            onChange={(s: boolean) => setIsChatMessageEmailEnabled(s)}
            isChecked={isChatMessageEmailEnabled}
          />
        </div>
        <div className="grow" />
        <Button onClick={handleSave} className="mb-4" text={t("save")} />
      </Container>
    </Layout>
  );
};

export default NotificationSettings;
