import MenuItem from "components/basics/MenuItem";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useTranslation } from "react-i18next";
import { HiOutlineBolt, HiOutlineChatBubbleLeftRight } from "react-icons/hi2";

const ChatSettingsPage = () => {
  const navigate = useStatefulNavigate();
  const { t } = useTranslation();

  return (
    <Layout>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("chats.settings.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        <div className="mb-4">
          <MenuItem
            handleOnClick={() => navigate("/chat/access")}
            Icon={HiOutlineChatBubbleLeftRight}
            text={t("chats.accessSettings.title")}
            data-testid={"chat-access"}
          />
          <MenuItem
            handleOnClick={() => navigate("/automated-messages")}
            Icon={HiOutlineBolt}
            text={t("navigation.automated-messages")}
            data-testid={"chat-access"}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default ChatSettingsPage;
