import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import StickyBottomBar from "components/StickyBottomBar";
import Header from "components/layouts/Header";
import Layout from "components/layouts/Layout";
import { supportEmail } from "const";
import { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import { ReactComponent as AccessDeniedSVG } from "svg/undraw_access_denied.svg";
import Button from "../../components/basics/Button";
import Container from "../../components/layouts/Container";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const VerificationError = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleVerificationStart = useCallback(() => navigate("/verification/start"), [navigate]);
  const handleClose = useCallback(() => navigate("/"), [navigate]);

  useEffect(() => {
    // Google Tag Manager: Verification Error Event
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "verification_error",
      user: {
        email: user.email,
        id: user._id,
      },
    });
  }, []);

  return (
    <Layout hasNavigation={false} hideNavigationMobile={false} hideVerificationButton hasStickyBottom>
      <Header hasBack={false} hasNavigation={false} title={t("verification.verifyAccount")} />

      <Container hasStickyBottom>
        <h1
          className={"mt-3 text-center font-serif text-3xl text-red-900 xxs:text-4xl"}
          data-testid="verificationErrorPage"
        >
          {t("verification.failed")}
        </h1>

        <p className="mt-8 text-center">
          <Trans i18nKey="verification.failedText">
            It looks like something went wrong with your verification. Please try again or contact
            <a href={`mailto:${supportEmail}`} className="text-red-900 underline">
              {supportEmail}
            </a>
            .
          </Trans>
        </p>

        <div className="mt-2 flex grow items-center justify-center">
          <AccessDeniedSVG className="h-fit max-h-[8.75rem] w-fit max-w-[8.75rem] md:max-h-[11.25rem] md:max-w-[11.25rem]" />
        </div>
      </Container>

      <StickyBottomBar>
        <Button variant="secondary" text={t("verification.tryAgainLater")} onClick={handleClose} />
        <Button className="mt-2" text={t("verification.tryAgain")} onClick={handleVerificationStart} />
      </StickyBottomBar>
    </Layout>
  );
};

export default VerificationError;
