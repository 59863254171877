import { ChatDto, MessageDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import SocketContext from "contexts/SocketContext";
import { messageToMessagePreview } from "helper/messageHelper";
import { useContext, useEffect, useRef } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";
import useChatsStore, { mapTypeToChatStoreKey } from "state/chatsState";
import useMessagesStore from "state/messagesState";
import useUserStore from "state/userState";

const useInbox = (type: ChatDto.inbox) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const socket = useContext(SocketContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const chats = useChatsStore((state) => {
    return state[mapTypeToChatStoreKey(type)];
  });
  const chatsRef = useRef<ChatDto[]>([]);
  chatsRef.current = chats;

  const fetchChats = async (next: QueryFunctionContext<string[], any>) => {
    if (user.isCreator) {
      return maloumClient.chats.getChatOverviewForCreator(next?.pageParam, 15);
    } else {
      return maloumClient.chats.getChatOverviewByInbox(type, next?.pageParam, 15);
    }
  };
  const {
    data: chatsData,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["fetch-chats", type], fetchChats, {
    getNextPageParam: (lastPage) => lastPage.next,
  });

  const setChats = async (chats: ChatDto[]) => {
    // add chats to array
    // we need to use chatsRef as chats can be out of date when this runs

    // filter chats for chats with the newest message
    const filteredChats = chatsRef.current.filter(
      (c1) =>
        chats.some((c2) => c1._id === c2._id) &&
        !chats.some(
          (c2) =>
            c1._id === c2._id &&
            new Date(c1.messages[0]?.sentAt || c1.createdAt) < new Date(c2.messages[0]?.sentAt || c2.createdAt),
        ),
    );
    // filter chatsNew for chats with the newest message
    const filteredNewChats = chats.filter(
      (c1) =>
        !chatsRef.current.some(
          (c2) =>
            c1._id === c2._id &&
            new Date(c1.messages[0]?.sentAt || c1.createdAt) <= new Date(c2.messages[0]?.sentAt || c2.createdAt),
        ),
    );
    let chatsTmp = [...filteredChats, ...filteredNewChats];

    chatsTmp = chatsTmp.sort(
      // @ts-ignore sort by date
      (c1, c2) => new Date(c2.messages[0]?.sentAt || c2.createdAt) - new Date(c1.messages[0]?.sentAt || c1.createdAt),
    );

    // update chats depending on type
    useChatsStore.setState((state) => ({
      ...state,
      [mapTypeToChatStoreKey(type)]: chatsTmp,
    }));
  };

  const replaceChat = async (chat: ChatDto) => {
    // abort if last message is broadcasted and sender is user
    if (chat.messages[0] && chat.messages[0].isBroadcasted && chat.messages[0].senderId === user._id) return;
    // filter chats for chats with the newest message
    const filteredChats = chatsRef.current.filter((c1) => c1._id !== chat._id);

    let chatsTmp = [...filteredChats, chat];
    chatsTmp = chatsTmp.sort(
      // @ts-ignore sort by date
      (c1, c2) => new Date(c2.messages[0]?.sentAt || c2.createdAt) - new Date(c1.messages[0]?.sentAt || c1.createdAt),
    );

    // update chats
    useChatsStore.setState((state) => ({
      ...state,
      [mapTypeToChatStoreKey(type)]: chatsTmp,
    }));
  };

  useEffect(() => {
    if (chatsData?.pages?.length) {
      setChats(chatsData?.pages?.flatMap((p) => p.data));
    }
  }, [chatsData]);

  const addOrUpdateChatByMessage = async (message: MessageDto) => {
    const chat = await maloumClient.chats.getChat(message.chat);
    replaceChat(chat);
  };

  const removeMessageFromChat = async (message: MessageDto) => {
    // check if chat exists
    const chatIdx = chatsRef.current.findIndex((c) => c._id === message.chat);
    // abort if chat does not exist
    if (chatIdx === -1) return;
    // abort if message is not the last message
    if (chatsRef.current[chatIdx].messages.findIndex((m) => m._id === message._id) === -1) return;

    // check messageStore for past messages
    const messagesForChat = useMessagesStore.getState().messages[message.chat];
    // if message is in messageStore, update chat with message store
    if (messagesForChat !== undefined && messagesForChat.filter((m) => m._id === message._id).length !== 0) {
      const chatsTmp = chatsRef.current;
      chatsTmp[chatIdx].messages = [messageToMessagePreview(messagesForChat.filter((m) => m._id !== message._id)[0])];
      useChatsStore.setState((state) => ({
        ...state,
        [mapTypeToChatStoreKey(type)]: chatsTmp,
      }));
    } else {
      // fetch chat from backend to get last relavant message
      const chat = await maloumClient.chats.getChat(message.chat);
      const chatsTmp = chatsRef.current;

      if (chat.messages.length !== 0) {
        // update chat if messages are left
        chatsTmp[chatIdx] = chat;
      } else {
        // delete chat if no messages are left
        delete chatsTmp[chatIdx];
      }
      useChatsStore.setState((state) => ({
        ...state,
        [mapTypeToChatStoreKey(type)]: chatsTmp,
      }));
    }
  };

  useEffect(() => {
    socket?.on("receive_message", addOrUpdateChatByMessage);
    socket?.on("delete_message_update", removeMessageFromChat);

    return () => {
      socket?.off("receive_message", addOrUpdateChatByMessage);
      socket?.off("delete_message_update", removeMessageFromChat);
    };
  }, []);

  return {
    chats,
    isLoading: isLoading && !chatsRef.current.length,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

export default useInbox;
