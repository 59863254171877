import { MediaCandidateDto, PostDto } from "@neolime-gmbh/api-gateway-client";
import { HiVideoCamera } from "react-icons/hi2";
import { useEffect, useRef } from "react";
import Media from "components/media/Media";
import StatefulLink from "components/atoms/utils/StatefulLink";

//TODO replace with useInView
export function useWhenInViewport(ref: any, callback: () => void) {
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callback();
        observer.disconnect();
      }
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);
}

type Props = {
  item: PostDto;
  onClick: () => void;
  onViewed: () => void;
};

const DiscoverGridPost = ({ item, onClick, onViewed }: Props) => {
  const ref = useRef(null);
  useWhenInViewport(ref, onViewed);

  return (
    <StatefulLink
      ref={ref}
      className="relative aspect-[3/4] overflow-hidden"
      to={`/discovery/feed/${item._id}`}
      onClick={onClick}
      state={{ scrollTop: true }}
    >
      <Media media={item.media[0]} thumbnail={item.thumbnail} hidePlayIcon />
      {item.media[0].type === MediaCandidateDto.type.VIDEO && (
        <HiVideoCamera className="absolute right-2 top-2 h-6 w-6 text-white" />
      )}
    </StatefulLink>
  );
};

export default DiscoverGridPost;
