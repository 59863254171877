import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronDown } from "react-icons/hi2";
import UnsubscribePopup from "../../profile/UnsubscribePopup";
import Button from "../Button";

type Props = {
  user: PublicUserDto;
  unsubscribeFromUser: (value: PublicUserDto) => void;
};

const UnsubscribeButton = ({ user, unsubscribeFromUser }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOnClick = useCallback(() => setOpen(true), [setOpen]);

  return (
    <>
      <Button
        onClick={handleOnClick}
        variant="secondary"
        text={t("unsubscribe.unsubscribe")}
        IconBack={HiChevronDown}
      />
      <UnsubscribePopup user={user} unsubscribeFromUser={unsubscribeFromUser} open={open} setOpen={setOpen} />
    </>
  );
};

export default UnsubscribeButton;
