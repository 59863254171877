import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import { create } from "zustand";

type TListsState = {
  lists: ChatListDto[];
  addOrReplaceList: (list: ChatListDto, beginning?: boolean) => void;
  addOrReplaceLists: (lists: ChatListDto[]) => void;
  removeList: (listId: string) => void;
};

const useListsState = create<TListsState>((set, get) => ({
  lists: [] as ChatListDto[],
  addOrReplaceList: (list: ChatListDto, beginning = false) => {
    const lists = get().lists;
    const index = lists.findIndex((l) => l._id === list._id);
    if (index === -1) {
      if (beginning) set({ lists: [list, ...lists] });
      else set({ lists: [...lists, list] });
    } else {
      lists[index] = list;
      set({ lists });
    }
  },
  addOrReplaceLists: (lists: ChatListDto[]) => {
    const currentLists = get().lists;
    // new lists that are not in the current lists
    const newLists = lists.filter((list) => !currentLists.some((l) => l._id === list._id));
    // replace existing lists
    const existingLists = lists.filter((list) => currentLists.some((l) => l._id === list._id));
    existingLists.forEach((list) => {
      const index = currentLists.findIndex((l) => l._id === list._id);
      currentLists[index] = list;
    });
    // set new lists
    set({ lists: [...currentLists, ...newLists] });
  },
  removeList: (listId: string) => {
    const lists = get().lists;
    const index = lists.findIndex((l) => l._id === listId);
    if (index !== -1) {
      lists.splice(index, 1);
      set({ lists });
    }
  },
}));

export default useListsState;
