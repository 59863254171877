import classNames from "classnames";
import Card from "components/atoms/Card";
import useScheduledBroadcastMessages from "hooks/useScheduledBroadcastMessagesAmount.hook";
import { Trans } from "react-i18next";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  className?: string;
  "data-testid"?: string;
};

const ScheduledBroadcastMessages = ({ className, "data-testid": dataTestId }: Props) => {
  const { scheduledBroadcastMessages, isLoading, isError } = useScheduledBroadcastMessages();
  if (!scheduledBroadcastMessages || isLoading || isError) return <></>;
  return (
    <Card className={classNames("my-2", className)} data-testid={dataTestId}>
      <Trans
        i18nKey="seeScheduledMessages"
        values={{ count: scheduledBroadcastMessages }}
        components={{
          1: <StatefulLink className="text-red-900" to="/queue?tab=messages" />,
          2: <span className="text-sm text-gray-500" />,
        }}
      />
    </Card>
  );
};

export default ScheduledBroadcastMessages;
