import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import { smallBusinessAustria, smallBusinessGermany, supportEmail } from "const";
import useChat from "hooks/useChat.hook";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

const SmallBusinessOwner = ({ onBack }: Props) => {
  const { t } = useTranslation();
  const { openChatWithMaloumOfficial } = useChat();

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("smallBusiness.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="pb-4">
        <div className="mb-4 mt-4 text-2xl font-semibold">{t("smallBusiness.title")}</div>
        <div>
          <h2 className={"text-base font-semibold"}>{t("smallBusiness.heading")}</h2>
          <p>{t("smallBusiness.description")} </p>
        </div>
        <div className="mt-4">
          <h3 className={"text-base font-semibold"}>{t("smallBusiness.forAustria")} </h3>
          <a className={"underline"} href={smallBusinessAustria} target={"_blank"}>
            {smallBusinessAustria}
          </a>
        </div>
        <div className="mt-4">
          <h3 className={"text-base font-semibold"}>{t("smallBusiness.forGermany")} </h3>
          <a className={"underline"} href={smallBusinessGermany} target={"_blank"}>
            {smallBusinessGermany}
          </a>
        </div>
        <div className="grow" />
        <p className="mb-4 text-center text-sm text-gray-500">
          <Trans i18nKey="smallBusiness.questions">
            <button type="button" className="underline" onClick={() => openChatWithMaloumOfficial()} />
            <a href={`mailto:${supportEmail}`} className="underline" />
          </Trans>
        </p>
        <Button text={t("smallBusiness.button")} onClick={onBack} />
      </Container>
    </Layout>
  );
};

export default SmallBusinessOwner;
