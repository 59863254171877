import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import RadioButtonCard from "components/molecules/RadioButtonCard";
import StickyBottomBar from "components/StickyBottomBar";
import useGender from "hooks/useGender";
import SignUpHeader from "pages/signup/SignUpHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  initialGender?: string | null;
  onSave: (value: string) => void;
};

const MoreGenders = ({ initialGender, onSave }: Props) => {
  const { t } = useTranslation();
  const [selectedGender, setSelectedGender] = useState(initialGender || "");
  const { longGenderList, isAllGendersLoading } = useGender();

  if (!longGenderList || isAllGendersLoading) {
    return (
      <>
        <Container hasStickyBottom isLoading>
          <article className="flex flex-col">
            {[...Array(3)].map((el) => (
              <>
                <div className="mt-2 flex h-3 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
                <ul className="mb-6 mt-2 grid gap-y-2">
                  {[...Array(4)].map((_, i) => (
                    <li
                      key={`loading-more-genders-${i}`}
                      className="cursor-pointer items-center rounded-md border border-transparent p-2 shadow focus:outline-none"
                    >
                      <div className="flex flex-1 justify-between">
                        <div className="mt-2 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
                        <div className="flex h-8 w-8 animate-pulse rounded-full bg-gray-200"></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ))}
          </article>
        </Container>
        <StickyBottomBar>
          <div className="flex h-10 w-full animate-pulse rounded-md bg-gray-200"></div>
        </StickyBottomBar>
      </>
    );
  }

  return (
    <>
      <Container>
        <div className="h-[1rem]" />
        <RadioGroup
          name="gender"
          value={selectedGender}
          onChange={(value: string) => setSelectedGender(value)}
          data-testid="gender-list"
        >
          {longGenderList.map(({ category, values }, categoryIndex) => {
            return (
              <div key={category}>
                <h3 className="text-sm text-gray-500">{t(`gender.${category}`)}</h3>
                <ul className="mb-6 mt-2 grid gap-y-2">
                  {values.map((gender, valueIndex) => (
                    <li key={gender.name}>
                      <RadioButtonCard
                        value={gender.name}
                        data-testid={categoryIndex === 0 && valueIndex === 0 ? "gender-select-button" : ""}
                      >
                        {t(`gender.${gender.name}`)}
                      </RadioButtonCard>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </RadioGroup>
        <div className="h-[5rem]" />
      </Container>
      <div className="pointer-events-none fixed bottom-[66px] h-[3rem] w-full bg-gradient-to-t from-white to-white/0" />
      <div className={classNames("sticky bottom-0 mx-auto flex w-full max-w-xl flex-col bg-white p-3")}>
        <Button
          variant={"primary"}
          disabled={!selectedGender}
          text={t("save")}
          type="button"
          data-testid="save-gender-button"
          onClick={() => onSave(selectedGender)}
        />
      </div>
    </>
  );
};

type PageProps = {
  onBack: () => void;
  isPartOfSignUpFlow: boolean;
} & Props;

const MoreGendersPage = ({ onBack, onSave, initialGender, isPartOfSignUpFlow = false }: PageProps) => {
  const { t } = useTranslation();
  return (
    <>
      {isPartOfSignUpFlow ? (
        <div className="pr-safe pl-safe pb-safe absolute flex w-full flex-1 grow flex-col md:order-2">
          <SignUpHeader onBack={onBack} title={t("gender.yourGender")} showSeperator />
          <MoreGenders onSave={onSave} initialGender={initialGender} />
        </div>
      ) : (
        <Layout hideNavigationMobile hideVerificationButton>
          <HeaderBar className="mb-3">
            <HeaderBar.SubPage>
              <HeaderBar.Left>
                <HeaderBar.Cancel onClick={onBack} />
              </HeaderBar.Left>
              <HeaderBar.Center>
                <HeaderBar.Title>{t("gender.yourGender")}</HeaderBar.Title>
              </HeaderBar.Center>
              <HeaderBar.Right />
            </HeaderBar.SubPage>
          </HeaderBar>
          <MoreGenders onSave={onSave} initialGender={initialGender} />
        </Layout>
      )}
    </>
  );
};

export default MoreGendersPage;
