import Spinner from "components/atoms/Spinner";
import { useTranslation } from "react-i18next";

type Props = {
  isSender: boolean;
  spinnerClassName?: string;
  hideText?: boolean;
};
const UnprocessedMedia = ({ isSender, spinnerClassName, hideText = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={"flex flex-col items-center justify-center text-center"}>
      <Spinner spinnerClassName={spinnerClassName} />
      {!hideText && <span className={"mt-1 text-xs text-gray-400"}>{t("media.processing")}...</span>}
      {!hideText && (
        <span className={"mt-3 text-xs text-gray-500"}>
          {isSender ? t("media.gettingReady") : t("media.contentGettingReady")}
        </span>
      )}
    </div>
  );
};

export default UnprocessedMedia;
