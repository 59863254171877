import { MediaCandidateDto, ThumbnailCandidateDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Spinner from "components/atoms/Spinner";
import ImageFullScreenPopup from "components/basics/ImageFullScreenPopup";
import VideoFullScreenPopup from "components/chats/VideoFullScreenPopup";
import { useState } from "react";
import { HiPlay } from "react-icons/hi2";

type Props = {
  media: MediaCandidateDto & { width?: number; height?: number };
  thumbnail: ThumbnailCandidateDto;
  cover?: boolean;
  allowFullScreen?: boolean;
  processingMessage?: string;
  hidePlayIcon?: boolean;
  className?: string;
  "data-testid"?: string;
};

const Media = ({
  media,
  thumbnail,
  cover = true,
  allowFullScreen = true,
  hidePlayIcon = false,
  processingMessage,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const [showFullScreen, setShowFullScreen] = useState(false);

  const isProcessing = media.uploadStatus === MediaCandidateDto.uploadStatus.PROCESSING;

  const mediaContent = (
    <>
      <DisplayMedia
        media={media}
        thumbnail={thumbnail}
        cover={cover}
        hidePlayIcon={hidePlayIcon}
        processingMessage={processingMessage}
      />
      {!isProcessing && (
        <>
          {media.type === MediaCandidateDto.type.PICTURE ? (
            <ImageFullScreenPopup isOpen={showFullScreen} onClose={() => setShowFullScreen(false)} picture={media} />
          ) : (
            <VideoFullScreenPopup
              isOpen={showFullScreen}
              onClose={() => setShowFullScreen(false)}
              video={media}
              thumbnail={thumbnail}
            />
          )}
        </>
      )}
    </>
  );

  const mediaClasses = classNames("relative h-full w-full overflow-hidden", className, {
    "flex flex-col items-center justify-center border border-gray-200 bg-gray-100": isProcessing,
  });

  return allowFullScreen ? (
    <button type="button" className={mediaClasses} onClick={() => setShowFullScreen(true)} data-testid={dataTestId}>
      {mediaContent}
    </button>
  ) : (
    <div className={mediaClasses} data-testid={dataTestId}>
      {mediaContent}
    </div>
  );
};

export default Media;

type DisplayMediaProps = {
  media: MediaCandidateDto;
  thumbnail: ThumbnailCandidateDto;
  cover: boolean;
  hidePlayIcon: boolean;
  processingMessage?: string;
};

const DisplayMedia = ({ media, thumbnail, cover, hidePlayIcon, processingMessage }: DisplayMediaProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  if (media.uploadStatus === MediaCandidateDto.uploadStatus.PROCESSING)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
        {processingMessage && (
          <span className="mt-3 max-w-[25rem] px-4 text-xs text-gray-500">{processingMessage}</span>
        )}
      </div>
    );
  return (
    <>
      {/* loading/error state */}
      <div
        className={classNames("absolute top-0 h-full w-full animate-pulse bg-gray-100", {
          hidden: isLoaded && !isError,
          block: !isLoaded || isError,
        })}
      />
      {!isError && (
        <img
          src={media.type === MediaCandidateDto.type.PICTURE ? media.url : thumbnail.url}
          className={classNames("absolute top-0 h-full w-full", {
            hidden: !isLoaded,
            block: isLoaded,
            "object-contain": !cover,
            "object-cover": cover,
          })}
          alt=""
          onLoad={() => setIsLoaded(true)}
          onError={() => setIsError(true)}
        />
      )}
      {media.type === MediaCandidateDto.type.VIDEO && !hidePlayIcon && (
        <HiPlay className="absolute left-1/2 top-1/2 h-8 max-h-[50%] w-8 max-w-[50%] -translate-x-1/2 -translate-y-1/2 transform text-white" />
      )}
    </>
  );
};
