import { MaloumClient, ProductDto, TransactionDto } from "@neolime-gmbh/api-gateway-client";
import { usePostHog } from "posthog-js/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronRight } from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";
import Button from "../../../components/basics/Button";
import Container from "../../../components/layouts/Container";
import Header from "../../../components/layouts/Header";
import Layout from "../../../components/layouts/Layout";
import MaloumClientContext from "../../../contexts/MaloumClientContext";
import ErrorPage from "../../basics/Error";
import Loading from "../../basics/Loading";
import CartItem from "../checkoutComponents/CartItem";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  type: TransactionDto.category;
};
// TODO: replace navigate onClicks with Link component
const SuccessOrder = ({ type }: Props) => {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const navigate = useStatefulNavigate();
  const { maloumClient }: { maloumClient: MaloumClient } = useContext(MaloumClientContext);

  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(TransactionDto.status.PENDING);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [transaction, setTransaction] = useState<TransactionDto | null>(null);

  const navigateToProfile = useCallback(() => {
    if (transaction) {
      navigate(`/creator/${transaction.to.username}`, { state: { forceFallback: true }, replace: true });
    }
  }, [transaction]);

  const checkPayment = useCallback(async () => {
    const orderId = searchParams.get("transactionId");
    if (!orderId) return;
    const response = await maloumClient.transactions.getPaymentStatus(orderId);
    if (!(response instanceof Error)) {
      setPaymentStatus(response.status);
    }
  }, [searchParams, intervalId, type, navigate, clearInterval, maloumClient, setPaymentStatus, setIntervalId]);

  const fetchTransaction = useCallback(async () => {
    const transactionId = searchParams.get("transactionId");
    if (!transactionId || paymentStatus !== TransactionDto.status.APPROVED) return;
    const response = await maloumClient.transactions.getById(transactionId);
    // although the external system has processed our system may have not
    if (response.status !== TransactionDto.status.APPROVED) return;
    if (!(response instanceof Error)) {
      setTransaction(response);
    }
  }, [searchParams, paymentStatus]);

  useEffect(() => {
    fetchTransaction();
    checkPayment();
    const tmpIntervalId = setInterval(checkPayment, 4000);
    setIntervalId(tmpIntervalId);

    return () => {
      clearInterval(tmpIntervalId);
    };
  }, [paymentStatus]);

  useEffect(() => {
    if (transaction && transaction.status === TransactionDto.status.APPROVED) {
      // Google Tag Manager: Any Purchase Event
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "any_purchase",
        transaction_id: transaction?._id,
        creator_id: transaction?.to._id,
        net_price: transaction?.price.net,
      });

      if (type === "SUBSCRIPTION") {
        // POSTHOG: emmit bought subscription event
        posthog?.capture("bought-subscription", { creator_id: transaction.to._id, amount: transaction?.price.net });

        // Google Tag Manager: Subscribe Event
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          event: "subscribe",
          transaction_id: transaction?._id,
          creator_id: transaction?.to._id,
          net_price_per_month: transaction?.price.net,
        });
      } else if (type === "TIP") {
        // POSTHOG: emmit bought tip event
        posthog?.capture("bought-tip", { creator_id: transaction.to._id, amount: transaction?.price.net });

        // Google Tag Manager: Tip Event
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          event: "tip",
          transaction_id: transaction?._id,
          creator_id: transaction?.to._id,
          net_price: transaction?.price.net,
        });
      } else if (type === "PRODUCT") {
        // POSTHOG: emmit bought product event
        posthog?.capture("bought-product", {
          creator_id: transaction.to._id,
          product: transaction.product?._id,
          amount: transaction?.price.net,
        });

        // Google Tag Manager: Purchase Event
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
          event: "purchase",
          transaction_id: transaction?._id,
          total_net_price: transaction?.price.net,
          tax: transaction?.price.vatAmount,
          items: [
            {
              item_id: transaction?.product?._id,
              item_name: transaction?.product?.name,
              net_price: transaction?.price.net,
              interests: transaction?.product?.categories.map((category: { _id: string }) => category._id),
              creator_id: transaction?.to._id,
            },
          ],
        });
      }
      if (type === TransactionDto.category.TIP || type === TransactionDto.category.CHAT_PRODUCT) {
        const chatId = searchParams.get("chatId");

        // POSTHOG: emmit bought chat product event
        if (chatId)
          posthog?.capture("bought-chat-product", {
            creator_id: transaction.to._id,
            chat: chatId,
            amount: transaction?.price.net,
          });

        if (!chatId) navigate("/checkout/error", { replace: true });
        navigate(`/chat/${chatId}`, { state: { forceFallback: true }, replace: true });
      }
    }
  }, [transaction]);

  useEffect(() => {
    if (intervalId && paymentStatus === TransactionDto.status.APPROVED) {
      clearInterval(intervalId);
    }
  }, [paymentStatus]);

  if (transaction === null || paymentStatus === TransactionDto.status.PENDING) {
    return <Loading />;
  }

  if (paymentStatus === TransactionDto.status.DECLINED) {
    return <ErrorPage />;
  }

  if (type === "TIP" || type === "CHAT_PRODUCT") return <Loading />;

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <Header title={t("checkout.success.thankYou")} hasNavigation={false} />
      <Container>
        <div className={"py-6 text-center"}>
          <h1 className={"mb-2 font-serif text-3xl text-red-900"}>{t("checkout.success.title")}</h1>
          <p className={"font-semibold text-gray-700"}>
            {type === TransactionDto.category.SUBSCRIPTION
              ? t("checkout.success.subscription")
              : t("checkout.success.order")}
          </p>
        </div>

        <div className={"mb-2 mt-3 text-xl font-medium"}>
          {type === TransactionDto.category.SUBSCRIPTION ? t("subscription.yourSubscription") : t("order.yours")}
        </div>
        <CartItem
          item={type === TransactionDto.category.SUBSCRIPTION ? transaction.to : (transaction.product as ProductDto)}
          showVatHint={false}
          type={type === TransactionDto.category.SUBSCRIPTION ? "SUBSCRIPTION" : "PRODUCT"}
          price={
            type === TransactionDto.category.SUBSCRIPTION
              ? transaction.to.subscriptionPrice
              : transaction.product?.price
          }
        />
        {type === TransactionDto.category.PRODUCT && (
          <>
            <div>
              <StatefulLink
                to={`/order/${transaction.order}`}
                className={"mb-2 mt-1 flex shrink items-center py-1 text-sm font-medium text-red-900"}
              >
                {t("order.details")}
                <HiChevronRight className={"ml-2 stroke-2"} />
              </StatefulLink>
            </div>

            <div className={"mb-1 text-sm text-gray-700"}>{t("nextSteps")}</div>
            <p>{t("order.nextStepsText")}</p>
          </>
        )}
      </Container>

      <Container hasGrow={false} className={"pb-8 pt-4"}>
        {type === TransactionDto.category.SUBSCRIPTION ? (
          <Button onClick={navigateToProfile} text={t("viewProfile") || ""} />
        ) : (
          <Button onClick={() => navigate("/")} text={t("continueShopping") || ""} />
        )}
      </Container>
    </Layout>
  );
};

export default SuccessOrder;
