import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import CategorySelection from "../components/category/CategorySelection";
import Layout from "../components/layouts/Layout";

type Props = {
  selected: CategoryDto[];
  onSubmit: (selectedCategories: CategoryDto[]) => void;
  onBack: () => void;
  minCategories: number;
  maxCategories: number;
  isPartOfWizard?: boolean;
  type?: "POST" | "PRODUCT";
};

const SelectCategoriesPage = ({
  selected,
  onSubmit,
  onBack,
  minCategories,
  maxCategories,
  isPartOfWizard = false,
  type = "PRODUCT",
}: Props) => {
  return (
    <Layout hideNavigationMobile hideVerificationButton hasStickyBottom>
      <CategorySelection
        selected={selected}
        onSubmit={onSubmit}
        onBack={onBack}
        minCategories={minCategories}
        maxCategories={maxCategories}
        isPartOfWizard={isPartOfWizard}
        type={type}
      />
    </Layout>
  );
};

export default SelectCategoriesPage;
