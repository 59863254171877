import classNames from "classnames";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useMutation } from "react-query";
import useListsState from "state/lists/listsState";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import { Trans, useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = { listId: string } & BaseComponent & PopUpComponent;

const DeleteListPopup = ({ listId, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const deleteListMutation = useMutation((listId: string) => maloumClient.chatLists.delete(listId));

  const { removeList } = useListsState();
  const navigate = useStatefulNavigate();

  const handleDeleteList = () => {
    deleteListMutation.mutate(listId, {
      onSuccess: () => {
        removeList(listId);
        navigate(-1);
        onClose();
      },
    });
  };

  const onQueueLinkClicked = () => {
    navigate({
      pathname: "/queue",
      search: createSearchParams({
        tab: "messages",
      }).toString(),
    });
  };

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("lists.delete.title") ?? ""} data-testid={dataTestId}>
      <div className={classNames("", className)}>
        <div className="mt-2">
          <Trans
            i18nKey="lists.delete.description"
            components={{ 1: <button className="inline text-red-900" onClick={onQueueLinkClicked} /> }}
          />
        </div>
        <div className="mt-4 flex items-center gap-2">
          <Button text={t("lists.delete.cancel")} onClick={onClose} variant="secondary" />
          <Button text={t("lists.delete.delete")} onClick={handleDeleteList} />
        </div>
      </div>
    </PopUp>
  );
};

export default DeleteListPopup;
