const getThreeDSBrowserData = () => {
  return {
    browserAcceptHeader: "text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8",
    browserJavaEnabled: window.navigator.javaEnabled(),
    browserLanguage: window.navigator.language,
    browserColorDepth: window.screen.colorDepth,
    browserScreenHeight: window.screen.height,
    browserScreenWidth: window.screen.width,
    browserTimezone: new Date().getTimezoneOffset(),
    browserUserAgent: window.navigator.userAgent,
    browserPlatform: typeof window.navigator.platform !== "undefined" ? window.navigator.platform : "",
  };
};

export {
  getThreeDSBrowserData
};