import Button from "components/basics/Button";
import Textarea from "components/basics/Textarea";
import PopUp from "components/popup/PopUp";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil, HiOutlinePlus } from "react-icons/hi2";
import * as Yup from "yup";

type Props = {
  note: string | undefined;
  setNote: (v: string) => void;
  className?: string;
  "data-testid"?: string;
};

const Note = ({ note, setNote, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <>
      <div className={className} data-testid={dataTestId}>
        {note ? (
          <button className="flex w-full justify-between gap-2" onClick={() => setIsPopupOpen(true)}>
            <span className="cursor-pointer py-0.5 text-left text-gray-500">{note}</span>
            <HiOutlinePencil className="mt-1 h-5 w-5 shrink-0" />
          </button>
        ) : (
          <button
            type={"button"}
            onClick={() => setIsPopupOpen(true)}
            className="mt-2 flex  w-full items-center py-0.5 text-left text-sm text-red-900"
          >
            <HiOutlinePlus className="mr-2 h-4 w-4" /> {t("checkout.addNote")}
          </button>
        )}
      </div>

      <PopUp title={t("checkout.addNote") as string} isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <Formik
          initialValues={{
            note: note,
          }}
          validationSchema={Yup.object().shape({
            note: Yup.string().required(t("validation.required") as string),
          })}
          onSubmit={(values) => {
            if (setNote) setNote(values.note as string);
            setIsPopupOpen(false);
          }}
        >
          {({ isValid }) => (
            <Form className="mt-4 text-left">
              <Textarea
                label={t("checkout.note.add") as string}
                type="text"
                name="note"
                placeholder={t("checkout.note.placeholder") as string}
                maxLength={600}
              />
              <Button type="submit" text={t("save") as string} disabled={!isValid} data-testid="save-note-btn" />
            </Form>
          )}
        </Formik>
      </PopUp>
    </>
  );
};

export default Note;
