import classNames from "classnames";
import { useMemo } from "react";

type Props = {
  text: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
  "data-testid"?: string;
};

const PillSelection = ({ text, selected, onClick, className, "data-testid": dataTestId }: Props) => {
  return (
    <button
      className={classNames(
        "rounded-full px-3 py-1",
        {
          "bg-red-900 text-white": selected,
          "bg-beige-400 text-gray-800": !selected,
        },
        className,
      )}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {text}
    </button>
  );
};

const PillSelectionLoading = ({ "data-testid": dataTestId }: { "data-testid"?: string }) => {
  const width = useMemo(() => `${64 + Math.random() * 100}px`, []);
  return (
    <div className="h-8 animate-pulse rounded-full bg-gray-200" style={{ width: width }} data-testid={dataTestId} />
  );
};

export default PillSelection;
export { PillSelectionLoading };
