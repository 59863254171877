import { useCallback, useContext, useState } from "react";
import PopUp from "../popup/PopUp";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import { ProductDto } from "@neolime-gmbh/api-gateway-client";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  product: ProductDto;
  deletedCallback: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
};
const ProductPopup = ({ product, deletedCallback, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { maloumClient } = useContext(MaloumClientContext);

  const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);

  const deleteProduct = useCallback(async () => {
    setConfirmDeletionOpen(false);
    const response = await maloumClient.products.delete(product._id);
    if (!(response instanceof Error)) {
      deletedCallback();
    }
  }, [product, deletedCallback]);

  const getPopupTitle = () => (confirmDeletionOpen ? t("product.deleteConfirmation") : t("product.modify"));

  const modifyOptions = [
    {
      title: t("edit"),
      onClick: () => navigate(`/product/${product._id}/edit`),
    },
    {
      title: t("delete"),
      testId: "delete-product",
      onClick: () => {
        setConfirmDeletionOpen(true);
      },
    },
  ];

  const deleteOptions = [
    {
      title: t("delete"),
      testId: "confirm-delete-product",
      onClick: async () => await deleteProduct(),
    },
  ];

  return (
    <PopUp
      isOpen={open || confirmDeletionOpen}
      onClose={() => (confirmDeletionOpen ? setConfirmDeletionOpen(false) : setOpen(false))}
      title={getPopupTitle()}
      options={confirmDeletionOpen ? deleteOptions : modifyOptions}
    />
  );
};

export default ProductPopup;
