import Logo from "assets/logo/Logo";
import classNames from "classnames";
import {
  dmcaLink,
  epochLink,
  imprintLink,
  privacyPolicyLink,
  recallPolicyLink,
  recordKeepingLink,
  supportEmail,
  termsAndConditionsLink,
  termsForCreatorsLink,
} from "const";
import { useTranslation } from "react-i18next";
import useUserStore from "../state/userState";

type Props = {
  className?: string;
  hasCopyRight?: boolean;
  showLogo?: boolean;
  showSocials?: boolean;
};
const LegalSection = ({ className, hasCopyRight = false, showLogo = false, showSocials = false }: Props) => {
  const { t } = useTranslation();
  const user = useUserStore((state) => state.user);

  return (
    <div
      className={classNames("flex flex-col text-center", className, { "gap-y-1 text-xs text-gray-400": !className })}
    >
      {showLogo && <Logo className={"h-5 fill-red-900"} />}
      {showSocials && (
        <div className={"flex justify-center gap-3"}>
          <a
            href={"https://www.tiktok.com/@maloum.official"}
            className={"inline-block rounded-full p-2"}
            target="_blank"
          >
            <img src="/images/tiktok.svg" className={"w-6 fill-red-900 text-red-900"} alt="" />
          </a>
          <a
            href={"https://www.instagram.com/maloum.official/"}
            className={"inline-block rounded-full p-2"}
            target="_blank"
          >
            <img src="/images/instagram.svg" className={"w-6 fill-red-900 text-red-900"} alt="" />
          </a>
        </div>
      )}
      <a href={`mailto:${supportEmail}`} className={"underline"}>
        {supportEmail}
      </a>
      <div>
        <div>{t("neolime.company")}</div>
        <div>{t("neolime.address")}</div>
      </div>

      <div id={"legal-links"} className={"flex flex-wrap justify-center gap-x-3 gap-y-1"}>
        <a href={termsAndConditionsLink} target="_blank">
          {t("legal.termsAndConditions")}
        </a>
        {user?.isCreator && (
          <a href={termsForCreatorsLink} target="_blank">
            {t("legal.termsForCreators")}
          </a>
        )}
        <a href={privacyPolicyLink} target="_blank">
          {t("legal.privacyPolicy")}
        </a>
        <a href={imprintLink} target="_blank">
          {t("legal.imprint")}
        </a>
        <a href={dmcaLink} target="_blank">
          {t("legal.dmca")}
        </a>
        <a href={recallPolicyLink} target="_blank">
          {t("legal.recallPolicy")}
        </a>
        <a href={recordKeepingLink} target="_blank">
          {t("legal.recordKeeping")}
        </a>
        <a href={epochLink} target="_blank">
          {t("legal.epochSupport")}
        </a>
      </div>

      {hasCopyRight && (
        <span>
          © {new Date().getFullYear()} MALOUM. {t("allRightsReserved")}
        </span>
      )}
    </div>
  );
};

export default LegalSection;
