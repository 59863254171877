import { useEffect } from "react";
import { HiXMark } from "react-icons/hi2";
import Container from "../layouts/Container";
import BlackBgPopUp from "components/popup/BlackBgPopUp";

type Props = {
  children: JSX.Element;
  isOpen: boolean;
  onClose: () => void;
  topLeftText?: string;
};

const MediaPopup = ({ children, isOpen, onClose, topLeftText = "" }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleCloseOnEscape);
      document.querySelector("meta[name='theme-color']")?.setAttribute("content", "#0A0A0B");
    }

    return () => {
      if (isOpen) {
        document.removeEventListener("keydown", handleCloseOnEscape);
        document.querySelector("meta[name='theme-color']")?.setAttribute("content", "#fff");
      }
    }
  }, [isOpen]);

  const handleCloseOnEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") onClose();
  }

  return (
    <BlackBgPopUp isOpen={isOpen} onClose={onClose}>
      <Container className="full-height py-2" hasPadding={false} data-testid={"image-popup"}>
        <div className="flex w-full items-center justify-between">
          <div className="pl-4 text-white">{topLeftText}</div>
          <button onClick={onClose} data-testid={"media-popup-close"} className="self-end p-2">
            <HiXMark className="h-6 w-6 text-white"/>
          </button>
        </div>
        <div className="flex max-h-[calc(100%-2rem)] grow flex-col items-center justify-center">{children}</div>
      </Container>
    </BlackBgPopUp>
  );
};

export default MediaPopup;
