import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import ChatInboxes from "components/chats/ChatInboxes";
import ChatsForCreators from "components/chats/ChatsForCreators";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import { useTranslation } from "react-i18next";
import { HiOutlineChartBarSquare, HiOutlineCog6Tooth, HiOutlinePencilSquare } from "react-icons/hi2";
import useUserStore from "state/userState";
import Container from "../../components/layouts/Container";

const ChatsParent = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  return (
    <Layout>
      <HeaderBar>
        <HeaderBar.MainPage>
          <HeaderBar.MainPageHeading>{t("navigation.chat")}</HeaderBar.MainPageHeading>
          {user.isCreator && (
            <HeaderBar.MainPageActions>
              <HeaderBar.Link to={user.isVerified ? "new" : "/verification/start"}>
                <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
              <HeaderBar.Link to={user.isVerified ? "broadcasts" : "/verification/start"}>
                <HiOutlineChartBarSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
              <HeaderBar.Link to={user.isVerified ? "settings" : "/verification/start"}>
                <HiOutlineCog6Tooth className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
            </HeaderBar.MainPageActions>
          )}
        </HeaderBar.MainPage>
      </HeaderBar>

      {user.isCreator ? (
        <Container className="relative bg-white" as="main">
          <ChatsForCreators />
        </Container>
      ) : (
        <Container hasPadding={false} className="relative bg-white" as="main">
          <ChatInboxes />
        </Container>
      )}
    </Layout>
  );
};

export default ChatsParent;
