import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { ChatNavIcon } from "components/atoms/icons/ChatNavIcon";
import { UserNavIcon } from "components/atoms/icons/UserNavIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiHome,
  HiOutlineHome,
  HiOutlineMagnifyingGlass,
  HiOutlinePlusCircle,
  HiOutlineShoppingBag,
  HiPlusCircle,
  HiShoppingBag,
} from "react-icons/hi2";
import { NavLink } from "react-router-dom";
import useUserStore from "../../state/userState";
import PopUp from "../popup/PopUp";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import {useFirstline} from "@first-line/firstline-react";

type Props = {
  hideOnMobile?: boolean;
};

const NavigationBar = ({ hideOnMobile = false }: Props) => {
  const { user: authUser } = useFirstline();
  const isCreator: boolean = authUser.roles?.includes("creator") || false;
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [openPopUp, setOpenPopUp] = useState(false);
  const popUpOptions = [
    {
      title: t("posts.add"),
      onClick: () => navigate("/post/create"),
    },
    {
      title: t("addProduct"),
      onClick: () => navigate("/product/create"),
    },
  ];
  const profileLink = isCreator ? `/creator/${user.username}` : "/profile";

  const tryToOpenPopUp = () => {
    if (user.isVerified) {
      setOpenPopUp(true);
    } else {
      navigate("/verification/start");
    }
  };

  return (
    <div
      className={classNames("pb-safe relative sm:hidden md:order-1 md:pb-0", {
        hidden: hideOnMobile,
      })}
    >
      <div
        className={
          "pb-safe fixed bottom-0 z-20 mx-auto w-full border-y border-gray-100 bg-white md:bottom-auto md:top-[50px] md:pb-0"
        }
      >
        <nav className={"relative mx-auto flex h-12 max-w-xl items-center px-3 transition-all"}>
          <NavLink to={"/"} end className={"flex flex-1 justify-center"}>
            {({ isActive }) => {
              if (isActive) {
                return <HiHome className={"h-7 w-7 text-gray-700"} />;
              }
              return <HiOutlineHome className={"h-7 w-7 text-gray-700"} />;
            }}
          </NavLink>
          <NavLink to={"/search"} className={"flex flex-1 justify-center"}>
            {({ isActive }) => (
              <HiOutlineMagnifyingGlass
                className={classNames("h-7 w-7 text-gray-700", {
                  "stroke-2": isActive,
                })}
              />
            )}
          </NavLink>
          {isCreator ? (
            <button onClick={tryToOpenPopUp} className={"flex flex-1 cursor-pointer justify-center"}>
              {openPopUp ? (
                <HiPlusCircle className={"h-7 w-7 text-gray-700"} />
              ) : (
                <HiOutlinePlusCircle className={"h-7 w-7 text-gray-700"} />
              )}
            </button>
          ) : (
            <NavLink to={"/shop"} className={"flex flex-1 justify-center"}>
              {({ isActive }) => {
                if (isActive) {
                  return <HiShoppingBag className={"h-7 w-7 text-gray-700"} />;
                }
                return <HiOutlineShoppingBag className={"h-7 w-7 text-gray-700"} />;
              }}
            </NavLink>
          )}
          <NavLink to={"/chat"} className={"flex flex-1 justify-center"}>
            {({ isActive }) => <ChatNavIcon selected={isActive} />}
          </NavLink>
          <NavLink to={profileLink} className={"flex flex-1 justify-center"}>
            {({ isActive }) => <UserNavIcon selected={isActive} />}
          </NavLink>
        </nav>

        <PopUp
          title={t("createContent")}
          options={popUpOptions}
          isOpen={openPopUp}
          onClose={() => setOpenPopUp(false)}
        />
      </div>
      <div
        className={classNames("h-12 md:hidden", {
          "hidden md:block": hideOnMobile,
        })}
      />
    </div>
  );
};

export default NavigationBar;
