import Card from "components/atoms/Card";
import classNames from "classnames";
import { HiOutlineCalendarDays, HiOutlineCamera, HiOutlineEllipsisHorizontal } from "react-icons/hi2";

const BroadcastCardLoading = () => {
  return (
    <Card className={classNames("grid animate-pulse grid-cols-1 gap-2 text-gray-300")}>
      <div className="flex items-center gap-2">
        <HiOutlineCalendarDays className="h-6 w-6" />
        <div className="h-5 w-32 rounded-md bg-gray-100" />
        <div className="grow" />
        <HiOutlineEllipsisHorizontal className="h-6 w-6" />
      </div>
      <div className="flex w-fit items-center gap-2 rounded-full bg-gray-100 px-3 py-1">
        <div className="h-5 w-16" />
      </div>
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2 h-5 rounded-md bg-gray-100" />
        <div className="col-span-1 h-5 rounded-md bg-gray-100" />
        <div className="col-span-1 h-5 rounded-md bg-gray-100" />
        <div className="col-span-2 h-5 rounded-md bg-gray-100" />
        <div className="col-span-2 h-5 rounded-md bg-gray-100" />
      </div>
      <div className="flex h-[6.5rem] gap-2">
        <div className="aspect-square h-full rounded-md bg-gray-100" />
        <div className="aspect-square h-full rounded-md bg-gray-100" />
      </div>
      <div className="flex items-center gap-2">
        <HiOutlineCamera className="h-4 w-4" />
        <div className="h-4 w-32 rounded-md bg-gray-100" />
      </div>
    </Card>
  );
};

export default BroadcastCardLoading;
