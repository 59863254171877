import { ContentServedDto, PostDto } from "@neolime-gmbh/api-gateway-client";
import useDiscovery from "hooks/useDiscovery.hook";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import NoResults from "./utilities/NoResults";
import classNames from "classnames";
import TopCreatorsSection from "./organisms/TopCreatorsSection";
import DiscoverGridPost from "components/molecules/DiscoverGridPost";
import lodash from "lodash";
import MaloumClientContext from "contexts/MaloumClientContext";

const ImageLoading = () => {
  return <div className={classNames("aspect-[3/4] h-full w-full animate-pulse bg-gray-200")} />;
};

type Props = {
  "data-testid"?: string;
};

const Discover = ({ "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  // ref for loading trigger div
  const { ref, inView } = useInView();

  // useDiscovery
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, updatePost } = useDiscovery();

  const viewedPosts: PostDto[] = [];

  const sendTrackedViewedPosts = () => {
    maloumClient.tracking.trackContent({
      context: ContentServedDto.context.DISCOVERY,
      content: viewedPosts.map((post) => ({ contentId: post._id, contentCreator: post.createdBy._id })),
    });
    viewedPosts.splice(0, viewedPosts.length);
  };
  const debouncedSendTrackedViewedPosts = lodash.debounce(sendTrackedViewedPosts, 1000);

  const trackViewedPost = useCallback(
    (postDto: PostDto) => {
      viewedPosts.push(postDto);
      debouncedSendTrackedViewedPosts();
    },
    [viewedPosts],
  );

  const createItemComponent = useCallback(
    (item: PostDto) => (
      <DiscoverGridPost
        key={item._id}
        item={item}
        onClick={() => {
          maloumClient.tracking.interactedWithContent({
            context: ContentServedDto.context.DISCOVERY,
            content: {
              contentId: item._id,
              contentCreator: item.createdBy._id,
            },
          });
        }}
        onViewed={() => {
          trackViewedPost(item);
        }}
      />
    ),
    [updatePost],
  );

  // loading item view
  const createLoadingItemComponent = useCallback((key: number) => <ImageLoading key={key} />, []);

  // view to display when no posts to show
  const NoResultsView = useCallback(() => <NoResults text={t("nothingLeftToDiscover")} />, []);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading, isFetchingNextPage]);

  useEffect(() => {
    const page = data?.pages.length ?? 0;
  }, [data]);

  return (
    <div data-testid={dataTestId}>
      <TopCreatorsSection />
      <div className="px-4 pb-2 text-xl font-semibold md:px-0">{t("suggestedForYou")}</div>
      <div className="relative pb-16">
        {data && !isLoading ? (
          <>
            <div className="grid grid-cols-3 gap-px">
              {data.pages.map((page) => page.data.map((e) => createItemComponent(e as PostDto)))}
            </div>
            {data.pages[0].data.length === 0 && <NoResultsView />}
            <div className="absolute bottom-[20rem]" ref={ref} />
          </>
        ) : (
          <div className="grid grid-cols-3 gap-px">
            {Array(18)
              .fill(null)
              .map((_, key) => createLoadingItemComponent(key))}
          </div>
        )}
        {isFetchingNextPage && (
          <div className="mt-1 grid grid-cols-3 gap-px">
            {Array(6)
              .fill(null)
              .map((_, key) => createLoadingItemComponent(key))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Discover;
