import { Form, Formik } from "formik";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import MaloumClientContext from "../contexts/MaloumClientContext";
import Button from "./basics/Button";
import Textarea from "./basics/Textarea";
import PopUp from "./popup/PopUp";

type Props = {
  id: string;
  type?: "POST" | "PRODUCT" | "CREATOR" | "COMMENT";
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const ReportPopUp = ({ id, type = "POST", isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const [hasError, setHasError] = useState<boolean | undefined>(undefined);

  const submitReport = useCallback(
    async (reason: string) => {
      try {
        const response = await maloumClient.reports.reportContent(type, id, {
          reason: reason,
        });

        if (!(response instanceof Error)) {
          setHasError(false);
        }
      } catch (error) {
        setHasError(true);
      }
    },
    [id, type, setHasError],
  );

  const popUpTitle = useMemo(() => {
    if (hasError === undefined) {
      return t("report.reportType", { type: t(`report.type.${type}`) });
    } else if (hasError === true) {
      return t("report.alreadyReported.title");
    } else {
      return t("report.success.title");
    }
  }, [hasError, t, type]);

  const handlePopupClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  return (
    <PopUp title={popUpTitle || ""} isOpen={isOpen} onClose={handlePopupClose}>
      <>
        {hasError === undefined && (
          <Formik
            initialValues={{
              report: "",
            }}
            validationSchema={Yup.object().shape({
              report: Yup.string().max(300, ({ max }) => t("validation.max", { max: max })),
            })}
            onSubmit={(values) => {
              submitReport(values.report);
            }}
          >
            <Form className={"mt-4 text-left"}>
              <>
                <div className={"mb-4"}>
                  <div>{t(`report.reason.${type}`)}</div>
                  <Textarea
                    name="report"
                    className={"mb-0"}
                    type="text"
                    maxLength={300}
                    placeholder={t("report.reason.title") || ""}
                  />
                </div>

                <Button type={"submit"} text={t("report.title")} />
              </>
            </Form>
          </Formik>
        )}
        {hasError === true && <div className={"mt-4"}>{t(`report.alreadyReported.${type}`)}</div>}
        {hasError === false && <div className={"mt-4"}>{t(`report.success.${type}`)}</div>}
      </>
    </PopUp>
  );
};

export default ReportPopUp;
