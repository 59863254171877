import { OrderDto } from "@neolime-gmbh/api-gateway-client";
import { useTranslation } from "react-i18next";
import OrderItem from "./OrderItem";

type Props = {
  heading?: string | null;
  orders?: OrderDto[];
  isLoading?: boolean;
};

const OrderSection = ({ heading, orders, isLoading }: Props) => {
  const { t } = useTranslation();

  if (isLoading || orders === undefined || !heading) {
    return (
      <>
        <div className={"mb-3 mt-4 h-5 w-32 animate-pulse rounded-full bg-gray-300"} />
        <div className={"grid gap-y-4"}>
          <OrderItem isLoading />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={"mb-2 mt-4 text-xl font-semibold"}>{heading}</div>
      <div className={"grid gap-y-4"}>
        {orders.map((order) => (
          <OrderItem key={order._id} order={order} />
        ))}

        {orders.length === 0 && <div className={"py-8 text-center"}>{t("order.empty")}</div>}
      </div>
    </>
  );
};

export default OrderSection;
