import PriceSettingPopup from "components/molecules/popup/PriceSettingPopup";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onDigitalProductPriceChange: (value: number | undefined) => void;
  digitalProductNetPrice?: number;
};

const ChatPriceSettingPopup = ({ isOpen, setIsOpen, onDigitalProductPriceChange, digitalProductNetPrice }: Props) => {
  const { t } = useTranslation();

  const changePrice = useCallback(
    async (price: number) => {
      onDigitalProductPriceChange(price);

      setIsOpen(false);
    },
    [setIsOpen],
  );

  return (
    <PriceSettingPopup
      heading={t("chatProduct.imageOrVideoPrice")!}
      priceChangeButtonText={t("chatProduct.setPrice")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onPriceChange={changePrice}
      netAmount={digitalProductNetPrice}
      required={false}
    />
  );
};

export default ChatPriceSettingPopup;
