import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SearchInput from "components/molecules/basics/SearchInput";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ShopCategoryFilter from "components/shop/ShopCategoryFilter";
import ShopDiscover from "components/shop/ShopDiscover";
import ShopProducts from "components/shop/ShopProducts";
import useCategories from "hooks/useCategories.hook";
import useScrollPosition from "hooks/useScrollPosition.hook";
import Loading from "pages/basics/Loading";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const ShopPage = () => {
  const { t } = useTranslation();

  const { categories, isLoading: categoriesLoading } = useCategories();
  const { scrollDirection, scrollYPosition } = useScrollPosition();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const queryCategory = searchParams.get("category");

  const [debounceQuery, setDebounceQuery] = useState<string>(query || "");
  const [isTyping, setIsTyping] = useState(true);

  const findCategory = useCallback(
    (categoryName: string | null) => {
      if (!categoryName || !categories || categoryName === "all") return null;
      return categories.find((category: CategoryDto) => category.name === categoryName) || null;
    },
    [categories],
  );

  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | null>(null);

  useEffect(() => {
    if (!categoriesLoading) setSelectedCategory(findCategory(queryCategory));
  }, [categoriesLoading]);

  const handleDebounceQueryChange = useCallback((value: string) => setDebounceQuery(value.trim()), [setDebounceQuery]);

  // if no changes happen to debounceQuery for XXXms, then query is set
  useEffect(() => {
    // stop if no category was selected yet
    if (selectedCategory) return;
    // Change category params regardless of query
    if (!debounceQuery) {
      searchParams.delete("query");
      setSearchParams(searchParams);
    }
    // Prevent initial render from changing any value
    if (debounceQuery && debounceQuery.trim().length > 0) {
      setIsTyping(true);
      const timer = setTimeout(() => {
        searchParams.set("query", debounceQuery || "");
        setSearchParams(searchParams);
        setIsTyping(false);
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setIsTyping(false);
      return;
    }
  }, [debounceQuery, selectedCategory]);

  useEffect(() => {
    if (!categoriesLoading) {
      searchParams.set("category", selectedCategory?.name || "all");
      setSearchParams(searchParams);
    }
  }, [selectedCategory, categoriesLoading]);

  if (!categories) return <Loading />;

  return (
    <Layout>
      <HeaderBar
        className={classNames({
          "h-14 sm:h-full": scrollDirection === "down" && scrollYPosition > 0 && !debounceQuery,
        })}
        showDividerOnDesktop={false}
      >
        <HeaderBar.MainPage className="flex-col">
          <HeaderBar.MainPageHeading>{t("navigation.shop")}</HeaderBar.MainPageHeading>
        </HeaderBar.MainPage>
        <div
          className={classNames("mt-4 px-4", {
            "mb-4": !debounceQuery,
            "mb-4 ": debounceQuery,
            "-translate-y-[2.75rem] opacity-0 sm:translate-y-0 sm:opacity-100":
              scrollDirection === "down" && scrollYPosition > 0 && !debounceQuery,
            "flex gap-2 opacity-100": scrollDirection !== "down" || scrollYPosition <= 0 || debounceQuery,
          })}
        >
          <SearchInput value={debounceQuery || ""} onChange={handleDebounceQueryChange} />
        </div>
      </HeaderBar>

      <div className="mx-auto w-full max-w-xl sm:px-4 md:px-0">
        <ShopCategoryFilter
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          className={classNames("mb-4", {
            "-translate-y-[2.75rem] opacity-0 sm:translate-y-0 sm:opacity-100":
              scrollDirection === "down" && scrollYPosition > 0 && !debounceQuery,
            "opacity-100": scrollDirection !== "down" || scrollYPosition <= 0 || debounceQuery,
          })}
        />
      </div>

      <Container hasGrow={false} className={"my-4"} as="main">
        {debounceQuery ? (
          <ShopProducts query={query ?? ""} selectedCategory={selectedCategory} isTyping={isTyping} />
        ) : (
          <ShopDiscover selectedCategory={selectedCategory} />
        )}
      </Container>
    </Layout>
  );
};

export default ShopPage;
