import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ListSelectionList from "components/organisms/lists/ListSelectionList";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSelectedListsState from "state/lists/chat/selectedListsState";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  type: "include" | "exclude";
};

const ListSelection = ({ type }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const hideListIds = state?.hideListIds as string[] | undefined;

  const { includeLists, excludeLists } = useSelectedListsState();

  const [selectedLists, setSelectedLists] = useState<ChatListDto[]>(
    type === "include" ? [...includeLists] : [...excludeLists],
  );

  const handleConfirm = () => {
    if (type === "include") useSelectedListsState.setState({ includeLists: selectedLists });
    else useSelectedListsState.setState({ excludeLists: selectedLists });
    navigate(-1);
  };

  const handleListClick = (list: ChatListDto) => {
    if (selectedLists.findIndex((l) => l._id === list._id) !== -1)
      setSelectedLists(selectedLists.filter((l) => l._id !== list._id));
    else setSelectedLists([...selectedLists, list]);
  };

  const title = type === "include" ? t("newMessage.sendTo") : t("newMessage.exclude");

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{title}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="relative mt-4" hasPadding={false}>
        <div className="px-4 text-xl font-semibold md:px-0">{title}</div>
        <ListSelectionList
          onItemClick={handleListClick}
          selectedListIds={selectedLists.map((l) => l._id)}
          hideListIds={hideListIds}
          className="mt-3 px-4 md:px-0"
        />
        <div className="grow" />
        <div className="sticky bottom-0 border-t border-t-gray-100 bg-white px-4 py-4 md:px-0">
          <Button text={t("next")} onClick={handleConfirm} />
        </div>
      </Container>
    </Layout>
  );
};

export default ListSelection;
