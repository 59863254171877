import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ReactComponent as SpinnerSVG } from "svg/spinner.svg";

type Props = {
  spinnerClassName?: string;
  innerText?: string;
  innerClassName?: string;
};

const Spinner = ({ spinnerClassName = "h-12 w-12", innerText, innerClassName = "text-xs text-gray-700" }: Props) => {
  const { t } = useTranslation();
  return (
    <div role="status" className="relative flex w-full items-center justify-center">
      {innerText && (
        <div className={classNames("absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2", innerClassName)}>
          {innerText}
        </div>
      )}
      <SpinnerSVG
        className={classNames(
          "inline animate-spin fill-red-900 text-gray-200 max-w-[50%] max-h-[50%]",
          spinnerClassName,
        )}
      />
      <span className="sr-only">{t("media.loading")}...</span>
    </div>
  );
};

export default Spinner;
