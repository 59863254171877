import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SelectedListItem from "components/molecules/lists/SelectedListItem";
import { HiPlus } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";
import {useTranslation} from "react-i18next";

type Props = {
  lists: ChatListDto[];
  removeList?: (list: ChatListDto) => void;
  goToListSelection?: () => void;
} & BaseComponent;

const SelectedLists = ({ lists, removeList, goToListSelection, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames("flex flex-col gap-2", className)} data-testid={dataTestId}>
      {goToListSelection && (
        <button
          onClick={goToListSelection}
          className="flex w-full justify-between rounded-md border border-gray-100 px-3 py-2 text-red-900"
        >
          <div>{t("lists.select")}</div>
          <HiPlus className="h-5 w-5" />
        </button>
      )}
      {lists.map((list) => (
        <SelectedListItem key={list._id} list={list} onClick={removeList} />
      ))}
    </div>
  );
};

export default SelectedLists;
