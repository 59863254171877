import classNames from "classnames";
import useScrollPosition from "hooks/useScrollPosition.hook";
import { ReactNode } from "react";
import { HiChevronLeft } from "react-icons/hi2";

type Props = {
  title?: ReactNode;
  isLoading?: boolean;
  onBack?: () => void;
  showSeperator?: boolean;
};

const SignUpHeader = ({ title, isLoading, onBack, showSeperator }: Props) => {
  const { isAtPageTop } = useScrollPosition();
  return (
    <div
      className={classNames(
        "sticky top-0 mx-auto flex w-full max-w-xl flex-shrink items-center border-b bg-white p-4 md:px-0",
        {
          "border-gray-100": !isAtPageTop && showSeperator,
          "border-transparent": isAtPageTop || !showSeperator,
        },
      )}
    >
      {onBack ? (
        <button className={"flex w-20 pr-2 text-gray-500 outline-none"} onClick={onBack} type={"button"}>
          <HiChevronLeft className={"h-6 w-6"} />
        </button>
      ) : (
        <div className={"flex w-20 justify-end pl-2"}></div>
      )}

      {isLoading ? (
        <div className="flex flex-1 justify-center">
          <div className="flex h-2 w-1/4 animate-pulse rounded-md bg-gray-200"></div>
        </div>
      ) : (
        <span className={"line-clamp-1 flex-1 px-3 text-center text-sm"}>{title}</span>
      )}

      <div className={"flex w-20 justify-end pl-2"}></div>
    </div>
  );
};

export default SignUpHeader;
