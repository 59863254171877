import { useFirstline } from "@first-line/firstline-react";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import LegalSection from "components/LegalSection";
import SecondaryButton from "components/atoms/buttons/SecondaryButton";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import MenuItem from "components/basics/MenuItem";
import ProfileImage from "components/basics/ProfileImage";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useChat from "hooks/useChat.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useTranslation } from "react-i18next";
import {
  HiArrowRightOnRectangle,
  HiChevronRight,
  HiOutlineCalendarDays,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCloud,
  HiOutlineCog6Tooth,
  HiOutlineCreditCard,
  HiOutlineListBullet,
  HiOutlineShoppingBag,
  HiOutlineUsers,
  HiOutlineWallet,
} from "react-icons/hi2";
import useUserStore from "state/userState";

const CreatorMobileMenu = () => {
  const { t } = useTranslation();
  const { logout } = useFirstline();
  const navigate = useStatefulNavigate();
  const { openChatWithMaloumOfficial } = useChat();

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  return (
    <>
      <Layout>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("profileMenu.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container>
          <StatefulLink
            to={"/profile/data"}
            data-testid={"creator-menu-page"}
            className={"my-5 flex w-full items-center gap-x-3"}
          >
            <ProfileImage url={user.profilePicture?.url} className={"h-12 w-12"} />

            <div className={"flex flex-1 flex-col"}>
              <h1 className="notranslate mb-0 flex flex-shrink items-center gap-x-1 truncate text-base font-semibold">
                {user.username} {user.isVerified && <VerifiedUserIcon />}
              </h1>
              <span className={"text-sm"}>{user.email}</span>
            </div>

            <HiChevronRight className={"h-6 w-6"} />
          </StatefulLink>

          <div className="mb-4">
            <MenuItem
              handleOnClick={() => navigate("/fans")}
              Icon={HiOutlineUsers}
              text={t("fans.title")}
              data-testid={"my-earnings"}
            />
            <MenuItem
              handleOnClick={() => navigate("/payout")}
              Icon={HiOutlineCreditCard}
              text={t("earnings.title")}
              data-testid={"my-earnings"}
            />
            <MenuItem
              handleOnClick={() => navigate("/subscriptions")}
              Icon={HiOutlineWallet}
              text={t("subscription.name_plural")}
              data-testid={"my-subscriptions"}
            />
            {(user.introduction.madeProductSale || user.introduction.madeProductPurchase) && (
              <MenuItem
                handleOnClick={() => navigate("/orders")}
                Icon={HiOutlineShoppingBag}
                text={t("order.name_plural")}
                data-testid={"my-orders"}
              />
            )}
            <MenuItem
              handleOnClick={() => navigate("/vault")}
              Icon={HiOutlineCloud}
              text={t("vault.vault")}
              data-testid={"vault"}
            />
            <MenuItem
              handleOnClick={() => navigate("/lists")}
              Icon={HiOutlineListBullet}
              text={t("navigation.lists")}
              data-testid={"lists"}
            />
            <MenuItem
              handleOnClick={() => navigate("/queue")}
              Icon={HiOutlineCalendarDays}
              text={t("queue.queue")}
              data-testid={"queue"}
            />
            <MenuItem
              handleOnClick={() => navigate("/creator-settings")}
              Icon={HiOutlineCog6Tooth}
              text={t("creatorSettings.title")}
              data-testid={"notifications"}
            />
            <MenuItem
              handleOnClick={() => openChatWithMaloumOfficial()}
              Icon={HiOutlineChatBubbleLeftRight}
              text={t("navigation.support")}
              data-testid={"support-button"}
            />
          </div>
        </Container>
        <Container hasGrow={false}>
          <SecondaryButton className="flex items-center justify-center gap-2" onClick={logout} type="button">
            <HiArrowRightOnRectangle className="h-6 w-6" />
            <span>{t("logOut")}</span>
          </SecondaryButton>
        </Container>
        <div className={"my-4"}>
          <LegalSection />
        </div>
      </Layout>
    </>
  );
};

export default CreatorMobileMenu;
