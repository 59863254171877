import classNames from "classnames";
import { HiUserGroup } from "react-icons/hi2";

type UserItemVariants = "small" | "big";
type UserItemProps = {
  messageTo: string;
  size: UserItemVariants;
  className?: string;
};

const UserItem = ({ messageTo, size = "big", className }: UserItemProps) => {
  return (
    <div
      className={classNames(
        "flex items-center gap-x-3",
        { "text-base": size === "big" },
        { "text-sm": size === "small" },
        className,
      )}
    >
      <div
        className={classNames(
          "flex items-center justify-center rounded-full bg-red-500",
          { "p-2": size === "big" },
          { "p-1": size === "small" },
        )}
      >
        <HiUserGroup
          className={classNames("text-red-900", { "h-6 w-6": size === "big" }, { "h-4 w-4": size === "small" })}
        />
      </div>
      <div className="min-w-fit">{messageTo}</div>
    </div>
  );
};

export default UserItem;
