import { useState, useEffect } from "react";

const useLongPress = (callback = () => {}, ms = 500) => {
  const [startLongPress, setStartLongPress] = useState(false);
  const [wasLongPress, setWasLongPress] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (startLongPress)
      timerId = setTimeout(() => {
        setWasLongPress(true);
        callback();
      }, ms);
    else clearTimeout(timerId);
    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setStartLongPress(false);
    },
    onClickCapture: (e: MouseEvent) => {
      if (wasLongPress) {
        e.preventDefault();
        e.stopPropagation();
      }
      setWasLongPress(false);
    },
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
};

export default useLongPress;
