import TernaryButton from "components/atoms/buttons/TernaryButton";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import CreateNewFolderPopup from "components/molecules/vault/popups/CreateNewFolderPopup";
import FolderSelectList from "components/organisms/vault/FolderSelectList";
import MaloumClientContext from "contexts/MaloumClientContext";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import useUploadStore from "state/uploadState";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

const AddMediaToFolders = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { addFoldersToUploads } = useUploadStore();
  const navigate = useStatefulNavigate();

  const [createPopupOpen, setCreatePopupOpen] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const selectedMedia = useMemo(() => state?.selectedMedia ?? [], []);

  const onCreateFolder = () => setCreatePopupOpen(true);
  const onCloseNewFolderPopup = (folderId?: string) => {
    if (folderId) handleFolderSelection(folderId);
    setCreatePopupOpen(false);
  };

  const handleFolderSelection = (folderId: string) => {
    if (selectedFolders.includes(folderId)) setSelectedFolders(selectedFolders.filter((id) => id !== folderId));
    else setSelectedFolders([...selectedFolders, folderId]);
  };

  const moveMedia = async () => {
    if (selectedFolders.length === 0) return;
    await maloumClient.uploads
      .addUploadsToFolders({
        uploadIds: selectedMedia.map((media: TSelectedMedia) => media._id),
        folderIds: selectedFolders,
      })
      .then(() =>
        addFoldersToUploads(
          selectedFolders,
          selectedMedia.map((media: TSelectedMedia) => media._id),
        ),
      );
  };

  const addMediaToFoldersMutation = useMutation(moveMedia, {
    onSuccess: () => navigate('/vault', { state: {...state, selectedMedia: [], folderId: undefined}, replace: true }),
  });

  // if no media is selected, redirect to vault
  if (selectedMedia.length === 0) return <StatefulNavigate to="/vault" />;

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="md:-ml-6">
            <HeaderBar.Cancel onClick={() => navigate('/vault', { state: {...state}, replace: true })}/>
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("vault.moveMedia.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0 min-w-fit md:-mr-4">
            <TernaryButton variant="red" size="sm" onClick={onCreateFolder}>
              {t("vault.newFolder.title")}
            </TernaryButton>
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false} noDesktopPadding>
        <div className="px-4 md:px-0">
          <div className="mt-4 text-xl font-semibold">{t("vault.moveMedia.title")}</div>
          <FolderSelectList
            selectedFolders={selectedFolders}
            onFolderSelection={handleFolderSelection}
            className="mt-4"
          />
        </div>
        <div className="grow" />
        <div className="sticky bottom-0 z-[10] border-t border-gray-100 bg-white px-4 py-4 md:px-0">
          <Button
            text={t("vault.moveMedia.add")}
            onClick={addMediaToFoldersMutation.mutate}
            disabled={addMediaToFoldersMutation.isLoading}
          />
        </div>
      </Container>

      <CreateNewFolderPopup isOpen={createPopupOpen} close={onCloseNewFolderPopup} />
    </Layout>
  );
};

export default AddMediaToFolders;
