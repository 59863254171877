import classNames from "classnames";
import { HiLockClosed, HiOutlinePhoto, HiOutlineVideoCamera } from "react-icons/hi2";

type TChatProductType = "video" | "picture" | "lock" | undefined;

type ContentTypeIconProps = {
  type: TChatProductType;
  className?: string;
};

const ContentTypeIcon = ({ type, className }: ContentTypeIconProps) => {
  switch (type) {
    case "picture":
      return <HiOutlinePhoto className={classNames({ "h-4 w-4 text-gray-700": !className }, className)} />;
    case "video":
      return <HiOutlineVideoCamera className={classNames({ "h-4 w-4 text-gray-700": !className }, className)} />;
    case "lock":
      return <HiLockClosed className={classNames({ "h-4 w-4 text-gray-700": !className }, className)} />;
    default:
      return <></>;
  }
};

export default ContentTypeIcon;
