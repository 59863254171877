import { ChatProductDto, MessageContentDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import UserItem from "components/atoms/lists/UserItem";
import Button from "components/basics/Button";
import HeaderBar from "components/layouts/HeaderBar";
import ChatPriceSettingPopup from "components/molecules/popup/ChatPriceSettingPopup";
import PopUp from "components/popup/PopUp";
import MaloumClientContext from "contexts/MaloumClientContext";
import useCurrency from "hooks/useCurrency.hook";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineCalendarDays,
  HiOutlineCheckCircle,
  HiOutlinePaperAirplane,
  HiOutlinePencil,
  HiOutlinePhoto,
  HiPlus,
  HiPlusSmall,
} from "react-icons/hi2";
import { ReactComponent as SendToAllSVG } from "svg/undraw_community.svg";
import { ReactComponent as FirstConversationSVG } from "svg/undraw_getting_coffee.svg";
import AutoGrowTextarea from "../../components/basics/AutoGrowTextarea";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";
import useUserStore from "../../state/userState";
import { formatSchedulingDate } from "helper/dateAndTimeHelper";
import DatePickerPopup from "components/post/DatePickerPopup";
import Media from "components/media/Media";
import MediaRemoveButton from "components/media/MediaRemoveButton";
import { useMutation } from "react-query";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useSelectedListsState from "state/lists/chat/selectedListsState";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import { useLocation } from "react-router-dom";
import { TSelectedMedia } from "types/vault/selectedMedia.type";

const BroadcastChat = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const { includeLists, excludeLists } = useSelectedListsState();

  const { maloumClient } = useContext(MaloumClientContext);

  const user = useUserStore((state) => state.user);

  // location state
  const { pathname, state } = useLocation();

  // text message
  const [message, setMessage] = useState(state?.data?.message ?? "");

  // price
  const [digitalProductPrice, setDigitalProductPrice] = useState<number | undefined>(state?.data?.digitalProductPrice);
  const { displayCurrency } = useCurrency();

  // popups
  const [pricePopupIsOpen, setPricePopupIsOpen] = useState(false);
  const [isSendToAllPopUpOpen, setIsSendToAllPopUpOpen] = useState(false);
  const [isSuccessfullySentToAllPopUpOpen, setIsSuccessfullySentToAllPopUpOpen] = useState(false);

  // media
  const selectedMedia = (state?.selectedMedia ?? []) as TSelectedMedia[];

  // scheduling
  const [selectedDate, setSelectedDate] = useState(state?.data?.scheduledAt ?? new Date().toISOString());

  const uploadMedia = () => {
    // stop user from uploading media if unverified creator and redirect to verification page
    if (user.isCreator && !user.isVerified) navigate("/verification/start");
    // start media selection
    else {
      // start media selection
      const data = { message: message, digitalProductPrice: digitalProductPrice, scheduledAt: selectedDate };
      navigate("/vault", {
        state: { redirectTo: pathname, data, allowMultipleSelect: true, selectedMedia },
        replace: true,
      });
    }
  };

  const handleSubmitMessage = () => {
    if (selectedMedia.length > 0 || message || message.trimStart() || digitalProductPrice)
      setIsSendToAllPopUpOpen(true);
  };

  const sendBroadcastMessage = async () => {
    let messageType;
    if (selectedMedia.length > 0 && !digitalProductPrice) messageType = MessageContentDto.type.MEDIA;
    else if (selectedMedia.length > 0 && digitalProductPrice) messageType = MessageContentDto.type.CHAT_PRODUCT;
    else messageType = MessageContentDto.type.TEXT;

    let chatProductId;
    if (digitalProductPrice) {
      chatProductId = await maloumClient.chatProducts
        .create({
          mediaIds: selectedMedia.map((m) => m._id),
          sentTo: [user._id],
          netAmount: digitalProductPrice,
        })
        .then((chatProduct: ChatProductDto) => chatProduct._id);
    }

    const broadcastMessage = {
      includeFromLists: includeLists.map((list) => list._id),
      excludeFromLists: excludeLists.map((list) => list._id),
      content: {
        type: messageType as MessageContentDto.type,
        ...(message?.trimStart() && { text: message }),
        ...(chatProductId !== undefined && { chatProductId: chatProductId }),
        ...(chatProductId === undefined && selectedMedia.length > 0 && { mediaIds: selectedMedia.map((m) => m._id) }),
      },
    };

    // reset everything
    const reset = () => {
      setMessage("");
      setIsSendToAllPopUpOpen(false);
      setIsSuccessfullySentToAllPopUpOpen(true);
    };
    if (new Date(selectedDate) > new Date())
      return await maloumClient.scheduledBroadcasts
        .scheduleBroadcast({
          ...broadcastMessage,
          scheduledAt: new Date(selectedDate).toUTCString(),
        })
        .then(reset);
    else return await maloumClient.chats.broadcastMessage(broadcastMessage).then(reset);
  };

  const sendBroadcastMessageMutation = useMutation("sendBroadcastMessage", sendBroadcastMessage);

  const messageScheduled = new Date(selectedDate) > new Date();

  const messageSentOrScheduledText = useMemo(() => {
    if (messageScheduled)
      return t("newMessage.successfullyScheduledDescription", { date: formatSchedulingDate(new Date(selectedDate)) });
    return t("newMessage.successfullySentDescription");
  }, [messageScheduled, selectedDate]);

  // navigate to /chat if no includeList is provided
  if (includeLists.length === 0) return <StatefulNavigate to="/chat/new" />;

  // TODO: clean up and split into components
  // TODO: reuse some components from Chat
  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <UserItem messageTo={t("newMessage.allSelectedLists")} size="small" className="justify-center" />
          </HeaderBar.Center>
          <HeaderBar.Right></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="max-h-full bg-white" hasPadding={false} as="section">
        {/* START: Placeholder bg */}
        <div className="grow overflow-auto">
          <div className="h-full max-h-full overflow-auto">
            <div className="messages-container relative flex h-full flex-col items-center justify-center px-4">
              <div className="absolute left-0 top-[10rem]" />
              <FirstConversationSVG width="60%" className="my-4" />
              <span className="text-gray-700">{t("newMessage.firstConversation")}</span>
            </div>
          </div>
        </div>
        {/* END: Placeholder bg */}
        <div
          className={classNames("relative bottom-0 bg-white px-4 pb-4", {
            "pt-2": selectedMedia.length === 0,
          })}
        >
          <ScheduleMassMessage
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            className="absolute -top-2 w-[calc(100%-2rem)] -translate-y-full"
          />
          {selectedMedia.length > 0 && (
            <div className="rounded-t-md border-l border-r border-t border-gray-100">
              <div className="relative mb-2 flex w-full justify-between px-2 pt-2">
                <div className="text-xs text-gray-700">{`${selectedMedia.length}/${selectedMedia.length}`}</div>
                {user.isCreator && (
                  <TernaryButton
                    size="xs"
                    className="group absolute right-1 top-[-2px] flex items-center gap-1"
                    onClick={() => setPricePopupIsOpen(true)}
                  >
                    {digitalProductPrice === undefined ? (
                      <>
                        {t("chatProduct.addPrice")}
                        <HiPlusSmall className="h-4 w-4 text-gray-700 group-hover:text-gray-500 group-active:text-gray-800" />
                      </>
                    ) : (
                      <>
                        {t("chatProduct.priceToView")}: {displayCurrency(digitalProductPrice)}
                        <HiOutlinePencil className="h-4 w-4 text-gray-700 group-hover:text-gray-500 group-active:text-gray-800" />
                      </>
                    )}
                  </TernaryButton>
                )}
              </div>
              <div className="flex w-fit max-w-full gap-2 overflow-x-auto bg-white px-2 pb-2">
                {selectedMedia.map((media) => (
                  <div className="relative h-[8.5rem] w-[8.5rem] shrink-0" key={media._id}>
                    <Media media={media} thumbnail={media.thumbnail} className="rounded-md" />
                    <MediaRemoveButton selectedMediaId={media._id} data-testid="remove-media" />
                  </div>
                ))}
                <button
                  className="flex h-[8.5rem] min-w-[8.5rem] items-center justify-center rounded-md border border-dashed border-red-900"
                  onClick={uploadMedia}
                >
                  <HiPlus className="h-10 w-10 fill-red-900" />
                </button>
              </div>
            </div>
          )}
          <div className="flex w-full items-end gap-2">
            {selectedMedia.length === 0 && (
              <button className="px-0.5 py-2" onClick={uploadMedia}>
                <HiOutlinePhoto className="h-6 w-6" />
              </button>
            )}

            <div className="flex grow flex-col">
              <div
                className={classNames("flex grow flex-wrap items-end rounded-b-md bg-gray-100 pr-1", {
                  "rounded-t-md": selectedMedia.length === 0,
                })}
              >
                <AutoGrowTextarea
                  placeholder={t("message.placeholder")}
                  maxRows={4}
                  value={message}
                  onChange={(value: string) => setMessage(value)}
                  maxLength={2000}
                  className="flex w-0 grow border-none bg-transparent px-3 py-2 text-base outline-none !ring-0 placeholder:text-gray-400 focus:outline-none"
                  onSubmit={handleSubmitMessage}
                />
                <button onClick={handleSubmitMessage} className="p-2.5">
                  <HiOutlinePaperAirplane className="h-5 w-5 cursor-pointer text-lg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ChatPriceSettingPopup
          isOpen={pricePopupIsOpen}
          setIsOpen={setPricePopupIsOpen}
          onDigitalProductPriceChange={setDigitalProductPrice}
          digitalProductNetPrice={digitalProductPrice}
        />
        <PopUp
          isOpen={isSendToAllPopUpOpen}
          onClose={() => setIsSendToAllPopUpOpen(false)}
          data-testid="send-to-all-popup"
          title={t("newMessage.sendToAllSelectedLists") ?? ""}
        >
          <div className={"flex flex-col items-center justify-center gap-4"}>
            <SendToAllSVG width="50%" />
            <span className={"text-gray-700"}>{t("newMessage.sendToAllSelectedListsDescription") ?? ""}</span>
          </div>
          <div className="mt-3 flex gap-x-2">
            <Button
              variant="secondary"
              onClick={() => setIsSendToAllPopUpOpen(false)}
              text={t("newMessage.editMessage")}
              disabled={sendBroadcastMessageMutation.isLoading}
            />
            <Button
              onClick={sendBroadcastMessageMutation.mutate}
              data-testid="send-to-all"
              text={messageScheduled ? t("newMessage.schedule") : t("newMessage.send")}
              disabled={sendBroadcastMessageMutation.isLoading}
            />
          </div>
        </PopUp>
        <PopUp
          isOpen={isSuccessfullySentToAllPopUpOpen}
          onClose={() => setIsSuccessfullySentToAllPopUpOpen(false)}
          title={t("newMessage.successfullySent") || ""}
        >
          <div className={"mb-6 mt-4 flex flex-col items-center justify-center gap-4"}>
            <HiOutlineCheckCircle className="h-16 w-16 text-red-900" />
            <span className={"text-gray-700"}>{messageSentOrScheduledText}</span>
          </div>
          <Button onClick={() => navigate("/chat")} text={t("gotIt")} />
        </PopUp>
      </Container>
    </Layout>
  );
};

export default BroadcastChat;

type ScheduleMassMessageProps = {
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  className?: string;
  "data-testid"?: string;
};

const ScheduleMassMessage = ({
  selectedDate,
  setSelectedDate,
  className,
  "data-testid": dataTestId,
}: ScheduleMassMessageProps) => {
  const { t } = useTranslation();
  const [showSchedulingPopUp, setShowSchedulingPopUp] = useState(false);

  return (
    <>
      <button
        className={classNames(
          "flex justify-between gap-2 rounded-md border border-gray-100 bg-white px-3 py-2",
          className,
        )}
        onClick={() => setShowSchedulingPopUp(true)}
        data-testid={dataTestId}
      >
        <HiOutlineCalendarDays className="h-6 w-6" />

        <div>
          <div className="text-left text-sm text-gray-700">{t("publishingDate")}</div>
          <div className="text-left">{formatSchedulingDate(new Date(selectedDate))}</div>
        </div>
        <div className="grow" />
        <span className="text-sm text-red-900">{t("change")}</span>
      </button>
      {showSchedulingPopUp && (
        <DatePickerPopup
          onClose={() => setShowSchedulingPopUp(false)}
          onSave={(date) => {
            setSelectedDate(date);
          }}
          savedDate={selectedDate}
          initialDate={new Date().toISOString()}
        />
      )}
    </>
  );
};
