import Subscribers from "pages/fans/Subscribers";
import EditSubscriptionPrice from "./EditSubscriptionPrice";

const IncomingSubscriptions = () => {
  return (
    <div className="mt-4">
      <EditSubscriptionPrice />
      <Subscribers className="mt-4" />
    </div>
  );
};

export default IncomingSubscriptions;
