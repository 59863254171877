import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import BaseSelectionItem, { LoadingBaseSelectionItem } from "components/atoms/base/item/BaseSelectionItem";
import ListName from "components/atoms/lists/ListName";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  list: ChatListDto;
  onItemClick: (list: ChatListDto) => void;
  selected: boolean;
} & BaseComponent;

const ListItemSelect = ({ list, onItemClick, selected, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  const handleOnClick = () => onItemClick(list);

  return (
    <BaseSelectionItem isSelected={selected} onClick={handleOnClick} className={className} data-testid={dataTestId}>
      <div className="flex flex-col gap-0.5 text-left">
        <ListName list={list} className="w-full truncate" />
        <div className="text-xs">{t("lists.item.members", { count: list.totalMemberCount })}</div>
      </div>
    </BaseSelectionItem>
  );
};

const LoadingListItemSelect = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return <LoadingBaseSelectionItem className={classNames("h-[3.75rem]", className)} data-testid={dataTestId} />;
};

export default ListItemSelect;
export { LoadingListItemSelect };
