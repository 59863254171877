import { OrderDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import MyInfiniteScroll from "../layouts/MyInfiniteScroll";
import OrderSection from "./OrderSection";

type SoldOrdersData = { PENDING: OrderDto[]; SHIPPED: OrderDto[]; DONE: OrderDto[] };

type SoldOrdersContextData = {
  data: SoldOrdersData;
  next: string | null;
};

type TNewItems = {
  data: OrderDto[];
  next: string | null;
};

const SoldOrders = () => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const [data, setData] = useState<SoldOrdersContextData>({
    next: null,
    data: { PENDING: [], SHIPPED: [], DONE: [] },
  });

  const loadNext = useCallback((next: string | null) => maloumClient.orders.sellerOverview(next), [maloumClient]);

  const setNewItems = useCallback(
    (newItems: TNewItems) => {
      const orderedOrders = newItems.data.filter((item) => item.status === OrderDto.status.PREPARING);
      const shippedOrders = newItems.data.filter((item) => item.status === OrderDto.status.SHIPPED);
      const doneOrders = newItems.data.filter((item) => item.status === OrderDto.status.DONE);

      setData({
        next: newItems.next,
        data: {
          PENDING: [...(data.data?.PENDING ?? []), ...orderedOrders],
          SHIPPED: [...(data.data?.SHIPPED ?? []), ...shippedOrders],
          DONE: [...(data.data?.DONE ?? []), ...doneOrders],
        },
      });
    },
    [data, setData],
  );
  // Iterate over grouped by Status Orders and return OrderSections
  // TODO: fix ts issue
  const createItemComponent = useCallback(
    (item: SoldOrdersData) =>
      Object.keys(item).map((key: "PENDING" | "SHIPPED" | "DONE") => (
        <OrderSection key={key} orders={item[key]} heading={t(`order.status.${key}`)} />
      )),
    [],
  );

  const createLoadingItemComponent = useCallback((key: number) => <OrderSection isLoading key={key} />, []);
  const NoResultsView = useCallback(() => <div className={"py-4 text-center"}>{t("order.noSales")}</div>, []);

  return (
    <div>
      <MyInfiniteScroll
        loadNext={loadNext}
        data={data}
        setData={setNewItems}
        createItemComponent={createItemComponent}
        createLoadingItemComponent={createLoadingItemComponent}
        NoResultsView={NoResultsView}
        loadingItemsCount={3}
        loadAnyway
      />
    </div>
  );
};

export default SoldOrders;
