import { CommentDto, PostDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import { generateTimeTag } from "helper/dateAndTimeHelper";
import useChat from "hooks/useChat.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useState } from "react";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import useUserStore from "state/userState";
import ProfileImage, { ProfileImageWithButton, ProfileImageWithLink } from "../../components/basics/ProfileImage";
import CommentActionPopup from "./CommentActionPopup";

type Props = {
  post?: PostDto;
  comment?: CommentDto;
  isLoading?: boolean;
};

const CommentItem = ({ post, comment, isLoading }: Props) => {
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const contentFromCreator = comment?.user._id === post?.createdBy?._id;
  const isUsersComment = comment?.user._id === user?._id;
  const { openChat } = useChat();

  const [isOpen, setOpen] = useState(false);
  const timeTag = generateTimeTag(new Date(comment?.createdAt || 0));

  const handleChat = useCallback(async () => {
    if (!user.isVerified && !comment?.user.isVerified) return;
    if (user._id !== comment?.user._id && comment?.user._id !== undefined) {
      await openChat(comment?.user._id);
    } else {
      navigate(`/profile`);
    }
  }, [comment?.user]);

  if (isLoading || !comment || !post) {
    return (
      <div className={"flex flex-1 animate-pulse gap-3"}>
        <div>
          <ProfileImage isLoading className={"h-12 w-12"} />
        </div>

        <div className={"flex flex-1 flex-col gap-1.5"}>
          <div className={"flex items-center justify-between gap-3"}>
            <span className={"h-3 w-40 rounded-full bg-gray-200"} />
            <span className={"-m-2 p-2"}>
              <HiEllipsisHorizontal className={"h-6 w-6 text-gray-300"} />
            </span>
          </div>
          <span className={"h-3 w-64 rounded-full bg-gray-200"} />
          <span className={"h-3 w-12 rounded-full bg-gray-200"} />
        </div>
      </div>
    );
  }

  const profileImageProps = {
    url: user.profilePictureThumbnail?.url,
    uploadStatus: user.profilePictureThumbnail?.uploadStatus,
    className: "h-12 w-12",
  };

  return (
    <>
      <div className={"flex flex-1 gap-3"}>
        <div>
          {comment.user.isCreator && comment.user.isVerified ? (
            <ProfileImageWithLink
              url={comment.user.profilePictureThumbnail?.url}
              uploadStatus={comment.user.profilePictureThumbnail?.uploadStatus}
              className={"h-12 w-12"}
              to={`/creator/${comment.user.username}`}
            />
          ) : (
            <ProfileImageWithButton
              onClick={handleChat}
              url={comment.user.profilePictureThumbnail?.url}
              uploadStatus={comment.user.profilePictureThumbnail?.uploadStatus}
              className={"h-12 w-12"}
            />
          )}
        </div>
        <div className={"flex flex-1 flex-col"}>
          <div className={"flex justify-between"}>
            {comment.user.isCreator && comment.user.isVerified ? (
              <StatefulLink
                className={"notranslate flex items-center gap-x-1"}
                to={`/creator/${comment.user.username}`}
              >
                {comment.user.username} {comment.user.isVerified && <VerifiedUserIcon />}
              </StatefulLink>
            ) : (
              <button className={"notranslate"} onClick={handleChat}>
                {comment.user.username}
              </button>
            )}
            <span className={"-m-2 cursor-pointer p-2"} onClick={() => setOpen(true)}>
              <HiEllipsisHorizontal className={"h-6 w-6"} />
            </span>
          </div>
          <span className={"notranslate break-words text-sm font-light"}>{comment.text}</span>
          <span className={"text-xs text-gray-500"}>{timeTag}</span>
        </div>
      </div>

      <CommentActionPopup comment={comment} isOpen={isOpen} handleClose={() => setOpen(false)} post={post} />
    </>
  );
};

export default CommentItem;
