import { Tab } from "@headlessui/react";
import { PostDto, ProductDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import MyTab from "components/basics/MyTab";
import SearchInput from "components/molecules/basics/SearchInput";
import Discover from "components/Discover";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import SearchCreator from "components/search/SearchCreator";
import SearchPost from "components/search/SearchPost";
import SearchProduct from "components/search/SearchProduct";
import useScrollPosition from "hooks/useScrollPosition.hook";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineAdjustmentsHorizontal,
  HiOutlineRectangleGroup,
  HiOutlineShoppingBag,
  HiOutlineUser,
  HiRectangleGroup,
  HiShoppingBag,
  HiUser,
} from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";
import useSearchStore from "state/searchState";

const SearchPage = () => {
  const { t } = useTranslation();
  const { scrollDirection, scrollYPosition } = useScrollPosition();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [debounceQuery, setDebounceQuery] = useState(query);
  const [isTyping, setIsTyping] = useState(false);

  const tabName = searchParams.get("tab");
  const tabs = ["posts", "creators", "products"];
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);

  const setSearchResults = useSearchStore<(value: { next: string | null; data: PublicUserDto[] }) => void>(
    (state) => state.setSearchResults,
  );
  const setProductsResults = useSearchStore<(value: { next: string | null; data: ProductDto[] }) => void>(
    (state) => state.setProductsResults,
  );
  const setPostsResults = useSearchStore<(value: { next: string | null; data: PostDto[] }) => void>(
    (state) => state.setPostsResults,
  );

  const onTabChange = useCallback(
    (tab: number) => {
      // POSTHOG: emmit change search tab event
      // posthog?.capture("change-search-tab", { tab: tab });
      setSelectedIndex(tab);
      setSearchParams({ query: query || "", tab: tabs[tab] }, { replace: true });
      useSearchStore.setState({ currentTab: tabs[tab] });
    },
    [setSelectedIndex, query, tabs, setSearchParams],
  );

  const handleDebounceQueryChange = useCallback((value: string) => setDebounceQuery(value), [setDebounceQuery]);

  // if no changes happen to debounceQuery for XXXms, then query is set
  useEffect(() => {
    // Prevent initial render from changing any value
    if (debounceQuery && debounceQuery.trim().length > 0) {
      setIsTyping(true);
      const timer = setTimeout(() => {
        setSearchParams({ query: debounceQuery, tab: tabs[selectedIndex] }, { replace: true });

        // Reset states because query changed
        if (query !== debounceQuery) {
          setProductsResults({ next: null, data: [] });
          setSearchResults({ next: null, data: [] });
          setPostsResults({ next: null, data: [] });
        }

        setIsTyping(false);
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    } else if (debounceQuery === "") {
      setSearchParams({}, { replace: true });
    }
  }, [debounceQuery]);

  return (
    <Layout>
      <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
        <HeaderBar
          className={classNames("overflow-hidden border-b-0 duration-200", {
            "h-14 sm:h-fit": scrollDirection === "down" && scrollYPosition > 0 && !debounceQuery,
            "h-32 sm:h-fit": (scrollDirection === "up" || scrollYPosition <= 0) && !debounceQuery,
            "h-fit sm:h-fit": debounceQuery,
          })}
          showDividerOnDesktop={false}
          possitionClassNames={classNames({ sticky: debounceQuery, "fixed sm:sticky": !debounceQuery })}
        >
          <HeaderBar.MainPage className={classNames({ "hidden sm:flex": debounceQuery })}>
            <HeaderBar.MainPageHeading>{t("navigation.discovery")}</HeaderBar.MainPageHeading>

            <HeaderBar.MainPageActions>
              <HeaderBar.Link to="/discover/preferences">
                <HiOutlineAdjustmentsHorizontal className={"h-6 w-6"} />
              </HeaderBar.Link>
            </HeaderBar.MainPageActions>
          </HeaderBar.MainPage>
          <div
            className={classNames("mt-4 px-4", {
              "mb-4": !debounceQuery,
              "mb-0 ": debounceQuery,
              "hidden -translate-y-[2.75rem] sm:block sm:translate-y-0":
                scrollDirection === "down" && scrollYPosition > 0 && !debounceQuery,
              "flex gap-2 opacity-100": scrollDirection !== "down" || scrollYPosition <= 0 || debounceQuery,
            })}
          >
            <SearchInput
              value={debounceQuery || ""}
              onChange={handleDebounceQueryChange}
              placeholder={t("discover.search") || ""}
            />
          </div>

          <Tab.List className={classNames("justify-around md:px-4", { flex: debounceQuery, hidden: !debounceQuery })}>
            <MyTab
              title={t("posts.name")}
              defaultIcon={<HiOutlineRectangleGroup className={"mr-3 h-5 w-5"} />}
              activeIcon={<HiRectangleGroup className={"mr-3 h-5 w-5"} />}
            />
            <MyTab
              title={t("creators")}
              defaultIcon={<HiOutlineUser className={"mr-3 h-5 w-5"} />}
              activeIcon={<HiUser className={"mr-3 h-5 w-5"} />}
            />
            <MyTab
              title={t("products")}
              defaultIcon={<HiOutlineShoppingBag className={"mr-3 h-5 w-5"} />}
              activeIcon={<HiShoppingBag className={"mr-3 h-5 w-5"} />}
            />
          </Tab.List>
        </HeaderBar>
        <Container hasPadding={false} as="main">
          {/* SHOW DISCOVER */}
          {!debounceQuery && (
            <div className="pt-32 sm:pt-0">
              <Discover data-testid={"discover-page"} />
            </div>
          )}

          <Tab.Panels className={"flex flex-1 flex-col"}>
            <Tab.Panel>{debounceQuery && <SearchPost query={query} key={query} isTyping={isTyping} />}</Tab.Panel>

            <Tab.Panel className={"flex-1 overflow-y-auto overflow-x-hidden"}>
              {debounceQuery && <SearchCreator query={query} key={query} isTyping={isTyping} />}
            </Tab.Panel>
            <Tab.Panel>
              <Container hasGrow={false}>
                {debounceQuery ? <SearchProduct query={query} key={query} isTyping={isTyping} /> : <></>}
              </Container>
            </Tab.Panel>
          </Tab.Panels>
        </Container>
      </Tab.Group>
    </Layout>
  );
};

export default SearchPage;
