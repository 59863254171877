import classNames from "classnames";
import useScheduledPosts from "hooks/useScheduledPosts.hook";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PostItem, { PostItemLoading } from "../post/PostItem";
import { useTranslation } from "react-i18next";
import EmptyScheduledPostsIllustration from "components/atoms/illustration/EmptyScheduledPostsIllustration";
import Button from "components/basics/Button";
import { HiOutlinePlus } from "react-icons/hi2";
import { PostDto } from "@neolime-gmbh/api-gateway-client";
import { formatDateWithStoredLanguageAndDay } from "helper/dateAndTimeHelper";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  className?: string;
  "data-testid"?: string;
};

const ScheduledPostsList = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const navigate = useStatefulNavigate();
  const { scheduledPosts, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, removePost, updatePost } =
    useScheduledPosts();

  const scheduledPostsList = scheduledPosts?.pages.flatMap((p) => p.data) || [];

  const groupedByDay = scheduledPostsList.reduce((acc: Map<string, PostDto[]>, post) => {
    const date = new Date(post.publishedAt);
    const day = date.toISOString();

    if (!acc.has(day)) acc.set(day, [post]);
    else acc.set(day, [...acc.get(day)!, post]);
    return acc;
  }, new Map<string, PostDto[]>());

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading]);

  return (
    <div className={classNames("relative flex flex-col gap-2", className)} data-testid={dataTestId}>
      {Array.from(groupedByDay).map(([date, posts]) => (
        <PostsForDay
          date={new Date(date)}
          posts={posts}
          deletedCallback={removePost}
          updateCallback={updatePost}
          key={date}
        />
      ))}
      {(isLoading || isFetchingNextPage) && (
        <>
          {Array(5)
            .fill(null)
            .map((_, i) => (
              <PostItemLoading key={i} data-testid="post-item" />
            ))}
        </>
      )}
      {!isLoading && !isFetchingNextPage && scheduledPostsList.length === 0 && (
        <div className="flex w-full grow items-center justify-center">
          <div>
            <EmptyScheduledPostsIllustration />
            <div className="pt-6 text-center text-sm text-gray-500">{t("queue.emptyPosts")}</div>
          </div>
        </div>
      )}
      <div className="absolute bottom-[10rem]" ref={ref} />
      <div className="sticky bottom-0 z-[49] border-t border-t-gray-100 bg-white p-4 pb-6">
        <Button text={t("queue.schedulePost")} IconBack={HiOutlinePlus} onClick={() => navigate("/post/create")} />
      </div>
    </div>
  );
};

export default ScheduledPostsList;

type PostsForDayProps = {
  date: Date;
  posts: PostDto[];
  deletedCallback: (postId: string) => void;
  updateCallback: (post: PostDto) => void;
  className?: string;
  "data-testid"?: string;
};

const PostsForDay = ({
  date,
  posts,
  deletedCallback,
  updateCallback,
  className,
  "data-testid": dataTestId,
}: PostsForDayProps) => {
  return (
    <div className={classNames("pb-8", className)} data-testid={dataTestId}>
      <div className="px-4 text-xl font-semibold md:px-0">{formatDateWithStoredLanguageAndDay(date)}</div>
      {posts.map((p) => (
        <PostItem post={p} deletedCallback={() => deletedCallback(p._id)} updateCallback={updateCallback} key={p._id} />
      ))}
    </div>
  );
};
