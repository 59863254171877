import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import TextInput from "components/basics/TextInput";
import PopUp from "components/popup/PopUp";
import { Form, Formik } from "formik";
import useVaultFolders from "hooks/vault/useVaultFolders.hook";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import * as Yup from "yup";

type Props = {
  folder: FolderDto;
} & PopUpComponent &
  BaseComponent;

type RenameVaultFolderType = {
  folderName: string;
};

const schema = Yup.object().shape({
  folderName: Yup.string().min(1, "").max(65, "").required("Required"),
});

const RenameVaultFolderPopup = ({ folder, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { renameFolderMutation } = useVaultFolders("", false);

  const setValuesRef = useRef<(values: RenameVaultFolderType) => void>();

  const handleOnSubmit = (values: RenameVaultFolderType) => {
    renameFolderMutation.mutate({ folderId: folder._id, newFolderName: values.folderName.trim() }, { onSuccess: onClose });
  };

  useEffect(() => {
    if (!setValuesRef.current) return;
    setValuesRef.current({ folderName: folder.name });
  }, [folder]);

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("vault.folderPopup.rename") ?? ""} data-testid={dataTestId}>
      <Formik initialValues={{ folderName: folder.name }} validationSchema={schema} onSubmit={handleOnSubmit}>
        {(formik) => {
          // set formik setValues function to ref
          setValuesRef.current = formik.setValues;

          // return form
          return (
            <Form className={classNames("mt-4", className)}>
              <TextInput
                name="folderName"
                maxLength={65}
                placeholder={t("vault.newFolder.title") ?? ""}
                className="text-left"
                label={t("vault.newFolder.label")}
                wrapperClassName="text-left"
              />
              <Button type="submit" className="mt-6" text={t("save")} disabled={renameFolderMutation.isLoading} />
            </Form>
          );
        }}
      </Formik>
    </PopUp>
  );
};

export default RenameVaultFolderPopup;
