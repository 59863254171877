import { PostDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import useDiscoveryStore from "state/discoveryState";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useDiscovery = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();
  const discoveryRandomQuery = useDiscoveryStore((state) => state.discoveryRandomQuery);

  const updatePost = (postToChange: PostDto) => {
    queryClient.setQueryData(["discovery", discoveryRandomQuery], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.map((post: PostDto) => (post._id === postToChange._id ? postToChange : post)),
          };
        }),
      };
    });
  };

  const removePost = (postId: string) => {
    queryClient.setQueryData(["discovery", discoveryRandomQuery], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((post: PostDto) => post._id !== postId),
          };
        }),
      };
    });
  };

  const fetchDiscovery = async (next: any) => {
    /** discoveryRandomQuery is needed to create new backend call to show different discovery results on reload */
    return maloumClient.content.discovery(discoveryRandomQuery, next.pageParam, 30);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["discovery", discoveryRandomQuery],
    fetchDiscovery,
    {
      getPreviousPageParam: (firstPage) => firstPage.next,
      getNextPageParam: (lastPage) => (lastPage.data.length > 0 ? lastPage.next : null),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    updatePost,
    removePost,
  };
};

export default useDiscovery;
