import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  list: ChatListDto;
} & BaseComponent;

const ListName = ({ list, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const listName = list.isManaged ? t(`lists.managedLists.${list.name}`) : list.name;

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      {listName}
    </div>
  );
};

export default ListName;
