import classNames from "classnames";
import * as Yup from "yup";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import Button from "components/basics/Button";
import Textarea from "components/basics/Textarea";
import PopUp from "components/popup/PopUp";
import { Form, Formik } from "formik";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowRight, HiOutlineCheckCircle } from "react-icons/hi2";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useMutation } from "react-query";

type CategorySuggestionPopUpProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CategorySuggestionPopUp = ({ isOpen, onClose }: CategorySuggestionPopUpProps) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const handleOnSubmit = useCallback(
    (data: { suggestedCategory: string }) => maloumClient.categories.survey({ category: data.suggestedCategory }),
    [maloumClient],
  );

  const submitNewCategoryMutation = useMutation(handleOnSubmit);

  const submitted = submitNewCategoryMutation.isSuccess;

  return (
    <PopUp
      title={(submitted ? t("successfullySubmited") : t("categorySubmission.submitACategory")) ?? ""}
      isOpen={isOpen}
      onClose={onClose}
    >
      {submitted && <HiOutlineCheckCircle className="mx-auto my-2 h-16 w-16 text-red-900" />}
      <div className="mt-2 text-left">
        {submitted ? t("categorySubmission.successDesc") : t("categorySubmission.popupDesc")}
      </div>
      {submitted ? (
        <Button text={t("gotIt")} className="mt-6" onClick={onClose} />
      ) : (
        <Formik
          initialValues={{ suggestedCategory: "" }}
          validationSchema={Yup.object().shape({
            suggestedCategory: Yup.string()
              .max(300, ({ max }) => t("validation.max", { max: max }))
              .required(t("categorySubmission.provideSuggestion") ?? ""),
          })}
          onSubmit={(data) => submitNewCategoryMutation.mutate(data)}
        >
          <Form className="mt-2">
            <Textarea
              label={t("categorySubmission.category")}
              name="suggestedCategory"
              className={"mb-0 text-left"}
              type="text"
              maxLength={300}
              placeholder={t("categorySubmission.category") ?? ""}
            />
            <Button type="submit" text={t("submit")} className="mt-6" disabled={submitNewCategoryMutation.isLoading} />
          </Form>
        </Formik>
      )}
    </PopUp>
  );
};

type Props = {
  className?: string;
  "data-testid"?: string;
};

const CategorySubmissionSection = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const [popUpOpen, setPopUpOpen] = useState(false);

  return (
    <>
      <div className={classNames("flex flex-col items-center", className)} data-testid={dataTestId}>
        <span>{t("categorySubmission.description")}</span>
        <TernaryButton variant="red" className="flex items-center gap-2" onClick={() => setPopUpOpen(true)}>
          {t("categorySubmission.submitACategory")} <HiArrowRight />
        </TernaryButton>
      </div>
      <CategorySuggestionPopUp isOpen={popUpOpen} onClose={() => setPopUpOpen(false)} />
    </>
  );
};

export default CategorySubmissionSection;
