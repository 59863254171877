import { Dialog } from "@headlessui/react";
import { CountryDto } from "@neolime-gmbh/api-gateway-client";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import { useField } from "formik";
import SelectCountryPage from "pages/SelectCountry";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  name: string;
  allowOther?: boolean;
};

//TODO: to implement more widely
const SelectCountryInput = ({ name, allowOther }: Props) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const selectCountry = useCallback(
    (country: CountryDto) => {
      setValue(t(`countries.${country.key.toUpperCase()}`));
      setShowCountrySelect(false);
    },
    [setValue, setShowCountrySelect],
  );

  return (
    <>
      <label className={"mb-2 text-sm text-gray-700"}>{t("address.country")} *</label>
      <SelectItemUnderline value={value || t("selectCountry")} onClick={() => setShowCountrySelect(true)} />
      {meta.error && meta.touched && <ValidationError message={meta.error} hasBackground={false} className={"mt-2"} />}

      <Dialog unmount open={showCountrySelect} onClose={() => setShowCountrySelect(false)} className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center overflow-y-auto">
          <Dialog.Panel className="w-full bg-white">
            <SelectCountryPage
              selected={t(`countries.${value}`)}
              onBack={() => setShowCountrySelect(false)}
              onSubmit={selectCountry}
              allowOther={allowOther ?? false}
            />
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default SelectCountryInput;
