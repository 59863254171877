import { BalanceDto, PublicPriceDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useQuery } from "react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useBalance = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchBalance = async (): Promise<PublicPriceDto> => {
    return await maloumClient.users.getBalance().then((response: BalanceDto) => {
      if (response instanceof Error) {
        Promise.reject(balance);
      }
      return response.balance;
    });
  };

  const { data: balance, isLoading, isError } = useQuery("fetch-balance", fetchBalance);
  return { balance: balance, isLoading, isError };
};

export default useBalance;
