import * as Sentry from "@sentry/react";

import * as tus from "tus-js-client";
import { AnonymousCredential, BlockBlobClient } from "@azure/storage-blob";
import MaloumClientContext from "contexts/MaloumClientContext";
import Compressor from "compressorjs";
import { useContext } from "react";
import useUploadStore from "state/uploadState";
import { TUpload } from "types/uploads.type";
import { BunnyUploadInformationDto, UploadUrlDto } from "@neolime-gmbh/api-gateway-client";

// allowed image formats
const imageMimeTypes = ["image/jpeg", "image/png"];
// allowed video formats
const videoMimeTypes = ["video/mp4", "video/quicktime"];

type Dimensions = {
  width: number;
  height: number;
};

type UploadMedia = {
  media: File | Blob;
  dimensions: Dimensions;
  folderId?: string;
};

export const EXTENSION_REGEX = /(?:\.([^.]+))?$/;

// TODO ADD ABORT FUNCTION FOR UPLOADS
const useUpload = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { addUpload, changeUploadedBytes, finishUpload } = useUploadStore((store) => store);

  const compressPicture = async (media: File | Blob): Promise<File | Blob> => {
    return new Promise((resolve, reject) => {
      new Compressor(media, {
        quality: 0.8,
        width: 1000, // max content width in system
        success: async (compressedResult: File | Blob) => {
          resolve(compressedResult);
        },
        error: async (error: Error) => {
          reject(error);
        },
      });
    });
  };

  const uploadMediaAzure = async (media: File | Blob, uploadInfo: UploadUrlDto, folderId?: string): Promise<string> => {
    addUpload({
      id: uploadInfo.id,
      bytesTotal: 1,
      bytesUploaded: 0,
      media: media,
      type: "image",
      folderIds: folderId ? [folderId] : [],
    } as TUpload);

    const compressedMedia = await compressPicture(media);
    const extension = EXTENSION_REGEX.exec(media.name)?.[1];
    const blockBlobClient = new BlockBlobClient(uploadInfo.uploadUrl, new AnonymousCredential());
    blockBlobClient
      .uploadData(compressedMedia, {
        blobHTTPHeaders: { blobContentType: media.type },
        metadata: {
          originalFileName: media.name.replace(/[^a-zA-Z ]/g, ""),
          originalExtension: extension ?? "",
        },
        onProgress: (e) => changeUploadedBytes(e.loadedBytes, compressedMedia.size, uploadInfo.id),
      })
      .then(() => finishUpload(uploadInfo.id, "success"));
    //TODO handle error case

    return uploadInfo.id;
  };

  const uploadMediaBunny = async (
    media: File | Blob,
    uploadInfo: BunnyUploadInformationDto,
    folderId?: string,
  ): Promise<string> => {
    addUpload({
      id: uploadInfo.id,
      bytesTotal: 1,
      bytesUploaded: 0,
      media: media,
      type: "video",
      folderIds: folderId ? [folderId] : [],
    } as TUpload);

    await new Promise<void>((resolve, reject) => {
      // TODO save upload instance to uploadStore to be able to abort it later
      const upload = new tus.Upload(media, {
        endpoint: uploadInfo.uploadUrl,
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: uploadInfo.authorizationSignature,
          AuthorizationExpire: uploadInfo.authorizationExpire.toString(),
          VideoId: uploadInfo.videoId,
          LibraryId: uploadInfo.libraryId,
        },
        metadata: {
          filetype: media.type,
          title: uploadInfo.id,
        },
        onError: function (error) {
          Sentry.captureException(error);
          finishUpload(uploadInfo.id, "error");
          reject();
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          changeUploadedBytes(bytesUploaded, bytesTotal, uploadInfo.id);
        },
        onSuccess: function () {
          finishUpload(uploadInfo.id, "success");
          resolve();
        },
      });

      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    });
    return uploadInfo.id;
  };

  const randomizeMediaName = (media: File | Blob): File | Blob => {
    const extension = media.name.split(".").pop();
    const currentTimestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 1000000);
    const newName = `${random}${currentTimestamp}.${extension}`;

    return new File([media], newName, { type: media.type });
  };

  const handleUpload = async (upload: UploadMedia, onFinish?: (mediaId: string) => Promise<void>) => {
    let mediaId;
    upload.media = randomizeMediaName(upload.media);
    if (imageMimeTypes.includes(upload.media.type)) {
      const uploadInfo = await maloumClient.uploads.generateUploadUrl({
        ...upload.dimensions,
        folderId: upload.folderId,
      });
      mediaId = uploadInfo.id;
      // dont await here, because we want to start the upload and not wait for it to finish
      uploadMediaAzure(upload.media, uploadInfo, upload.folderId).then((mediaId: string) => {
        if (onFinish) onFinish(mediaId);
      });
    }
    if (videoMimeTypes.includes(upload.media.type)) {
      const uploadInfo = await maloumClient.uploads.generateBunnyUploadInformation({
        ...upload.dimensions,
        folderId: upload.folderId,
      });
      mediaId = uploadInfo.id;
      // dont await here, because we want to start the upload and not wait for it to finish
      uploadMediaBunny(upload.media, uploadInfo, upload.folderId).then((mediaId: string) => {
        if (onFinish) onFinish(mediaId);
      });
    }
    if (!mediaId) throw new Error("Error during upload");
    return mediaId;
  };

  const getImageDimensions = (file: File | Blob): Promise<Dimensions> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => resolve({ width: 0, height: 0 });
    });
  };

  const getVideoDimensions = (file: File | Blob): Promise<Dimensions> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve({ width: video.videoWidth, height: video.videoHeight });
      };
      video.onerror = () => resolve({ width: 0, height: 0 });
    });
  };

  const getMediaDimensions = (file: File | Blob) => {
    if (file.type.startsWith("image/")) return getImageDimensions(file);
    if (file.type.startsWith("video/")) return getVideoDimensions(file);
    return { width: 0, height: 0 };
  };

  const isValidAspectRatio = (width: number, height: number) => {
    if (width === 0 || height === 0) return false;
    const aspectRatio = width / height;

    return !(aspectRatio > 3 || aspectRatio < 1 / 3);
  };

  return { imageMimeTypes, videoMimeTypes, handleUpload, getMediaDimensions, isValidAspectRatio };
};

export default useUpload;
