import { CategoryDto, ProductDto } from "@neolime-gmbh/api-gateway-client";
import { useInView } from "react-intersection-observer";
import useShop from "hooks/useShop.hook";
import { useCallback, useEffect } from "react";
import ProductPreview from "components/product/ProductPreview";
import NoResults from "components/utilities/NoResults";

type Props = {
  query: string;
  selectedCategory: CategoryDto | null;
  isTyping: boolean;
};
const ShopProducts = ({ query, selectedCategory, isTyping }: Props) => {
  const { ref, inView } = useInView();

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useShop({
    query,
    selectedCategory,
  });

  const createProductComponent = useCallback(
    (item: ProductDto) => <ProductPreview key={item._id} product={item} />,
    [],
  );
  const createLoadingProductComponent = useCallback((key: number) => <ProductPreview isLoading key={key} />, []);
  const NoProductResultsView = useCallback(() => <NoResults searchedText={query} />, [query]);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className="relative" data-testid={"shop-search-results"}>
      {data && !isLoading && !isTyping && (
        <>
          <div className={"grid grid-cols-2 gap-4"}>
            {data.pages.map((page) => page.data.map((e: ProductDto) => createProductComponent(e)))}
          </div>
          {data.pages[0].data.length === 0 && <NoProductResultsView />}
          <div className="absolute bottom-[20rem]" ref={ref} />
        </>
      )}
      {(isLoading || isTyping) && (
        <div className={"grid grid-cols-2 gap-4"}>
          {Array(12)
            .fill(null)
            .map((_, key) => createLoadingProductComponent(key))}
        </div>
      )}
    </div>
  );
};

export default ShopProducts;
