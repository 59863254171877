import { HiChevronRight } from "react-icons/hi2";

type Props = {
  value: string;
  onClick: () => void;
  "data-testid"?: string;
};

const SelectItemUnderline = ({ value, onClick, "data-testid": dataTestId }: Props) => {
  return (
    <div
      onClick={onClick}
      className={"flex cursor-pointer items-center border-b border-b-gray-400"}
      data-testid={dataTestId}
    >
      <div className={"flex flex-1 pb-1"}>{value}</div>

      <HiChevronRight className={"h-5 w-5"} />
    </div>
  );
};

export default SelectItemUnderline;
