import {PictureCandidateDto, ThumbnailCandidateDto} from "@neolime-gmbh/api-gateway-client";
import MediaPopup from "components/media/MediaPopup";
import VideoPlayer from "components/VideoPlayer";

type Props = {
  video: PictureCandidateDto;
  thumbnail?: ThumbnailCandidateDto;
  isOpen: boolean;
  onClose: () => void;
};

const VideoFullScreenPopup = ({ video, thumbnail, isOpen, onClose }: Props) => {
  return (
    <MediaPopup isOpen={isOpen} onClose={onClose}>
      <VideoPlayer url={video.url ?? ""} width={video.width} height={video.height} thumbnail={thumbnail} />
    </MediaPopup>
  );
};

export default VideoFullScreenPopup;
