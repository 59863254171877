import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SearchInput from "components/molecules/basics/SearchInput";
import { LoadingListItem } from "components/molecules/lists/ListItem";
import SelectListItem from "components/molecules/lists/SelectListItem";
import NoResults from "components/utilities/NoResults";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import useLists from "hooks/lists/useLists.hook";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { BaseComponent } from "types/components/component.type";

type Props = {
  onItemClick: (list: ChatListDto) => void;
  selectedListIds: string[];
  hideListIds?: string[];
  showManagedLists?: boolean;
} & BaseComponent;

const ListSelectionList = ({
  onItemClick,
  selectedListIds,
  hideListIds = [],
  showManagedLists = true,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery();

  const { lists, isLoading, hasNextPage, fetchNextPage } = useLists(query);

  const filteredLists = useMemo(() => {
    const tmpLists = lists.filter((list) => !hideListIds.includes(list._id));
    if (showManagedLists) return tmpLists;
    return tmpLists.filter((list) => !list.isManaged);
  }, [lists, hideListIds, showManagedLists]);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading) fetchNextPage();
  }, [inView, isLoading, hasNextPage]);

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <SearchInput
        value={debounceQuery}
        onChange={setDebounceQuery}
        placeholder={t("lists.search.placeholder") ?? ""}
        className="mb-4"
      />
      <div className="relative flex flex-col gap-1.5 pb-16">
        {!isTyping &&
          filteredLists.map((list) => (
            <SelectListItem
              list={list}
              onItemClick={onItemClick}
              selected={selectedListIds.includes(list._id)}
              key={list._id}
            />
          ))}
        {!!debounceQuery && query && !isTyping && !filteredLists.length && !isLoading && (
          <div>
            <NoResults searchedText={debounceQuery} />
            <div className="text-center text-sm text-gray-500">{t("lists.search.noResults")}</div>
          </div>
        )}
        {!query && !isTyping && !filteredLists.length && !(isLoading && hasNextPage) && <NoResults />}
        {((isLoading && hasNextPage) || isTyping) && (
          <>
            {Array.from(Array(10).keys()).map((i) => (
              <LoadingListItem key={i} className="xl:mt-1.5" />
            ))}
          </>
        )}
        <div className="absolute bottom-40" ref={ref} />
      </div>
    </div>
  );
};

export default ListSelectionList;
