import classNames from "classnames";

type Props = {
  value: string;
  selected: boolean;
  setSelected: () => void;
  disabled?: boolean;
  "data-testid"?: string;
};

const SelectButton = ({ value, selected, setSelected, disabled, "data-testid": dataTestId }: Props) => {
  return (
    <button
      type={"button"}
      onClick={setSelected}
      disabled={disabled}
      className={classNames("flex-1 rounded-md py-2 duration-150", {
        "border border-red-900 text-red-900": selected,
        "border border-gray-500 text-gray-700": !selected && !disabled,
        "cursor-not-allowed border border-gray-300 text-gray-300": disabled,
      })}
      data-testid={dataTestId}
    >
      {value}
    </button>
  );
};

export default SelectButton;
