import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import BaseSelectionItem, { LoadingBaseSelectionItem } from "components/atoms/base/item/BaseSelectionItem";
import FolderName from "components/atoms/vault/FolderName";
import { HiOutlinePhoto, HiOutlineVideoCamera } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";
import FolderThumbnails from "./FolderThumbnails";

type Props = {
  folder: FolderDto;
  selected: boolean;
  onClick: () => void;
} & BaseComponent;

const FolderSelectListItem = ({ folder, selected, onClick, className, "data-testid": dataTestId }: Props) => {
  return (
    <BaseSelectionItem
      isSelected={selected}
      onClick={onClick}
      className={classNames("", className)}
      data-testid={dataTestId}
    >
      <div className="max-w-[calc(100%-108px)]">
        <FolderName folder={folder} className={classNames("truncate text-left", { "font-semibold": selected })} />
        <div className={classNames("mt-0.5 flex items-center gap-1", { "text-gray-500": !selected })}>
          <div className="flex items-center gap-1">
            <HiOutlinePhoto className="h-4 w-4" />
            <div className="text-xs">{folder.pictureCount}</div>
          </div>
          {folder.videoCount > 0 && (
            <div className="flex items-center gap-1">
              <HiOutlineVideoCamera className="h-4 w-4" />
              <div className="text-xs">{folder.videoCount}</div>
            </div>
          )}
        </div>
      </div>
      <div className="grow" />
      <FolderThumbnails thumbnails={folder.mostRecentMediaThumbnails} className="pr-2" />
    </BaseSelectionItem>
  );
};

const LoadingFolderSelectListItem = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return <LoadingBaseSelectionItem className={classNames("h-[3.75rem]", className)} data-testid={dataTestId} />;
};

export default FolderSelectListItem;
export { LoadingFolderSelectListItem };
