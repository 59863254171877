import { ProductDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Pill from "components/atoms/Pill";
import useCurrency from "hooks/useCurrency.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  product?: ProductDto;
  isLoading?: boolean;
  isActive?: boolean;
};

const ProductInfo = ({ product, isLoading = false, isActive = true }: Props) => {
  const navigate = useStatefulNavigate();
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const isInReview = useMemo(() => {
    return product?.reviewed === false;
  }, [product?.reviewed]);

  const goToProduct = useCallback(() => {
    navigate(`/product/${product?._id}`);
  }, [product]);

  if (isLoading || !product) {
    return (
      <div className={"mt-2 animate-pulse"}>
        <div className={"mb-1 h-3 w-full rounded-full bg-gray-200"} />
        <div className={"h-2 w-12 rounded-full bg-gray-200"} />
      </div>
    );
  }

  return (
    <div className={classNames("pt-2", { "opacity-70": !isActive })}>
      <div onClick={goToProduct} className={"flex cursor-pointer justify-between"}>
        <span className="notranslate line-clamp-2 break-words pr-2 text-sm xxs:text-base">{product.name}</span>
        {isInReview && (
          <div>
            <Pill className="notranslate">{t("content.inReview")}</Pill>
          </div>
        )}
      </div>
      <div onClick={goToProduct} className={"cursor-pointer text-sm text-gray-500"}>
        {displayCurrency(product.price.net)}
      </div>
    </div>
  );
};

export default ProductInfo;
