import PopUp from "components/popup/PopUp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RenameVaultFolderPopup from "./RenameVaultFolderPopup";
import DeleteVaultFolderPopup from "./DeleteVaultFolderPopup";
import { BaseComponent, PopUpComponent } from "types/components/component.type";
import { FolderDto } from "@neolime-gmbh/api-gateway-client";

type Props = { folder: FolderDto } & PopUpComponent & BaseComponent;

const EditVaultFolderPopup = ({ folder, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const [renamePopupIsOpen, setRenamePopupIsOpen] = useState(false);
  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);

  const options = [
    { title: t("vault.folderPopup.rename"), onClick: () => setRenamePopupIsOpen(true) },
    { title: t("vault.folderPopup.delete"), onClick: () => setDeletePopupIsOpen(true) },
  ];

  if (renamePopupIsOpen)
    return (
      <RenameVaultFolderPopup
        folder={folder}
        open={renamePopupIsOpen}
        onClose={() => {
          setRenamePopupIsOpen(false);
          onClose();
        }}
      />
    );

  if (deletePopupIsOpen)
    return (
      <DeleteVaultFolderPopup
        folder={folder}
        open={deletePopupIsOpen}
        onClose={() => {
          setDeletePopupIsOpen(false);
          onClose();
        }}
      />
    );

  return (
    <PopUp
      isOpen={open}
      onClose={onClose}
      title={t("vault.folderPopup.title") ?? ""}
      options={options}
      data-testid={dataTestId}
    />
  );
};

export default EditVaultFolderPopup;
