import EmptySentBroadcastsIllustration from "components/atoms/illustration/EmptySentBroadcastsIllustration";
import { useTranslation } from "react-i18next";

const EmptySentBroadcasts = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-full grow flex-col items-center justify-center gap-6">
      <EmptySentBroadcastsIllustration />
      <div className="text-center text-sm text-gray-500">{t("chats.broadcasts.empty")}</div>
    </div>
  );
};

export default EmptySentBroadcasts;
