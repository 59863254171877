import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import TopCreatorsIllustration from "components/atoms/illustration/TopCreatorsIllustration";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import SuggestedCreatorCard, { SuggestedCreatorCardLoading } from "components/molecules/card/SuggestedCreatorCard";
import PopupBottom from "components/molecules/PopupBottom";
import useTopCreators from "hooks/useTopCreators.hook";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import useUserStore from "state/userState";
import useShare from "hooks/useShare.hook";
import useChat from "hooks/useChat.hook";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import classNames from "classnames";
import StatefulLink from "components/atoms/utils/StatefulLink";

type TopCreatorsPopupProps = {
  popupOpen: boolean;
  setPopupOpen: (v: boolean) => void;
};

const TopCreatorsPopup = ({ popupOpen, setPopupOpen }: TopCreatorsPopupProps) => {
  const { t } = useTranslation();

  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { share, successfullyShared } = useShare(`${window.location.origin}/creator/${user.username}`);
  const { openChatWithMaloumOfficial } = useChat();

  return (
    <PopupBottom open={popupOpen} setOpen={setPopupOpen}>
      <TopCreatorsIllustration className="mx-auto my-2" />
      <div className="mt-4 text-center text-xl font-semibold">{t("topCreators.popupHeader")}</div>
      <div className="mx-auto my-4 grid w-fit max-w-xl grid-cols-1 gap-3 px-6 text-sm text-gray-500">
        <div className="flex items-start gap-2">
          <span>1.</span>
          <span>
            <Trans
              i18nKey="topCreators.how.firstStep"
              components={{
                1: <button className="text-red-900" onClick={share} />,
                2: (
                  <HiOutlineCheckCircle
                    className={classNames("-mt-1 ml-1 inline h-4 w-4 text-red-900", { hidden: !successfullyShared })}
                  />
                ),
              }}
            />
          </span>
        </div>
        <div className="flex items-start gap-2">
          <span>2.</span>
          <span>
            <Trans
              i18nKey="topCreators.how.secondStep"
              components={{
                1: <StatefulLink className="text-red-900" to="/post/create" />,
              }}
            />
          </span>
        </div>
        <div className="flex items-start gap-2">
          <span>3.</span>
          <span>
            <Trans
              i18nKey="topCreators.how.thirdStep"
              components={{
                1: <StatefulLink className="text-red-900" to="/chat" />,
              }}
            />
          </span>
        </div>
      </div>
      <div className="my-4 text-center text-sm text-gray-500">
        <div>{t("topCreators.questions.any")}</div>
        <Trans
          i18nKey="topCreators.questions.write"
          components={{
            1: <button className="underline" onClick={() => openChatWithMaloumOfficial()} />,
            2: <a href="mailto:support@maloum.com" className="underline" />,
          }}
        />
      </div>
      <TernaryButton variant="red" className="w-full text-center" onClick={() => setPopupOpen(false)}>
        <div className="grow text-center">{t("gotIt")}</div>
      </TernaryButton>
    </PopupBottom>
  );
};

const TopCreators = () => {
  const { t } = useTranslation();
  // ref for loading trigger div
  const { ref, inView } = useInView();

  const [popupOpen, setPopupOpen] = useState(false);
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const { topCreators, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useTopCreators();

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading, isFetchingNextPage]);

  return (
    <>
      <TopCreatorsPopup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
      <Layout>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("topCreators.header")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container>
          <div className="relative pb-24 pt-4">
            {user.isCreator && (
              <div className="mb-4 grid grid-cols-1 gap-2 rounded-md border border-gray-200 p-4">
                <div className="text-xl font-semibold">{t("topCreators.info.header")}</div>
                <div>
                  <span className="text-gray-500">{t("topCreators.info.desc")}</span>{" "}
                  <span className="cursor-pointer text-red-900" onClick={() => setPopupOpen(true)}>
                    {t("topCreators.info.cta")}
                  </span>
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
              {isLoading
                ? Array(15)
                    .fill(null)
                    .map((_, key) => (
                      <SuggestedCreatorCardLoading
                        fullWidth
                        data-testid={`top-creator-card-loading-${key}`}
                        key={key}
                      />
                    ))
                : topCreators.map((c) => (
                    <SuggestedCreatorCard
                      username={c.user.username}
                      isVerified={c.user.isVerified}
                      backgroundImage={c.lastPost?.media[0]}
                      profileImage={c.user.profilePictureThumbnail}
                      rank={c.rank}
                      key={c.rank}
                      fullWidth
                    />
                  ))}

              {isFetchingNextPage &&
                Array(5)
                  .fill(null)
                  .map((_, key) => (
                    <SuggestedCreatorCardLoading fullWidth data-testid={`top-creator-card-loading-${key}`} key={key} />
                  ))}
            </div>
            <div className="absolute bottom-[10rem]" ref={ref} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default TopCreators;
