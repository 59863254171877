import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import BaseListItem, { LoadingBaseListItem } from "components/atoms/base/item/BaseListItem";
import ListName from "components/atoms/lists/ListName";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  list: ChatListDto;
  selected: boolean;
  onSelect: () => void;
} & BaseComponent;

const ListItem = ({ list, selected, onSelect, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  return (
    <BaseListItem isSelected={selected} onClick={onSelect} className={className} data-testid={dataTestId}>
      <ListName list={list} className={classNames("truncate", { "font-semibold": selected })} />
      <div className={classNames("mt-0.5 text-xs", { "text-gray-500": !selected })}>
        {t("lists.item.members", { count: list.totalMemberCount })}
      </div>
    </BaseListItem>
  );
};

const LoadingListItem = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return <LoadingBaseListItem className={classNames("h-[3.75rem]", className)} data-testid={dataTestId} />;
};

export default ListItem;
export { LoadingListItem };
