import classNames from "classnames";
import { ReactNode } from "react";
import { HiXMark } from "react-icons/hi2";

// TODO: type is repeatable -> should be moved into generalised types file and shared
type TOption = {
  title: string;
  onClick: () => void;
  testId?: string;
};

type Props = {
  title?: string;
  options?: TOption[];
  onClose: () => void;
  children?: ReactNode;
  footer?: ReactNode;
  preventClose?: boolean;
};

// TODO: clean up Pop-Up and Pop-Up Panel
const PopUpPanel = ({ title, options, onClose, children, footer, preventClose = false }: Props) => {
  return (
    <div
      className={
        "mx-auto flex w-full max-w-[600px] flex-col justify-center rounded-2xl bg-white px-7 pb-8 pt-5 text-center shadow-[0_0_64px_0_rgba(0,0,0,0.2)]"
      }
    >
      <div className={classNames("flex items-center", { "mb-10": options })}>
        {!preventClose && <div className="w-8 shrink-0" />}
        <div className="grow" />
        {title && <div className={classNames("font-semibold")}>{title}</div>}
        <div className="grow" />
        {!preventClose && (
          <button onClick={onClose} className={"cursor-pointer p-1 outline-none"} data-testid={"close-popup"}>
            <HiXMark className={"h-6 w-6 text-gray-900 "} />
          </button>
        )}
      </div>
      <div className={"w-full max-w-xl"}>{children}</div>

      {options && (
        <div className={"mx-auto w-full max-w-xl"}>
          {options.map((option) => (
            <div
              key={option.title}
              onClick={option.onClick}
              data-testid={option.testId}
              className={
                "mb-3 flex cursor-pointer items-center justify-center border-b border-gray-200 pb-3 last:border-b-0"
              }
            >
              {option.title}
            </div>
          ))}
        </div>
      )}

      {footer}
    </div>
  );
};

export default PopUpPanel;
