import { PostDto } from "@neolime-gmbh/api-gateway-client";
import SearchNoResults from "components/utilities/SearchNoResults";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useSearchStore from "../../state/searchState";
import MyInfiniteScroll from "../layouts/MyInfiniteScroll";
import PostItem, { PostItemLoading } from "../post/PostItem";

type TData = {
  next: string | null;
  data: PostDto[];
};

type Props = {
  query: string | null;
  isTyping: boolean;
};

const SearchPost = ({ query, isTyping = false }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const searchPosts = useCallback(
    (query: string, next: string | null) => {
      return maloumClient.posts.search(query, next);
    },
    [maloumClient],
  );

  const setPostsResults = useSearchStore<(value: TData, addToData?: boolean) => void>((state) => state.setPostsResults);
  const data = useSearchStore<TData>((state) => state.postsResults);
  const setData = useCallback((newData: TData) => setPostsResults(newData, true), [setPostsResults]);
  const loadNextPosts = useCallback(
    (next: string | null) => (query ? searchPosts(query, next) : null),
    [query, searchPosts],
  );
  const removePost = useCallback(
    (postId: string) => {
      setPostsResults({
        next: data.next,
        data: data.data.filter((post) => post._id !== postId),
      });
    },
    [setPostsResults, data],
  );
  const updatePost = useCallback(
    (post: PostDto) => {
      setPostsResults(
        {
          next: data.next,
          data: data.data.map((item) => (item._id === post._id ? post : item)),
        },
        false,
      );
    },
    [setPostsResults, data],
  );

  const createPostComponent = useCallback(
    (item: PostDto) => (
      <PostItem key={item._id} post={item} updateCallback={updatePost} deletedCallback={() => removePost(item._id)} />
    ),
    [removePost],
  );
  const createLoadingPostComponent = (key: number) => <PostItemLoading key={key} data-testid="post-item" />;

  const NoPostResultsView = useCallback(
    () => (
      <div className={"py-4"}>
        <SearchNoResults query={query} tab={t("posts.name")} />
      </div>
    ),
    [],
  );

  return (
    <MyInfiniteScroll
      loadNext={loadNextPosts}
      loadingItemsCount={6}
      createItemComponent={createPostComponent}
      createLoadingItemComponent={createLoadingPostComponent}
      NoResultsView={NoPostResultsView}
      showLoader={isTyping}
      data={data}
      setData={setData}
    />
  );
};

export default SearchPost;
