import { ProductDto } from "@neolime-gmbh/api-gateway-client";
import { useState } from "react";
import ProductCreator from "./ProductCreator";
import ProductInfo from "./ProductInfo";
import StatusProductPopup from "./StatusProductPopup";
import Media from "components/media/Media";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  product?: ProductDto;
  setProduct?: (value: ProductDto) => void;
  hasUser?: boolean;
  isLoading?: boolean;
};

const ProductPreview = ({ product, setProduct, hasUser = true, isLoading = false }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const [openPopup, setOpenPopup] = useState(false);

  const handleProductImageClick = () => (product?.active ? navigate(`/product/${product?._id}`) : setOpenPopup(true));

  if (isLoading || !product) {
    return (
      <div>
        {hasUser && <ProductCreator isLoading />}
        <div className="aspect-square w-full animate-pulse rounded-md bg-gray-100" />
        <ProductInfo isLoading />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {hasUser && <ProductCreator user={product.createdBy} />}
        <button className="relative aspect-square w-full overflow-hidden rounded-md" onClick={handleProductImageClick}>
          <Media
            media={product.media[0]}
            thumbnail={product.thumbnail}
            allowFullScreen={false}
            className={classNames({ "opacity-70": !product.active })}
          />
          {!product.active && (
            <div
              className={classNames(
                "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-full bg-white/70 px-3 py-1 font-medium",
              )}
            >
              {t("product.status.inactive").toLowerCase()}
            </div>
          )}
        </button>
        <ProductInfo product={product} isActive={product.active} />
      </div>

      <StatusProductPopup product={product} setProduct={setProduct} open={openPopup} setOpen={setOpenPopup} />
    </>
  );
};

export default ProductPreview;
