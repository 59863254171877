import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import Logo from "assets/logo/Logo";
import LegalSection from "components/LegalSection";
import Button from "components/basics/Button";
import Layout from "components/layouts/Layout";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import { useParams } from "react-router-dom";
import useGuestStore from "state/guestState";
import Container from "../../components/layouts/Container";
import CreatorProfileInfo from "../../components/profile/CreatorProfileInfo";
import ProfileTabs from "../../components/profile/ProfileTabs";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import NotFoundPage from "../basics/404";
import Loading from "../basics/Loading";
import { Helmet } from "react-helmet";
import SuggestedcreatorSection from "components/molecules/SuggestedCreatorsSection";
import HeaderBar from "components/layouts/HeaderBar";
import GuestContext from "contexts/GuestContext";
import {useFirstline} from "@first-line/firstline-react";

const GuestCreatorProfile = () => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { trackCreatorProfileVisit } = useContext(GuestContext);
  const { loginWithRedirect } = useFirstline();
  const { username } = useParams();
  const [user, setUser] = useState<PrivateUserDto | PublicUserDto | null>(null);
  const [userNotFound, setUserNotFound] = useState(false);

  const showAuthPopup = useGuestStore((state) => state.showAuthPopup);

  const getCreator = async () => {
    if (!username) {
      setUserNotFound(true);
      return;
    }
    await maloumClient.guest
      .getProfileByUsername(username)
      .then((response) => {
        setUser(response);
        setUserNotFound(false);
      })
      .catch((e) => {
        console.log(e);
        setUserNotFound(true);
      });
  };

  useEffect(() => {
    getCreator();
    // scroll to top
    window.scrollTo({ top: 0 });

    // save username to localstorage for correct redirect after signup
    localStorage.setItem("initialCreator", username!);
  }, [username]);

  if (!username || userNotFound) {
    return <NotFoundPage />;
  }

  // TODO Change to loading skeleton
  if (!user) return <Loading />;

  trackCreatorProfileVisit(user!._id);

  return (
    <Layout hasNavigation={false}>
      <Helmet>
        <title>{`${user.username} - MALOUM`}</title>
        <meta name="description" content={user.bio} />
      </Helmet>
      <div className={"relative z-50 mb-[4.1875rem] hidden md:block"}>
        <div className={"fixed z-50 w-full border-b border-b-gray-100 bg-white"}>
          <Container>
            <div className={"flex items-center justify-between py-3 md:px-0"}>
              <Logo className={"h-5 fill-red-900"} />
              <div className={"flex gap-2"}>
                <Button onClick={loginWithRedirect} text={t("login")} variant={"outline"} className="min-w-[6rem]" />
                <Button onClick={() => loginWithRedirect({action_hint: "signup"})} text={t("signup")} className="min-w-[6rem]" />
              </div>
            </div>
          </Container>
        </div>
      </div>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left />
          <HeaderBar.Center>
            <HeaderBar.Title>{user.username}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right>
            <HeaderBar.Button onClick={showAuthPopup}>
              <HiEllipsisHorizontal className={"h-6 w-6"} />
            </HeaderBar.Button>
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasGrow={false} hasPadding={false} className={"px-4 md:px-0"}>
        <CreatorProfileInfo user={user} />
        <div className="mt-5">
          <Button text={t("signUpMore")} onClick={() => loginWithRedirect({action_hint:"signup"})} />
        </div>
      </Container>
      <Container hasPadding={false} hasGrow={false} className="grow">
        <ProfileTabs user={user} viewedByGuest={true} />
        <SuggestedcreatorSection className="mb-5" />
      </Container>
      <div className={"bg-beige-400"}>
        <Container hasGrow={false}>
          <LegalSection
            hasCopyRight
            showLogo
            showSocials
            className={"mb-[58px] gap-y-3 bg-beige-400 py-5 pb-12 text-sm text-gray-700 md:mb-0"}
          />
        </Container>
      </div>
      <div className={"relative z-[9990] w-full md:hidden"}>
        <div className={"fixed bottom-0 w-full bg-white"}>
          <div className={"mx-auto grid max-w-xl grid-cols-2 gap-2 p-4 md:px-0"}>
            <Button onClick={loginWithRedirect} text={t("login")} variant={"outline"} />
            <Button onClick={() => loginWithRedirect({action_hint: "signup"})} text={t("signup")} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GuestCreatorProfile;
