import { RadioGroup } from "@headlessui/react";
import { OrderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import useOrderDetails from "../../../hooks/useOrderDetails.hook";
import OrderStatusRadioButton from "../OrderStatusRadioButton";

type Props = {
  currentStatus: OrderDto.status;
  currentTrackingNumber?: string;
  className?: string;
};

const OrderStatusSingleSelectList = ({ currentStatus, currentTrackingNumber, className }: Props) => {
  const { updateOrderStatus } = useOrderDetails();
  const [status, setStatus] = useState<OrderDto.status>(currentStatus);
  const [trackingNumber, setTrackingNumber] = useState(currentTrackingNumber ? currentTrackingNumber : "");

  const handleStatusSubmit = useCallback(
    (statusValue: OrderDto.status, trackingNumberValue: string) => {
      if (statusValue === OrderDto.status.SHIPPED && trackingNumberValue.length > 0) {
        updateOrderStatus({ status: statusValue, trackingNumber: trackingNumberValue });
      }

      if (statusValue !== OrderDto.status.SHIPPED) {
        updateOrderStatus({ status: statusValue });
      }
    },
    [updateOrderStatus],
  );

  const handleStatusChange = useCallback(
    (statusValue: OrderDto.status) => {
      setStatus(statusValue);

      handleStatusSubmit(statusValue, trackingNumber);
    },
    [trackingNumber, updateOrderStatus],
  );

  const handleTrackingNumberChange = useCallback(
    (trackingNumberValue: string) => {
      setTrackingNumber(trackingNumberValue);

      handleStatusSubmit(status, trackingNumberValue);
    },
    [status, updateOrderStatus],
  );

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  return (
    <RadioGroup value={status} onChange={handleStatusChange}>
      <ul className={classNames("grid gap-2", className)}>
        {Object.values(OrderDto.status)
          .filter((status) => status !== OrderDto.status.ORDERED)
          .map((status) => (
            <li key={status}>
              {status === OrderDto.status.SHIPPED ? (
                <OrderStatusRadioButton
                  orderStatus={status}
                  saveTrackingNumber={handleTrackingNumberChange}
                  trackingNumber={trackingNumber}
                />
              ) : (
                <OrderStatusRadioButton orderStatus={status} />
              )}
            </li>
          ))}
      </ul>
    </RadioGroup>
  );
};

export default OrderStatusSingleSelectList;
