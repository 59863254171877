import {TSelectedMedia} from "types/vault/selectedMedia.type";
import {TUpload} from "types/uploads.type";
import {MediaCandidateDto} from "@neolime-gmbh/api-gateway-client";

function mapUploadToSelectedMedia(
  upload: TUpload,
  thumbnail: string | undefined,
  size: { width: number, height: number }
): TSelectedMedia {
  return {
    _id: upload.id,
    url: URL.createObjectURL(upload.media),
    type: upload.type === "image" ? MediaCandidateDto.type.PICTURE : MediaCandidateDto.type.VIDEO,
    uploadStatus: MediaCandidateDto.uploadStatus.FINISHED,
    width: size.width,
    height: size.height,
    thumbnail: {
      url: thumbnail ?? URL.createObjectURL(upload.media),
      uploadStatus: MediaCandidateDto.uploadStatus.FINISHED,
    },
  }
}

export { mapUploadToSelectedMedia };