import { create } from "zustand";

const useSearchStore = create((set) => ({
  searchResults: {
    next: null,
    data: [],
  },
  productsResults: {
    next: null,
    data: [],
  },
  postsResults: {
    next: null,
    data: [],
  },
  searchTerm: "",
  currentTab: "",
  setSearchResults: (searchResults, addToData = false) => {
    return set((state) => ({
      searchResults: {
        next: searchResults.next,
        data: addToData ? [...state.searchResults.data, ...searchResults.data] : searchResults.data,
      },
    }));
  },
  setProductsResults: (data, addToData = false) =>
    set((state) => ({
      productsResults: {
        next: data.next,
        data: addToData ? [...state.productsResults.data, ...data.data] : data.data,
      },
    })),
  setPostsResults: (data, addToData = false) =>
    set((state) => ({
      postsResults: {
        next: data.next,
        data: addToData ? [...state.postsResults.data, ...data.data] : data.data,
      },
    })),
}));

export default useSearchStore;
