import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import { supportEmail } from "const";
import useChat from "hooks/useChat.hook";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  onBack: () => void;
};

const SmallBusinessOwner = ({ onBack }: Props) => {
  const { t } = useTranslation();
  const { openChatWithMaloumOfficial } = useChat();

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("taxIdInfo.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="pb-4">
        <h1 className="mb-4 mt-4 text-2xl font-semibold">{t("taxIdInfo.title")}</h1>
        <div>
          <Trans i18nKey="taxIdInfo.companyTaxDescription"></Trans>
        </div>
        <div className="mt-6">
          <Trans i18nKey="taxIdInfo.personalTaxDescription"></Trans>
        </div>
        <div className="grow" />
        <p className="mb-4 text-center text-sm text-gray-500">
          <Trans i18nKey="smallBusiness.questions">
            <button type="button" className="underline" onClick={() => openChatWithMaloumOfficial()} />
            <a href={`mailto:${supportEmail}`} className="underline" />
          </Trans>
        </p>
        <Button text={t("smallBusiness.button")} onClick={onBack} />
      </Container>
    </Layout>
  );
};

export default SmallBusinessOwner;
