import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import ValidationError from "components/utilities/ValidationError";
import { isOver18 } from "helper/dateAndTimeHelper";
import { usePostHog } from "posthog-js/react";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import Button from "../../components/basics/Button";
import InputField from "../../components/basics/InputField";
import Container from "../../components/layouts/Container";
import UserContext from "../../contexts/UserContext";

type Props = {
  onNext: () => void;
};

const SetBirthdayPage = ({ onNext }: Props) => {
  const posthog = usePostHog();
  const { setBirthday, setRole } = useContext(UserContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const birthday = useMemo(() => (user.birthday ? user.birthday.split(".") : ["", "", ""]), [user]);
  const [day, setDay] = useState(birthday[0]);
  const [month, setMonth] = useState(birthday[1]);
  const [year, setYear] = useState(birthday[2]);
  const [errorMessage, setErrorMessage] = useState("");

  const refMonth = useRef<HTMLInputElement>();
  const refYear = useRef<HTMLInputElement>();

  const submitBirthday = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const date = new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);

    const response = await setBirthday(date, false);
    if (!(response instanceof Error)) {
      // POSTHOG: emmit set birthday view event
      // posthog?.capture("set-birthday");

      // Google Tag Manager: Sign Up Birthdate Entered Event
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "sign_up_fan",
        user: {
          email: user.email,
          id: user._id,
        },
      });

      // comment back in when user should select role in intro
      // onNext();
      // fan role is default
      await setRole("fan", true).then(() => {
        const initialCreator = localStorage.getItem("initialCreator");
        if (initialCreator) window.location.assign(`/creator/${initialCreator}`);
      });
    } else {
      setErrorMessage(t("validation.validDate") ?? "");
    }
    setIsLoading(false);
  }, [year, month, day, setBirthday, onNext, posthog]);

  const validateAge = useCallback(() => {
    const date = new Date(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
    const minDate = new Date("1900-01-01");

    let hasError = false;

    if (isNaN(date.getTime()) || !(year.length === 4 && month !== "" && day !== "")) {
      hasError = true;
      setErrorMessage(t("validation.validDate") || "");
    } else if (date <= minDate) {
      hasError = true;
      setErrorMessage(t("validation.minDate", { minDate: "1900" }) || "");
    } else if (!isOver18(date)) {
      hasError = true;
      setErrorMessage(t("underAged") || "");
    }

    if (!hasError) {
      submitBirthday();
    }
  }, [day, month, year, t, submitBirthday, setErrorMessage, isOver18]);

  useEffect(() => {
    if (day.length === 2 && !birthday[1]) refMonth.current?.focus();
  }, [day]);

  useEffect(() => {
    if (month.length === 2 && !birthday[2]) refYear.current?.focus();
  }, [month]);

  return (
    <Container hasPadding={false} className={"px-4 pb-4 pt-14 md:px-0 md:pb-40"}>
      <h1 className={"text-3xl font-semibold text-gray-900"}>{t("birthday")}</h1>

      <div className={"mt-2"}>
        <p className="text-gray-500">{t("ageInfo")}</p>
        <form className={"mt-6 flex items-center gap-x-3"} onSubmit={validateAge}>
          <InputField
            value={day}
            className={"text-center"}
            placeholder={t("birthdayPlaceholder.day")}
            required
            name="day"
            onChange={setDay}
            onFocus={() => setErrorMessage("")}
            maxLength={2}
          />
          <InputField
            value={month}
            className={"text-center"}
            placeholder={t("birthdayPlaceholder.month")}
            required
            name="month"
            onChange={setMonth}
            onFocus={() => setErrorMessage("")}
            maxLength={2}
            ref={refMonth}
          />
          <InputField
            value={year}
            className={"text-center"}
            placeholder={t("birthdayPlaceholder.year")}
            required
            name="year"
            onChange={setYear}
            onFocus={() => setErrorMessage("")}
            maxLength={4}
            ref={refYear}
          />
        </form>
        {errorMessage && <ValidationError hasBackground={false} message={errorMessage} />}
      </div>

      <Button className="mt-6" onClick={validateAge} variant="primary" text={t("next")} disabled={isLoading} />
    </Container>
  );
};

export default SetBirthdayPage;
