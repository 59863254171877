import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PrivateUserDto, UpdateProductDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import EditProductData from "components/product/EditProductData";
import Layout from "components/layouts/Layout";
import Loading from "pages/basics/Loading";
import useCategories from "hooks/useCategories.hook";
import HeaderBar from "components/layouts/HeaderBar";
import useProduct from "hooks/useProduct.hook";
import Media from "components/media/Media";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useUserStore from "state/userState";

const EditProduct = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { product } = useProduct(productId ?? "");
  const { maloumClient } = useContext(MaloumClientContext);
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const { categories, isLoading: categoriesLoading } = useCategories();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const updateProduct = async (productData: UpdateProductDto) => {
    if (!productId) return;
    // remove mediaIds from productData as it can no be updated after creation
    delete productData.mediaIds;
    // update product
    await maloumClient.products.update(productId, productData).then(() => {
      navigate(`/creator/${user.username}?tab=products`);
    });
  };

  const hydrateCategories = (_categories: { _id: string }[]) =>
    categories.filter((c) => _categories.some((_c) => _c._id === c._id));

  useEffect(() => {
    if (!product) return;
    navigate(".", {
      state: {
        data: {
          description: product.description,
          name: product.name,
          categories: hydrateCategories(product.categories),
          netAmount: product.price.net,
        },
      },
      replace: true,
    });
  }, [product]);

  if (!product || categoriesLoading || !state.data) return <Loading />;

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("product.modify")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <div className="relative mx-auto aspect-[1.5/1] min-h-fit w-full max-w-xl bg-beige-400">
        <Media media={product.media[0]} thumbnail={product.thumbnail} cover={false} />
      </div>

      <EditProductData submit={updateProduct} isEditMode />
    </Layout>
  );
};

export default EditProduct;
