import { RadioGroup } from "@headlessui/react";
import { CountryDto } from "@neolime-gmbh/api-gateway-client";
import RadioButtonCard from "components/molecules/RadioButtonCard";
import useCountries from "hooks/useCountries.hook";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/basics/Button";
import Container from "../components/layouts/Container";
import Layout from "../components/layouts/Layout";
import HeaderBar from "components/layouts/HeaderBar";

type Props = {
  selected?: string | null;
  onSubmit: (value: CountryDto) => void;
  onBack: () => void;
  allowOther: boolean;
};

const SelectCountryPage = ({ selected, onSubmit, onBack, allowOther }: Props) => {
  const { t } = useTranslation();
  const { countries, isLoading: countriesLoading } = useCountries(allowOther);

  const [selectedItem, setSelectedItem] = useState<CountryDto>();

  const _toggle = useCallback(
    (selected: CountryDto) => {
      setSelectedItem(selected);
    },
    [setSelectedItem],
  );

  const handleSubmit = useCallback(() => {
    if (selectedItem) onSubmit(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (!countriesLoading) {
      let selectedTmp: string;
      if (selected) selectedTmp = selected;
      else selectedTmp = "de";
      setSelectedItem(countries.find((c) => c.key.toLowerCase() === selectedTmp.toLowerCase()));
    }
  }, [countriesLoading]);

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("selectCountry")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        <div className="mb-4 mt-4 text-2xl font-semibold">{t("selectYourCountry")}</div>
        <div className="flex grow flex-col overflow-hidden md:flex-grow-0">
          <RadioGroup value={selectedItem} onChange={_toggle} className="grid grid-cols-1 gap-2">
            {countries.map((country) => (
              <RadioButtonCard value={country} key={country.key}>
                <div className="flex items-center gap-2">
                  <img src={`/images/${country.key.toUpperCase()}.svg`} alt="" className="h-full w-[16px]" />
                  <div>{t(`countries.${country.key.toUpperCase()}`)}</div>
                </div>
              </RadioButtonCard>
            ))}
          </RadioGroup>
        </div>

        <div className="sticky bottom-0 flex w-full bg-white pb-4 pt-2">
          <Button
            variant="primary"
            disabled={!selectedItem}
            onClick={handleSubmit}
            data-testid="select-country-submit"
            text={t("save")}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default SelectCountryPage;
