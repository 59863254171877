import classNames from "classnames";
import EmptyListSelectionIllustration from "components/atoms/illustration/EmptyListSelectionIllustration";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = BaseComponent;

const EmptyAutomatedMessagesSelection = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames("flex h-full w-full grow flex-col items-center justify-center gap-6", className)}
      data-testid={dataTestId}
    >
      <EmptyListSelectionIllustration />
      <div className="mx-20 text-center text-sm text-gray-500">{t("automatedMessages.noSelectionDescription")}</div>
    </div>
  );
};

export default EmptyAutomatedMessagesSelection;
