import { ProductDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import { getDateRelativeToNow } from "helper/dateAndTimeHelper";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiEllipsisHorizontal, HiShoppingBag } from "react-icons/hi2";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import ContentReportPopUp from "./ContentReportPopUp";
import ProductInfoBanner from "./ProductInfoBanner";
import ContentMedia from "components/media/ContentMedia";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import StatefulLink from "components/atoms/utils/StatefulLink";
import { useFirstline } from "@first-line/firstline-react";

type Props = {
  product?: ProductDto;
  user?: PublicUserDto;
  deletedCallback?: () => void;
  isLoading?: boolean;
};

const Product = ({ product, user, deletedCallback, isLoading = false }: Props) => {
  const navigate = useStatefulNavigate();
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { user: authUser } = useFirstline();
  const [modifyMenuOpen, setModifyMenuOpen] = useState(false);
  const [confirmDeletionOpen, setConfirmDeletionOpen] = useState(false);

  const isOwnContent = user?.username === authUser?.username;

  const profileLink = useMemo(() => {
    return `/creator/${user?.username}`;
  }, [user]);

  const deleteProduct = useCallback(async () => {
    if (product) {
      setConfirmDeletionOpen(false);
      setModifyMenuOpen(false);
      const response = await maloumClient.products.delete(product._id);
      if (!(response instanceof Error)) {
        if (deletedCallback) {
          deletedCallback();
        }
      }
    }
  }, [product, deletedCallback]);

  const handleClosePopup = useCallback(
    () => (confirmDeletionOpen ? setConfirmDeletionOpen(false) : setModifyMenuOpen(false)),
    [confirmDeletionOpen, setConfirmDeletionOpen, setModifyMenuOpen],
  );

  if (isLoading || !product || !user) {
    return (
      <div className={"animate-pulse py-3"} data-testid={"post-item"}>
        <div className={"flex items-center px-4 pb-1 md:px-0"}>
          <div className={"mr-3 h-8 w-8 cursor-pointer rounded-full bg-gray-200"} />
          <div className={"flex flex-1 cursor-pointer"}>
            <div className={"h-3 w-[200px] rounded-full bg-gray-200"} />
          </div>
          <div className={"cursor-pointer py-2 pl-2"}>
            <HiEllipsisHorizontal className={"h-7 w-7 text-gray-300"} />
          </div>
        </div>
        <div className={"aspect-square w-full cursor-pointer bg-gray-200"} />
        <div className={"flex items-center justify-between px-1 py-1"} />

        <div className={"px-3"}>
          <div className={"mb-2 h-2 rounded-full bg-gray-200"} />
          <div className={"mb-2 h-2 rounded-full bg-gray-200"} />
          <div className={"h-2 w-3/4 rounded-full bg-gray-200"} />
        </div>
      </div>
    );
  }

  const viewProduct = () => {
    navigate(`/product/${product._id}`);
  };

  const getPopupTitle = () => {
    return confirmDeletionOpen ? t("product.deleteConfirmation") : t("product.modify");
  };

  const modifyOptions = [
    {
      title: t("edit"),
      onClick: () => navigate(`/product/${product._id}/edit`),
    },
    {
      title: t("delete"),
      testId: "delete-product",
      onClick: () => {
        setConfirmDeletionOpen(true);
      },
    },
  ];

  const deleteOptions = [
    {
      title: t("delete"),
      testId: "confirm-delete-product",
      onClick: async () => await deleteProduct(),
    },
  ];

  return (
    <div className={"py-3"} data-testid={"post-item"}>
      <div className={"flex items-center px-4 pb-1 md:px-0"}>
        <ProfileImageWithLink
          url={product.createdBy.profilePictureThumbnail?.url}
          className={"mr-3 h-8 w-8"}
          uploadStatus={product.createdBy.profilePictureThumbnail?.uploadStatus}
          to={profileLink}
        />
        <StatefulLink to={profileLink} className={"notranslate flex h-10 flex-1 items-center gap-x-1"}>
          {user.username} {user.isVerified && <VerifiedUserIcon />}
        </StatefulLink>

        <div className={"cursor-pointer py-2 pl-2"} onClick={() => setModifyMenuOpen(true)} data-testid={"modify-item"}>
          <HiEllipsisHorizontal className={"h-7 w-7"} />
        </div>
      </div>

      <button className="relative w-full cursor-pointer bg-beige-400" onClick={viewProduct}>
        <ContentMedia media={product.media[0]} thumbnail={product.thumbnail} />
        <div
          data-testid="shopping-bag-display"
          className="absolute bottom-14 left-4 flex h-10 w-10 items-center justify-center rounded-full bg-white/25"
        >
          <HiShoppingBag className="h-6 w-6 text-red-900" />
        </div>
        <ProductInfoBanner price={product.price} name={product.name} />
      </button>
      <span className={"px-4 text-xs text-gray-300 md:px-0"}>{getDateRelativeToNow(new Date(product.createdAt))}</span>

      {isOwnContent ? (
        <PopUp
          isOpen={modifyMenuOpen || confirmDeletionOpen}
          onClose={handleClosePopup}
          title={getPopupTitle()}
          options={confirmDeletionOpen ? deleteOptions : modifyOptions}
        />
      ) : (
        <ContentReportPopUp id={product._id} isProduct isOpen={modifyMenuOpen} setIsOpen={setModifyMenuOpen} />
      )}
    </div>
  );
};

export default Product;
