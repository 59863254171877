import { HiXMark } from "react-icons/hi2";

type Props = {
  interest: string;
  onRemove?: () => void;
  "data-testid"?: string;
};

const InterestBadge = ({ interest, onRemove, "data-testid": dataTestId }: Props) => {
  return (
    <div
      className={"inline-flex items-center rounded-full bg-beige-400 px-3 py-1 align-middle font-medium"}
      data-testid={dataTestId}
    >
      <span>{interest}</span>
      {onRemove && (
        <span className={"ml-2 cursor-pointer p-0.5"}>
          <HiXMark className={"h-5 w-5"} onClick={onRemove} data-testid={"remove-interest"} />
        </span>
      )}
    </div>
  );
};

export default InterestBadge;
