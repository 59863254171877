import classNames from "classnames";
import { FieldHookConfig, useField } from "formik";
import { ReactNode, useEffect } from "react";
import ValidationError from "../utilities/ValidationError";

type TVariant = "underline" | undefined;

type Props = {
  label?: string | null;
  hint?: string;
  Icon?: JSX.Element;
  spacing?: string;
  required?: boolean;
  className?: string;
  variant?: TVariant;
  onChange?: (value: string) => void; // we need to delete this event or adjust the name, because otherwise we have to onChange events , which causes conflict
  prefix?: string;
  suffix?: ReactNode;
  placeholder?: string | null;
  wrapperClassName?: string;
  isTouched?: boolean;
  maxLength?: number;
};

// TODO Remove spacing from inside of component
// TODO: fix input type conflict
const TextInput = ({
  label,
  hint,
  Icon,
  spacing,
  required = false,
  className,
  variant = "underline",
  onChange,
  prefix,
  suffix,
  placeholder,
  wrapperClassName,
  isTouched,
  maxLength,
  ...props
}: Props & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const { value } = meta;
  const { setTouched } = helpers;

  const styling = variant === "underline" ? "input-underline" : "input-field";
  let inputStyle = meta.touched && meta.error ? `${styling} error` : styling;

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (isTouched !== undefined) {
      setTouched(isTouched);
    }
  }, [isTouched]);

  return (
    <div className={classNames(wrapperClassName, spacing, { "mb-5": !spacing })}>
      {label && (
        <label htmlFor={props.id || props.name} className={"text-xs"}>
          {label} {required && <span> *</span>}
        </label>
      )}
      <div className="relative flex border-b border-b-gray-400 focus-within:border-b-red-900">
        {prefix && (
          <span className={classNames(inputStyle, "!w-[unset] !pr-1.5")} style={{ borderBottomStyle: "none" }}>
            {prefix}
          </span>
        )}
        <input
          type="text"
          className={classNames(inputStyle, className)}
          style={{ borderBottomStyle: "none" }}
          placeholder={placeholder}
          maxLength={maxLength}
          {...field}
          {...props}
        />
        {Icon && <button type="button">{Icon}</button>}
        {suffix && (
          <span className={classNames(inputStyle, "!w-[unset] !pl-1.5")} style={{ borderBottomStyle: "none" }}>
            {suffix}
          </span>
        )}
      </div>
      {maxLength !== undefined && <div className="mt-1 text-left text-xs">{`${value.length}/${maxLength}`}</div>}
      <div>
        {meta.touched && meta.error && <ValidationError className="mt-2" message={meta.error} hasBackground={false} />}
      </div>
      <div>{hint && <p className="text-xs">{hint}</p>}</div>
    </div>
  );
};

export default TextInput;
