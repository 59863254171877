import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import lodash from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileImage from "../basics/ProfileImage";
import NumberBadge from "../utilities/NumberBadge";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";

type Props = {
  user?: PublicUserDto | PrivateUserDto;
  isCurrentUser?: boolean;
};

const CreatorProfileInfo = ({ user, isCurrentUser = false }: Props) => {
  const { t } = useTranslation();

  const [clamped, setClamped] = useState(true);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const hasClamping = (el: HTMLDivElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("line-clamp-3");
        // Make sure that CSS clamping is applied if applicable.
        if (!hadClampClass) containerRef.current.classList.add("line-clamp-3");
        // Check for clamping and show or hide button accordingly.
        setShowMoreButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("line-clamp-3");
      }
    };

    const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  if (!user) {
    return <Loader isCurrentUser={isCurrentUser} />;
  }
  return (
    <>
      <div className="mb-3 flex gap-x-6">
        <ProfileImage
          url={user?.profilePictureThumbnail?.url}
          className={"h-24 min-h-[6rem] w-24 min-w-[6rem]"}
          uploadStatus={user.profilePictureThumbnail?.uploadStatus}
          isCurrentUser={isCurrentUser}
        />
        <div className="flex flex-1 flex-col justify-between">
          <h1 className="notranslate mb-1 flex flex-shrink items-center gap-x-1 truncate font-serif text-2xl">
            {user.username} {user.isVerified && <VerifiedUserIcon className={"mt-1 h-5 w-5"} />}
          </h1>
          <div className={"h-5"}>
            {user.isGenderPublic && user.gender?.name && user.gender.name !== "preferNotToSay" && (
              <span className="block text-sm text-gray-500" data-testid="user-gender-name">
                {t(`gender.${user.gender.name}`)}
              </span>
            )}
          </div>
          <div
            className={classNames("mt-3 flex max-w-[14rem] flex-row", {
              "justify-between": isCurrentUser,
              "gap-12": !isCurrentUser,
            })}
          >
            {isCurrentUser && <NumberBadge number={user.postLikeCount} title={t("likes")} />}
            <NumberBadge number={user.publicPostCount} title={t("public")} />
            <NumberBadge number={user.privatePostCount} title={t("private")} />
          </div>
        </div>
      </div>

      <p
        className={classNames("notranslate whitespace-pre-wrap break-words", { "line-clamp-3": clamped })}
        ref={containerRef}
        data-testid="user-bio"
      >
        {user.bio}
      </p>

      {user.bio && showMoreButton && (
        <button
          className={"-ml-2 inline w-fit cursor-pointer select-none p-2 text-xs"}
          onClick={() => setClamped(!clamped)}
          data-testid="show-more-bio-button"
        >
          {clamped ? t("showMore") : t("showLess")}
        </button>
      )}
    </>
  );
};

const Loader = ({ isCurrentUser }: { isCurrentUser: boolean }) => (
  <div className={"animate-pulse"}>
    <div className="mb-3 flex space-x-6">
      <div className={"h-24 min-h-[6rem] w-24 min-w-[6rem] rounded-full bg-gray-200 "} />
      <div className="flex flex-1 flex-col justify-between">
        <div>
          <div className={"mb-6 mt-2 h-6 w-44 rounded-full bg-gray-200"} />
          <div className={"h-2 w-24 rounded-full bg-gray-200"} />
        </div>

        <div
          className={classNames("mt-3 flex max-w-[14rem] flex-row", {
            "justify-between": isCurrentUser,
            "gap-12": !isCurrentUser,
          })}
        >
          <div className={"flex flex-col"}>
            <div className={"mb-1 h-4 w-12 rounded-full bg-gray-200"} />
            <div className={"h-2 w-10 rounded-full bg-gray-200"} />
          </div>

          <div className={"flex flex-col"}>
            <div className={"mb-1 h-4 w-12 rounded-full bg-gray-200"} />
            <div className={"h-2 w-10 rounded-full bg-gray-200"} />
          </div>

          {isCurrentUser && (
            <div className={"flex flex-col"}>
              <div className={"mb-1 h-4 w-12 rounded-full bg-gray-200"} />
              <div className={"h-2 w-10 rounded-full bg-gray-200"} />
            </div>
          )}
        </div>
      </div>
    </div>

    <div className="mt-6">
      <div className={"my-2 h-4 rounded-full bg-gray-200"} />
      <div className={"mb-2 h-4 w-3/4 rounded-full bg-gray-200"} />
      <div className={"h-1 w-16 rounded-full bg-gray-200"} />
    </div>
  </div>
);
export default CreatorProfileInfo;
