import classNames from "classnames";
import Spinner from "components/atoms/Spinner";

type UnprocessedProfileImageProps = {
  className?: string;
  spinnerClassName?: string;
};

const UnprocessedProfileImage = ({ className = "w-8 h-8", spinnerClassName }: UnprocessedProfileImageProps) => (
  <div
    className={classNames(
      "flex items-center justify-center rounded-full border border-gray-200 bg-gray-100",
      className,
    )}
  >
    <Spinner spinnerClassName={spinnerClassName} />
  </div>
);

export default UnprocessedProfileImage;
