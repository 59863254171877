import classNames from "classnames";

type Props = {
  imageUrl?: string;
  altText?: string;
  className?: string;
  isLoading?: boolean;
};
const ProductThumbnail = ({ imageUrl, altText, className, isLoading }: Props) => {
  if (isLoading) {
    return <div className={classNames("flex animate-pulse rounded-md bg-gray-200", className)}></div>;
  }

  return <img src={imageUrl} alt={altText} className={classNames("rounded-md", className)} />;
};

export default ProductThumbnail;
