import React from "react";
import classNames from "classnames";

type Props = {
  isLoaded: boolean;
  style: React.CSSProperties;
  className?: string;
};
const MediaPlaceholder = ({ isLoaded, style, className }: Props) => {
  return (
    <div
      className={classNames(
        "w-full animate-pulse bg-gray-200",
        {
          hidden: isLoaded,
          block: !isLoaded,
        },
        className,
      )}
      style={style}
    />
  );
};

export default MediaPlaceholder;
