import { useContext } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { SentBroadcastDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client/models/SentBroadcastDtoKeysetResponse";

const useSentBroadcasts = () => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const fetchSentBroadcasts = async ({ pageParam }: { pageParam?: string }) => {
    return maloumClient.broadcasts.getSentBroadcasts(pageParam);
  };

  const { data, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["sent-broadcasts"],
    fetchSentBroadcasts,
    {
      getNextPageParam: (lastPage) => lastPage.next || null,
    },
  );

  const revokeBroadcast = async (broadcastId: string) => {
    await maloumClient.broadcasts.revokeBroadcast(broadcastId);
    return broadcastId;
  };

  const revokeBroadcastMutation = useMutation({
    mutationFn: revokeBroadcast,
    onSuccess: (broadcastId) => {
      queryClient.setQueryData(["sent-broadcasts"], (oldData: any) => {
        return {
          ...oldData,
          pages: oldData.pages.map((page: SentBroadcastDtoKeysetResponse) => ({
            data: page.data.map((broadcast) =>
              broadcast._id === broadcastId ? { ...broadcast, isRevoked: true } : broadcast,
            ),
            next: page.next,
          })),
        };
      });
    },
  });

  return {
    broadcasts: data?.pages.flatMap((page) => page.data) || [],
    isLoading: isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    revokeBroadcastMutation,
  };
};

export default useSentBroadcasts;
