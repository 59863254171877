import classNames from "classnames";
import useUploadStore from "state/uploadState";
import { BaseComponent } from "types/components/component.type";
import {useTranslation} from "react-i18next";

type Props = BaseComponent;

const UploadsCallout = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { uploads } = useUploadStore();

  const mediasStillUploading = uploads.filter((u) => u.status === "uploading");

  if (mediasStillUploading.length === 0) return <></>;

  return (
    <div className={classNames("rounded-md bg-beige-400 p-2 text-sm", className)} data-testid={dataTestId}>
      {t("vault.uploadWarning")}
    </div>
  );
};

export default UploadsCallout;
