import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useRef } from "react";
import useMessagesStore from "state/messagesState";
import { DeleteMessageDto, MessageDto } from "@neolime-gmbh/api-gateway-client";
import SocketContext from "contexts/SocketContext";

const useSendMessage = (chatId: string) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { removeMessageFromChat, setMessagesForChat } = useMessagesStore();
  const messages = useMessagesStore((state) => state.messages[chatId] || []);
  const messagesRef = useRef<MessageDto[]>([]);
  messagesRef.current = messages;
  const socket = useContext(SocketContext);

  // TODO: rework content type
  const emitSendMessageEvent = (chatId: string, content: any) => {
    socket?.emit("send_message", {
      chat: chatId,
      content: content,
    });
  };

  const deleteMessage = (messageId: string, deleteTextOnly: boolean, deleteBroadcastMessage?: boolean) => {
    if (deleteTextOnly) {
      const messagesTmp = messagesRef.current;
      const msgIndex = messagesTmp.findIndex((m) => m._id === messageId);
      messagesTmp[msgIndex].content.text = undefined;

      setMessagesForChat(chatId, messagesTmp);
    } else {
      removeMessageFromChat(messageId, chatId);
    }
    socket?.emit("delete_message", {
      chat: chatId,
      message: messageId,
      deleteTextOnly,
      deleteBroadcastMessage,
    } as DeleteMessageDto);
  };

  const sendTextMessage = (message: string) => {
    emitSendMessageEvent(chatId, {
      type: "text",
      text: message,
    });
  };

  const sendMediaMessage = (mediaIds: string[], message: string | undefined) => {
    emitSendMessageEvent(chatId, {
      type: "media",
      text: message,
      mediaIds: mediaIds,
    });
  };

  const sendChatProduct = async (mediaIds: string[], netAmount: number, sentTo: string, message?: string) => {
    await maloumClient.chatProducts
      .create({ mediaIds: mediaIds, netAmount: netAmount, sentTo: [sentTo] })
      .then((chatProduct) => {
        emitSendMessageEvent(chatId, {
          type: "chat_product",
          chatProductId: chatProduct._id,
          ...(message !== undefined && { text: message }),
        });
      });
  };

  return { sendTextMessage, sendMediaMessage, sendChatProduct, deleteMessage };
};

export default useSendMessage;
