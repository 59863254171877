import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

type Props = {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button";
};

const SecondaryButton = ({ children, onClick, className, type, disabled }: Props) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        "rounded-md border border-red-900 p-2 text-red-900 hover:border-red-600 hover:text-red-600 active:border-red-800 active:text-red-800 disabled:text-gray-300",
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default SecondaryButton;
