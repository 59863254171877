import classNames from "classnames";
import DeleteMediaPopup from "components/molecules/vault/popups/DeleteMediaPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineFolderOpen,HiOutlineTrash } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import Button from "components/basics/Button";
import TernaryButton from "components/atoms/buttons/TernaryButton";

type Props = {
  currentlySelectedFolderId: string | undefined;
  isSelectingMedia: boolean;
  allowMultipleSelect: boolean;
  selectedMedia: TSelectedMedia[];
  clearMedia: () => void;
  handleOnSubmit: () => void;
  handleNavigateToMoveMedia: () => void;
} & BaseComponent;

const MediaSelectionStickyBottomBar = ({
  currentlySelectedFolderId,
  isSelectingMedia,
  allowMultipleSelect,
  selectedMedia,
  clearMedia,
  handleOnSubmit,
  handleNavigateToMoveMedia,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const [deleteMediaPopupIsOpen, setDeleteMediaPopupIsOpen] = useState(false);

  if (!isSelectingMedia && selectedMedia.length === 0) return <></>;

  if (isSelectingMedia)
    return (
      <>
        <div
          className={classNames("sticky bottom-0 z-10 border-t border-gray-100", className)}
          data-testid={dataTestId}
        >
          {allowMultipleSelect && selectedMedia.length > 0 && (
            <SelectionInformation
              message={t("vault.xMediaSelected", { count: selectedMedia.length })}
              showActions={false}
            />
          )}
          <div className={classNames("bg-white px-4 py-4 md:px-0")}>
            <Button text={t("next")} onClick={handleOnSubmit} />
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className={classNames("sticky bottom-0 z-10 border-t border-gray-100", className)} data-testid={dataTestId}>
        <SelectionInformation
          message={t("vault.xMediaSelected", { count: selectedMedia.length })}
          showActions
          onMoveClick={handleNavigateToMoveMedia}
          onDeleteClick={() => setDeleteMediaPopupIsOpen(true)}
        />
      </div>
      <DeleteMediaPopup
        currentlySelectedFolderId={currentlySelectedFolderId}
        selectedMedia={selectedMedia}
        clearMedia={clearMedia}
        open={deleteMediaPopupIsOpen}
        onClose={() => setDeleteMediaPopupIsOpen(false)}
      />
    </>
  );
};

type SelectionInformationProps = {
  message: string;
  showActions: boolean;
  onMoveClick?: () => void;
  onDeleteClick?: () => void;
};

const SelectionInformation = ({ message, showActions, onMoveClick, onDeleteClick }: SelectionInformationProps) => {
  return (
    <div className={classNames("flex min-h-16 items-center bg-white/90 px-3 py-3")}>
      <div>{message}</div>
      <div className="grow" />
      {showActions && onMoveClick && (
        <TernaryButton onClick={onMoveClick}>
          <HiOutlineFolderOpen className="h-6 w-6" />
        </TernaryButton>
      )}
      {showActions && onDeleteClick && (
        <TernaryButton onClick={onDeleteClick}>
          <HiOutlineTrash className="h-6 w-6" />
        </TernaryButton>
      )}
    </div>
  );
};

export default MediaSelectionStickyBottomBar;
