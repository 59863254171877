import { ReactNode } from "react";
import classNames from "classnames";

type Props = {
  onClick: () => void;
  children: ReactNode;
  className?: string;
};

const PillButton = ({ onClick, children, className }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "inline-flex items-center gap-1 whitespace-nowrap rounded-full bg-gray-100 px-4 py-1 text-sm",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default PillButton;
