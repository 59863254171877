import { useQueryClient } from "react-query";

const useQueryManipulator = () => {
  const queryClient = useQueryClient();

  const invalidateChat = (chatId: string) => {
    invalidateQueries(["fetch-chat", chatId]);
  };

  const setChatData = (chatId: string, data: any) => {
    setQueryData(["fetch-chat", chatId], data);
  };

  //keep this private
  const invalidateQueries = (key: string | string[]) => {
    queryClient.invalidateQueries(key);
  };

  //keep this private
  const setQueryData = (key: string | string[], value: any) => {
    queryClient.setQueryData(key, value);
  };

  return { invalidateChat, setChatData };
};

export default useQueryManipulator;
