import { BaseComponent } from "types/components/component.type";

type Props = BaseComponent;

const EmptyListSelectionIllustration = ({ className, "data-testid": dataTestId }: Props) => {
  return (
    <svg
      width="134"
      height="122"
      viewBox="0 0 134 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={dataTestId}
    >
      <g clipPath="url(#clip0_10402_65407)">
        <path
          d="M115.984 103.028H17.6875V86.466H19.2039V74.1385C19.2072 71.2341 20.3712 68.4496 22.4405 66.3958C24.5099 64.3421 27.3156 63.1869 30.242 63.1836H103.608C106.535 63.1869 109.34 64.3421 111.41 66.3959C113.479 68.4496 114.643 71.2341 114.646 74.1385V86.466H115.984V103.028Z"
          fill="#E4E4E4"
        />
        <path
          d="M118.648 121.256H113.754V121.168C113.749 116.873 112.028 112.755 108.968 109.718C105.908 106.681 101.759 104.972 97.431 104.967H36.2406C31.9129 104.972 27.7638 106.681 24.7036 109.718C21.6435 112.755 19.9221 116.873 19.9172 121.168V121.256H15.0239C13.9091 121.255 12.8403 120.815 12.0519 120.033C11.2636 119.25 10.8202 118.189 10.819 117.083V90.0097C9.39801 89.2129 8.24231 84.8857 8.99299 81.4299C9.46409 79.2612 10.8241 76.642 14.6278 76.4798C18.3306 76.3218 21.0789 79.7718 21.3244 83.0929C21.4575 84.8404 20.9403 86.5758 19.8708 87.9704H19.9172V91.8326C27.9466 89.2789 35.9874 87.9795 43.8179 87.9704C51.8309 87.9781 59.7881 89.2924 67.3716 91.8608C83.1392 86.7994 98.7434 86.7009 113.754 91.5692V87.9704H113.801C112.731 86.5758 112.214 84.8404 112.347 83.0929C112.616 79.4502 115.619 76.4836 119.04 76.4798H119.051C123.085 76.4798 124.512 78.8901 124.999 80.9132C125.86 84.4936 124.279 89.2063 122.853 90.0096V117.083C122.851 118.189 122.408 119.25 121.62 120.033C120.831 120.815 119.762 121.255 118.648 121.256Z"
          fill="#CACACA"
        />
        <path d="M91.3086 114.012L93.6706 115.687L101.303 107.442L97.8165 104.971L91.3086 114.012Z" fill="#FFB6B6" />
        <path
          d="M91.2577 112.818L91.9225 113.29L95.2721 114.082L95.9092 116.116L95.9094 116.116C96.6955 116.674 97.2263 117.518 97.3851 118.464C97.5438 119.41 97.3175 120.38 96.756 121.16L96.6872 121.255L89.0713 115.856L91.2577 112.818Z"
          fill="#2F2E41"
        />
        <path
          d="M107.315 91.9381H89.9717C88.3545 91.9372 86.8037 91.299 85.6605 90.1638C84.5172 89.0285 83.875 87.4891 83.875 85.8841C83.875 84.279 84.5172 82.7397 85.6605 81.6044C86.8037 80.4692 88.3545 79.8309 89.9717 79.8301H107.315C108.933 79.8301 110.484 80.4679 111.628 81.6033C112.772 82.7386 113.415 84.2785 113.415 85.8841C113.415 87.4897 112.772 89.0296 111.628 90.1649C110.484 91.3003 108.933 91.9381 107.315 91.9381Z"
          fill="#2F2E41"
        />
        <path
          d="M98.8053 49.6641L101.603 51.7781L104.976 52.8071C104.976 52.8071 106.371 72.2777 107.628 73.525C108.884 74.7722 107.751 74.8057 108.004 76.426C108.256 78.0462 108.87 81.3675 108.87 81.3675C100.387 85.824 93.074 85.9435 87.1997 80.7287C87.1246 80.4061 87.1627 80.0677 87.3079 79.7696C87.4531 79.4715 87.6966 79.2316 87.998 79.0897C89.0913 78.5139 86.9902 75.8267 87.8836 75.3774C88.7771 74.9281 87.5204 71.0305 87.5204 71.0305L88.3448 62.8984L87.6935 61.5985L89.283 53.6444L92.041 52.6448L93.1426 50.0104L98.8053 49.6641Z"
          fill="#F33800"
        />
        <path
          d="M100.558 31.4846C100.546 31.8362 100.611 32.1863 100.749 32.5102C100.888 32.834 101.096 33.1238 101.36 33.3591C101.624 33.5944 101.936 33.7695 102.275 33.872C102.614 33.9745 102.972 34.002 103.323 33.9525L107.265 41.5577L109.462 37.6846L105.381 31.0563C105.258 30.4767 104.925 29.9618 104.446 29.6095C103.966 29.2571 103.373 29.0916 102.778 29.1444C102.184 29.1972 101.629 29.4646 101.22 29.8959C100.811 30.3272 100.575 30.8925 100.558 31.4846Z"
          fill="#FFB6B6"
        />
        <path
          d="M107.198 33.3457L115.654 46.3527L115.626 46.4126C115.594 46.4811 113.679 50.5855 111.824 54.3449C111.538 54.9239 111.254 55.4946 110.978 56.0412C110.859 56.2771 110.741 56.508 110.626 56.7341C109.847 58.2588 109.166 59.519 108.759 60.1177C107.86 61.4711 106.522 62.4788 104.967 62.9732L104.927 62.9832L104.89 62.9678C104.129 62.6491 103.5 62.0827 103.107 61.3618C102.715 60.6409 102.581 59.8086 102.728 59.0022C103.462 55.1125 105.096 51.4452 107.503 48.2898L108.873 46.4965L104.109 36.2174L107.198 33.3457Z"
          fill="#F33800"
        />
        <path
          d="M92.5882 29.8928C92.6008 30.2444 92.5355 30.5945 92.3969 30.9184C92.2584 31.2422 92.05 31.532 91.7864 31.7673C91.5228 32.0026 91.2105 32.1777 90.8713 32.2802C90.5322 32.3827 90.1745 32.4102 89.8235 32.3607L85.881 39.9659L83.6846 36.0928L87.7651 29.4645C87.888 28.8849 88.2209 28.3701 88.7007 28.0177C89.1805 27.6653 89.7738 27.4998 90.3683 27.5526C90.9628 27.6054 91.5172 27.8728 91.9263 28.3041C92.3354 28.7354 92.571 29.3007 92.5882 29.8928Z"
          fill="#FFB6B6"
        />
        <path
          d="M85.9482 31.752L77.4922 44.759L77.5201 44.8188C77.552 44.8874 79.4669 48.9918 81.3223 52.7512C81.608 53.3301 81.8923 53.9009 82.1681 54.4474C82.2872 54.6833 82.4044 54.9143 82.5199 55.1403C83.2992 56.665 83.9801 57.9253 84.3865 58.5239C85.2854 59.8774 86.6238 60.885 88.1793 61.3795L88.2185 61.3895L88.2558 61.374C89.0167 61.0554 89.6455 60.4889 90.0384 59.768C90.4314 59.0471 90.5651 58.2149 90.4175 57.4084C89.684 53.5187 88.0497 49.8514 85.6434 46.696L84.2725 44.9027L89.0365 34.6236L85.9482 31.752Z"
          fill="#F33800"
        />
        <path
          d="M95.9416 48.4872C98.9976 48.4872 101.475 46.0285 101.475 42.9956C101.475 39.9626 98.9976 37.5039 95.9416 37.5039C92.8856 37.5039 90.4082 39.9626 90.4082 42.9956C90.4082 46.0285 92.8856 48.4872 95.9416 48.4872Z"
          fill="#FFB6B6"
        />
        <path
          d="M91.4351 38.2785C91.8902 36.9646 94.1554 36.0533 97.8278 35.9266C101.5 35.7999 102.432 40.0949 102.432 40.0949C104.883 42.0687 99.8137 47.3529 99.5685 47.1625L99.6787 46.1697C99.8613 44.5253 100.637 43.2422 99.6505 41.5284L98.703 41.5392L96.9058 41.8822C96.7938 41.9036 96.6778 41.8878 96.5757 41.8373C96.4736 41.7868 96.3911 41.7043 96.3409 41.6027C96.2906 41.501 96.2755 41.3858 96.2977 41.2748C96.32 41.1637 96.3785 41.0631 96.4641 40.9882L96.4805 40.9739L96.4397 40.9111C95.8066 41.1994 95.1542 41.4667 94.4621 41.5071C93.6834 41.5525 92.8321 41.2449 92.4591 40.565C92.3596 40.3781 92.2987 40.1733 92.2802 39.9627C91.2302 40.3791 90.75 41.6638 90.75 41.6638C90.75 41.6638 90.9801 39.5924 91.4351 38.2785Z"
          fill="#2F2E41"
        />
        <path d="M106.379 111.675L104.164 113.537L95.875 105.943L99.1443 103.195L106.379 111.675Z" fill="#FFB6B6" />
        <path
          d="M108.763 113.331L101.62 119.334L101.543 119.244C100.919 118.513 100.613 117.565 100.693 116.609C100.773 115.653 101.232 114.768 101.969 114.149L101.97 114.149L102.436 112.07L105.709 111.006L106.332 110.482L108.763 113.331Z"
          fill="#2F2E41"
        />
        <path
          d="M92.6894 90.677L94.5204 86.7363C94.4999 83.1418 91.4681 80.2225 87.8534 80.4478C85.8403 80.5732 83.9553 81.6907 83.1765 85.0118C81.1738 93.552 100.824 111.324 100.824 111.324L104.435 109.11L104.601 106.77L102.579 105.533L102.577 103.538L100.649 102.63L92.6894 90.677Z"
          fill="#2F2E41"
        />
        <path
          d="M104.718 89.5917L102.455 86.289C102.178 82.705 104.957 79.5482 108.579 79.4777C110.595 79.4385 112.566 80.3983 113.618 83.6447C116.321 91.9925 98.2099 111.308 98.2099 111.308L94.428 109.396L94.0684 107.078L95.9811 105.68L95.8176 103.691L97.6641 102.629L104.718 89.5917Z"
          fill="#2F2E41"
        />
        <path d="M59.1586 118.454L56.4519 118.454L55.1641 108.092L59.1591 108.092L59.1586 118.454Z" fill="#A0616A" />
        <path
          d="M59.2545 121.256L50.9307 121.256V121.151C50.9307 120.298 51.2721 119.481 51.8797 118.878C52.4873 118.275 53.3113 117.936 54.1705 117.936H54.1707L55.6912 116.791L58.5281 117.936L59.2547 117.936L59.2545 121.256Z"
          fill="#2F2E41"
        />
        <path d="M78.3113 118.454L81.0179 118.454L82.3056 108.092L78.3105 108.092L78.3113 118.454Z" fill="#A0616A" />
        <path
          d="M78.216 121.256L86.5398 121.256V121.151C86.5398 120.298 86.1984 119.481 85.5908 118.878C84.9832 118.275 84.1592 117.936 83.2999 117.936H83.2997L81.7793 116.791L78.9424 117.936L78.2158 117.936L78.216 121.256Z"
          fill="#2F2E41"
        />
        <path
          d="M55.0452 100.477C55.0852 99.8854 55.4031 96.6156 55.4031 96.6156L56.1644 89.681L57.5063 71.299L57.5938 70.0957L64.623 68.7564L69.427 66.4707L75.1371 68.2823L79.5834 69.9371C79.5834 69.9371 79.591 70.6454 79.6252 71.5012C79.6709 72.6214 79.7509 73.9947 79.9412 74.3517C80.259 74.9826 80.1791 78.3696 80.1791 78.3696C80.1791 78.3696 80.4951 85.2607 80.8929 86.0163C81.2907 86.7719 81.3706 88.3455 81.2507 88.6609C81.1308 88.9764 81.6485 97.2522 81.6485 97.2522C81.6485 97.2522 82.9773 111.897 82.1436 114.105C81.3099 116.314 78.1597 114.867 78.1597 114.867C78.1597 114.867 78.3119 114.278 77.3983 114C76.4847 113.722 73.702 92.9566 73.702 92.9566C73.702 92.9566 73.1843 88.6987 72.7084 87.4784C72.2326 86.2581 71.7948 82.8296 71.7948 82.8296C71.7948 82.8296 68.856 77.155 68.818 75.8931C68.818 75.8931 68.4335 74.7125 68.0699 76.0121C67.7064 77.3117 66.6348 80.4248 66.6348 80.4248L65.5898 84.2482L64.3603 89.9605L63.5647 94.77C63.5647 94.77 62.3732 101.153 62.1828 101.429C61.9925 101.705 62.1828 107.536 62.1828 107.536C62.1828 107.536 61.2616 112.502 59.9597 113.369C58.6578 114.236 55.2086 113.383 55.2086 113.383C55.2086 113.383 55.0053 101.068 55.0452 100.477Z"
          fill="#2F2E41"
        />
        <path
          d="M58.0441 50.921L59.6067 54.9819L57.6785 56.9622L58.5275 57.7159L57.9014 58.7818L56.2681 69.9513L65.2216 72.8808L68.2365 69.6195L70.6138 72.8808L80.9739 70.6969L78.332 56.4532V55.522L77.5859 53.7241V49.9176L81.0119 38.5871L73.6764 36.8102L70.8652 34.8906L69.9553 37.5903L66.082 36.9958L65.981 34.9678L63.0175 37.3833L59.0661 37.9975L56.078 38.5871L55.6973 39.4081L56.4585 39.2438L57.7909 49.9258L58.0441 50.921Z"
          fill="#2F2E41"
        />
        <path
          d="M64.069 39.9566L65.981 34.9794L70.865 34.8906L72.4857 39.9566L78.3109 72.2526C78.3109 72.2526 60.4649 77.1849 60.6459 71.256C60.8362 65.0223 61.695 56.7418 61.695 56.7418L64.069 39.9566Z"
          fill="#3F3D56"
        />
        <path
          d="M68.314 33.5861C71.4915 33.5861 74.0674 31.0296 74.0674 27.8761C74.0674 24.7225 71.4915 22.166 68.314 22.166C65.1364 22.166 62.5605 24.7225 62.5605 27.8761C62.5605 31.0296 65.1364 33.5861 68.314 33.5861Z"
          fill="#A0616A"
        />
        <path
          d="M62.2215 23.7343C63.1582 22.7225 64.3298 20.8704 67.8785 20.0926C72.2634 19.1316 74.3354 23.0629 73.2296 23.1274C76.463 24.0991 74.3859 30.7401 73.4589 30.1831C73.451 30.1776 73.4433 30.1717 73.436 30.1654C72.9375 29.801 72.519 29.3396 72.2062 28.8093C71.8934 28.279 71.6928 27.691 71.6167 27.0812L71.4408 25.5097C71.4157 26.7883 70.0988 26.9575 68.7536 26.4866C68.8331 26.2062 68.9849 25.9513 69.194 25.747C69.4031 25.5426 69.6623 25.396 69.946 25.3214C69.1231 26.0747 68.0519 26.6137 66.5557 26.7854C66.7394 26.0799 67.1052 25.5605 67.678 25.2527L67.6249 25.2009C67.0767 25.615 66.5054 26.013 65.8462 26.2005C65.1047 26.4114 64.2161 26.2973 63.708 25.7212C63.5712 25.5622 63.4679 25.3776 63.4041 25.1784C63.5139 26.191 62.8296 27.3469 62.298 27.1484C61.8558 27.0631 61.598 24.4079 62.2215 23.7343Z"
          fill="#2F2E41"
        />
        <path
          d="M75.408 27.5619L80.6405 36.2987L84.5079 34.4859C84.5079 34.4859 77.8439 26.2253 77.6315 25.9876C77.7225 25.5861 77.6884 25.1667 77.5339 24.7848C77.3794 24.4029 77.1117 24.0766 76.7663 23.8489C76.4208 23.6213 76.0139 23.5031 75.5993 23.5101C75.1847 23.517 74.782 23.6486 74.4444 23.8876C74.1069 24.1266 73.8504 24.4617 73.7089 24.8485C73.5674 25.2353 73.5475 25.6556 73.6519 26.0539C73.7564 26.4522 73.9802 26.8096 74.2937 27.0789C74.6073 27.3481 74.9958 27.5166 75.408 27.5619Z"
          fill="#A0616A"
        />
        <path
          d="M77.9414 49.2722L86.0621 43.4662C86.5919 43.0874 87.0362 42.6031 87.3666 42.0442C87.6971 41.4853 87.9064 40.8641 87.9811 40.2203C88.0559 39.5765 87.9945 38.9244 87.8009 38.3055C87.6072 37.6866 87.2856 37.1147 86.8565 36.6262L81.0123 29.9727L78.4663 33.3967L80.4286 37.4689L76.6289 38.4633L77.9414 49.2722Z"
          fill="#2F2E41"
        />
        <path
          d="M64.0033 28.3823L58.7708 37.119L54.9033 35.3062C54.9033 35.3062 61.5674 27.0456 61.7797 26.808C61.6888 26.4064 61.7228 25.987 61.8773 25.6051C62.0318 25.2232 62.2995 24.8969 62.645 24.6693C62.9904 24.4416 63.3973 24.3235 63.8119 24.3304C64.2265 24.3373 64.6292 24.4689 64.9668 24.7079C65.3044 24.9469 65.5609 25.282 65.7024 25.6688C65.8439 26.0556 65.8637 26.476 65.7593 26.8742C65.6548 27.2725 65.431 27.6299 65.1175 27.8992C64.8039 28.1684 64.4154 28.3369 64.0033 28.3823Z"
          fill="#A0616A"
        />
        <path
          d="M61.47 50.0945L53.3494 44.2885C52.8196 43.9097 52.3753 43.4253 52.0448 42.8664C51.7144 42.3075 51.5051 41.6863 51.4303 41.0425C51.3555 40.3988 51.4169 39.7466 51.6106 39.1277C51.8042 38.5089 52.1259 37.9369 52.5549 37.4484L58.3992 30.7949L60.9452 34.2189L58.9828 38.2911L62.7825 39.2855L61.47 50.0945Z"
          fill="#2F2E41"
        />
        <path
          d="M5.36613 31.6726C5.49089 31.8557 5.65459 32.0094 5.84568 32.1229C6.03677 32.2364 6.2506 32.3069 6.47213 32.3295C6.69365 32.3521 6.91748 32.3262 7.12784 32.2537C7.3382 32.1812 7.52997 32.0637 7.68965 31.9097L12.5267 34.3215L12.1972 31.549L7.65435 29.6837C7.3758 29.4346 7.01487 29.2956 6.63996 29.293C6.26505 29.2904 5.9022 29.4244 5.62015 29.6695C5.33811 29.9147 5.15646 30.254 5.10965 30.6232C5.06283 30.9923 5.15409 31.3657 5.36613 31.6726Z"
          fill="#9E616A"
        />
        <path
          d="M25.377 42.8635L22.9686 41.215C22.9686 41.215 20.2107 41.0294 19.7959 40.1592C19.3811 39.289 19.3112 39.5816 18.869 39.4775C18.4268 39.3734 18.1986 38.0637 17.4574 38.2182C15.7897 38.5658 7.94669 33.4224 8.79317 32.7796L9.42238 30.1465C10.0872 30.3928 10.7804 30.556 11.4859 30.6322C12.5526 30.7288 15.0659 31.9384 15.4382 32.335C15.8104 32.7316 16.4699 32.5779 17.3543 32.786C18.2387 32.9942 18.5482 34.406 20.015 34.0303C22.0788 33.5017 32.7709 38.5348 33.9277 39.0047C35.1973 39.5203 36.0839 41.0573 36.0839 41.0573L35.7343 42.5201L25.377 42.8635Z"
          fill="#F33800"
        />
        <path d="M42.759 117.647H44.858L45.8568 109.611H42.7588L42.759 117.647Z" fill="#9E616A" />
        <path
          d="M42.0732 116.22L45.4267 116.021V117.447L48.615 119.633C48.7725 119.741 48.8911 119.896 48.9535 120.075C49.0159 120.255 49.0188 120.449 48.9619 120.631C48.9049 120.812 48.7911 120.971 48.6369 121.083C48.4828 121.196 48.2964 121.256 48.105 121.256H44.1126L43.4244 119.846L43.1557 121.256H41.6504L42.0732 116.22Z"
          fill="#2F2E41"
        />
        <path d="M16.4502 115.878L18.4004 116.648L22.3229 109.549L19.4446 108.412L16.4502 115.878Z" fill="#9E616A" />
        <path
          d="M16.3458 114.299L19.5356 115.345L19.0042 116.67L21.152 119.871C21.2581 120.029 21.3105 120.216 21.3016 120.406C21.2926 120.596 21.2228 120.778 21.1024 120.925C20.9819 121.073 20.817 121.178 20.6319 121.226C20.4467 121.274 20.2509 121.262 20.0731 121.192L16.3638 119.727L16.25 118.163L15.4748 119.375L14.0762 118.823L16.3458 114.299Z"
          fill="#2F2E41"
        />
        <path
          d="M38.5277 93.7357L35.0275 77.5244L29.5618 95.5365L20.9071 112.617L20.8612 112.707L18.1197 111.242C17.7328 104.307 18.6517 97.8082 22.0126 92.1253L23.6041 76.5273C23.6529 75.9943 24.84 63.4819 28.599 61.263L29.6999 58.7717L40.6491 56.0879L40.689 56.1324C41.7419 57.3069 42.4374 58.7538 42.6947 60.3049L45.9664 79.9757L45.9683 79.9867L45.091 93.7093L46.291 113.955H41.9491C39.263 108.939 38.1226 102.199 38.5277 93.7357Z"
          fill="#2F2E41"
        />
        <path
          d="M47.9534 25.7468C48.1203 25.8931 48.2528 26.074 48.3416 26.2767C48.4304 26.4793 48.4734 26.6989 48.4675 26.9198C48.4616 27.1407 48.407 27.3577 48.3074 27.5554C48.2079 27.7531 48.0659 27.9268 47.8914 28.0641L49.6794 33.135L46.9513 32.4541L45.6717 27.7431C45.4586 27.4369 45.3662 27.0638 45.4118 26.6945C45.4574 26.3252 45.6379 25.9853 45.9191 25.7392C46.2004 25.4931 46.5628 25.358 46.9377 25.3594C47.3126 25.3608 47.674 25.4986 47.9534 25.7468Z"
          fill="#9E616A"
        />
        <path
          d="M26.4721 64.0494C26.1772 64.0494 26.0097 63.992 25.9449 63.8689C25.8497 63.6883 26.0149 63.4524 26.206 63.179C26.3394 62.9886 26.7289 62.6099 26.5374 62.5867C23.6328 62.2346 25.0271 44.4619 26.2859 42.1127C26.5416 41.6353 26.271 40.903 26.559 40.0929C27.3458 37.8807 28.4197 37.2008 30.559 35.8465C30.7327 35.7365 30.9135 35.6217 31.1015 35.502C31.3447 35.3469 31.3312 34.8326 31.3168 34.288C31.301 33.6827 31.2845 33.0567 31.5832 32.6655L31.6027 32.64L31.634 32.6327C32.3567 32.4669 34.7873 32.1591 36.2267 32.635L36.2466 32.6417L36.2614 32.6564C36.6033 32.9957 36.6345 33.653 36.6646 34.2887C36.6893 34.8105 36.7127 35.3033 36.9061 35.5173C38.385 37.1534 40.0624 37.1215 40.0794 37.1212L40.1625 37.1188L40.1707 37.2008C41.2076 39.1595 40.3958 46.4338 41.1165 50.9523C41.8487 55.5429 48.1021 59.4507 42.7475 60.1445C42.759 60.1622 42.7655 60.1826 42.7661 60.2036C42.7668 60.2247 42.7616 60.2455 42.7512 60.2638C42.4277 60.8637 27.5835 64.0494 26.4721 64.0494Z"
          fill="#F33800"
        />
        <path
          d="M50.6779 41.6645L47.706 43.1276C41.9791 45.3109 38.9861 44.7791 37.4824 43.949C35.8779 43.0634 35.5998 41.7056 35.5889 41.6483L35.5879 41.6206L35.9545 38.7386C36.0011 38.3725 36.1255 38.0204 36.3196 37.7057C36.5137 37.391 36.7729 37.1208 37.0802 36.913C37.3875 36.7053 37.7357 36.5648 38.1019 36.5007C38.468 36.4367 38.8437 36.4506 39.204 36.5416L46.0415 38.2686L45.9168 34.9745L46.1872 33.7884L45.6884 32.3467L45.8928 29.4512L48.3454 28.8555L50.918 33.4287L50.4559 34.6909L50.8914 35.3192L50.8905 35.3483L50.6779 41.6645Z"
          fill="#F33800"
        />
        <path
          d="M133.788 121.447L0.212777 121.501C0.156548 121.501 0.102679 121.479 0.0629758 121.439C0.0232731 121.4 0.000976562 121.346 0.000976562 121.291C0.000976562 121.235 0.0232731 121.181 0.0629758 121.142C0.102679 121.102 0.156548 121.08 0.212777 121.08L133.788 121.025C133.844 121.026 133.898 121.048 133.938 121.087C133.977 121.127 134 121.18 134 121.236C134 121.292 133.977 121.346 133.938 121.385C133.898 121.425 133.844 121.447 133.788 121.447Z"
          fill="#CACACA"
        />
        <path
          d="M38.5051 33.7991C38.3517 33.5211 38.0877 32.7261 37.7915 32.7368C37.4886 32.75 37.1852 32.7244 36.8889 32.6606C36.8567 32.6553 36.8246 32.6482 36.7943 32.6412C35.5433 32.3313 34.2605 32.165 32.9713 32.1454C31.9312 32.1312 30.7894 32.2552 30.0634 32.9935C29.3373 33.7318 29.4443 35.2544 30.4451 35.536C27.1006 36.1107 23.6625 35.3544 20.8759 33.4308C19.1044 32.195 17.6844 30.5395 16.1823 28.9921C14.6801 27.4429 13.0246 25.9539 10.9998 25.1961C8.97321 24.4365 6.49526 24.5428 4.86293 25.9574C2.87201 27.6819 2.71859 30.6493 2.7168 33.2715C2.71502 34.2523 3.02365 34.0452 3.89601 34.5073C4.70594 34.9358 5.5569 35.8175 6.05106 35.0508C6.54522 34.2842 6.63263 33.337 6.73432 32.4322C6.83422 31.5275 6.98229 30.5749 7.5603 29.8685C8.55397 28.6539 10.511 28.6415 11.8829 29.4117C13.2548 30.1819 14.1771 31.5293 15.0494 32.8324C15.9218 34.1337 16.8423 35.4846 18.2124 36.2601C20.0124 37.2782 22.2727 37.1277 24.1513 37.9952C25.3448 38.5476 26.3099 39.4736 27.3 40.3323C28.2919 41.191 29.3783 42.0179 30.6646 42.2958C31.949 42.5756 33.4743 42.1542 34.1059 41.0087C34.6749 39.9729 34.3966 38.6468 34.9033 37.5774C35.294 36.7523 36.0914 36.2052 36.8104 35.6351C37.1065 35.3996 37.1832 36.2105 37.4348 35.9237C38.1608 35.7803 38.8423 34.4099 38.5051 33.7991Z"
          fill="#3F3D56"
        />
        <path
          d="M33.5023 32.3336C36.6155 32.3336 39.1393 29.8288 39.1393 26.7391C39.1393 23.6493 36.6155 21.1445 33.5023 21.1445C30.389 21.1445 27.8652 23.6493 27.8652 26.7391C27.8652 29.8288 30.389 32.3336 33.5023 32.3336Z"
          fill="#2F2E41"
        />
        <path
          d="M33.9566 32.795C36.1931 32.7321 37.9547 30.8818 37.8913 28.6623C37.8279 26.4427 35.9636 24.6943 33.7272 24.7572C31.4907 24.8201 29.7291 26.6704 29.7925 28.89C29.8558 31.1096 31.7202 32.8579 33.9566 32.795Z"
          fill="#9E616A"
        />
        <path
          d="M33.8805 22.644L32.2383 22.6902C31.2034 22.7202 30.2227 23.1564 29.5115 23.9031C28.8003 24.6498 28.4167 25.6461 28.4448 26.6732L28.4458 26.7093L28.4758 26.728C29.3684 27.3141 30.4219 27.6116 31.4917 27.5796L31.584 27.577L32.4914 27.5362C32.644 27.1461 32.6951 26.7242 32.6398 26.3094C32.7768 26.7026 32.8838 27.1055 32.9598 27.5147L37.0311 27.3307C37.268 27.3191 37.4909 27.216 37.6523 27.0435C37.8137 26.8711 37.9009 26.6428 37.8951 26.4075C37.8646 25.3804 37.4247 24.4073 36.672 23.7017C35.9194 22.9962 34.9154 22.6158 33.8805 22.644Z"
          fill="#2F2E41"
        />
        <path
          d="M30.6087 21.6359C30.2381 22.0895 29.6429 22.2785 29.1081 22.5242C28.1646 22.967 27.3442 23.6316 26.718 24.4603C26.0917 25.2889 25.6786 26.2567 25.5145 27.2796C25.3885 28.1126 25.4326 28.97 25.2192 29.7846C25.0073 30.5992 24.448 31.4083 23.617 31.572C23.0546 31.6836 22.4819 31.483 21.9442 31.2842C21.6486 31.1758 21.3529 31.0669 21.0572 30.9576C21.3404 30.1896 21.6232 29.4217 21.9055 28.6538C21.4374 29.3842 20.888 30.06 20.2677 30.6686C19.2081 30.2788 18.1475 29.889 17.0859 29.4993C19.6684 29.4364 19.1568 26.961 19.6632 24.4487C19.9416 23.0682 22.6189 22.0634 23.1118 20.7435C23.3781 20.0277 23.8454 19.4025 24.4585 18.942C25.0716 18.4815 25.8047 18.205 26.5713 18.1451C27.3378 18.0853 28.1055 18.2446 28.7836 18.6043C29.4617 18.9639 30.0218 19.5089 30.3976 20.1746C30.9337 20.3693 30.9725 21.188 30.6087 21.6359Z"
          fill="#2F2E41"
        />
        <path
          d="M30.8666 23.2992C32.0259 23.2992 32.9657 22.3666 32.9657 21.216C32.9657 20.0655 32.0259 19.1328 30.8666 19.1328C29.7074 19.1328 28.7676 20.0655 28.7676 21.216C28.7676 22.3666 29.7074 23.2992 30.8666 23.2992Z"
          fill="#2F2E41"
        />
      </g>
      <defs>
        <clipPath id="clip0_10402_65407">
          <rect width="134" height="121" fill="white" transform="translate(0 0.501953)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyListSelectionIllustration;
