import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import MenuItem from "components/basics/MenuItem";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineBell,
  HiOutlineChatBubbleLeftRight,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineIdentification,
} from "react-icons/hi2";
import useUserStore from "state/userState";

const CreatorSettings = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const navigateIfVerified = useCallback(
    (to: string) => (user.isVerified ? navigate(to) : navigate("/verification/start")),
    [navigate, user],
  );

  return (
    <>
      <Layout>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("creatorSettings.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container>
          <MenuItem
            handleOnClick={() => navigate("/profile/data")}
            Icon={HiOutlineIdentification}
            text={t("profileMenu.personalData")}
            data-testid={"profile-data"}
          />
          <MenuItem
            handleOnClick={() => navigateIfVerified("/chat/settings")}
            Icon={HiOutlineChatBubbleOvalLeft}
            text={t("chats.settings.title")}
            data-testid={"chat-access"}
          />
          <MenuItem
            handleOnClick={() => navigate("/notifications/settings")}
            Icon={HiOutlineBell}
            text={t("notifications.title")}
            data-testid={"notifications"}
          />
          <MenuItem
            handleOnClick={() => navigateIfVerified("/comment-settings")}
            Icon={HiOutlineChatBubbleLeftRight}
            text={t("comment.settings.title")}
            data-testid={"comment-settings"}
          />
        </Container>
      </Layout>
    </>
  );
};

export default CreatorSettings;
