import RelatedUsersList from "components/organisms/RelatedUsersList";
import useBroadcastUsersHook from "hooks/chats/useBroadcastUsers.hook";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  broadcastId: string;
  userGroup: "receivedBy" | "viewedBy" | "boughtBy";
};

const BroadcastUsersList = ({ broadcastId, userGroup }: Props) => {
  const { t } = useTranslation();
  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery();
  const { users, isLoading, fetchNextPage, hasNextPage } = useBroadcastUsersHook(broadcastId, userGroup, query);

  const triggerFetchNextPage = useCallback(() => {
    if (hasNextPage && !isLoading) fetchNextPage();
  }, [isLoading, hasNextPage]);

  return (
    <div className="mt-4 pb-12">
      <RelatedUsersList
        users={users}
        searchQuery={debounceQuery}
        onSearchQueryChange={setDebounceQuery}
        searchPlaceholder={t(`chats.broadcasts.${userGroup}Search`) ?? ""}
        isTyping={isTyping}
        fetchNextPage={triggerFetchNextPage}
        isLoading={isLoading ? isLoading && hasNextPage : false}
        renderAction={(userId) => <MessageAction userId={userId} />}
      />
    </div>
  );
};

export default BroadcastUsersList;

type ActionProps = {
  userId: string;
};

const MessageAction = ({ userId }: ActionProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-fit">
      <StatefulLink
        className="flex rounded-md border border-beige-400 bg-beige-400 p-2 font-medium text-gray-700 hover:border-beige-600 hover:bg-beige-600 hover:text-gray-800"
        to={`/chat/with/${userId}`}
      >
        <div className="flex items-center text-base">{t("chats.messageUser")}</div>
      </StatefulLink>
    </div>
  );
};
