import { PostDto, UpdatePostDto } from "@neolime-gmbh/api-gateway-client";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Media from "components/media/Media";
import useCategories from "hooks/useCategories.hook";
import usePost from "hooks/usePost.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../components/layouts/Layout";
import EditPostData from "../components/post/EditPostData";
import MaloumClientContext from "../contexts/MaloumClientContext";
import useUserStore from "../state/userState";

const EditPostPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { post, updatePost } = usePost(id ?? "");
  const { maloumClient } = useContext(MaloumClientContext);
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const { categories, isLoading: categoriesLoading } = useCategories();
  const { bumpPublicPostCount, bumpPrivatePostCount, reducePublicPostCount, reducePrivatePostCount } = useUserStore();

  const submitPost = async (updatedValues: UpdatePostDto) => {
    // stop if no post is loaded or no id is provided
    if (!post || !id) return;
    // delete mediaIds from updatedValues as media can not be updated
    delete updatedValues.mediaIds;
    // update post
    await maloumClient.posts.update(id, updatedValues).then((post: PostDto) => {
      updatePost(post);
      if (post.public && !updatedValues.public) {
        bumpPrivatePostCount();
        reducePublicPostCount();
      } else if (!post.public && updatedValues.public) {
        bumpPublicPostCount();
        reducePrivatePostCount();
      }
    });
  };

  const hydrateCategories = (_categories: { _id: string }[]) => {
    return categories.filter((c) => _categories.some((_c) => _c._id === c._id));
  };

  useEffect(() => {
    if (!post) return;
    navigate(".", {
      state: {
        data: {
          caption: post.caption,
          categories: hydrateCategories(post.categories),
          public: post.public,
          selectedDate: post.publishedAt,
          originalSelectedDate: post.publishedAt,
        },
      },
      replace: true,
    });
  }, [post]);

  // loading state
  if (!post || categoriesLoading || !state.data) {
    return (
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.Cancel />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("posts.edit")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>
        <Container>
          <div className={"mb-3 aspect-square w-full cursor-pointer bg-gray-200"} />

          <div className={"px-3"}>
            <div className={"mb-2 h-4 w-1/4 rounded-full bg-gray-200"} />
            <div className={"mb-3 h-8 rounded-full bg-gray-200"} />
            <div className={"mb-2 h-4 w-1/2 rounded-full bg-gray-200"} />
            <div className={"mb-3 h-8 rounded-full bg-gray-200"} />
            <div className={"mb-2 h-4 w-1/4 rounded-full bg-gray-200"} />
            <div className={"mb-5 h-8 rounded-full bg-gray-200"} />
            <div className={"h-8 rounded-full bg-gray-200"} />
          </div>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("posts.edit")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="relative mx-auto aspect-[1.5/1] min-h-fit w-full max-w-xl bg-beige-400">
        <Media media={post.media[0]} thumbnail={post.thumbnail} cover={false} />
      </div>

      <EditPostData submit={submitPost} isEditMode />
    </Layout>
  );
};

export default EditPostPage;
