import { CreateProductDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Layout from "../components/layouts/Layout";
import MaloumClientContext from "../contexts/MaloumClientContext";
import EditProductData from "components/product/EditProductData";
import useUserStore from "state/userState";
import HeaderBar from "components/layouts/HeaderBar";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const CreateProductPage = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { maloumClient } = useContext(MaloumClientContext);

  const submitProduct = async (productData: CreateProductDto) =>
    await maloumClient.products.create(productData).then(() => {
      // Google Tag Manager: New Product Event
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "new_product",
        email: user?.email,
        user_id: user?._id,
        price_netAmount: productData.netAmount,
      });
      navigate(`/creator/${user.username}?tab=products`);
    });

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("addProduct")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <EditProductData submit={submitProduct} isEditMode={false} />
    </Layout>
  );
};

export default CreateProductPage;
