import {ReactNode} from "react";
import {UserRole} from "types/navigation.types";
import useUserStore from "state/userState";
import {populateUsername} from "helper/routeHelper";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";

type Props = {
  hasRole: UserRole;
  children: ReactNode;
  redirectTo?: string
}

const ProtectedRoute = ({ hasRole, children, redirectTo }: Props) => {
  const user = useUserStore((state) => state.user);
  const userRole: UserRole = user.isCreator ? "creator" : "fan";

  if (userRole === hasRole) {
    return <>{children}</>
  }

  if (redirectTo) {
    const populatedTo = populateUsername(redirectTo, user);
    return <StatefulNavigate to={populatedTo} replace />
  }

  return <StatefulNavigate to="/404" replace />
}

export default ProtectedRoute;