import classNames from "classnames";
import { HiCheckBadge } from "react-icons/hi2";

type Props = {
  className?: string;
};

const VerifiedUserIcon = ({ className }: Props) => {
  return (
    <div className="relative">
      <HiCheckBadge className={classNames("text-red-900", className)} />
      <div className="absolute left-1/2 top-1/2 -z-[1] h-[60%] w-[60%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-white" />
    </div>
  );
};

export default VerifiedUserIcon;
