import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import useUserStore from "state/userState";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useCreator = (username: string | undefined, isCurrentUser?: boolean, loadData: boolean = true) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);

  const queryClient = useQueryClient();

  const updateCreatorLocally = useCallback(
    (updatedUser: PublicUserDto) => {
      queryClient.setQueryData(`fetch-creator-${username}`, () => updatedUser);
    },
    [username],
  );

  const fetchCreator = useCallback(
    async (username: string): Promise<PublicUserDto | PrivateUserDto> => {
      return maloumClient.users.getProfileByUsername(username);
    },
    [username],
  );

  const getCreator = useCallback(async (): Promise<PublicUserDto | PrivateUserDto> => {
    if (isCurrentUser) return currentUser;
    if (!username) throw Error;
    return fetchCreator(username);
  }, [username]);

  const { data: creator, isLoading, isError } = useQuery(`fetch-creator-${username}`, getCreator, {enabled: loadData});

  return { creator, isLoading, isError, updateCreatorLocally };
};

export default useCreator;
