type Props = {
  className?: string;
  "data-testid"?: string;
};

const ChangePriceIllustration = ({ className, "data-testid": dataTestId }: Props) => {
  return (
    <svg
      width="162"
      height="100"
      viewBox="0 0 162 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={dataTestId}
    >
      <g clipPath="url(#clip0_8541_34850)">
        <path
          d="M120.332 87.9702C119.726 90.0862 117.955 91.5674 116.107 92.8257C115.849 93.0014 115.591 93.1731 115.333 93.3407C115.331 93.3415 115.33 93.3432 115.328 93.3439C115.315 93.352 115.303 93.36 115.291 93.3681C115.238 93.4026 115.185 93.4372 115.132 93.4713L115.161 93.4844C115.161 93.4844 115.189 93.4996 115.158 93.4869C115.149 93.483 115.139 93.4794 115.13 93.4755C114.057 93.0402 112.965 92.5729 112.142 91.7575C111.289 90.9107 110.79 89.5826 111.252 88.4702C111.313 88.3245 111.39 88.186 111.481 88.0574C111.519 88.0035 111.559 87.9519 111.601 87.9012C111.856 87.5884 112.189 87.3496 112.566 87.2092C112.943 87.0688 113.35 87.0319 113.746 87.1023C114.142 87.1728 114.512 87.348 114.819 87.61C115.125 87.872 115.356 88.2113 115.489 88.593C115.625 87.3222 114.583 86.2577 113.58 85.4723C112.577 84.6873 111.437 83.8323 111.243 82.5686C111.135 81.8655 111.378 81.1841 111.821 80.6371C111.834 80.6205 111.848 80.6038 111.862 80.5877C112.401 79.9506 113.16 79.5415 113.986 79.4421C115.524 79.2813 117.018 80.1001 118.136 81.1757C119.934 82.9063 121.022 85.5643 120.332 87.9702Z"
          fill="#F2F2F2"
        />
        <path
          d="M117.442 85.2029C117.737 85.6648 117.964 86.1676 118.115 86.6953C118.243 87.1669 118.299 87.6551 118.283 88.1437C118.242 89.149 117.962 90.1296 117.465 91.0031C117.091 91.6658 116.635 92.2783 116.109 92.8261C115.851 93.0018 115.593 93.1735 115.334 93.3411C115.333 93.3419 115.331 93.3435 115.329 93.3443C115.317 93.3524 115.304 93.3604 115.293 93.3685C115.239 93.403 115.186 93.4376 115.133 93.4717C115.133 93.4717 115.191 93.5 115.16 93.4872C115.15 93.4833 115.141 93.4798 115.131 93.4759C115.228 92.6829 115.133 91.8782 114.857 91.1293C114.58 90.3804 114.129 89.7091 113.541 89.1717C112.952 88.6425 112.246 88.2608 111.482 88.0578C111.52 88.0039 111.56 87.9523 111.602 87.9016C111.897 87.9848 112.184 88.0941 112.46 88.2281C113.446 88.7043 114.254 89.4872 114.763 90.4609C115.22 91.345 115.417 92.3418 115.33 93.3344C115.374 93.2942 115.418 93.2532 115.461 93.2125C116.277 92.4479 117.001 91.5703 117.491 90.5574C117.916 89.6996 118.126 88.7499 118.1 87.7917C118.06 86.747 117.646 85.7793 117.047 84.9369C116.39 84.0382 115.614 83.2339 114.74 82.5466C113.86 81.8376 112.897 81.2395 111.872 80.7658C111.849 80.7549 111.831 80.7355 111.821 80.7117C111.812 80.6878 111.812 80.6612 111.822 80.6375C111.829 80.6161 111.844 80.5984 111.863 80.5881C111.874 80.5837 111.885 80.5816 111.896 80.5821C111.907 80.5826 111.918 80.5856 111.927 80.5908C112.054 80.6494 112.18 80.7088 112.304 80.7707C113.342 81.2867 114.313 81.9304 115.193 82.687C116.043 83.4161 116.844 84.249 117.442 85.2029Z"
          fill="white"
        />
        <path
          d="M37.8797 83.9413C38.8613 87.3661 41.7278 89.7635 44.7178 91.8001C45.1353 92.0845 45.5529 92.3624 45.9707 92.6337C45.9735 92.635 45.9764 92.6376 45.9793 92.6389C45.9994 92.6519 46.0195 92.6649 46.0382 92.678C46.1245 92.7338 46.2107 92.7898 46.2962 92.845L46.2492 92.8662C46.2492 92.8662 46.2031 92.8909 46.2536 92.8702C46.2686 92.8639 46.2843 92.8582 46.2993 92.8518C48.0363 92.1473 49.8042 91.3909 51.1354 90.0711C52.5163 88.7006 53.3232 86.551 52.5755 84.7505C52.4772 84.5147 52.3531 84.2906 52.2054 84.0824C52.1447 83.9951 52.0794 83.9116 52.0114 83.8296C51.5989 83.3234 51.0598 82.9368 50.4495 82.7095C49.8392 82.4822 49.1798 82.4226 48.5389 82.5366C47.8981 82.6506 47.299 82.9342 46.8033 83.3582C46.3076 83.7823 45.9331 84.3315 45.7184 84.9493C45.4988 82.8924 47.1853 81.1695 48.8083 79.8983C50.432 78.6277 52.277 77.244 52.5909 75.1986C52.7662 74.0605 52.3727 72.9577 51.6559 72.0723C51.6337 72.0454 51.6115 72.0185 51.5886 71.9924C50.716 70.9612 49.4887 70.2991 48.1518 70.1382C45.662 69.878 43.2436 71.2033 41.4348 72.9441C38.5236 75.7451 36.7637 80.0473 37.8797 83.9413Z"
          fill="#F2F2F2"
        />
        <path
          d="M42.5594 79.4595C42.0816 80.2072 41.7147 81.0209 41.4704 81.875C41.2638 82.6384 41.1718 83.4285 41.1976 84.2193C41.2423 85.8446 41.7396 87.4328 42.5221 88.8475C43.1274 89.9201 43.8651 90.9114 44.7177 91.798C45.1352 92.0824 45.5528 92.3603 45.9706 92.6316C45.9734 92.6328 45.9763 92.6355 45.9792 92.6368C45.9993 92.6497 46.0194 92.6628 46.0381 92.6758C46.1243 92.7317 46.2106 92.7876 46.2961 92.8429C46.2961 92.8429 46.203 92.8888 46.2535 92.8681C46.2685 92.8617 46.2842 92.8561 46.2992 92.8497C46.1435 91.5661 46.296 90.2637 46.744 89.0516C47.1919 87.8395 47.9222 86.753 48.8737 85.8832C49.8274 85.0267 50.969 84.4089 52.2053 84.0803C52.1446 83.993 52.0793 83.9095 52.0113 83.8275C51.5338 83.9621 51.0691 84.139 50.6226 84.3559C49.0265 85.1267 47.7196 86.3939 46.8953 87.9697C46.1557 89.4007 45.8373 91.0142 45.9777 92.6207C45.9063 92.5557 45.8349 92.4892 45.7656 92.4234C44.4456 91.1859 43.2732 89.7654 42.4798 88.126C41.7915 86.7377 41.4531 85.2004 41.4947 83.6497C41.5594 81.9587 42.2294 80.3925 43.1994 79.029C44.2384 77.5697 45.5411 76.2787 46.9322 75.1602C48.357 74.0126 49.9161 73.0446 51.5748 72.2778C51.6122 72.2603 51.6414 72.2289 51.6565 72.1903C51.6716 72.1516 51.6713 72.1086 51.6557 72.0702C51.6447 72.0357 51.6205 72.007 51.5885 71.9903C51.5721 71.9831 51.5542 71.9798 51.5363 71.9806C51.5184 71.9813 51.5009 71.9862 51.4851 71.9947C51.2803 72.0895 51.0769 72.1856 50.8752 72.2859C49.1949 73.121 47.6243 74.1629 46.1995 75.3875C44.8233 76.5675 43.5276 77.9156 42.5594 79.4595Z"
          fill="white"
        />
        <path
          d="M104.322 26.6029H103.842V13.3773C103.842 12.3721 103.645 11.3767 103.263 10.448C102.88 9.51928 102.32 8.67544 101.613 7.96465C100.906 7.25385 100.067 6.69001 99.1435 6.30533C98.22 5.92065 97.2302 5.72266 96.2306 5.72266H68.3676C66.3488 5.72265 64.4128 6.52911 62.9853 7.96463C61.5578 9.40014 60.7559 11.3471 60.7559 13.3773V85.9339C60.7559 87.964 61.5578 89.911 62.9853 91.3465C64.4127 92.7821 66.3488 93.5885 68.3675 93.5885H96.2306C98.2493 93.5885 100.185 92.7821 101.613 91.3466C103.04 89.911 103.842 87.9641 103.842 85.9339V36.0171H104.322L104.322 26.6029Z"
          fill="#CCCCCC"
        />
        <path
          d="M102.223 13.4315V85.8814C102.223 87.3975 101.624 88.8515 100.558 89.9236C99.4922 90.9957 98.0464 91.598 96.5387 91.598H68.542C67.0344 91.598 65.5885 90.9957 64.5224 89.9236C63.4564 88.8515 62.8574 87.3975 62.8574 85.8814V13.4315C62.8574 11.9153 63.4564 10.4613 64.5224 9.38924C65.5885 8.31717 67.0344 7.71487 68.542 7.71484H71.9383C71.7715 8.12726 71.7079 8.5746 71.7533 9.01751C71.7986 9.46043 71.9516 9.88534 72.1985 10.2549C72.4455 10.6244 72.7791 10.9273 73.1697 11.1368C73.5604 11.3463 73.9963 11.456 74.4391 11.4563H90.4015C90.8443 11.456 91.2802 11.3462 91.6709 11.1367C92.0616 10.9272 92.3951 10.6244 92.6421 10.2549C92.8891 9.88533 93.042 9.46042 93.0873 9.01751C93.1327 8.5746 93.0692 8.12726 92.9023 7.71484H96.5387C98.0464 7.71487 99.4922 8.31717 100.558 9.38924C101.624 10.4613 102.223 11.9153 102.223 13.4315Z"
          fill="white"
        />
        <path
          d="M79.3331 39.0425C77.846 38.3555 76.8685 36.3913 76.9241 34.4541C76.9798 32.517 77.9935 30.7053 79.3916 29.7815C80.7897 28.8577 82.5158 28.7878 84.0163 29.4301C85.5168 30.0724 87.5314 30.2473 88.4333 31.8438C89.1264 33.0707 88.8767 35.8077 88.386 37.1719C87.9619 38.351 86.9248 39.0154 85.9135 39.3872C84.7941 39.795 83.6007 39.9563 82.4139 39.8602C81.2272 39.764 80.0748 39.4127 79.0348 38.83L79.3331 39.0425Z"
          fill="#FDB714"
        />
        <path
          d="M87.8011 61.5307L87.1732 58.9567L87.1288 58.772C87.093 58.6287 87.0199 58.4977 86.9169 58.3924C86.8139 58.2871 86.6848 58.2114 86.5429 58.1732L85.9162 58.0041L85.3604 57.8532L84.161 57.5297H84.1598L81.7359 56.8753L79.5906 56.2946L79.0719 56.1545L77.6469 55.7694L76.7609 55.5304L75.3863 55.1597L75.2758 55.1295C75.0848 55.0785 74.882 55.0978 74.7039 55.1839C74.5258 55.27 74.3842 55.4173 74.3046 55.5992C74.2445 55.738 74.1857 55.8793 74.1281 56.0229C73.4269 57.8042 72.8955 59.6484 72.541 61.5307C72.5254 61.6104 72.5085 61.6913 72.4941 61.7722H85.2199C85.2199 61.7867 85.2199 61.7999 85.2211 61.8144C85.2427 61.8012 85.2643 61.7879 85.286 61.7722H87.8599L87.8011 61.5307Z"
          fill="#2F2E41"
        />
        <path
          d="M85.5914 39.5543C87.3503 37.7855 87.3503 34.9177 85.5914 33.1488C83.8325 31.38 80.9808 31.38 79.2219 33.1488C77.463 34.9177 77.463 37.7855 79.2219 39.5543C80.9808 41.3231 83.8325 41.3231 85.5914 39.5543Z"
          fill="#FFCEC2"
        />
        <path
          d="M87.2104 60.1243C86.7416 60.6498 86.2157 61.1208 85.6425 61.5284C85.526 61.6117 85.4072 61.6926 85.2859 61.7699C85.2643 61.7856 85.2427 61.7989 85.2211 61.8121C85.2199 61.7976 85.2199 61.7844 85.2199 61.7699H74.9024C74.8816 61.7527 74.862 61.7341 74.8436 61.7143C74.7787 61.6564 74.7151 61.5924 74.6539 61.5284C74.2498 61.1126 73.9084 60.6396 73.6406 60.1243L74.9012 56.5398L75.3862 55.1574L76.091 53.1557L76.8497 50.9958C76.7838 50.796 76.73 50.5924 76.6888 50.3861V50.3849C75.9697 46.7171 79.5089 43.1579 79.5089 43.1579C79.5365 43.1361 79.5641 43.1156 79.5918 43.0963C79.9328 42.8428 80.3033 42.632 80.6951 42.4685C82.3542 41.7767 84.0913 42.0556 85.2607 42.413C85.6671 42.5357 86.0643 42.6874 86.4492 42.8669C86.5861 42.9321 86.6606 42.9732 86.6606 42.9732L86.777 46.5178L86.9355 51.364L87.0207 54.0672L87.1275 57.4779L87.1732 58.9544L87.2104 60.1243Z"
          fill="#FFCEC2"
        />
        <path
          d="M92.2818 61.5283L91.1485 55.7392L90.99 54.9303L91.7559 51.4822L91.397 49.6483L90.0584 42.8088C90.0254 42.6441 89.9439 42.4932 89.8244 42.3759C89.7049 42.2585 89.553 42.1801 89.3885 42.1508L87.7509 41.8671L86.2298 41.6027C86.0833 41.5763 85.9323 41.5907 85.7933 41.6443C85.6543 41.6979 85.5325 41.7888 85.4411 41.907C85.326 42.0502 85.2629 42.2287 85.2622 42.4128C85.2625 42.5266 85.2858 42.6392 85.3306 42.7436C86.4411 45.3478 86.0257 50.0805 85.6247 54.6562C85.6127 54.7902 85.6007 54.9243 85.5899 55.0583C85.5059 56.0096 85.4267 56.9465 85.3618 57.8508C85.3402 58.1514 85.321 58.4496 85.303 58.743C85.2442 59.7209 85.2106 60.6566 85.2178 61.5283C85.2178 61.6092 85.219 61.6901 85.2214 61.7697C85.2214 61.7842 85.2214 61.7975 85.2226 61.812C85.2442 61.7987 85.2658 61.7854 85.2874 61.7697H92.3286L92.2818 61.5283Z"
          fill="#FF9270"
        />
        <path
          d="M80.6968 42.468C80.6637 42.3335 80.5977 42.2095 80.5049 42.1072C80.4121 42.0049 80.2953 41.9274 80.1652 41.882C80.0351 41.8365 79.8958 41.8245 79.7599 41.8469C79.624 41.8693 79.4958 41.9255 79.387 42.0104L78.9704 42.3907L75.8093 45.2846C75.7199 45.366 75.6494 45.4661 75.6028 45.578C75.3087 46.2867 74.7601 47.6208 74.1118 49.2821C73.8825 49.8712 73.6399 50.5027 73.3914 51.1607C72.9772 52.2581 74.3627 53.9387 73.9473 55.1291C73.8753 55.338 73.7988 55.5432 73.718 55.7448C72.8764 57.8758 71.6807 59.6759 71.2677 61.5279C71.2497 61.6088 71.2317 61.6897 71.2148 61.7694H75.7877C75.7937 61.7512 75.7997 61.7331 75.8057 61.7138C75.8237 61.6522 75.8429 61.5907 75.8622 61.5279C76.1995 60.4208 76.5765 59.205 76.9739 57.9277C77.0291 57.7514 77.0843 57.5739 77.1395 57.3952C77.3064 56.8604 77.4761 56.3175 77.6486 55.7666C77.6726 55.6869 77.6978 55.6072 77.723 55.5275C77.7806 55.3416 77.8387 55.1557 77.8971 54.9697C77.9739 54.7259 78.0508 54.4812 78.1276 54.2357C78.3269 53.5982 78.5274 52.9571 78.7279 52.3197C79.5406 49.7252 79.363 47.2296 79.9752 45.2376C79.9848 45.2086 79.9932 45.1808 80.0016 45.1531C80.2646 44.2959 80.4963 43.5352 80.6836 42.9014C80.7261 42.7607 80.7307 42.6111 80.6968 42.468Z"
          fill="#FF9270"
        />
        <path
          d="M85.6095 30.715C85.7716 30.7388 85.9371 30.7081 86.0802 30.6278C86.2233 30.5475 86.3361 30.422 86.4011 30.2707C86.4612 30.1174 86.4855 29.9523 86.4723 29.788C86.4591 29.6238 86.4087 29.4648 86.3249 29.3232C86.1543 29.0411 85.9163 28.8063 85.6324 28.6403C85.3056 28.4206 84.9277 28.2898 84.5356 28.2608C84.3399 28.2479 84.1438 28.2774 83.9605 28.3474C83.7772 28.4173 83.611 28.5261 83.4731 28.6663C83.3386 28.81 83.2498 28.9908 83.2181 29.1855C83.1863 29.3803 83.2131 29.5801 83.2949 29.7594C83.4596 30.0788 83.8052 30.2579 84.1419 30.3795C84.8098 30.6201 85.5212 30.7141 86.2283 30.6551L85.6095 30.715Z"
          fill="#2F2E41"
        />
        <path
          d="M87.2114 35.5979C86.5096 35.8035 86.5478 35.4232 86.163 34.7982C85.7782 34.1732 85.278 33.4377 84.5481 33.4769C83.9499 33.5089 83.5333 34.0554 83.107 34.4785C82.347 35.2327 81.2439 35.7102 80.2011 35.48C79.1582 35.2498 78.2984 34.1679 78.5275 33.1192C78.6559 32.5318 79.088 32.0432 79.6058 31.7423C80.1236 31.4413 80.7217 31.3087 81.3158 31.2396C82.6394 31.0866 83.9802 31.2276 85.2437 31.6527C86.1158 31.947 86.9926 32.423 87.4266 33.2386C87.8607 34.0542 88.2407 35.2345 87.3947 35.5979H87.2114Z"
          fill="#FDB714"
        />
        <path
          d="M98.7469 36.3146V40.469C98.6664 40.4895 98.5872 40.5089 98.5068 40.5258C97.9281 40.6549 97.3386 40.7407 96.7528 40.8119C95.8439 40.923 94.9111 41.0051 94.0251 40.7769C92.7861 40.4582 91.7489 39.5382 91.1198 38.4166C90.4919 37.2962 90.2446 35.9899 90.235 34.7029C90.2278 33.7008 90.3574 32.6975 90.289 31.6979C90.2194 30.6982 89.9204 29.6635 89.2001 28.9717C88.4798 28.2787 87.2624 28.0518 86.4725 28.6615C86.3385 28.7705 86.2121 28.8887 86.0943 29.0152C85.9417 29.1868 85.762 29.332 85.5625 29.445C85.2047 29.6261 84.6392 29.4849 84.6152 29.0828L84.9898 28.3789C84.9778 28.3633 84.9646 28.3463 84.9526 28.3307C84.4039 27.57 84.6332 26.4122 85.3115 25.7591C86.0043 25.0902 87.0355 24.8826 87.9912 24.9671C88.9389 25.0629 89.8449 25.4078 90.6183 25.967C91.3916 26.5263 92.0051 27.2802 92.3972 28.1532C93.0022 29.5428 92.9962 31.1328 93.5437 32.5478C93.8386 33.2996 94.2817 33.9837 94.8461 34.559C95.4106 35.1343 96.0848 35.589 96.8284 35.8957C97.365 36.1123 97.9306 36.2478 98.5068 36.2977C98.5872 36.305 98.6664 36.3098 98.7469 36.3146Z"
          fill="#FDB714"
        />
        <path
          d="M84.2123 54.1949H84.1614V52.7923C84.1614 52.577 84.0763 52.3705 83.925 52.2182C83.7736 52.066 83.5682 51.9805 83.3541 51.9805H80.3991C80.2931 51.9805 80.1881 52.0015 80.0901 52.0423C79.9922 52.0831 79.9032 52.1429 79.8282 52.2182C79.7533 52.2936 79.6938 52.3831 79.6532 52.4816C79.6127 52.5801 79.5918 52.6857 79.5918 52.7923V60.4874C79.5918 60.7027 79.6769 60.9092 79.8282 61.0614C79.9796 61.2137 80.185 61.2992 80.3991 61.2992H83.3541C83.4601 61.2992 83.5651 61.2782 83.6631 61.2374C83.761 61.1966 83.85 61.1368 83.925 61.0614C83.9999 60.9861 84.0594 60.8966 84.0999 60.7981C84.1405 60.6996 84.1614 60.594 84.1614 60.4874V55.1934H84.2123V54.1949Z"
          fill="#3F3D56"
        />
        <path
          d="M81.9013 55.848C82.4076 55.848 82.8181 55.4352 82.8181 54.926C82.8181 54.4167 82.4076 54.0039 81.9013 54.0039C81.3949 54.0039 80.9844 54.4167 80.9844 54.926C80.9844 55.4352 81.3949 55.848 81.9013 55.848Z"
          fill="white"
        />
        <path
          d="M81.5101 57.3845C81.4885 57.3579 81.4645 57.3326 81.4405 57.3072C81.2424 57.103 81.0039 56.9429 80.7405 56.8373C80.477 56.7316 80.1944 56.6828 79.911 56.6939C79.8208 56.6986 79.7309 56.7091 79.6421 56.7253L79.5916 56.6746L79.073 56.153L77.8965 54.9711L77.1665 54.237L76.092 53.1565L75.2853 52.3464L74.9719 52.0313L75.5254 49.8521L74.1111 49.2834L72.2683 48.5433L72.2395 48.5312L72.1711 48.7196L71.6788 50.0923L71.3115 51.1125C71.0976 51.7152 71.0612 52.3671 71.2067 52.9901C71.3521 53.613 71.6732 54.1806 72.1314 54.6246C72.2359 54.7257 72.347 54.8197 72.464 54.9059L73.7174 55.7462L74.1292 56.0214L74.9023 56.5406L76.1785 57.3966L76.9732 57.929L77.9817 58.6051C77.977 58.6714 77.9758 58.7379 77.9781 58.8043C77.9899 59.0894 78.0613 59.3688 78.1877 59.6243C78.3141 59.8797 78.4926 60.1055 78.7116 60.2869C78.9739 60.5065 79.2882 60.6542 79.6241 60.7155H79.6253C80.0459 60.7935 80.4803 60.7361 80.8666 60.5513C81.1214 60.4274 81.3476 60.2513 81.5306 60.0342C81.7136 59.8171 81.8493 59.5639 81.9291 59.2909C81.9705 59.1534 81.9962 59.0116 82.0059 58.8683C82.0261 58.6002 81.9926 58.3308 81.9074 58.0759C81.8223 57.8211 81.6872 57.586 81.5101 57.3845Z"
          fill="#FFCEC2"
        />
        <path
          d="M93.3528 52.0493L93.1679 50.9338L92.9038 49.3438L91.3959 49.648L89.4354 50.044L89.5879 52.2884L89.4751 52.3669L87.0211 54.0668L85.5889 55.058L84.1614 56.0468L84.1602 56.048L84.1494 56.0552C84.0655 56.0223 83.9792 55.9957 83.8913 55.9755C83.6147 55.9125 83.3279 55.9087 83.0497 55.9645C82.7715 56.0202 82.5081 56.1342 82.2766 56.2991C82.0606 56.4525 81.877 56.6474 81.7363 56.8726C81.6369 57.0311 81.5605 57.203 81.5094 57.3833C81.4763 57.4974 81.4531 57.6141 81.4398 57.7322C81.4092 58.0117 81.4369 58.2945 81.521 58.5627C81.6051 58.8308 81.7439 59.0784 81.9284 59.2896C82.1562 59.5537 82.4489 59.7531 82.7772 59.8679C83.2257 60.0276 83.7161 60.0212 84.1602 59.8498H84.1614C84.2521 59.8157 84.34 59.7745 84.4244 59.7267C84.818 59.5065 85.127 59.1601 85.302 58.7427C85.3492 58.6329 85.3861 58.5189 85.4124 58.4023C85.4265 58.3372 85.4373 58.2716 85.4448 58.2055L85.9166 58.0014L87.128 57.4774L91.1474 55.7389L91.5364 55.5711C91.8683 55.4101 92.1704 55.1933 92.4296 54.93C92.7993 54.5577 93.0758 54.1023 93.2363 53.6015C93.3968 53.1007 93.4367 52.5687 93.3528 52.0493Z"
          fill="#FFCEC2"
        />
        <path
          d="M79.8705 43.7647C79.8101 43.5301 79.7167 43.3053 79.5932 43.0971C79.4319 42.8244 79.2205 42.5851 78.9701 42.392C78.8645 42.3107 78.7529 42.2377 78.6363 42.1735C78.0469 41.8485 77.3533 41.7721 76.7079 41.9611C76.0625 42.1501 75.5181 42.5891 75.1944 43.1816L72.2686 48.5433L72.1714 48.7196L71.4883 49.9728L71.6792 50.0923L73.3911 51.162L75.2856 52.3463L75.4189 52.4297L76.6902 50.3869V50.3857L79.5667 45.7604C79.6774 45.5832 79.7661 45.3931 79.8309 45.1942C79.8393 45.1664 79.8477 45.1375 79.8561 45.1097C79.9815 44.6709 79.9865 44.2061 79.8705 43.7647Z"
          fill="#FF9270"
        />
        <path
          d="M90.9264 43.3726C90.6921 42.7581 90.2308 42.2584 89.6389 41.9779C89.0469 41.6973 88.3701 41.6576 87.7497 41.867C87.6717 41.8936 87.5949 41.9238 87.5192 41.9576C87.0844 42.1529 86.7135 42.4681 86.4495 42.8667C86.343 43.0262 86.2552 43.1976 86.1878 43.3774C86.0689 43.6903 86.0127 44.0238 86.0226 44.3587C86.0325 44.6936 86.1082 45.0232 86.2454 45.3284L86.7773 46.5176L89.4029 52.3949L89.4749 52.3671L91.7548 51.4821L93.1678 50.934L93.72 50.7203L90.9264 43.3726Z"
          fill="#FF9270"
        />
        <path
          d="M76.2536 19.8499H67.6097C67.0206 19.8499 66.4557 19.6146 66.0392 19.1957C65.6227 18.7768 65.3887 18.2087 65.3887 17.6163C65.3887 17.024 65.6227 16.4559 66.0392 16.037C66.4557 15.6181 67.0206 15.3828 67.6097 15.3828H76.2536C76.8427 15.3828 77.4076 15.6181 77.8241 16.037C78.2406 16.4559 78.4746 17.024 78.4746 17.6163C78.4746 18.2087 78.2406 18.7768 77.8241 19.1957C77.4076 19.6146 76.8427 19.8499 76.2536 19.8499Z"
          fill="#F6F4F0"
        />
        <path
          d="M76.9645 69.2132H68.3206C67.7316 69.2132 67.1666 68.9778 66.7501 68.559C66.3336 68.1401 66.0996 67.572 66.0996 66.9796C66.0996 66.3873 66.3336 65.8191 66.7501 65.4003C67.1666 64.9814 67.7316 64.7461 68.3206 64.7461H76.9645C77.5536 64.7461 78.1185 64.9814 78.535 65.4003C78.9516 65.8191 79.1856 66.3873 79.1856 66.9796C79.1856 67.572 78.9516 68.1401 78.535 68.559C78.1185 68.9778 77.5536 69.2132 76.9645 69.2132Z"
          fill="#F6F4F0"
        />
        <path
          d="M96.8337 79.2288H88.1898C87.6007 79.2288 87.0358 78.9935 86.6193 78.5746C86.2027 78.1557 85.9688 77.5876 85.9688 76.9953C85.9688 76.4029 86.2027 75.8348 86.6193 75.4159C87.0358 74.997 87.6007 74.7617 88.1898 74.7617H96.8337C97.4227 74.7617 97.9877 74.997 98.4042 75.4159C98.8207 75.8348 99.0547 76.4029 99.0547 76.9953C99.0547 77.5876 98.8207 78.1557 98.4042 78.5746C97.9877 78.9935 97.4227 79.2288 96.8337 79.2288Z"
          fill="#DFDFE2"
        />
        <path
          d="M124.882 93.7219H37.122C37.0902 93.7219 37.0596 93.7092 37.0371 93.6866C37.0146 93.6639 37.002 93.6332 37.002 93.6012C37.002 93.5692 37.0146 93.5385 37.0371 93.5158C37.0596 93.4932 37.0902 93.4805 37.122 93.4805H124.882C124.914 93.4805 124.944 93.4932 124.967 93.5158C124.989 93.5385 125.002 93.5692 125.002 93.6012C125.002 93.6332 124.989 93.6639 124.967 93.6866C124.944 93.7092 124.914 93.7219 124.882 93.7219Z"
          fill="#3F3D56"
        />
        <path
          d="M96.7058 23.5H68.373C67.8319 23.5008 67.3132 23.7173 66.9307 24.102C66.5481 24.4867 66.3328 25.0083 66.332 25.5524V59.7195C66.3328 60.2636 66.5481 60.7852 66.9307 61.17C67.3132 61.5547 67.8319 61.7712 68.373 61.7719H96.7058C97.247 61.7714 97.7657 61.555 98.1484 61.1702C98.531 60.7854 98.7462 60.2637 98.7468 59.7195V25.5524C98.7462 25.0083 98.531 24.4866 98.1484 24.1018C97.7657 23.717 97.247 23.5006 96.7058 23.5ZM98.5067 59.7195C98.5061 60.1996 98.3162 60.6599 97.9786 60.9994C97.641 61.3389 97.1833 61.5299 96.7058 61.5305H68.373C67.8955 61.5299 67.4378 61.3389 67.1002 60.9994C66.7626 60.6599 66.5727 60.1996 66.5721 59.7195V25.5524C66.5727 25.0723 66.7626 24.612 67.1002 24.2725C67.4378 23.933 67.8955 23.742 68.373 23.7415H96.7058C97.1833 23.742 97.641 23.933 97.9786 24.2725C98.3162 24.612 98.5061 25.0723 98.5067 25.5524V59.7195Z"
          fill="#3F3D56"
        />
        <path
          d="M60.8798 34.3832C65.5467 34.3832 69.3299 30.5505 69.3299 25.8225C69.3299 21.0945 65.5467 17.2617 60.8798 17.2617C56.2129 17.2617 52.4297 21.0945 52.4297 25.8225C52.4297 30.5505 56.2129 34.3832 60.8798 34.3832Z"
          fill="#FDB714"
        />
        <path
          d="M62.2215 23.0275C61.5084 23.0572 60.7022 23.1068 60.1751 23.6612C59.7171 24.1428 59.6168 24.8575 60.0812 25.3717C60.5468 25.8872 61.2707 25.957 61.8074 26.3592C62.4376 26.8314 62.3453 27.7657 61.7354 28.2133C61.0163 28.7411 60.0878 28.495 59.4303 27.9928C59.2619 27.8641 59.0983 28.1524 59.2649 28.2796C59.8539 28.7295 60.6225 28.9799 61.3508 28.7647C62.0081 28.5704 62.5176 28.0274 62.5694 27.3212C62.5942 26.9841 62.4996 26.642 62.2927 26.3759C62.0743 26.095 61.7597 25.9221 61.4417 25.7815C60.8943 25.5393 59.9518 25.226 60.1109 24.4377C60.3039 23.4822 61.4519 23.3916 62.2215 23.3596C62.4317 23.3509 62.4328 23.0187 62.2215 23.0275Z"
          fill="white"
        />
        <path
          d="M60.8986 22.5784L60.8672 29.8688C60.8663 30.0825 61.1941 30.0828 61.195 29.8688L61.2264 22.5784C61.2273 22.3646 60.8995 22.3644 60.8986 22.5784Z"
          fill="white"
        />
        <path
          d="M89.1638 70.4724C92.8873 70.4724 95.9057 67.4294 95.9057 63.6757C95.9057 59.9219 92.8873 56.8789 89.1638 56.8789C85.4403 56.8789 82.4219 59.9219 82.4219 63.6757C82.4219 67.4294 85.4403 70.4724 89.1638 70.4724Z"
          fill="#FDB714"
        />
        <path
          d="M90.6427 61.1681C90.0434 61.1937 89.3659 61.2364 88.923 61.7138C88.5381 62.1285 88.4538 62.744 88.8441 63.1868C89.2353 63.6308 89.8437 63.6908 90.2947 64.0372C90.8243 64.4439 90.7467 65.2484 90.2342 65.6338C89.6299 66.0883 88.8496 65.8764 88.2971 65.444C88.1555 65.3332 88.0181 65.5814 88.1581 65.6909C88.6531 66.0784 89.299 66.2941 89.911 66.1087C90.4634 65.9414 90.8915 65.4738 90.9351 64.8656C90.9559 64.5753 90.8764 64.2807 90.7025 64.0516C90.519 63.8097 90.2546 63.6608 89.9874 63.5397C89.5274 63.3312 88.7353 63.0614 88.8691 62.3825C89.0312 61.5597 89.9959 61.4817 90.6427 61.4541C90.8194 61.4466 90.8203 61.1605 90.6427 61.1681Z"
          fill="white"
        />
        <path
          d="M89.5486 61.2886L89.5176 66.7709C89.5167 66.9316 89.8405 66.9319 89.8414 66.7709L89.8724 61.2886C89.8733 61.1279 89.5495 61.1277 89.5486 61.2886Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8541_34850">
          <rect width="88" height="88" fill="white" transform="translate(37.002 5.72266)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChangePriceIllustration;
