import { ChatAccessSettingsDto, PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import MaloumClientContext from "contexts/MaloumClientContext";
import { getThreeDSBrowserData } from "helper/checkoutHelper";
import useStoredCreditCard from "hooks/checkout/useStoredCreditCard.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useMutation } from "react-query";
import { createSearchParams } from "react-router-dom";
import useUserStore from "state/userState";
import OrderType from "../../enums/OrderType";
import useCurrency from "../../hooks/useCurrency.hook";
import Button from "../basics/Button";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import TextWithCheckMark from "../utilities/TextWithCheckMark";

type Props = {
  creator: PublicUserDto;
  open: boolean;
  setOpen: (value: boolean) => void;
};

const SubscribePopup = ({ creator, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { displayCurrency } = useCurrency();
  const navigate = useStatefulNavigate();
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  const storedCreditCard = useStoredCreditCard(currentUser);
  const price = creator.subscriptionPrice;

  const handleBuySubscription = async () => {
    if (!currentUser) return;
    const body = {
      creatorId: creator._id,
      address: { country: currentUser.address.country },
      paymentMethod: {
        paymentMethod: currentUser.storedPaymentInformation[0].paymentMethod,
        referenceId: currentUser.storedPaymentInformation[0].referenceUUID,
      },
      threeDSBrowserData: getThreeDSBrowserData(),
    };
    // @ts-ignore
    await maloumClient.checkout.checkoutSubscription(body).then((r) => window.location.replace(r.redirectUrl));
  };

  const handleBuySubscriptionMutation = useMutation(handleBuySubscription);

  const goToCheckout = useCallback(() => {
    // Google Tag Manager: Subscription Go To Checkout Event
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "subscription_go_to_checkout",
      creator_id: creator._id,
      net_price_per_month: creator.subscriptionPrice.net,
    });
    navigate({
      pathname: "/checkout",
      search: createSearchParams({
        type: OrderType.Subscription,
        id: creator.username,
      }).toString(),
    });
  }, [creator]);

  const handleClosePopup = useCallback(() => setOpen(false), []);

  return (
    <PopUp title={t("subscription.benefits") as string} isOpen={open} onClose={handleClosePopup}>
      <div className={"flex justify-center py-6"}>
        <ProfileImageWithLink
          to={`/creator/${creator?.username}`}
          url={creator.profilePictureThumbnail?.url}
          className={"h-24 w-24"}
        />
      </div>
      <div className="pb-8 text-left">
        <TextWithCheckMark
          text={t("subscription.fullAccess", {
            number: creator.privatePostCount,
          })}
        />
        <TextWithCheckMark text={t("subscription.alwaysNewContent")} />
        <TextWithCheckMark text={t("subscription.cancelAtAnyTime")} />
        {creator.chatAccessSettings?.exclusiveChatType !== ChatAccessSettingsDto.exclusiveChatType.ALL && (
          <TextWithCheckMark text={t("unlockChat")} />
        )}
      </div>
      {price && storedCreditCard ? (
        <div className="flex flex-col items-center">
          <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
            <span>{t("subscription.name")}</span>
            <span>{displayCurrency(price.net)}</span>
          </div>
          {price.paymentFeeAmount > 0 && (
            <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
              <span>{t("checkout.serviceFee")}</span>
              <span>{displayCurrency(price.paymentFeeAmount)}</span>
            </div>
          )}
          <div className="flex w-full justify-between text-xs text-gray-400 xs:text-sm">
            <span>{t("checkout.VAT")}</span>
            <span>{displayCurrency(price.vatAmount)}</span>
          </div>
          <div className="mt-0.5 flex w-full justify-between text-xl font-semibold">
            <span>{t("checkout.total")}</span>
            <span>{t("checkout.monthly", { price: displayCurrency(price.gross) })}</span>
          </div>
          <Button
            type="submit"
            text={
              handleBuySubscriptionMutation.isLoading
                ? undefined
                : t("subscription.subscribeWith", { hint: storedCreditCard.hint })
            }
            IconFront={
              handleBuySubscriptionMutation.isLoading
                ? () => <AiOutlineLoading3Quarters className="my-0.5 h-5 w-5 animate-spin" />
                : undefined
            }
            className="mt-4"
            onClick={handleBuySubscriptionMutation.mutate}
            disabled={handleBuySubscriptionMutation.isLoading}
          />
          <TernaryButton
            type="submit"
            className="mt-2 w-full text-sm"
            onClick={goToCheckout}
            disabled={handleBuySubscriptionMutation.isLoading}
          >
            <div className="w-full text-center">{t("tips.chooseDifferentPaymentMethod")}</div>
          </TernaryButton>
        </div>
      ) : (
        <Button onClick={goToCheckout} text={t("subscribeFor", { price: displayCurrency(price?.net ?? 0) })} />
      )}
    </PopUp>
  );
};

export default SubscribePopup;
