import { MessageDto, MessagePreviewDto, TipMessageContentDto } from "@neolime-gmbh/api-gateway-client";

export function messageToMessagePreview(message: MessageDto): MessagePreviewDto {
  const messagePreview: MessagePreviewDto = {
    _id: message._id,
    senderId: message.senderId,
    sentAt: message.sentAt,
    type: message.content.type,
    text: message.content.text,
    isBroadcasted: message.isBroadcasted,
  };

  if (message.content.type === MessagePreviewDto.type.TIP) {
    messagePreview.tip = (message.content as TipMessageContentDto).price.net;
  }

  return messagePreview;
}
