import classNames from "classnames";
import { ReactNode } from "react";
import { ScrollRestoration } from "react-router-dom";
import Sidebar from "./Sidebar";
import NavigationBar from "./NavigationBar";
import useNavigationStore from "state/navigationState";
import { t } from "i18next";
import PopUp from "components/popup/PopUp";
import { BaseComponent } from "types/components/component.type";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  leftColumn?: ReactNode;
  rightColumn?: ReactNode;
  hideNavigationOnMobile?: boolean;
} & BaseComponent;

const TwoColumnLayout = ({
  leftColumn,
  rightColumn,
  hideNavigationOnMobile = false,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const navigate = useStatefulNavigate();
  const { isCreationPopUpOpen, closeCreationPopUp } = useNavigationStore();

  const popUpOptions = [
    {
      title: t("posts.add"),
      onClick: () => {
        navigate("/post/create");
        closeCreationPopUp();
      },
    },
    {
      title: t("addProduct"),
      onClick: () => {
        navigate("/product/create");
        closeCreationPopUp();
      },
    },
  ];

  const getKey = (location: Location, _: any[]): string | null => {
    // @ts-ignore
    return location.key;
  };

  return (
    <div className={classNames("full-height flex flex-col", className)} data-testid={dataTestId}>
      <Sidebar />
      <div className="ml-0 flex grow flex-col sm:ml-20 sm:flex-row md:ml-60">
        <div className="full-height hidden w-80 shrink-0 xl:block xl:overflow-y-auto xl:px-4" id="leftColumn">
          {leftColumn}
        </div>
        <div className="hidden h-full w-px bg-gray-100 xl:block" />
        <div
          className="xl:full-height mx-auto flex min-h-full w-full max-w-xl flex-col xl:mx-0 xl:block xl:overflow-y-auto xl:px-4"
          id="rightColumn"
        >
          {rightColumn}
        </div>
        {!hideNavigationOnMobile && <NavigationBar />}
      </div>

      <PopUp
        title={t("createContent") ?? ""}
        options={popUpOptions}
        isOpen={isCreationPopUpOpen}
        onClose={closeCreationPopUp}
      />
      <ScrollRestoration getKey={getKey} storageKey={window.location.pathname} />
    </div>
  );
};

export default TwoColumnLayout;
