import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const useDebounceQuery = (queryKey = "query", delay = 400) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const [query, setQuery] = useState(searchParams.get(queryKey) ?? "");
  const [debounceQuery, setDebounceQuery] = useState(query);

  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const searchParamsTmp = { ...Object.fromEntries([...searchParams]) };
    searchParamsTmp[queryKey] = debounceQuery;
    if (debounceQuery === "") delete searchParamsTmp[queryKey];
    if (debounceQuery.length === 0) {
      setIsTyping(false);
      setQuery("");
      setSearchParams(searchParamsTmp, { replace: true, state: { ...state } });
      return;
    }
    setIsTyping(true);
    const timeout = setTimeout(() => {
      setQuery(debounceQuery);
      setSearchParams(searchParamsTmp, { replace: true, state: { ...state } });
      setIsTyping(false);
    }, delay);
    return () => clearTimeout(timeout);
  }, [debounceQuery]);

  return { query, debounceQuery, setDebounceQuery, isTyping };
};

export default useDebounceQuery;
