import classNames from "classnames";
import HeaderBar from "components/layouts/HeaderBar";
import ChatUserCard from "../../components/molecules/ChatUserCard";
import Container from "../layouts/Container";
import Layout from "../layouts/Layout";

const LoadingChat = ({ showHeader = true }) => {
  const createLoadingItemComponent = (key: number) => {
    const right = key % 2 === 0;
    return (
      <div className={classNames("mb-2 flex w-full items-center")} key={key}>
        {right && <div className="grow" />}
        <div
          className={classNames("h-12 w-2/3 animate-pulse rounded-t-[30px]", {
            "rounded-l-[30px] bg-beige-400": right,
            "rounded-r-[30px] bg-gray-100": !right,
          })}
        />
      </div>
    );
  };

  if (!showHeader) {
    return (
      <div className="grow px-4" data-testid={"loading-chat"}>
        {Array(5)
          .fill(null)
          .map((_, key) => createLoadingItemComponent(key))}
      </div>
    );
  }

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <ChatUserCard />
          </HeaderBar.Center>
          <HeaderBar.Right></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="bg-white" hasPadding={false}>
        <div className="grow px-4" data-testid={"loading-chat"}>
          {Array(5)
            .fill(null)
            .map((_, key) => createLoadingItemComponent(key))}
        </div>
        <div className="sticky bottom-0 mt-2 bg-white px-4 pb-8">
          <div className="h-10 animate-pulse rounded-md bg-gray-100"></div>
        </div>
      </Container>
    </Layout>
  );
};

export default LoadingChat;
