import { createContext, useContext, useEffect, useRef } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";

function getCrypto() {
  try {
    return window.crypto;
  } catch {
    return crypto;
  }
}

const localStorageKey = "tracked-guest";

// @ts-ignore
const GuestContext = createContext<{
  trackedGuest: string;
  trackCreatorProfileVisit: (creatorId: string) => void;
}>();
export default GuestContext;

export const GuestProvider = ({ children }) => {
  const { trackingServiceClient } = useContext(MaloumClientContext);

  const trackedGuest = useRef(localStorage.getItem(localStorageKey) ?? getCrypto().randomUUID());

  useEffect(() => {
    localStorage.setItem(localStorageKey, trackedGuest.current);
  }, [trackedGuest]);

  const trackCreatorProfileVisit = async (creatorId: string) => {
    await trackingServiceClient.default.guestViewedProfile({
      guestId: trackedGuest.current,
      creatorId: creatorId,
      currentUrl: window.location.href,
      referralUrl: document.referrer,
    });
  };

  return (
    <GuestContext.Provider value={{ trackedGuest: trackedGuest.current, trackCreatorProfileVisit }}>
      {children}
    </GuestContext.Provider>
  );
};
