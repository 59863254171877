import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useMutation } from "react-query";

const useVerifyUID = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const validateVatCall = async (uid?: string): Promise<boolean> => {
    return await maloumClient.users.validateVat(uid).then((response: any) => {
      if (response instanceof Error) {
        Promise.reject(response);
      }
      return response.isValid;
    });
  };

  const {
    data: isVatValid,
    isLoading: isVatValidLoading,
    isError: isVatValidError,
    mutate: validateVat,
  } = useMutation({
    mutationFn: (uid: string) => {
      return validateVatCall(uid);
    },
  });

  return { isVatValid, isVatValidLoading, isVatValidError, validateVat };
};

export default useVerifyUID;
