import { generateRandomString } from "helper/randomStringHelper";
import { create } from "zustand";

interface IDiscoveryStore {
  discoveryRandomQuery: string;
  generateRandomQuery: () => void;
}

const useDiscoveryStore = create<IDiscoveryStore>((set) => ({
  discoveryRandomQuery: generateRandomString(8),
  generateRandomQuery: () => {
    const randomString = generateRandomString(8);
    set({ discoveryRandomQuery: randomString });
  },
}));

export default useDiscoveryStore;
