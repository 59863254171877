import RadialProgress from "components/atoms/RadialProgress";
import { useTranslation } from "react-i18next";

type Props = {
  percent: number;
  showText?: boolean;
};

const UploadSpinner = ({ percent, showText = true }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={"flex h-full flex-col items-center justify-center text-center"} data-testid={"upload-spinner"}>
      <RadialProgress radius={24} percentage={percent} />
      {showText && <span className={"mt-1 text-xs text-gray-700"}>{t("media.uploading")}...</span>}
    </div>
  );
};

export default UploadSpinner;
