import classNames from "classnames";
import { ComponentType } from "react";

// TODO: move hover & active colors into tailwindconfig
// TODO: add hover & active colors to secondary button

type TVariants = "primary" | "secondary" | "outline" | "text-only";
type ButtonTypes = "button" | "submit" | "reset" | undefined;

type Props = {
  variant?: TVariants;
  disabled?: boolean;
  text?: string | null | JSX.Element;
  IconBack?: ComponentType<{ className: string }>;
  IconFront?: ComponentType<{ className: string }>;
  onClick?: () => void | Promise<void>;
  type?: ButtonTypes;
  className?: string;
  "data-testid"?: string;
};

const Button = ({
  variant = "primary",
  disabled,
  text,
  IconBack,
  IconFront,
  onClick,
  type = "button",
  className,
  "data-testid": dataTestId,
}: Props) => {
  return (
    <button
      data-testid={dataTestId}
      type={type}
      className={classNames(
        "flex h-fit w-full select-none items-center justify-center gap-x-2 truncate rounded-md p-2 font-medium leading-none transition-all disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-100 disabled:text-gray-300",
        className,
        {
          "border border-red-900 bg-red-900 text-white hover:border-red-600 hover:bg-red-600 active:border-red-800 active:bg-red-800":
            variant === "primary",
          "border border-red-900 text-red-900 hover:border-red-600 hover:text-red-600 active:border-red-800 active:text-red-800":
            variant === "outline",
          "border border-beige-400 bg-beige-400 text-gray-700 hover:border-beige-600 hover:bg-beige-600 hover:text-gray-800 active:border-beige-500 active:bg-beige-500 active:text-gray-800":
            variant === "secondary",
          "text-gray-700 active:bg-beige-500 active:text-gray-800": variant === "text-only",
        },
      )}
      disabled={disabled}
      onClick={disabled ? () => {} : onClick}
    >
      {IconFront && <IconFront className={"h-6 w-6"} />}
      {text && <div className={"flex items-center gap-2 text-base"}>{text}</div>}
      {IconBack && <IconBack className={"h-6 w-6"} />}
    </button>
  );
};

export default Button;
