import { useCallback, useEffect, useState } from "react";

export enum ScreenType {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

export const useScreenType = () => {
  const [screenType, setScreenType] = useState<ScreenType>(ScreenType.Desktop);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setScreenType(ScreenType.Mobile);
    } else if (window.innerWidth <= 1024) {
      setScreenType(ScreenType.Tablet);
    } else {
      setScreenType(ScreenType.Desktop);
    }
  }, [setScreenType]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return screenType;
};
