import classNames from "classnames";
import { ElementType, ReactNode } from "react";

type Props<C extends ElementType> = {
  className?: string;
  "data-testid"?: string;
  children: ReactNode;
  as?: C;
};

const Card = <C extends ElementType>({ className, "data-testid": dataTestId, children, as }: Props<C>) => {
  const Component = as || "div";

  return (
    <Component
      className={classNames("rounded-md border border-gray-200 bg-white px-3 py-2", className)}
      data-testid={dataTestId}
    >
      {children}
    </Component>
  );
};

export default Card;
