import {Link} from "react-router-dom";
import {forwardRef, ReactNode} from "react";

type StatefulLinkProps = {
  to: string;
  onClick?: () => void
  state?: any;
  children?: ReactNode;
  className?: string;
  "data-testid"?: string;
}

const StatefulLink = forwardRef(
  ({to, onClick, state, children, className, "data-testid": dataTestId}: StatefulLinkProps, ref) => {
    const host = window.location.hostname;

    return (
      <Link
        to={to}
        state={{...state, host}}
        onClick={onClick}
        className={className}
        data-testid={dataTestId}
        ///@ts-ignore
        ref={ref}
      >
        {children}
      </Link>
    )
  }
);

export default StatefulLink;