import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import StickyBottomBar from "components/StickyBottomBar";
import ValidationError from "components/utilities/ValidationError";
import { toggleCategory } from "helper/categoryHelper";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CategorySelectionSection from "./CategorySelectionSection";
import CategorySubmissionSection from "./CategorySubmissionSection";
import HeaderBar from "components/layouts/HeaderBar";

type Props = {
  selected: CategoryDto[];
  onSubmit: (selectedCategories: CategoryDto[]) => void;
  onBack: () => void;
  minCategories: number;
  maxCategories: number;
  isPartOfWizard: boolean;
  type?: "POST" | "PRODUCT";
};

const CategorySelection = ({ selected, onSubmit, onBack, minCategories, maxCategories, type = "PRODUCT" }: Props) => {
  const { t } = useTranslation();

  const [selectedCategories, setSelectedCategories] = useState(selected);

  const _toggleCategory = useCallback(
    (category: CategoryDto) => {
      const toggledCategories = toggleCategory(selectedCategories, category);
      setSelectedCategories(toggledCategories);
    },
    [selectedCategories, maxCategories, toggleCategory],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(selectedCategories);
  }, [selectedCategories, onSubmit]);

  return (
    <>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel onClick={onBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("categories.select")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Container hasStickyBottom className="px-0">
        <h1 className={"mb-1 text-xl font-semibold text-gray-900"} data-testid={"category-select"}>
          {t("categories.select")}
        </h1>
        <p className={"mb-4 text-sm text-gray-700 xxs:text-base"}>
          {t(`categories.describe.${type}`, { count: maxCategories })}
        </p>

        {selectedCategories.length > maxCategories && (
          <div className={"-mt-4 mb-4"}>
            <ValidationError
              className="mt-2"
              hasBackground={false}
              message={t("categories.selectUpToXsmall", { count: maxCategories })}
            />
          </div>
        )}

        <CategorySelectionSection
          selectedCategories={selectedCategories}
          handleCategoryClick={_toggleCategory}
          type={type}
        />
        <CategorySubmissionSection className="mt-6 py-4" />
      </Container>
      <StickyBottomBar>
        <Button
          variant={"primary"}
          disabled={selectedCategories.length <= minCategories - 1 || selectedCategories.length > maxCategories}
          onClick={handleSubmit}
          text={t("save")}
          data-testid="save-button"
        />
      </StickyBottomBar>
    </>
  );
};

export default CategorySelection;
