import { Tab } from "@headlessui/react";
import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import GuestClickHandler from "components/guest/GuestClickHandler";
import { PostItemLoading } from "components/post/PostItem";
import ProductPreview from "components/product/ProductPreview";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineRectangleGroup, HiOutlineShoppingBag, HiRectangleGroup, HiShoppingBag } from "react-icons/hi2";
import { useSearchParams } from "react-router-dom";
import MyTab from "../basics/MyTab";
import Container from "../layouts/Container";
import CreatorFeed from "./CreatorFeed";
import CreatorProductFeed from "./CreatorProductFeed";

type Props = {
  user?: PublicUserDto | PrivateUserDto;
  viewedByGuest?: boolean;
};

const ProfileTabs = ({ user, viewedByGuest = false }: Props) => {
  const { t } = useTranslation();

  const tabs = ["posts", "products"];
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab");
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);

  useEffect(() => {
    setSelectedIndex(tabName ? tabs.indexOf(tabName) : 0);
  }, [tabName]);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedIndex(tab);
      setSearchParams({ tab: tabs[tab] }, { replace: true });
    },
    [setSelectedIndex, setSearchParams, tabs],
  );

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
      <Tab.List className={"mt-6 flex justify-around"}>
        <MyTab
          title={t("posts.name")}
          isLoading={!user}
          defaultIcon={<HiOutlineRectangleGroup className={"mr-3 h-5 w-5"} />}
          activeIcon={<HiRectangleGroup className={"mr-3 h-5 w-5"} />}
        />

        <MyTab
          title={t("products")}
          isLoading={!user}
          defaultIcon={<HiOutlineShoppingBag className={"mr-3 h-5 w-5"} />}
          activeIcon={<HiShoppingBag className={"mr-3 h-5 w-5"} />}
        />
      </Tab.List>
      <Tab.Panels className={"focus:outline-none"}>
        <Tab.Panel>
          {user ? (
            <CreatorFeed creator={user as PublicUserDto} viewedByGuest={viewedByGuest} />
          ) : (
            <div className="relative pb-16">
              {Array(2)
                .fill(null)
                .map((_, key) => (
                  <PostItemLoading key={key} data-testid="post-item" />
                ))}
            </div>
          )}
        </Tab.Panel>
        <Tab.Panel>
          <div className={"py-4"}>
            <Container hasGrow={false} hasPadding={false} className="px-4 md:px-0">
              {user ? (
                <CreatorProductFeed creator={user as PublicUserDto} viewedByGuest={viewedByGuest} />
              ) : (
                <div className="relative pb-16">
                  <div className="grid grid-cols-2 gap-4">
                    {Array(4)
                      .fill(null)
                      .map((_, key) => (
                        <GuestClickHandler isGuest={viewedByGuest} key={key}>
                          <ProductPreview isLoading hasUser={false} />
                        </GuestClickHandler>
                      ))}
                  </div>
                </div>
              )}
            </Container>
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default ProfileTabs;
