import classNames from "classnames";
import { HiExclamationCircle, HiOutlineExclamationCircle } from "react-icons/hi2";

type Props = {
  message: string;
  hasBackground?: boolean;
  className?: string;
};

const ValidationError = ({ message, hasBackground = true, className }: Props) => {
  return (
    <div
      data-testid="validation-error"
      className={classNames(
        "flex rounded-md py-2 text-sm",
        {
          "bg-gray-200 px-3": hasBackground,
          "font-semibold": !hasBackground,
        },
        className,
      )}
    >
      {hasBackground ? (
        <HiExclamationCircle className="mr-2 h-5 w-5 shrink-0" />
      ) : (
        <HiOutlineExclamationCircle className="mr-2 h-5 w-5 shrink-0" />
      )}
      <span>{message}</span>
    </div>
  );
};

export default ValidationError;
