import TextInput from "components/basics/TextInput";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TFunction } from "i18next";

export function addressFieldValidation(t: TFunction) {
  return {
    city: Yup.string()
      .required(t("errorMessage.city") || "")
      .max(100, ({ max }) => t("validation.max", { max: max })),
    postalCode: Yup.number()
      .typeError(t("validation.onlyNumbers") || "")
      .required(t("errorMessage.postalCode") || ""),
    line1: Yup.string().required(t("errorMessage.address") || ""),
    line2: Yup.string(),
  };
}

const AddressFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        label={t("address.streetNumber")}
        type="text"
        name="line1"
        placeholder={t("address.street") || ""}
        required
      />

      <TextInput
        label={t("address.floorApartment") || ""}
        type="text"
        name="line2"
        placeholder={t("address.floorOrApartment") || ""}
      />

      <div className={"grid grid-cols-2 gap-x-6"}>
        <TextInput
          label={t("address.zipCode")}
          type="text"
          name="postalCode"
          placeholder={t("address.zipCode") || ""}
          required
        />

        <TextInput label={t("address.city")} type="text" name="city" placeholder={t("address.city") || ""} required />
      </div>
    </>
  );
};

export default AddressFields;
