import { useCallback, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";
import { PostDto } from "@neolime-gmbh/api-gateway-client";

const usePost = (_id: string) => {
  const queryClient = useQueryClient();
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchPost = useCallback(async () => await maloumClient.posts.getById(_id), [_id]);

  const updatePost = async (post: PostDto) => {
    queryClient.setQueryData(`fetch-post-${_id}`, (prev: PostDto | undefined) => {
      if (!prev) return post;

      return {
        ...prev,
        ...post,
      };
    });
  };

  const { data: post, isLoading, isError } = useQuery(`fetch-post-${_id}`, fetchPost);

  return { post, isLoading, isError, updatePost };
};

export default usePost;
