import {Navigate} from "react-router";

type StatefulNavigateProps = {
  to: string;
  replace?: boolean;
  state?: any;
}

const StatefulNavigate = ({to, replace, state}: StatefulNavigateProps) => {
  const host = window.location.hostname;

  return <Navigate
    to={to}
    replace={replace}
    state={{...state, host}}
  />
}

export default StatefulNavigate;