import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { t } from "i18next";
import * as Yup from "yup";
import FormikPriceInput from "../../basics/from/FormikPriceInput";

type PriceSettingPopupProps = {
  heading: string;
  priceChangeButtonText: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onPriceChange: (netAmount: number) => void;
  netAmount?: number;
  required?: boolean;
};

const PriceSettingPopup = ({
  heading,
  priceChangeButtonText,
  isOpen,
  setIsOpen,
  onPriceChange,
  netAmount,
  required = true,
}: PriceSettingPopupProps) => {
  const { displayCurrency } = useCurrency();

  const decimalSchema = Yup.number()
    .typeError(t("validation.onlyNumbers") || "")
    .min(required ? 1 : 0, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) || "")
    .max(9999, t("validation.amountBetween", { min: displayCurrency(1), max: displayCurrency(9999) }) || "")
    .test("is-decimal", t("validation.only2Decimals") || "", (val) => {
      if (val !== undefined) {
        return /^\d+(\.\d{0,2})?$/.test(val.toString());
      }
      return !required;
    });
  if (required) decimalSchema.required(t("validation.required") || "");

  return (
    <PopUp title={heading} isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Formik
        initialValues={{
          price: netAmount?.toFixed(2) ?? "",
        }}
        validationSchema={Yup.object().shape({
          price: decimalSchema,
        })}
        onSubmit={(values) => {
          onPriceChange(parseFloat(values.price));
        }}
      >
        {({ isValid }) => (
          <Form className="mt-4 text-left">
            <div className="mb-4">
              <FormikPriceInput
                data-testid="net-price-input"
                name="price"
                className="text-center font-semibold"
                textSizeClassName="text-2xl"
              />
            </div>
            <Button type="submit" disabled={!isValid} text={priceChangeButtonText} data-testid="save-price" />
          </Form>
        )}
      </Formik>
    </PopUp>
  );
};

export default PriceSettingPopup;
