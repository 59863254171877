import { MessageDto } from "@neolime-gmbh/api-gateway-client";
import { create } from "zustand";

type TMessageStore = {
  messages: { [key: string]: MessageDto[] };
  deletedMessages: { [key: string]: string[] };
  setMessagesForChat: (chatId: string, messages: MessageDto[]) => void;
  removeMessageFromChat: (messageId: string, chatId: string) => void;
};

const useMessagesStore = create<TMessageStore>((set) => ({
  messages: {},
  deletedMessages: {},
  setMessagesForChat: (chatId, messages) => {
    set((state) => {
      const messagesTmp = { ...state.messages };
      messagesTmp[chatId] = messages.filter((m) => !state.deletedMessages[chatId]?.includes(m._id));
      return { messages: messagesTmp };
    });
  },
  removeMessageFromChat: (messageId, chatId) =>
    set((state) => {
      const messagesTmp = { ...state.messages };
      messagesTmp[chatId] = messagesTmp[chatId].filter((m) => m._id !== messageId);

      const deletedMessagesTmp = { ...state.deletedMessages };
      if (deletedMessagesTmp[chatId]) deletedMessagesTmp[chatId].push(messageId);
      else deletedMessagesTmp[chatId] = [messageId];

      return { messages: messagesTmp, deletedMessages: deletedMessagesTmp };
    }),
}));

export default useMessagesStore;
