import { create } from "zustand";

const modifyPublicPostCount = (set, modifyBy) => {
  return set((state) => ({
    user: {
      ...state.user,
      publicPostCount: state.user.publicPostCount + modifyBy,
    },
  }));
};
const modifyPrivatePostCount = (set, modifyBy) => {
  return set((state) => ({
    user: {
      ...state.user,
      privatePostCount: state.user.privatePostCount + modifyBy,
    },
  }));
};

const useUserStore = create((set) => ({
  user: null,

  bumpPublicPostCount: () => modifyPublicPostCount(set, 1),
  bumpPrivatePostCount: () => modifyPrivatePostCount(set, 1),

  reducePublicPostCount: () => modifyPublicPostCount(set, -1),
  reducePrivatePostCount: () => modifyPrivatePostCount(set, -1),
}));

export default useUserStore;
