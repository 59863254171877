import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

const useTransactions = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchNextTransactions = async (query: QueryFunctionContext<string[], any>) =>
    await maloumClient.transactions.getHistory(query?.pageParam);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["transactions"],
    fetchNextTransactions,
    { getNextPageParam: (lastPage) => lastPage.next },
  );

  // move data into memo and flatMap
  const transactions = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((p) => p.data);
  }, [data]);

  return { transactions, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage };
};

export default useTransactions;
