import { useTranslation } from "react-i18next";
import Button from "../../components/basics/Button";
import Container from "../../components/layouts/Container";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  title?: string;
  text?: string;
};
// TODO: replace Button with Link
const NotFoundPage = ({ title, text }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  return (
    <Container className={"mb-4 h-full overflow-y-auto text-center"}>
      <h1 className={"my-8 px-12 font-serif text-red-900"}>{title ? title : t("notFoundTitle")}</h1>
      <p className={"px-12 text-xl font-bold"}>{text ? text : t("notFoundText")}</p>

      <div className={"mx-auto flex-1"}>
        <img src="/images/404.svg" alt="Page not found" className={"my-16 h-full max-h-[300px]"} />
      </div>

      <Button onClick={() => navigate("/")} text={t("backToHome")} className={"my-4"} />
    </Container>
  );
};
export default NotFoundPage;
