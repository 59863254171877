import { PostDto, PostDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";

const useScheduledPosts = (loadData = true) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  // update post
  const updatePost = (postToChange: PostDto) => {
    queryClient.setQueryData("scheduledPosts", (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.map((post: PostDto) => (post._id === postToChange._id ? postToChange : post)),
          };
        }),
      };
    });
  };

  const removePost = async (postId: string) => {
    // remove from query data
    queryClient.setQueryData("scheduledPosts", (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((post: PostDto) => post._id !== postId),
          };
        }),
      };
    });
  };

  const fetchScheduledPosts = async (next: any): Promise<PostDtoKeysetResponse> =>
    await maloumClient.posts.scheduledPosts(next.pageParam, 15);

  const {
    data: scheduledPosts,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery("scheduledPosts", fetchScheduledPosts, {
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    enabled: loadData,
  });

  return {
    scheduledPosts,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removePost,
    updatePost,
  };
};
export default useScheduledPosts;
