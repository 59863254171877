import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import Linkify from "linkify-react";

type Props = {
  displayAsLink: boolean;
  children: string;
  tag?: string;
  className?: string;
};

const linkifyOptions = {
  className: "font-medium text-red-900 text-blue-500 hover:underline",
  target: "_blank",
};

export function canUserDisplayLinks(user: PublicUserDto | PrivateUserDto) {
  return user?.isTrusted;
}

const LinkifiedText = ({ displayAsLink, children, tag = "span", className }: Props) => {
  if (displayAsLink) {
    return (
      <Linkify as={tag} className={className} options={linkifyOptions} data-testid="linkify-link">
        {children}
      </Linkify>
    );
  }

  const Text = tag as keyof JSX.IntrinsicElements;
  return <Text className={className}>{children}</Text>;
};

export default LinkifiedText;
