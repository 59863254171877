const LogoSymbol = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1299.8 1137.52" className={className} fill="fill-current">
      <g>
        <path d="M650.74,1129.29q-129.15,1.64-221.88-91.07T336.13,816.34q0-34.77,11.58-81.13L448.64,349.37a130.24,130.24,0,0,0,5-34.77q0-57.9-40.56-98.5T314.6,173.88h-1.67q-58,1.67-98.52,42.22t-40.56,98.52v822.9H0V314.62Q0,185.47,91.89,92.74T312.93,0h1.67q129.15,0,221,92.73t91.91,221.88q0,43.15-9.22,79.66L515.66,780.08a121.51,121.51,0,0,0-5.69,36.4q0,57.9,41.4,98.42t99.35,40.51q56.25,0,97.7-40.56t41.38-98.5a135.89,135.89,0,0,0-5-36.43L683.83,394.09q-9.92-36.4-9.93-79.48,0-129.15,91.89-221.88T986.85,0q129.15,0,221,92.73t91.91,221.88v822.9H1125.93V314.61q0-59.61-39.73-99.35t-99.35-41.4q-59.62,1.66-99.35,41.37t-39.75,99.22q0,23.18,4,34.75L954.45,735a359.68,359.68,0,0,1,9.22,81.31q0,129.15-91.91,221.88T650.74,1129.29Z" />
      </g>
    </svg>
  );
};

export default LogoSymbol;
