import {To, useNavigate} from "react-router-dom";
import type {NavigateOptions} from "react-router/dist/lib/context";

const useStatefulNavigate = () => {
  const navigate = useNavigate();

  return (to: To | number, options?: NavigateOptions) => {
    if (typeof to === 'number') navigate(to);
    else {
      const host = window.location.hostname;

      navigate(to, {
        ...options,
        state: {
          ...options?.state,
          host
        }
      });
    }
  };
}

export default useStatefulNavigate;