type ContentTypeIconProps = {
  className?: string;
  color?: string;
};

export default function ItemSentIcon({ className, color = "white" }: ContentTypeIconProps) {
  return (
    <svg className={`h-4 w-4 ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 682.66669 682.66669">
      <defs>
        <clipPath id="clipPath326" clipPathUnits="userSpaceOnUse">
          <path d="M 0,512 H 512 V 0 H 0 Z" />
        </clipPath>
      </defs>
      <mask id="custom">
        <rect x="0" y="0" width="100%" height="100%" fill={color} />
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)" />
      </mask>
      <g mask="url(#custom)">
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
          <g clipPath="url(#clipPath326)">
            <g transform="translate(32.457,349.7637)">
              <path
                d="m 0,0 c -22.471,-7.49 -23.369,-21.972 -1.994,-32.177 l 155.453,-74.245 c 21.373,-10.208 47.217,-36.05 57.426,-57.423 l 74.24,-155.454 c 10.209,-21.376 24.691,-20.478 32.182,1.992 l 145.035,435.106 c 7.49,22.471 -4.768,34.728 -27.24,27.234 z"
                stroke={color}
                fill="none"
                strokeWidth="30"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeDasharray="none"
                strokeOpacity="1"
              />
            </g>
            <g transform="translate(218.8125,218.8145)">
              <path
                d="M 0,0 273.602,273.602"
                stroke={color}
                fill="none"
                strokeWidth="30"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeDasharray="none"
                strokeOpacity="1"
              />
            </g>
            <g transform="translate(152.4551,259.3203)">
              <path
                d="m 0,0 -54.193,-133.82 c -7.491,-22.472 4.765,-34.729 27.238,-27.239 l 133.822,54.194"
                stroke={color}
                fill="none"
                strokeWidth="30"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeDasharray="none"
                strokeOpacity="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
