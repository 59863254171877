import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import StickyBottomBar from "components/StickyBottomBar";
import { supportEmail } from "const";
import { getAndFormatFirstDayOfNextMonthDate } from "helper/dateAndTimeHelper";
import { Trans, useTranslation } from "react-i18next";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useChat from "hooks/useChat.hook";

const PayoutInformationExplanation = () => {
  const { t } = useTranslation();
  const { openChatWithMaloumOfficial } = useChat();

  const navigate = useStatefulNavigate();

  const payoutDate = getAndFormatFirstDayOfNextMonthDate();

  const onBack = () => {
    navigate("/payout");
  };

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("payout.payoutInformation")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasStickyBottom>
        <div className="mb-4 mt-4 text-2xl font-semibold">{t("payout.payoutInformation")}</div>
        <div>
          <p>
            {t("payout.payoutInformationDescription", {
              date: payoutDate,
            })}
          </p>
        </div>
        <div className="grow" />
        <p className="mb-4 text-center text-sm text-gray-500">
          <Trans i18nKey="smallBusiness.questions">
            <button type="button" className="underline" onClick={() => openChatWithMaloumOfficial()} />
            <a href={`mailto:${supportEmail}`} className="underline" />
          </Trans>
        </p>
      </Container>
      <StickyBottomBar>
        <Button text={t("smallBusiness.button")} onClick={onBack} />
      </StickyBottomBar>
    </Layout>
  );
};

export default PayoutInformationExplanation;
