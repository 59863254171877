import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  hasSeparator?: boolean;
};

const StickyBottomBar = ({ children, className, hasSeparator }: Props) => {
  return (
    <div
      className={classNames(
        "mx-auto flex w-full max-w-xl flex-col p-3",
        { "border-t border-gray-100": hasSeparator },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default StickyBottomBar;
