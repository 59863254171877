import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import useListsState from "state/lists/listsState";

const useLists = (query: string) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const { lists, addOrReplaceLists } = useListsState();

  const fetchLists = async (next: any) => await maloumClient.chatLists.me(undefined, next.pageParam, 25);

  const {
    isFetching: baseIsFetching,
    hasNextPage: baseHasNextPage,
    fetchNextPage: baseFetchNextPage,
  } = useInfiniteQuery("lists", fetchLists, {
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    onSuccess: (data) => addOrReplaceLists(data.pages.flatMap((page) => page.data)),
    enabled: !!!query,
  });

  const fetchSearchLists = async (next: any) => {
    return await maloumClient.chatLists.me(query, next.pageParam, 25);
  };

  const {
    data: searchResult,
    isFetching: searchIsFetching,
    hasNextPage: searchHasNextPage,
    fetchNextPage: searchFetchNextPage,
  } = useInfiniteQuery(["searchList", query], fetchSearchLists, {
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    enabled: !!query,
  });

  const isSearching = !!query;

  if (isSearching) {
    const results = searchResult?.pages.flatMap((page) => page.data) || [];
    return {
      lists: results,
      isLoading: searchIsFetching,
      hasNextPage: searchHasNextPage ?? true,
      fetchNextPage: searchFetchNextPage,
    };
  } else
    return {
      lists,
      isLoading: baseIsFetching,
      hasNextPage: baseHasNextPage ?? true,
      fetchNextPage: baseFetchNextPage,
    };
};

export default useLists;
