import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteForAllPopup = ({ open, onClose, onDelete }: Props) => {
  const { t } = useTranslation();

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("deleteMassMessage") ?? ""}>
      <div className="mt-2 text-center">{t("chats.deleteBroadcastMessages.description")}</div>
      <div className="mt-6 flex gap-2">
        <Button onClick={onClose} text={t("cancel")} variant="secondary" />
        <Button onClick={onDelete} text={t("delete")} variant="primary" />
      </div>
    </PopUp>
  );
};

export default DeleteForAllPopup;
