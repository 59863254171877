import { useEffect, useRef } from "react";

const createScrollStopListener = (element: HTMLDivElement, callback: () => void, timeout: number) => {
  let removed = false;

  let handle = null as null | ReturnType<typeof setTimeout>;
  const onScroll = () => {
    if (handle) clearTimeout(handle);
    handle = setTimeout(callback, timeout || 200); // default 200 ms
  };
  element.addEventListener("scroll", onScroll);
  return () => {
    if (removed) return;
    removed = true;
    if (handle) clearTimeout(handle);
    element.removeEventListener("scroll", onScroll);
  };
};

const useScrollStopListener = (callback: () => void, timeout: number) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      const destroyListener = createScrollStopListener(containerRef.current, callback, timeout);
      return () => destroyListener();
    } else return;
  }, [containerRef.current]);
  return containerRef;
};

export default useScrollStopListener;
