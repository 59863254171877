import { PostDto } from "@neolime-gmbh/api-gateway-client";
import useDiscovery from "hooks/useDiscovery.hook";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import PostItem, { PostItemLoading } from "../components/post/PostItem";
import NoResults from "../components/utilities/NoResults";
import Layout from "../components/layouts/Layout";
import Container from "../components/layouts/Container";
import HeaderBar from "../components/layouts/HeaderBar";
import { useParams } from "react-router-dom";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  "data-testid"?: string;
};

const DiscoverFeed = ({ "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { _id } = useParams();
  const navigate = useStatefulNavigate();

  // ref for loading trigger div
  const { ref, inView } = useInView();

  // useDiscovery
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, updatePost } = useDiscovery();

  // move data into memo
  const dataFlatMap = useMemo(() => {
    if (!data) return [];
    const pageDataFlatMap = data.pages.flatMap((p) => p.data);
    const index = pageDataFlatMap.findIndex((post) => post._id === _id);
    return pageDataFlatMap.slice(index);
  }, [data]);

  // create PostItem
  const createItemComponent = useCallback(
    (item: PostDto) => <PostItem key={item._id} post={item} updateCallback={updatePost} />,
    [updatePost],
  );

  // loading item view
  const createLoadingItemComponent = (key: number) => <PostItemLoading key={key} data-testid="post-item" />;

  // view to display when no posts to show
  const NoResultsView = useCallback(() => <NoResults text={t("nothingLeftToDiscover")} />, []);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  // navigate back to grid discovery if entering on this page (eg. reload on DiscoveryFeed) as discovery returns random posts
  useEffect(() => {
    if (!data) navigate("/search");
  }, []);

  return (
    <Layout>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("navigation.discovery")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false} data-testid={dataTestId} as="section">
        <div className="relative pb-16">
          {!isLoading ? (
            <>
              {dataFlatMap.map((e) => createItemComponent(e as PostDto))}
              {dataFlatMap.length === 0 && <NoResultsView />}
              <div className="absolute bottom-[40rem]" ref={ref} />
            </>
          ) : (
            <div data-testid="loadingState">
              {Array(2)
                .fill(null)
                .map((_, key) => createLoadingItemComponent(key))}
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default DiscoverFeed;
