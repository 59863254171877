import HeaderBar from "components/layouts/HeaderBar";

const EditAutomatedMessageLoading = () => {
  //todo make
  return (
    <div>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage>
          <HeaderBar.Left className="xl:ml-0">
            <div className="hidden h-6 w-32 animate-pulse rounded-md bg-gray-100 text-xl font-semibold xl:block" />
            <HeaderBar.BackButton className="xl:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="xl:hidden">
            <div className="mx-auto h-5 w-32 animate-pulse rounded-md bg-gray-100" />
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0"></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="flex animate-pulse flex-col gap-1 px-4 md:px-0">
        <div className="my-8 flex items-center gap-2">
          <div className="h-6 w-6 rounded-md bg-gray-100" />
          <div className="h-5 w-56 rounded-md bg-gray-100" />
          <div className="ml-auto h-7 w-16 rounded-full bg-gray-100 sm:ml-0" />
        </div>
        <div className="mt-4  h-3 w-60  rounded-md bg-gray-100" />
        <div className="h-6 w-20  rounded-md bg-gray-100" />
        <div className=" h-1 w-full rounded-md bg-gray-100" />
        <div className="h-4 w-12 rounded-md bg-gray-100" />
        <div className="mt-6  h-3 w-20 rounded-md bg-gray-100" />
        <div className="mt-2 flex gap-2">
          <div className="h-48 w-48 rounded-md bg-gray-100" />
          <div className="h-48 w-48 rounded-md bg-gray-100" />
        </div>
        <div className="mt-6  h-3 w-32  rounded-md bg-gray-100" />
        <div className="h-6 w-20  rounded-md bg-gray-100" />
        <div className="h-1 w-full rounded-md bg-gray-100" />
        <div className="h-4 w-44 rounded-md bg-gray-100" />
      </div>
    </div>
  );
};

export default EditAutomatedMessageLoading;
