import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil } from "react-icons/hi2";
import useCurrency from "../../hooks/useCurrency.hook";
import useUserStore from "../../state/userState";
import Button from "../basics/Button";
import SubscriptionPriceSettingPopup from "./SubscriptionPriceSettingPopup";
const EditSubscriptionPrice = () => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  return (
    <>
      {user.subscriptionPrice ? (
        <div className={classNames("rounded-md p-3 text-center text-gray-700 shadow-card")}>
          <span className={"text-sm font-semibold"}>{t("subscription.price.yourMonthly")}</span>
          <div
            data-testid="subscription-price"
            className={"mt-3 flex items-center justify-center text-3xl font-semibold"}
          >
            {displayCurrency(user.subscriptionPrice.net)}
            <span
              className={"ml-2 cursor-pointer p-2"}
              onClick={() => setPopupIsOpen(true)}
              data-testid={"edit-subscription-price-button"}
            >
              <HiOutlinePencil className={"h-6 w-6"} />
            </span>
          </div>
        </div>
      ) : (
        <div className={"mt-6"}>
          <Button
            text={t("subscription.price.setSub")}
            onClick={() => setPopupIsOpen(true)}
            data-testid={"edit-subscription-price-button"}
          />
        </div>
      )}
      <SubscriptionPriceSettingPopup setIsOpen={setPopupIsOpen} isOpen={popupIsOpen} />
    </>
  );
};

export default EditSubscriptionPrice;
