import CostCalculationTableRow from "../../atoms/table/CostCalculationTableRow";

type Props = {
  costArray: Array<{ name: string; amount: string }>;
};

const PayoutCalculationTable = ({ costArray }: Props) => {
  return (
    <dl className="grid gap-y-0.5" data-testid="payout-calculation-table-list">
      {costArray.map((costItem) => (
        <CostCalculationTableRow
          name={costItem.name}
          amount={costItem.amount}
          className="last:font-semibold text-gray-500 last:text-gray-700 last:border-t last:border-t-gray-200 last:mt-0.5 last:pt-1"
          data-testid="cost-calculation-table-row"
          key={costItem.name}
        />
      ))}
    </dl>
  );
};

export default PayoutCalculationTable;
