import { FollowDto } from "@neolime-gmbh/api-gateway-client";
import FanItem from "components/atoms/lists/FanItem";
import { generateTimeTagForFans } from "helper/dateAndTimeHelper";
import useFollowers from "hooks/useFollowers.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import useUserStore from "state/userState";
import StatefulLink from "components/atoms/utils/StatefulLink";

type FollowersProps = {
  className?: string;
  "data-testid"?: string;
};

const NoFollowers = ({ className, "data-testid": dataTestId }: FollowersProps) => {
  const { t } = useTranslation();

  return (
    <section className={className} data-testid={dataTestId}>
      <h2 className="text-xl font-semibold text-gray-800">{`0 ${t("fans.followers")}`}</h2>
      <p className="text-gray-500">{t("fans.noFollowers")}</p>
    </section>
  );
};

const Followers = ({ className, "data-testid": dataTestId }: FollowersProps) => {
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useFollowers();
  const { ref, inView } = useInView(); // ref for loading trigger div
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  if (!data) {
    return <NoFollowers className={className} data-testid={dataTestId} />;
  }

  return (
    <>
      {data?.pages[0]?.data?.length > 0 ? (
        <section className={className} data-testid={dataTestId}>
          <div className="relative mt-4 flex flex-col gap-y-4">
            <h2 className="text-xl font-semibold text-gray-800" data-testid="followersHeadline">{`${
              user.followerCount
            } ${t("fans.followers")}`}</h2>
            {data.pages.map((page) =>
              page.data.map((follow: FollowDto) => (
                <FanItem
                  key={follow?.user?.username}
                  userId={follow?.user?._id}
                  username={follow.user?.username}
                  userProfilePictureUrl={follow.user?.profilePictureThumbnail?.url}
                  userProfilePictureUploadStatus={follow.user?.profilePictureThumbnail?.uploadStatus}
                  additionalInfo={
                    <span className="text-xs text-gray-500">{`${t("fans.followingSince")} ${generateTimeTagForFans(
                      new Date(follow.followedAt),
                    )}`}</span>
                  }
                  actions={
                    <StatefulLink
                      to={`/chat/with/${follow.user._id}`}
                      className="flex select-none items-center justify-center rounded-md border border-beige-400 bg-beige-400 p-2 font-medium text-gray-700 transition-all hover:border-beige-600 hover:bg-beige-600 hover:text-gray-800 active:border-beige-500 active:bg-beige-500 active:text-gray-800"
                      data-testid={`message-with-${follow?.user?.username}`}
                    >
                      <span>{t("message.title")}</span>
                    </StatefulLink>
                  }
                  isUserVerified={follow.user?.isVerified}
                />
              )),
            )}
            <div className="absolute bottom-[40rem]" ref={ref} />
          </div>
        </section>
      ) : (
        <NoFollowers className={className} data-testid={dataTestId} />
      )}
    </>
  );
};
export default Followers;
