import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import PillSelection from "components/atoms/PillSelection";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import useCategories from "hooks/useCategories.hook";
import { ScreenType, useScreenType } from "hooks/useScreenType.hook";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  selectedCategory: CategoryDto | null;
  setSelectedCategory: (category: CategoryDto | null) => void;
  className?: string;
};
const ShopCategoryFilter = ({ selectedCategory, setSelectedCategory, className }: Props) => {
  const { t } = useTranslation();
  const { categories } = useCategories();

  const [showMore, setShowMore] = useState(false);

  const screenType = useScreenType();
  const isMobile = useMemo(() => screenType === ScreenType.Mobile, [screenType]);

  const filteredCategories = useMemo(() => {
    return (
      categories
        // filter relevant categories
        .filter((c) => (c.type === "PRODUCT" || c.type === "ALL") && c.stats.productCount >= 15)
        // sort alphabetically
        .sort((c1, c2) => t(`categories.${c1.name}`).localeCompare(t(`categories.${c2.name}`)))
    );
  }, [categories]);

  const categoriesToShow = useMemo(() => {
    if (showMore || isMobile) return filteredCategories;
    return filteredCategories.slice(0, 5);
  }, [showMore, filteredCategories, screenType]);

  return (
    <>
      <div
        className={classNames(
          "scrollbar-hide w-full select-none flex-col overflow-auto md:block md:max-w-xl",
          className,
        )}
      >
        <div className={"inline-flex gap-2 md:w-full md:flex-wrap md:justify-center"}>
          <PillSelection
            text={t("categories.all")}
            selected={!selectedCategory}
            onClick={() => setSelectedCategory(null)}
            className="ml-4 sm:ml-0"
          />
          {categoriesToShow.map((category: CategoryDto, i) => (
            <PillSelection
              text={t(`categories.${category.name}`)}
              selected={selectedCategory?._id === category._id}
              onClick={() => setSelectedCategory(category)}
              className={classNames({ "mr-4 sm:mr-0": categoriesToShow.length === i + 1 })}
              key={category._id}
            />
          ))}
        </div>
        {!isMobile && filteredCategories.length > 5 && (
          <div className={"mt-2 flex justify-center"}>
            <TernaryButton className={"text-sm"} onClick={() => setShowMore(!showMore)}>
              {showMore ? t("showLess") : t("showMore")}
            </TernaryButton>
          </div>
        )}
      </div>
    </>
  );
};

export default ShopCategoryFilter;
