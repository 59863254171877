import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { QueryFunctionContext, useInfiniteQuery } from "react-query";

const useFollowers = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchNextFollowers = async (query: QueryFunctionContext<string[], any>) => {
    return await maloumClient.users.getFollower(query?.pageParam);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["followers"],
    fetchNextFollowers,
    {
      getNextPageParam: (lastPage) => lastPage.next,
    },
  );

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage };
};

export default useFollowers;
