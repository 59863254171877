import { ChatProductDto, ProductDto, PublicPriceDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";
import { ProfileImageWithLink } from "components/basics/ProfileImage";
import useCurrency from "hooks/useCurrency.hook";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineBanknotes } from "react-icons/hi2";

type Props = {
  children?: React.ReactNode;
  type: "SUBSCRIPTION" | "PRODUCT" | "TIP" | "CHAT_PRODUCT" | "CHAT_UNLOCK";
  showVatHint?: boolean;
  item: ProductDto | PublicUserDto | ChatProductDto | undefined;
  price: PublicPriceDto | undefined;
  className?: string;
  "data-testid"?: string;
};

const CartItem = ({
  children,
  type,
  showVatHint = false,
  item,
  price,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const image = useMemo(() => {
    if (!item)
      return (
        <div className="flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-md bg-beige-400">
          <HiOutlineBanknotes className="h-12 w-12" />
        </div>
      );
    else if ("username" in item)
      return (
        <ProfileImageWithLink
          url={item.profilePictureThumbnail?.url}
          className="h-[5.5rem] w-[5.5rem]"
          to={`/creator/${item.username}`}
        />
      );
    else if ("reviewed" in item && item.reviewed === true)
      return <img src={item.thumbnail.url} className="h-[5.5rem] w-[5.5rem] rounded-md" alt={item.name} />;
    else
      return (
        <div className="flex h-[5.5rem] w-[5.5rem] items-center justify-center rounded-md bg-beige-400">
          <ContentTypeIcon type={item.media[0]?.type} className="h-12 w-12" />
        </div>
      );
  }, [item]);

  const checkoutTitle = useMemo(() => {
    if (!item) return t("tips.name");
    else if ("username" in item)
      if (type === "SUBSCRIPTION")
        return (
          <StatefulLink to={`/creator/${item.username}`} className="notranslate">
            {item.username}
          </StatefulLink>
        );
      else return t("chatWith", { username: item.username });
    else if ("reviewed" in item && item.reviewed === true) return item.name;
    else
      return (
        <>
          {item.media.length > 0
            ? t("exclusiveContent")
            : item.media[0]?.type === "picture"
            ? t("exclusiveImage")
            : t("exclusiveVideo")}
        </>
      );
  }, [type, item, t]);

  return (
    <div className={classNames("rounded-md border border-gray-100 p-3", className)} data-testid={dataTestId}>
      <div className="flex items-center gap-2">
        {image}
        {price && (
          <div className="grow">
            <div className={"text-sm font-medium"}>{checkoutTitle}</div>
            <div className={"flex justify-between text-xs text-gray-500"}>
              <div>{t("checkout.subtotal")}</div>
              <div>{displayCurrency(price.net)}</div>
            </div>
            {price.paymentFeeAmount > 0 && (
              <div className={"flex justify-between text-xs text-gray-500"}>
                <div>{t("checkout.serviceFee")}</div>
                <div>{displayCurrency(price.paymentFeeAmount)}</div>
              </div>
            )}
            <div className={"flex justify-between text-xs text-gray-500"}>
              <div>{`${t("checkout.VAT")}${showVatHint ? "*" : ""}`}</div>
              <div>{displayCurrency(price.vatAmount)}</div>
            </div>
            <div className={"flex justify-between text-sm"}>
              <div>{t("checkout.total")}</div>
              <div>{`${displayCurrency(price.gross)}${type === "SUBSCRIPTION" ? `/${t("month")}` : ""}`}</div>
            </div>
          </div>
        )}
        {!price && (
          <div className="grow">
            <div className={"text-sm font-medium"}>{checkoutTitle}</div>
            <div className={"flex justify-between pt-1"}>
              <div className="h-3 w-16 rounded-md bg-gray-100" />
              <div className="h-3 w-10 rounded-md bg-gray-100" />
            </div>
            <div className={"flex justify-between pt-1"}>
              <div className="h-3 w-16 rounded-md bg-gray-100" />
              <div className="h-3 w-10 rounded-md bg-gray-100" />
            </div>
            <div className={"flex justify-between pt-1"}>
              <div className="h-3 w-16 rounded-md bg-gray-100" />
              <div className="h-3 w-10 rounded-md bg-gray-100" />
            </div>
            <div className={"flex justify-between pt-1"}>
              <div className="h-4 w-16 rounded-md bg-gray-100" />
              <div className="h-4 w-10 rounded-md bg-gray-100" />
            </div>
          </div>
        )}
      </div>
      {showVatHint && <p className={"mt-3 text-left text-xs text-gray-500"}>{t("checkout.vatMayChange")}</p>}
      {children && (
        <>
          <div className="my-2 h-px w-full bg-gray-100" />
          {children}
        </>
      )}
    </div>
  );
};

export default CartItem;
