import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import PillSelection, { PillSelectionLoading } from "components/atoms/PillSelection";
import SearchInput from "components/molecules/basics/SearchInput";
import useCategories from "hooks/useCategories.hook";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as NoResultsSVG } from "svg/undraw_no_results.svg";

type Props = {
  selectedCategories: CategoryDto[];
  handleCategoryClick: (c: CategoryDto) => void;
  reset?: () => void;
  type: "POST" | "PRODUCT";
  minPosts?: number;
  showAll?: boolean;
  className?: string;
  "data-testid"?: string;
};

const CategorySelectionSection = ({
  selectedCategories,
  handleCategoryClick,
  reset,
  type,
  minPosts = 0,
  showAll = false,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { categories, isLoading } = useCategories();

  // search query
  const [query, setQuery] = useState("");

  const categoriesToDisplay = useMemo(() => {
    return (
      categories
        // filter only relevant categories
        .filter((c) => (c.type === type || c.type === "ALL") && c.stats.postCount >= minPosts)
        // sort alphabetically
        .sort((c1, c2) => t(`categories.${c1.name}`).localeCompare(t(`categories.${c2.name}`)))
        // filter with query
        .filter((c) => t(`categories.${c.name}`).toLowerCase().includes(query.toLowerCase()))
    );
  }, [categories, type, query]);

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <SearchInput
        onChange={setQuery}
        value={query}
        placeholder={t("categories.searchPlaceholder") as string}
        className="mb-4"
      />
      <div className="flex flex-wrap gap-2">
        {!isLoading && categoriesToDisplay.length > 0 ? (
          <>
            {showAll && reset && (
              <PillSelection text={t("categories.all")} selected={selectedCategories.length === 0} onClick={reset} />
            )}
            {categoriesToDisplay.map((c) => (
              <PillSelection
                text={t(`categories.${c.name}`)}
                selected={selectedCategories.includes(c)}
                onClick={() => handleCategoryClick(c)}
                key={c._id}
              />
            ))}
          </>
        ) : (
          <>
            {categoriesToDisplay.length === 0 && !isLoading && (
              <div
                className={classNames(
                  "mx-auto mt-6 flex w-full flex-1 flex-col items-center justify-center px-4 sm:max-w-[25rem]",
                  className,
                )}
                data-testid={dataTestId}
              >
                <NoResultsSVG className={"mb-6 max-w-xs"} />
                <p className={"mb-2 max-w-full truncate text-center text-lg"}>
                  <Trans i18nKey={"noResultsForQuery"} values={{ query }} />
                </p>
                <p className={"text-center text-gray-500"}>{t("tryDifferentKeyword")}</p>
              </div>
            )}
            {isLoading &&
              Array(32)
                .fill(null)
                .map((_, i) => <PillSelectionLoading key={i} data-testid="pill-selection-loading" />)}
          </>
        )}
      </div>
    </div>
  );
};
export default CategorySelectionSection;
