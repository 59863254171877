import classNames from "classnames";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/SearchIcon";
import { ChangeEvent, KeyboardEvent } from "react";

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
};

const SearchInput = ({ value, onChange, placeholder, className }: Props) => {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
  const handleOnKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (e.key === "Enter") e.target.blur();
  };

  return (
    <div className={classNames("flex grow items-center rounded-md bg-beige-400 px-3 py-2", className)}>
      <SearchIcon className="mr-2" />
      <input
        placeholder={placeholder || (t("search") as string)}
        className={"w-full border-none bg-transparent px-0 py-0 outline-none ring-0 focus:ring-0 active:ring-0"}
        value={value}
        onChange={handleOnChange}
        onKeyUp={handleOnKeyUp}
      />
    </div>
  );
};

export default SearchInput;
