import { CommentDto } from "@neolime-gmbh/api-gateway-client";
import PopUp from "../../components/popup/PopUp";
import { useTranslation } from "react-i18next";
import useComments from "hooks/useComments.hook";

type Props = {
  postId: string;
  comment: CommentDto;
  isOpen: boolean;
  handleClose: () => void;
};

const DeleteCommentPopup = ({ postId, comment, isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const { deleteComment } = useComments(postId, false);

  const handleDelete = async () => {
    deleteComment(comment._id).then(handleClose);
  };

  const options = [
    {
      title: t("delete"),
      onClick: handleDelete,
    },
  ];

  return <PopUp isOpen={isOpen} onClose={handleClose} title={t("comment.delete.text") || ""} options={options} />;
};

export default DeleteCommentPopup;
