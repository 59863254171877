import ListName from "components/atoms/lists/ListName";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import MembersList from "components/organisms/lists/MembersList";
import MaloumClientContext from "contexts/MaloumClientContext";
import useList from "hooks/lists/useList.hook";
import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const AddMembers = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useStatefulNavigate();
  const { maloumClient } = useContext(MaloumClientContext);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);

  const handleSelectMember = (memberId: string) => {
    if (selectedMembers.includes(memberId)) setSelectedMembers(selectedMembers.filter((id) => id !== memberId));
    else setSelectedMembers([...selectedMembers, memberId]);
  };

  if (!id) return <StatefulNavigate to="/lists" />;

  const { list } = useList(id);

  const addMembersMutation = useMutation((listId: string) =>
    maloumClient.chatLists.updateMembersById(listId, { add: selectedMembers }),
  );

  if (!list) return <AddMembersLoading />;

  const handleAddMembers = async () => {
    addMembersMutation.mutate(list._id, {
      onSuccess: () => {
        navigate(-1);
      }
    });
  };

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("lists.members.header")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false} className="relative">
        <div className="mt-4 px-4 text-lg font-semibold md:mt-3 md:block md:px-0 md:text-xl">
          <Trans
            i18nKey="lists.members.title"
            components={{ 1: <ListName list={list} className="inline" /> }}
          />
        </div>
        <MembersList
          className="mt-4 grow px-4 pb-12 md:px-0"
          isManaged={false}
          listId={list._id}
          selectedMembers={selectedMembers}
          handleSelectMember={handleSelectMember}
        />
        <div className="sticky bottom-0 w-full border-t border-t-gray-100 bg-white px-3 py-3 md:px-0">
          <Button onClick={handleAddMembers} text={t("lists.members.save")} disabled={addMembersMutation.isLoading} />
        </div>
      </Container>
    </Layout>
  );
};

const AddMembersLoading = () => {
  const { t } = useTranslation();

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("lists.members.header")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false}>
        <div className="mx-4 mt-5 h-6 w-3/5 rounded-md bg-gray-100 md:mx-0 md:mt-3 md:h-7" />
        <div className="mx-4 mt-4 h-10 rounded-md bg-gray-100 md:mx-0" />
        <div className="mx-4 mt-4 flex flex-col gap-3 md:mx-0">
          {Array.from(Array(10).keys()).map((i) => (
            <div key={i} className="h-10 rounded-md bg-gray-100" />
          ))}
        </div>
      </Container>
    </Layout>
  );
};

export default AddMembers;
