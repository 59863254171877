import {MediaCandidateDto, ThumbnailCandidateDto} from "@neolime-gmbh/api-gateway-client";
import Media from "./Media";
import VideoPlayer from "components/VideoPlayer";
import { useMemo } from "react";
import classNames from "classnames";

type Props = {
  media: MediaCandidateDto & { width?: number; height?: number };
  thumbnail: ThumbnailCandidateDto;
  className?: string;
  "data-testid"?: string;
};

const ContentMedia = ({ media, thumbnail, className, "data-testid": dataTestId }: Props) => {
  const aspectRatio = useMemo(() => ({ aspectRatio: `${(media.width ?? 0) / (media.height ?? 1)}` }), [media]);

  if (
    media.type === MediaCandidateDto.type.PICTURE ||
    media.uploadStatus === MediaCandidateDto.uploadStatus.PROCESSING
  ) {
    return (
      <div
        className={classNames("relative w-full bg-beige-400", {
          "h-96": media.uploadStatus !== MediaCandidateDto.uploadStatus.FINISHED,
        })}
        style={aspectRatio}
      >
        <Media
          media={media}
          thumbnail={thumbnail}
          allowFullScreen={false}
          className={className}
          data-testid={dataTestId}
        />
      </div>
    );
  }
  return <VideoPlayer url={media.url ?? ""} thumbnail={thumbnail} width={media.width} height={media.height} />;
};

export default ContentMedia;
