import classNames from "classnames";

type Props = {
  radius?: number;
  percentage: number;
  showPercentage?: boolean;
};

const RadialProgress = ({ radius = 24, percentage, showPercentage = true }: Props) => {
  const strokeWidth = 4;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDasharray = `${(percentage * circumference) / 100} ${circumference}`;
  const size = radius * 2;

  return (
    <div className="relative" style={{ width: size, height: size }}>
      <svg className="h-full w-full" viewBox={`0 0 ${size} ${size}`}>
        <circle
          className="stroke-current text-gray-200"
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
        />
        <circle
          className="origin-center -rotate-90 stroke-current text-red-900"
          cx={radius}
          cy={radius}
          r={normalizedRadius}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
          strokeDasharray="0, 314"
          style={{ strokeDasharray }}
        />
      </svg>
      {showPercentage && (
        <div
          className={classNames(
            "absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 text-xs text-gray-700",
          )}
        >
          {percentage}%
        </div>
      )}
    </div>
  );
};

export default RadialProgress;
