const SearchIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9998 19.4999C9.16722 19.4999 5.24962 15.5824 5.24962 10.75C5.24962 5.91749 9.16722 2 13.9998 2C18.8324 2 22.75 5.91749 22.75 10.75C22.75 15.5824 18.8324 19.4999 13.9998 19.4999Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.8125 16.9375L2.74989 22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SearchIcon;
