import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  title: string;
  "data-testid": string;
  className?: string;
  children: ReactNode;
};

const FloatingAction = ({ title, "data-testid": dataTestId, className, children }: Props) => {
  return (
    <div
      className={classNames(
        "fixed bottom-[5rem] left-1/2 z-[50] w-full max-w-xl -translate-x-1/2 px-4 sm:bottom-[2rem] sm:left-[calc(50vw+2.5rem)] md:left-[calc(50vw+7.5rem)] md:w-[calc(100vw-15rem)]",
        className,
      )}
      data-testid={dataTestId}
    >
      <div className="flex w-full items-center gap-2 rounded-md bg-white p-3 shadow-card">
        <span className="grow truncate">{title}</span>
        {children}
      </div>
    </div>
  );
};

export default FloatingAction;
