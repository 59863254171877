import { useTranslation } from "react-i18next";
import { ReactComponent as NoResultsSVG } from "svg/undraw_no_results.svg";

type Props = {
  text?: string | null;
  searchedText?: string | null;
};

const NoResults = ({ text, searchedText }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={"mx-auto mt-6 flex w-full max-w-[16rem] flex-1 flex-col items-center justify-center gap-6"}
      data-testid={"no-results"}
    >
      <NoResultsSVG className={"max-w-xs"} />
      <p className={"text-center"}>
        {searchedText ? (
          <>
            {t("noResultsFor")} <strong>"{searchedText}"</strong>
          </>
        ) : (
          text || t("noResults")
        )}
      </p>
    </div>
  );
};

export default NoResults;
