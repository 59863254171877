import { PostDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext, useState } from "react";
import { HiHeart } from "react-icons/hi2";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import useUserStore from "../../state/userState";
import ContentMedia from "components/media/ContentMedia";
import classNames from "classnames";

type Props = {
  post: PostDto;
  setPost: (post: PostDto) => void;
  className?: string;
  "data-testid"?: string;
};

const PostMedia = ({ post, setPost, className, "data-testid": dataTestId }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const [showHeart, setShowHeart] = useState(false);

  const likePost = useCallback(async () => {
    // show heart and hide heart after 900ms
    setShowHeart(true);
    setTimeout(() => setShowHeart(false), 900);
    // update post
    setPost({ ...post, liked: true, likeCount: post.likeCount + 1 });

    // like post
    await maloumClient.posts
      .like(post._id)
      .then(() => {
        // update user postLikeCount if current user is post creator
        if (post.createdBy._id === user._id)
          useUserStore.setState({ user: { ...user, postLikeCount: user.postLikeCount + 1 } });
      })
      // if error, undo like
      .catch(() => setPost({ ...post, liked: false, likeCount: post.likeCount - 1 }));
  }, [post, setPost]);

  // like post if post is not yet liked by user
  const onDoubleClick = () => !post?.liked && likePost();

  return (
    <div
      className={classNames("relative select-none", className)}
      data-testid={dataTestId}
      onDoubleClick={onDoubleClick}
    >
      <ContentMedia media={post.media[0]} thumbnail={post.thumbnail} />

      {showHeart && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <HiHeart className="h-16 w-16 animate-ping-once text-red-900" />
        </div>
      )}
    </div>
  );
};

export default PostMedia;
