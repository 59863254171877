import { useState } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "../popup/PopUp";
import ReportPopUp from "../ReportPopUp";

type Props = {
  id: string;
  isProduct?: boolean;
  isOpen: boolean;
  setIsOpen: (vallue: boolean) => void;
};

const ContentReportPopUp = ({ id, isProduct = false, isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();

  const [reportIsOpen, setReportIsOpen] = useState(false);

  const options = [
    {
      title: t("report.title"),
      onClick: () => setReportIsOpen(true),
    },
  ];

  return (
    <>
      <PopUp title={t("report.action")} isOpen={isOpen} onClose={() => setIsOpen(false)} options={options} />
      <ReportPopUp
        type={isProduct ? "PRODUCT" : "POST"}
        id={id}
        isOpen={reportIsOpen}
        setIsOpen={() => setReportIsOpen(false)}
      />
    </>
  );
};

export default ContentReportPopUp;
