// @ts-nocheck
import { ComponentType, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

// rework

type TLoadingViewProps = {
  createLoadingItemComponent: (key: number) => JSX.Element;
  loadingItemsCount: number;
};

const LoadingView = ({ createLoadingItemComponent, loadingItemsCount }: TLoadingViewProps) => {
  return (
    <>
      {Array(loadingItemsCount)
        .fill(null)
        .map((_, key) => createLoadingItemComponent(key))}
    </>
  );
};

type TData = { data: any[]; next: string | null };

type Props = {
  loadNext: (value: string | null) => Promise<TData>;
  createItemComponent: (value: any, value?: number) => JSX.Element | JSX.Element[];
  loadingItemsCount?: number;
  createLoadingItemComponent: (value: number) => JSX.Element;
  NoResultsView: ComponentType;
  showLoader?: boolean;
  data: TData | object;
  setData: any;
  loadAnyway?: boolean;
  className?: string;
  parentId?: string;
  "data-testid"?: string;
};

const MyInfiniteScroll = ({
  loadNext,
  createItemComponent,
  loadingItemsCount = 2,
  createLoadingItemComponent,
  NoResultsView,
  showLoader = false,
  data,
  setData,
  loadAnyway = false,
  className,
  parentId,
  "data-testid": dataTestId,
}: Props) => {
  const [loading, setLoading] = useState(true);

  const fetchData = async (isLoadingNext = false) => {
    if ((isLoadingNext && loading) || (!data.next && data.data.length > 0 && isLoadingNext)) {
      return;
    }
    if (data.next && loadAnyway && !isLoadingNext) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const response = await loadNext(data.next);
    if (response && !(response instanceof Error)) {
      setData(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (data.data.length === 0 || loadAnyway) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <InfiniteScroll
        loadMore={() => fetchData(true)}
        hasMore={!!data.next}
        initialLoad={false}
        className={className}
        data-testid={dataTestId}
        useWindow={!parentId}
        getScrollParent={() => document.getElementById(parentId)}
      >
        {(data.data instanceof Array ? data.data.map(createItemComponent) : createItemComponent(data.data))}

        {(loading || showLoader) && (
          <LoadingView createLoadingItemComponent={createLoadingItemComponent} loadingItemsCount={loadingItemsCount} />
        )}
      </InfiniteScroll>
      {data.data.length === 0 && !loading && !showLoader && <NoResultsView />}
    </>
  );
};

export default MyInfiniteScroll;
