import { OrderDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Card from "components/atoms/Card";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineChevronRight } from "react-icons/hi2";
import useCurrency from "../../hooks/useCurrency.hook";
import useUserStore from "../../state/userState";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  order?: OrderDto;
  isLoading?: boolean;
};

const OrderItem = ({ order, isLoading = false }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const [imageHasError, setImageHasError] = useState(false);
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  if (isLoading || !order) {
    return (
      <div className={"animate-pulse rounded-md p-3 shadow"}>
        <div className={"flex"}>
          <div className={"pr-4"}>
            <div className={"h-20 w-20 rounded-md bg-gray-200"} />
          </div>
          <div className={"flex h-20 flex-1 flex-col justify-between pb-1"}>
            <div className={"mt-1 flex justify-between font-semibold"}>
              <div className={"mt-2 h-4 w-24 rounded-full bg-gray-200"} />
              <div className={"mt-2 h-4 w-14 rounded-full bg-gray-200"} />
            </div>
            <div className={"mt-2 h-3 w-20 rounded-full bg-gray-200"} />
            <div className={"mt-2 h-2 w-14 rounded-full bg-gray-200"} />
          </div>
        </div>

        <div className={"mt-2 flex flex-1 justify-end"}>
          <div className={"mt-2 h-4 w-20 rounded-full bg-gray-200"} />
        </div>
      </div>
    );
  }

  const isSeller = user._id === order.sellingUser._id;

  return (
    <StatefulLink to={`/order/${order._id}`}>
      <Card>
        <div className={"flex"}>
          <div className={"pr-4"}>
            <div
              className={classNames("h-20 w-20 animate-pulse rounded-md bg-gray-200", {
                hidden: imageIsLoaded && !imageHasError,
                block: !imageIsLoaded || imageHasError,
              })}
            />
            <img
              className={classNames("h-20 w-20 rounded-md", {
                hidden: !imageIsLoaded || imageHasError,
                block: imageIsLoaded && !imageHasError,
              })}
              src={order.product.thumbnail.url}
              alt=""
              onError={() => setImageHasError(true)}
              onLoad={() => setImageIsLoaded(true)}
            />
          </div>
          <div className={"flex min-h-full min-w-0 flex-1 flex-col justify-between"}>
            <div className={"flex justify-between font-semibold"}>
              <span className={"truncate"}>{order.product.name}</span>
              <div className={"shrink-0"}>
                {displayCurrency(
                  order.buyingUser._id === user._id ? order.transaction.price.gross : order.transaction.price.net,
                )}
              </div>
            </div>
            <div className={"flex items-center "}>
              {!isSeller && (
                <>
                  <ProfileImageWithLink
                    to={`/creator/${order.sellingUser.username}`}
                    url={order.sellingUser.profilePictureThumbnail?.url}
                    className={"mr-2 h-6 w-6"}
                  />
                  <StatefulLink to={`/creator/${order.sellingUser.username}`} className="notranslate flex items-center gap-x-1">
                    {order.sellingUser.username} {order.sellingUser.isVerified && <VerifiedUserIcon />}
                  </StatefulLink>
                </>
              )}
            </div>
            <div className="grow" />
            {isSeller && (
              <div className={"line-clamp-2 text-sm"}>
                {order.status === "DONE" ? (
                  <Trans
                    i18nKey="hasBeenDelivered"
                    components={[<span className="notranslate">name</span>]}
                    values={{ name: order.buyingUser.username }}
                  />
                ) : (
                  <>
                    {t("orderedBy")} <span className="notranslate">{order.buyingUser.username}</span>
                  </>
                )}
              </div>
            )}
            <div className="grow" />
            <div className="flex items-center">
              <div className={"text-sm text-gray-500"}>
                {new Date(order.createdAt).toLocaleString("de-AT", {
                  dateStyle: "medium",
                })}
              </div>
              <div className="grow" />
              <button className="flex items-center gap-2 text-sm text-gray-700">
                <span>{t("moreDetails")}</span>
                <HiOutlineChevronRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </Card>
    </StatefulLink>
  );
};

export default OrderItem;
