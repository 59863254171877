import EmptyFolderSelectionIllustration from "components/atoms/illustration/EmptyFolderSelectionIllustration";
import classNames from "classnames";
import { BaseComponent } from "types/components/component.type";
import { Trans } from "react-i18next";

type Props = { onCreateFolder: () => void } & BaseComponent;

const EmptyFolderSelection = ({ onCreateFolder, className, "data-testid": dataTestId }: Props) => {
  return (
    <div
      className={classNames("flex h-full w-full grow flex-col items-center justify-center gap-6", className)}
      data-testid={dataTestId}
    >
      <EmptyFolderSelectionIllustration />
      <div className="mx-20 text-center text-sm text-gray-500">
        <Trans
          i18nKey="vault.noSelectionDescription"
          components={{ 1: <button className="inline text-red-900" onClick={onCreateFolder} /> }}
        />
      </div>
    </div>
  );
};

export default EmptyFolderSelection;
