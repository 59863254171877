import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

type Props = {
  text: string;
  className?: string;
};

const CollapsableText = ({ text, className }: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(getComputedStyle(textRef.current).lineHeight, 10);
      const thresholdHeight = lineHeight * 5; // Approximate height for 5 lines
      setShowMoreButton(textRef.current.clientHeight > thresholdHeight);
    }
  }, [text]);

  return (
    <div className={classNames("overflow-hidden", className)}>
      <p ref={textRef} className={classNames("text-sm", { "line-clamp-5": !isExpanded && showMoreButton })}>
        {text}
      </p>
      {showMoreButton && (
        <button onClick={toggleExpansion} className="mt-2 text-xs text-gray-400">
          {isExpanded ? t("showLess") : t("showMore")}
        </button>
      )}
    </div>
  );
};

export default CollapsableText;
