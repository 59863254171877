import {MediaCandidateDto, PrivateUserDto, ThumbnailCandidateDto} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Media from "components/media/Media";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";

type Props = {
  media: MediaCandidateDto & { width?: number; height?: number };
  thumbnail: ThumbnailCandidateDto;
  senderId: string;
};

const SingleMediaMessage = ({ media, thumbnail, senderId }: Props) => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const isSender = user._id === senderId;

  const isProcessing = media.uploadStatus === MediaCandidateDto.uploadStatus.PROCESSING;

  return (
    <div
      className={classNames("flex w-full cursor-pointer items-center justify-center overflow-hidden bg-gray-200", {
        "h-80 min-h-[10rem]": isProcessing,
      })}
      style={{
        maxHeight: "calc(min(70vw, 25rem) * 1.5)",
        aspectRatio: `${media.width} / ${media.height}`,
      }}
    >
      <Media
        media={media}
        thumbnail={thumbnail}
        processingMessage={(isSender ? t("media.gettingReady") : t("media.contentGettingReady")) ?? ""}
        className={classNames("rounded-t-[1.5rem]", {
          "rounded-br-[1.5rem]": !isSender,
          "rounded-bl-[1.5rem]": isSender,
          "rounded-b-none": isProcessing,
        })}
      />
    </div>
  );
};

export default SingleMediaMessage;
