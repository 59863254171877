import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import ChatList from "components/chats/ChatList";
import ChatRequestActions from "components/chats/ChatRequestActions";
import ChatSettingsPopup from "components/chats/chatSettings/ChatSettingsPopup";
import LoadingChat from "components/chats/LoadingChat";
import MessageBar from "components/chats/MessageBar";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import ChatUserCard from "components/molecules/ChatUserCard";
import useChatItem from "hooks/useChatItem.hook";
import useMaloumOfficial from "hooks/useMaloumOfficial.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useState } from "react";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import { useLocation, useParams } from "react-router-dom";

const Chat = () => {
  const { id } = useParams();
  const { maloumOfficial } = useMaloumOfficial();
  const navigate = useStatefulNavigate();
  const { state } = useLocation();

  // navigate to chats overview if no id is provided
  if (!id) return <StatefulNavigate to="/chat" />;

  const { chat } = useChatItem(id);
  const chatPartner = chat?.chatPartner;

  // If the chat is moved to another inbox, we need to navigate back to the selected inbox.
  // We can't simply go to the chat's inbox because the user might have come from elsewhere and expects to return to their previous location.
  const handleNavigationBack = () => {
    if (state?.fromInbox && state?.fromInbox !== chat?.inbox) {
      if (chat?.inbox === ChatDto.inbox.REQUEST) {
        navigate("/chat?tab=request", { replace: true });
      } else {
        navigate("/chat", { replace: true });
      }
    } else {
      navigate(-1);
    }
  };

  // state for chat settings popup
  const [settingsPopupIsOpen, setSettingsPopupIsOpen] = useState(false);

  if (!chatPartner) return <LoadingChat />;

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton onClick={handleNavigationBack} />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <ChatUserCard
              url={chatPartner.profilePictureThumbnail ? chatPartner.profilePictureThumbnail.url : undefined}
              username={chatPartner.username}
              isCreator={chatPartner.isCreator}
              uploadStatus={chatPartner.profilePictureThumbnail?.uploadStatus}
              userIsVerified={chatPartner.isVerified}
            />
          </HeaderBar.Center>
          <HeaderBar.Right>
            {!chatPartner.isBlocked && chatPartner._id !== maloumOfficial?._id && (
              <HeaderBar.Button onClick={() => setSettingsPopupIsOpen(true)}>
                <HiEllipsisHorizontal className="h-6 w-6" />
              </HeaderBar.Button>
            )}
          </HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container>
        {/* display messages */}
        <ChatList chatId={chat._id} chat={chat} />
        {/* message bar bottom */}
        {chat.chatPartner.isBlocked ||
        chat.chatPartner.hasBlocked ||
        chat.inbox !== ChatDto.inbox.REQUEST ||
        state?.data?.mockPrimary ? (
          <MessageBar chatId={chat._id} />
        ) : (
          <ChatRequestActions chat={chat} userId={chat.chatPartner._id} />
        )}
        {/* settings popup */}
        <ChatSettingsPopup chat={chat} open={settingsPopupIsOpen} onClose={() => setSettingsPopupIsOpen(false)} />
      </Container>
    </Layout>
  );
};

export default Chat;
