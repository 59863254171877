import { TUpload } from "types/uploads.type";
import { create } from "zustand";

interface UploadStore {
  uploads: TUpload[];
  addUpload: (newUpload: TUpload) => void;
  removeUpload: (uploadId: string) => void;
  changeUploadedBytes: (uploadedBytes: number, totalBytes: number, uploadId: string) => void;
  finishUpload: (uploadId: string, status: "error" | "success") => void;
  addFoldersToUploads: (folderIds: string[], mediaIds: string[]) => void;
  resetUploads: () => void;
}

const useUploadStore = create<UploadStore>((set) => ({
  uploads: [],
  addUpload: (newUpload: TUpload) =>
    set((state) => ({ uploads: [...state.uploads, { ...newUpload, status: "uploading" }] })),
  removeUpload: (uploadId: string) => set((state) => ({ uploads: state.uploads.filter((u) => u.id !== uploadId) })),
  changeUploadedBytes: (uploadedBytes: number, totalBytes: number, uploadId: string) =>
    set((state) => {
      const uploadsTmp = [...state.uploads];
      const idx = uploadsTmp.findIndex((u: TUpload) => u.id === uploadId);
      if (idx !== -1) {
        uploadsTmp[idx].bytesUploaded = uploadedBytes;
        uploadsTmp[idx].bytesTotal = totalBytes;
      }
      return { uploads: uploadsTmp };
    }),
  addFoldersToUploads(folderIds, mediaIds) {
    set((state) => {
      const uploadsTmp = [...state.uploads];
      mediaIds.forEach((mediaId) => {
        const idx = uploadsTmp.findIndex((u: TUpload) => u.id === mediaId);
        if (idx !== -1) {
          uploadsTmp[idx].folderIds = [...(uploadsTmp[idx].folderIds ?? []), ...folderIds];
        }
      });
      return { uploads: uploadsTmp };
    });
  },
  finishUpload: (uploadId: string, status: "error" | "success") =>
    set((state) => {
      const uploadsTmp = [...state.uploads];
      const idx = uploadsTmp.findIndex((u: TUpload) => u.id === uploadId);
      if (idx !== -1) {
        uploadsTmp[idx].status = status;
      }
      return { uploads: uploadsTmp };
    }),
  resetUploads: () => set(() => ({ uploads: [] })),
}));

export default useUploadStore;
