import classNames from "classnames";
import useGender from "hooks/useGender";
import { useTranslation } from "react-i18next";
import PillSelection, { PillSelectionLoading } from "./atoms/PillSelection";

type Props = {
  selectedGenders: string[];
  handleGenderClick: (g: string) => void;
  className?: string;
  "data-testid"?: string;
};

const SexualOrientationSection = ({
  selectedGenders,
  handleGenderClick,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { parentGenderList } = useGender();

  return (
    <div className={classNames("flex flex-wrap gap-2", className)} data-testid={dataTestId}>
      {parentGenderList ? (
        <>
          {parentGenderList.map((g) => (
            <PillSelection
              text={t(`gender.${g.name}`)}
              selected={selectedGenders.includes(g._id)}
              key={g._id}
              onClick={() => handleGenderClick(g._id)}
            />
          ))}
        </>
      ) : (
        <>
          {Array(3)
            .fill(null)
            .map((_, i) => (
              <PillSelectionLoading key={i} data-testid="pill-selection-loading" />
            ))}
        </>
      )}
    </div>
  );
};
export default SexualOrientationSection;
