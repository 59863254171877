import { HiOutlineCheck } from "react-icons/hi2";

type Props = {
  text: string;
};

const TextWithCheckMark = ({ text }: Props) => {
  return (
    <div className={"flex py-1"}>
      <span className={"flex-1"}>{text}</span>
      <HiOutlineCheck className={"h-6 w-6 text-red-900"} />
    </div>
  );
};

export default TextWithCheckMark;
