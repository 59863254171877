import { PrivateUserDto, PublicPriceDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import TextInput from "components/basics/TextInput";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import ValidationError from "components/utilities/ValidationError";
import { Form, Formik } from "formik";
import useCurrency from "hooks/useCurrency.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";
import * as Yup from "yup";

type Props = {
  submit: (values: {
    firstName: string;
    lastName: string;
    city: string;
    postalCode: string;
    line1: string;
    line2: string;
  }) => void;
  selectedCountry: string | undefined;
  showCountrySelect: () => void;
  price: PublicPriceDto | undefined;
  className?: string;
  "data-testid"?: string;
};

const Shippment = ({
  submit,
  selectedCountry,
  showCountrySelect,
  price,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const [showCountryNotSelectedError, setShowCountryNotSelectedError] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("validation.required") as string)
      .max(100, ({ max }) => t("validation.max", { max: max })),
    lastName: Yup.string()
      .required(t("validation.required") as string)
      .max(100, ({ max }) => t("validation.max", { max: max })),
    city: Yup.string()
      .required(t("validation.required") as string)
      .max(100, ({ max }) => t("validation.max", { max: max })),
    postalCode: Yup.number().required(t("validation.required") as string),
    line1: Yup.string().required(t("validation.required") as string),
    line2: Yup.string(),
  });

  const handleOnSubmit = (values: {
    firstName: string;
    lastName: string;
    city: string;
    postalCode: string;
    line1: string;
    line2: string;
  }) => {
    if (!selectedCountry) {
      setShowCountryNotSelectedError(true);
      return;
    }
    submit(values);
  };

  const handleShowCountrySelect = () => {
    showCountrySelect();
    setShowCountryNotSelectedError(false);
  };

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <div className="text-2xl font-semibold">{t("checkout.shipping.details")}</div>
      <p className="mt-1 text-sm text-gray-500">{t("checkout.shipping.text")}</p>
      <Formik
        initialValues={{
          city: user.address.city || "",
          postalCode: user.address.postalCode || "",
          line1: user.address.line1 || "",
          line2: user.address.line2 || "",
          firstName: user.firstName || "",
          lastName: user.lastName || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        <Form className="mt-6">
          <TextInput
            label={t("profile.firstName") || ""}
            type="text"
            name="firstName"
            placeholder={t("profile.firstName") || ""}
            required
          />
          <TextInput
            label={t("profile.lastName") || ""}
            type="text"
            name="lastName"
            placeholder={t("profile.lastName") || ""}
            required
          />
          <TextInput
            label={t("address.streetNumber")}
            type="text"
            name="line1"
            placeholder={t("address.street") || ""}
            required
          />
          <TextInput
            label={t("address.floorApartment") || ""}
            type="text"
            name="line2"
            placeholder={t("address.floorOrApartment") || ""}
          />
          <div className="grid grid-cols-2 gap-x-6">
            <TextInput
              label={t("address.zipCode") as string}
              type="text"
              name="postalCode"
              placeholder={t("address.zipCode") as string}
              required
            />

            <TextInput
              label={t("address.city") as string}
              type="text"
              name="city"
              placeholder={t("address.city") as string}
              required
            />
          </div>

          <label className="mb-2 text-sm text-gray-700">{`${t("address.country")} *`}</label>
          <SelectItemUnderline
            value={selectedCountry ? t(`countries.${selectedCountry.toUpperCase()}`) : t("selectCountry")}
            onClick={handleShowCountrySelect}
          />
          {showCountryNotSelectedError && (
            <ValidationError message={t("selectCountryError")} hasBackground={false} className="mt-2" />
          )}
          <div className="h-[7rem]" />
          <div className="fixed bottom-0 left-0 mx-auto grid w-full grid-cols-2 items-center border-t border-t-gray-100 bg-white p-3 sm:left-auto sm:w-[calc(100%-5rem)] sm:max-w-xl sm:-translate-x-4 md:w-[calc(100%-15rem)]">
            {price && <span>{displayCurrency(price.gross)}</span>}
            {!price && <span className="h-5 w-16 rounded-md bg-gray-100" />}
            <Button type="submit" data-testid="proceed-checkout-button" text={t("continue") as string} />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Shippment;
