import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import useVaultFolders from "hooks/vault/useVaultFolders.hook";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { BaseComponent, PopUpComponent } from "types/components/component.type";

type Props = { folder: FolderDto } & PopUpComponent & BaseComponent;

const DeleteVaultFolderPopup = ({ folder, open, onClose, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { state } = useLocation();

  const [deleteMediaConfirmationOpen, setDeleteMediaConfirmationOpen] = useState(false);

  const { deleteFolderMutation } = useVaultFolders("", false);

  const handleOnDelete = (deleteMedia = false) => {
    deleteFolderMutation.mutate(
      { folderId: folder._id, deleteMedia },
      {
        onSuccess: () => {
          // unselect folder and media
          navigate(".", { state: { ...state, folderId: undefined, selectedMedia: [] }, replace: true });
          onClose();
        },
      },
    );
  };

  if (deleteMediaConfirmationOpen) {
    return (
      <PopUp isOpen={open} onClose={onClose} title={t("delete") ?? ""} data-testid={dataTestId}>
        <div className={classNames("mt-4", className)}>
          <div>{t("vault.deleteFolder.areYouSureIncludingMedia", { folder: folder.name })}</div>
          <div className="mt-4 flex gap-2">
            <Button text={t("cancel")} onClick={onClose} variant="secondary" />
            <Button text={t("delete")} onClick={() => handleOnDelete(true)} />
          </div>
        </div>
      </PopUp>
    );
  }

  return (
    <PopUp isOpen={open} onClose={onClose} title={t("delete") ?? ""} data-testid={dataTestId}>
      <div className={classNames("mt-4 grid grid-cols-1 gap-2", className)}>
        <div className="pb-2">{t("vault.deleteFolder.description", { folder: folder.name })}</div>
        <Button text={t("vault.deleteFolder.onlyFolder")} onClick={() => handleOnDelete(false)} />
        <Button
          text={t("vault.deleteFolder.folderAndMedia")}
          onClick={() => setDeleteMediaConfirmationOpen(true)}
          variant="outline"
        />
      </div>
    </PopUp>
  );
};

export default DeleteVaultFolderPopup;
