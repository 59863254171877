import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

export type TColorVariant = "red" | "black";
export type TSizeVariant = "xs" | "sm" | "base" | "lg";

type Props = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "reset" | "button";
  className?: string;
  disabled?: boolean;
  variant?: TColorVariant;
  size?: TSizeVariant;
  "data-testid"?: string;
};

const TernaryButton = ({
  children,
  onClick,
  className,
  type,
  disabled,
  variant = "black",
  size = "base",
  "data-testid": dataTestId,
}: Props) => {
  return (
    <button
      data-testid={dataTestId}
      type={type}
      onClick={onClick}
      className={classNames(
        "flex rounded-md border-2 border-transparent p-2 outline-none focus:border-blue-violet disabled:text-gray-300",
        {
          "text-red-900 hover:text-red-600 active:text-red-800 ": variant === "red",
          "text-gray-700 hover:text-gray-500 active:text-gray-800 ": variant === "black",
        },
        {
          "text-xs": size === "xs",
          "text-sm": size === "sm",
          "text-base": size === "base",
          "text-lg": size === "lg",
        },
        className,
      )}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default TernaryButton;
