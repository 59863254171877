import classNames from "classnames";
import { ReactNode } from "react";

type TSizeVariants = "xs" | "sm";

type Props = {
  children: ReactNode;
  className?: string;
  colors?: string;
  size?: TSizeVariants;
};

const Pill = ({ className, colors, children, size = "xs" }: Props) => {
  return (
    <span
      className={classNames(
        "inline-block whitespace-nowrap rounded-full ",
        { "px-2 py-0 text-xs": size === "xs" },
        { "px-3 py-1 text-sm": size === "sm"},
        { "bg-red-600 text-white": !colors },
        className,
        colors,
      )}
    >
      {children}
    </span>
  );
};

export default Pill;
