import _ from "lodash";

const order = [
  "underwear",
  "stockings",
  "socks",
  "shoes",
  "feet",
  "latex",
  "wornClothes",
  "pictures",
  "bdsm",
  "bodyPositivity",
  "sexEducation",
  "sexuality",
  "general",
];

const categorySort = (categories) => {
  return _.sortBy(categories, (item) => {
    return order.indexOf(item.name);
  });
};

const transformChildrenInCategories = (categories = []) => {
  categories.forEach((category) => {
    // Add subcategories to category
    category.subCategories = categories.filter((c) => {
      return c.parentCategory && c.parentCategory._id === category._id;
    });
  });
  return categories.sort((categoryA, categoryB) => categoryA.subCategories.length - categoryB.subCategories.length);
};

const searchTransformedCategories = (categories, value) => {
  return categories.filter((category) => {
    if (category.subCategories.length === 0) {
      return category.name.toLowerCase().startsWith(value.toLowerCase());
    }
  });
};

const toggleCategory = (selectedCategories, category) => {
  if (selectedCategories.includes(category)) {
    return selectedCategories.filter((c) => c !== category);
  }
  return [...selectedCategories, category];
};

export { transformChildrenInCategories, searchTransformedCategories, toggleCategory, categorySort };
