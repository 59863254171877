import {
  MediaCandidateDto,
  PictureCandidateDto,
  PublicUserDto,
  ThumbnailCandidateDto
} from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import SubscribeButton from "components/basics/buttons/SubscribeButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiLockClosed } from "react-icons/hi2";
import Button from "components/basics/Button";
import ContentTypeIcon from "components/atoms/icons/ContentTypeIcon";
import { formatDurationInSecondsIntoHoursMinutesSeconds } from "helper/dateAndTimeHelper";
import ContentMedia from "components/media/ContentMedia";
import {useFirstline} from "@first-line/firstline-react";

type Props = {
  media: (MediaCandidateDto & { length?: number }) | undefined;
  thumbnail: ThumbnailCandidateDto;
  user: PublicUserDto;
  className?: string;
  "data-testid"?: string;
};

const PrivateMedia = ({ media, thumbnail, user, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useFirstline();

  const [errorLoading, setErrorLoading] = useState(false);
  const showNoMedia = !media || media.type === PictureCandidateDto.type.VIDEO || errorLoading;

  useEffect(() => {
    if (media?.type === MediaCandidateDto.type.PICTURE) {
      // try to load image and set error state if it fails
      const img = new Image();
      img.src = media.url ?? "";
      img.onload = () => setErrorLoading(false);
      img.onerror = () => setErrorLoading(true);
    }
  }, [media]);

  return (
    <div
      className={classNames(
        "relative flex min-h-[262px] w-full items-center bg-beige-400",
        { "h-96": showNoMedia },
        className,
      )}
      data-testid={dataTestId}
    >
      {!showNoMedia && <ContentMedia media={media} thumbnail={thumbnail} />}
      <div
        className={classNames("absolute top-1/2 z-10 flex w-full -translate-y-1/2 flex-col items-center", {
          "text-white": !showNoMedia,
          "text-gray-700": showNoMedia,
        })}
      >
        {media?.type === PictureCandidateDto.type.VIDEO ? (
          <>
            <div className="flex items-center gap-2">
              <ContentTypeIcon type={media.type} className="h-12 w-12 text-gray-700" />
              <span className="text-2xl">{formatDurationInSecondsIntoHoursMinutesSeconds(media?.length ?? 0)}</span>
            </div>

            <span className="mb-4 max-w-[16rem] text-center font-semibold">
              {isAuthenticated ? t("posts.privateVideoExplanation") : t("posts.privateExplanationGuest")}
            </span>
          </>
        ) : (
          <>
            <HiLockClosed className="mb-3 h-16 w-16" />

            <span className="mb-4 max-w-[16rem] text-center font-semibold">
              {isAuthenticated ? t("posts.privateExplanation") : t("posts.privateExplanationGuest")}
            </span>
          </>
        )}
        <div>
          {isAuthenticated ? (
            <SubscribeButton user={user} className="px-4" />
          ) : (
            <Button text={t("signUpNow")} onClick={loginWithRedirect} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivateMedia;
