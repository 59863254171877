import { AutomatedMessageDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import TwoColumnLayout from "components/layouts/TwoColumnLayout";
import EmptyAutomatedMessagesSelection from "components/molecules/automated-messages/EmptyAutomatedMessagesSelection";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import useUserStore from "state/userState";
import AutomatedMessages from "./AutomatedMessages";
import EditAutomatedMessage from "./EditAutomatedMessage";

const AutomatedMessagesParent = () => {
  const navigate = useStatefulNavigate();
  const { state } = useLocation();
  const { automatedMessageType } = useParams<{ automatedMessageType: AutomatedMessageDto.type }>();
  const isVerified = useUserStore<PrivateUserDto>((state) => state.user.isVerified);

  useEffect(() => {
    if (!isVerified) navigate("/verification/start");
  }, [isVerified]);

  const handleAutomatedMessageTypeSelection = (type: AutomatedMessageDto.type) => {
    if (state) state.data = undefined;
    navigate(`/automated-messages/${type}`, { replace: true });
  };

  const center = (
    <>
      <div className="hidden h-full xl:block">
        {automatedMessageType ? (
          <EditAutomatedMessage type={automatedMessageType} />
        ) : (
          <EmptyAutomatedMessagesSelection />
        )}
      </div>
      <div className="xl:hidden">
        {automatedMessageType ? (
          <EditAutomatedMessage type={automatedMessageType} />
        ) : (
          <AutomatedMessages
            selectedType={automatedMessageType}
            onTypeSelection={handleAutomatedMessageTypeSelection}
          />
        )}
      </div>
    </>
  );

  return (
    <TwoColumnLayout
      leftColumn={
        <AutomatedMessages selectedType={automatedMessageType} onTypeSelection={handleAutomatedMessageTypeSelection} />
      }
      rightColumn={center}
      hideNavigationOnMobile
    />
  );
};

export default AutomatedMessagesParent;
