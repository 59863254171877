import classNames from "classnames";

type Props = {
  name: string;
  onChange: (value: boolean) => void;
  value?: string;
  className?: string;
  isChecked?: boolean;
  "data-testid"?: string;
};

const Toggle = ({ className, value, onChange, isChecked, name, "data-testid": dataTestId }: Props) => {
  return (
    <label
      className={classNames("relative inline-flex cursor-pointer items-center", className)}
      data-testid={dataTestId}
    >
      <input
        type="checkbox"
        value={value}
        name={name}
        className="peer sr-only"
        checked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <div className="peer h-6 w-11 rounded-full bg-gray-300 after:absolute after:left-0.5 after:top-0.5 after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:bg-red-900 peer-checked:after:translate-x-full"></div>
    </label>
  );
};

export default Toggle;
