import { create } from "zustand";
interface CreationPopUp {
  isCreationPopUpOpen: boolean;
  openCreationPopUp: () => void;
  closeCreationPopUp: () => void;
}

const useNavigationStore = create<CreationPopUp>()((set) => ({
  isCreationPopUpOpen: false,
  openCreationPopUp: () => set({ isCreationPopUpOpen: true }),
  closeCreationPopUp: () => set({ isCreationPopUpOpen: false }),
}));

export default useNavigationStore;
