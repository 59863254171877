import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "../popup/PopUp";
import ReportPopUp from "../ReportPopUp";

type Props = {
  id: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const CreatorProfilePopUp = ({ id, isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();

  const [reportIsOpen, setReportIsOpen] = useState(false);

  const options = [
    {
      title: t("report.title"),
      onClick: () => setReportIsOpen(true),
    },
  ];

  const handlePopUpClose = useCallback(() => setIsOpen(false), []);
  const handleReportPopupClose = useCallback(() => setReportIsOpen(false), []);

  return (
    <>
      <PopUp title={t("report.action")} isOpen={isOpen} onClose={handlePopUpClose} options={options} />
      <ReportPopUp type={"CREATOR"} id={id} isOpen={reportIsOpen} setIsOpen={handleReportPopupClose} />
    </>
  );
};

export default CreatorProfilePopUp;
