import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { AutomatedMessageDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  key: string;
  type: AutomatedMessageDto.type;
  IconFront: ComponentType<{ className: string }>;
  messageTitle: string;
  active: boolean | undefined | null;
  isSelected: boolean;
  onClick: (type: AutomatedMessageDto.type) => void;
} & BaseComponent;

const AutomatedMessageItem = ({
  key,
  type,
  IconFront,
  messageTitle,
  active,
  isSelected,
  onClick,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  return (
    <button
      key={key}
      onClick={() => onClick(type)}
      className={classNames(
        "flex w-full items-center gap-2 border-b border-gray-100 py-4 text-left xl:px-2",
        { "bg-beige-400 active:bg-beige-500": isSelected, "bg-white hover:bg-beige-300 active:bg-white": !isSelected },
        className,
      )}
      data-testid={dataTestId}
    >
      <IconFront className={"h-6 w-6"} />
      <span className={classNames({ "font-semibold": isSelected })}>{messageTitle}</span>
      <span className="ml-auto text-gray-500">
        {active === true ? t("automatedMessages.active") : active === false ? t("automatedMessages.inactive") : ""}
      </span>
      <ChevronRightIcon className="h-6 w-6 text-gray-500 xl:hidden" />
    </button>
  );
};

const LoadingAutomatedMessageItem = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return (
    <div className={classNames("w-full animate-pulse rounded-md bg-gray-100", className)} data-testid={dataTestId} />
  );
};

export default AutomatedMessageItem;
export { LoadingAutomatedMessageItem };
