import { useCallback, useContext } from "react";
import { CategoryDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useInfiniteQuery } from "react-query";

type Props = {
  selectedCategory: CategoryDto | null;
};

const useDiscoverShop = ({ selectedCategory }: Props) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchProducts = useCallback(
    async (next: any) => {
      return maloumClient.products.shop([selectedCategory?._id, selectedCategory?._id], next.pageParam);
    },
    [maloumClient, selectedCategory],
  );

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["shop-discover", selectedCategory?._id],
    fetchProducts,
    {
      getPreviousPageParam: (firstPage) => firstPage.next || null,
      getNextPageParam: (lastPage) => lastPage.next || null,
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage };
};

export default useDiscoverShop;
