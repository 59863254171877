import { CountryDto } from "@neolime-gmbh/api-gateway-client";
import { useQuery } from "react-query";

const useCountries = (allowOther: boolean) => {
  const fetchCountries = (): CountryDto[] => {
    return [
      { currency: "EUR", key: "at", vat: 0.2 },
      { currency: "EUR", key: "de", vat: 0.19 },
      ...(allowOther ? [{ currency: "EUR", key: "etc", vat: 0.2 }] : []),
    ];
  };

  const { data: countries, isLoading } = useQuery("fetch-countries", fetchCountries);

  return { countries: countries || [], isLoading };
};

export default useCountries;
