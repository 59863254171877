import classNames from "classnames";
import useCurrency from "hooks/useCurrency.hook";

type Props = {
  title: string;
  amount: number;
  isLoading?: boolean;
  className?: string;
};

const DisplayMoneyAmount = ({ title, amount, className, isLoading = false }: Props) => {
  const { displayCurrency } = useCurrency();

  if (isLoading) {
    return (
      <div
        className={classNames("flex flex-col items-center justify-center py-5", className)}
        data-testId="display-money-amount-loading"
      >
        <div className={"mb-2 h-4 w-24 rounded-full bg-gray-200"} />
        <div className={"h-6 w-36 rounded-full bg-gray-200"} />
      </div>
    );
  }

  return (
    <div className={classNames("flex flex-col gap-y-2 py-3 text-center", className)}>
      <span className={"text-sm font-semibold"}>{title}</span>
      <span className={"text-3xl font-semibold"}>{displayCurrency(amount)}</span>
    </div>
  );
};

export default DisplayMoneyAmount;
