import { CommentDto, PostDto } from "@neolime-gmbh/api-gateway-client";
import PopUp from "../../components/popup/PopUp";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import EditCommentPopup from "./EditCommentPopup";
import useUserStore from "../../state/userState";
import DeleteCommentPopup from "../../components/comment/DeleteCommentPopup";
import ReportPopUp from "../../components/ReportPopUp";

type Props = {
  post: PostDto;
  comment: CommentDto;
  isOpen: boolean;
  handleClose: () => void;
};

const CommentActionPopup = ({ post, comment, isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const [editPopupIsOpen, setEditPopupIsOpen] = useState(false);
  const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
  const [reportPopupIsOpen, setReportPopupIsOpen] = useState(false);
  const user = useUserStore((state) => state.user);

  const options = useMemo(() => {
    const opt = [];
    if (comment.user._id === user._id) {
      opt.push({
        title: t("edit"),
        onClick: () => setEditPopupIsOpen(true),
      });
    }

    if (comment.user._id !== user._id) {
      opt.push({
        title: t("report.title"),
        onClick: () => setReportPopupIsOpen(true),
      });
    }

    if (comment.user._id === user._id || post.createdBy._id === user._id) {
      opt.push({
        title: t("delete"),
        onClick: () => setDeletePopupIsOpen(true),
      });
    }
    return opt;
  }, [comment, post, user, t]);

  return (
    <>
      <PopUp
        isOpen={isOpen}
        onClose={handleClose}
        options={options}
        title={comment.user._id === user._id ? t("comment.edit.title") || "" : t("comment.action") || ""}
      />

      <EditCommentPopup
        postId={post._id}
        comment={comment}
        isOpen={editPopupIsOpen}
        handleClose={() => setEditPopupIsOpen(false)}
      />

      <DeleteCommentPopup
        postId={post._id}
        comment={comment}
        isOpen={deletePopupIsOpen}
        handleClose={() => setDeletePopupIsOpen(false)}
      />

      <ReportPopUp
        type="COMMENT"
        id={comment._id}
        isOpen={reportPopupIsOpen}
        setIsOpen={() => setReportPopupIsOpen(false)}
      />
    </>
  );
};

export default CommentActionPopup;
