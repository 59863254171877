import { OrderDto, UpdateOrderDto } from "@neolime-gmbh/api-gateway-client";
import { supportEmail } from "const";
import { formatDateWithStoredLanguage } from "helper/dateAndTimeHelper";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import CreatedBy from "../components/CreatedBy";
import Container from "../components/layouts/Container";
import Header from "../components/layouts/Header";
import Layout from "../components/layouts/Layout";
import OrderStatusCard from "../components/molecules/OrderStatusCard";
import OrderStatusSingleSelectList from "../components/molecules/list/OrderStatusSingleSelectList";
import OrderProductCard from "../components/product/OrderProductCard";
import NoResults from "../components/utilities/NoResults";
import useOrderDetails from "../hooks/useOrderDetails.hook";
import {useFirstline} from "@first-line/firstline-react";

const withData =
  <P extends object>(Component: React.ComponentType<P>) =>
  () => {
    const { order, isError, isLoading, updateOrderStatus } = useOrderDetails();
    const { user } = useFirstline();
    const { t } = useTranslation();

    const isSellingUser = useMemo(() => {
      if (!!order && !!user) {
        return user.username === order.sellingUser.username;
      }
    }, [order, user]);

    if (isLoading) {
      return <OrderLoadingPage />;
    }

    if (isError) {
      return (
        <Layout>
          <Header hasBack title={t("order.details")} hasNavigation={false} />
          <Container>
            <NoResults />
          </Container>
        </Layout>
      );
    }

    return <Component {...({ order, isSellingUser, updateOrderStatus } as P)} />;
  };

const OrderLoadingPage = () => (
  <Layout>
    <Header hasBack isLoading hasNavigation={false} />
    <Container>
      <article>
        <div className="flex h-8 w-1/4 animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 cursor-pointer items-center rounded-md border border-transparent p-2 shadow focus:outline-none">
          <div className="flex flex-1 justify-between">
            <div className="mt-2 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
            <div className="flex h-8 w-8 animate-pulse rounded-full bg-gray-200"></div>
          </div>
          <div className="flex h-4 w-3/6 animate-pulse rounded-full bg-gray-200"></div>
          <div className="mt-2 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>
        </div>
        <div className="mt-4 flex h-2 w-full animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 flex h-2 w-full animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 flex h-2 w-full animate-pulse rounded-full bg-gray-200"></div>

        <div className="mt-4 flex h-4 w-1/6 animate-pulse rounded-full bg-gray-200"></div>

        <OrderProductCard isLoading className="mt-3" />
        <CreatedBy className="mt-3" />

        <div className="mt-5 flex h-3 w-1/6 animate-pulse rounded-full bg-gray-100"></div>
        <div className="mt-2 flex h-3 w-2/6 animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 flex h-3 w-3/6 animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-2 flex h-3 w-2/6 animate-pulse rounded-full bg-gray-200"></div>
        <div className="mt-5 flex h-3 w-1/6 animate-pulse rounded-full bg-gray-100"></div>
        <div className="mt-2 flex h-3 w-2/6 animate-pulse rounded-full bg-gray-200"></div>
      </article>
    </Container>
  </Layout>
);

type OrderDetailLayoutProps = {
  order: OrderDto;
  isSellingUser: boolean;
  updateOrderStatus: ({ status, trackingNumber }: UpdateOrderDto) => Promise<void>;
};

const OrderDetailLayout = ({ order, isSellingUser, updateOrderStatus }: OrderDetailLayoutProps) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Header hasBack title={t("order.details")} hasNavigation={false} />

      <Container className={"pb-16"}>
        <article>
          <h2 className="text-xl font-semibold">{`${t("order.title")} #${order._id}`}</h2>
          <h3 className="mt-4 text-base font-semibold">{t("status")}</h3>

          {isSellingUser ? (
            <>
              <OrderStatusSingleSelectList
                currentStatus={order.status}
                currentTrackingNumber={order.trackingNumber}
                className="mt-3"
              />
              <p className="mt-2 text-xs text-gray-500">
                <Trans i18nKey="order.trackingNumberHint">
                  Keep your Customers up to date by changing the Order Status appropriately and provide a Tracking
                  Number. If you have any problems with your order please contact
                  <a href={`mailto:${supportEmail}`} className="text-red-900 underline">
                    {supportEmail}
                  </a>
                </Trans>
              </p>
            </>
          ) : (
            <>
              <OrderStatusCard orderStatus={order.status} className="mt-2 flex items-center justify-between" />
              {order.trackingNumber && order.status === OrderDto.status.SHIPPED && (
                <dl className="mt-2">
                  <dt>
                    <h4 className="text-xs text-gray-500">{t("order.trackingNumber")}</h4>
                  </dt>
                  <dd className="overflow-hidden text-ellipsis whitespace-nowrap">{order.trackingNumber}</dd>
                </dl>
              )}
            </>
          )}

          <h3 className="mt-6 text-base font-semibold">{t("order.title")}</h3>
          <OrderProductCard
            className="mt-2"
            productName={order.product.name}
            productImageUrl={order.product.thumbnail.url}
            price={{
              net: order.transaction.price.net,
              vatAmount: order.transaction.price.vatAmount,
              gross: order.transaction.price.gross,
            }}
            isSellingUser={isSellingUser}
            note={order.notes}
          />
          {isSellingUser ? (
            <CreatedBy user={order.buyingUser} className="mt-4" />
          ) : (
            <CreatedBy user={order.sellingUser} className="mt-4" />
          )}

          <h4 className="mt-8 text-xs text-gray-500">{t("order.shippingTo")}</h4>
          <address className="mt-1 not-italic">
            <dl>
              <dt className="sr-only">{t("name")}</dt>
              <dd className="break-words">
                {order.buyingUser.firstName} {order.buyingUser.lastName}
              </dd>

              <dt className="sr-only">{t("address.streetNumber")}</dt>
              <dd className="break-words">
                {order.shippingAddress.line1}
                {order.shippingAddress.line2 ? ` / ${order.shippingAddress.line2}` : ""}
              </dd>

              <dt className="sr-only">{t("address.zipCode")}</dt>
              <dd className="break-words">
                {order.shippingAddress.postalCode} {order.shippingAddress.city}
              </dd>

              <dt className="sr-only">{t("address.country")}</dt>
              <dd className="break-words">{order.shippingAddress.country}</dd>
            </dl>
          </address>

          <dl>
            <dt>
              <h4 className="mt-4 text-xs text-gray-500">{t("order.orderedOn")}</h4>
            </dt>
            <dd>{formatDateWithStoredLanguage(new Date(order.createdAt))}</dd>
          </dl>
        </article>
      </Container>
    </Layout>
  );
};

const OrderDetailPage = withData(OrderDetailLayout);

export default OrderDetailPage;
