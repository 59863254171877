import classNames from "classnames";
import { isMobileDevice } from "helper/deviceHelpers";
import { KeyboardEvent, useCallback, useEffect, useRef } from "react";
// TODO: Rework TextArea and AutoGrowTextarea

type Props = {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  className?: string;
  maxLength?: number;
  autogrow?: boolean;
  maxRows?: number;
  placeholder?: string | null;
  disabled?: boolean;
  "data-testid"?: string;
};

const AutoGrowTextarea = ({
  className,
  maxLength,
  autogrow = true,
  value,
  onChange,
  maxRows = 5,
  onSubmit,
  placeholder,
  disabled = false,
  "data-testid": dataTestId,
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const maxHeight = maxRows * 26;

  useEffect(() => {
    if (autogrow && textAreaRef.current) {
      textAreaRef.current.style.height = "32px";
      textAreaRef.current.style.maxHeight = `${maxHeight}px`;

      const scrollHeight = textAreaRef.current.scrollHeight;

      textAreaRef.current.style.height = scrollHeight + "px";
      if (!isMobileDevice()) textAreaRef.current.focus();
    }
  }, [textAreaRef, value]);

  // TODO: keyCode is deprecated according to sonarlint -> adjust
  // call submit on Enter & not Shift press
  const handleEnterToSubmit = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (!e.shiftKey && e.keyCode === 13) {
        onSubmit();
        e.preventDefault();
      }
    },
    [onSubmit],
  );

  return (
    <textarea
      className={classNames(className, "resize-none overflow-y-auto transition-none")}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      rows={maxRows}
      maxLength={maxLength}
      onKeyDown={handleEnterToSubmit}
      ref={textAreaRef}
      placeholder={placeholder !== null ? placeholder : ""}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
};

export default AutoGrowTextarea;
