import { useContext } from "react";
import { useQuery } from "react-query";
import MaloumClientContext from "../contexts/MaloumClientContext";

const useMaloumOfficial = () => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchMaloumOfficial = () => maloumClient.users.getMaloumOfficial();

  const {
    data: maloumOfficial,
    isLoading,
    isError,
  } = useQuery(`fetch-maloum-official`, fetchMaloumOfficial, { staleTime: 7 * 24 * 60 * 60 * 1000 });

  return { maloumOfficial, isLoading, isError };
};

export default useMaloumOfficial;
