import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ProfileImage from "components/basics/ProfileImage";
import { HiOutlineUser, HiUser } from "react-icons/hi2";
import useUserStore from "state/userState";

type UserNavIconProps = {
  selected: boolean;
};

const UserNavIcon = ({ selected = false }: UserNavIconProps) => {
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  if (user?.isCreator && user.profilePictureThumbnail?.uploadStatus) {
    return (
      <ProfileImage
        url={user?.profilePictureThumbnail?.url}
        className={classNames("h-6 w-6 border", { "border-gray-800": selected, "border-transparent": !selected })}
        uploadStatus={user.profilePictureThumbnail?.uploadStatus}
        spinnerClassName="w-4 h-4"
        isCurrentUser={true}
      />
    );
  } else {
    return (
      <>
        {selected ? <HiUser className="h-7 w-7 sm:h-6 sm:w-6" /> : <HiOutlineUser className="h-7 w-7 sm:h-6 sm:w-6" />}
      </>
    );
  }
};

const UserNavIconSelected = () => <UserNavIcon selected />;

export { UserNavIcon, UserNavIconSelected };
