import { RelatedUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";

const useMembers = (listId: string, query: string, fetchData = true, addMembers = false) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const queryClient = useQueryClient();

  const removeMemberFromQuery = async (memberId: string) => {
    queryClient.setQueryData(["members", query, listId, false], (oldData: any) => {
      return {
        pages: oldData.pages.map((page: any) => ({
          ...page,
          data: page.data.filter((member: RelatedUserDto) => member.user._id !== memberId),
        })),
      };
    });
  };

  const fetchMembers = async (next: any) => {
    if (!addMembers) return await maloumClient.chatLists.getMembersById(listId, query, next.pageParam, 15);
    return await maloumClient.chatLists.getRelevantMembersById(listId, query, next.pageParam, 15);
  };

  const {
    data: members,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(["members", query, listId, addMembers], fetchMembers, {
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    enabled: fetchData,
  });

  return {
    members: members?.pages.flatMap((page) => page.data) || [],
    isLoading: isFetching,
    hasNextPage: hasNextPage ?? true,
    fetchNextPage,
    removeMemberFromQuery,
  };
};

export default useMembers;
