import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import SelectedLists from "components/organisms/lists/SelectedLists";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";

const SelectedListsOverview = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { include, exclude } = state;

  // navigate to the home page if no lists are selected
  if (!include && !exclude) return <StatefulNavigate to="/" />;

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("lists.selected")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="mt-6">
        <div className="font-semibold">{t("newMessage.sendTo")}</div>
        <SelectedLists lists={include} className="mt-4" />
        <div className="mt-10 font-semibold">{t("newMessage.exclude")}</div>
        <SelectedLists lists={exclude} className="mt-4" />
      </Container>
    </Layout>
  );
};

export default SelectedListsOverview;
