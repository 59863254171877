import { PostDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import useCreatorFeed from "hooks/useCreatorFeed.hook";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle, HiOutlineRectangleGroup } from "react-icons/hi2";
import { useInView } from "react-intersection-observer";
import PostItem, { PostItemLoading } from "../post/PostItem";
import GuestClickHandler from "components/guest/GuestClickHandler";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import StatefulLink from "components/atoms/utils/StatefulLink";
import { useFirstline } from "@first-line/firstline-react";

type Props = { creator: PublicUserDto; viewedByGuest?: boolean };

const CreatorFeed = ({ creator, viewedByGuest = false }: Props) => {
  const { t } = useTranslation();
  // ref for loading trigger div
  const { ref, inView } = useInView();

  const { user: authUser } = useFirstline();
  const isOwnProfile = creator.username === authUser?.username;

  // useCreatorFeed
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, updatePost, removePost } = useCreatorFeed({
    creator,
    viewedByGuest,
  });

  // create PostItem
  const createItemComponent = useCallback(
    (item: PostDto) => (
      <GuestClickHandler isGuest={viewedByGuest} key={item._id}>
        <PostItem
          post={item}
          deletedCallback={() => removePost(item._id)}
          updateCallback={updatePost}
          viewedByGuest={viewedByGuest}
        />
      </GuestClickHandler>
    ),
    [removePost, updatePost],
  );

  // loading item view
  const createLoadingItemComponent = (key: number) => <PostItemLoading key={key} data-testid="post-item" />;

  // empty creator feed view
  const NoPostResultsView = useCallback(
    () =>
      isOwnProfile ? (
        <StatefulLink to="/post/create" className={"mt-12 flex flex-col items-center"}>
          <div className={"text-center"}>{t("posts.first")}</div>
          <HiOutlinePlusCircle className={"mt-4 h-16 w-16 stroke-1"} />
        </StatefulLink>
      ) : (
        <div className={"mt-4 flex flex-col items-center p-5"}>
          <span className={"mb-3"}>{t("posts.empty")}</span>
          <HiOutlineRectangleGroup className={"h-7 w-7"} />
        </div>
      ),
    [isOwnProfile],
  );

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className="relative pb-16">
      {data ? (
        <>
          {data.pages.map((page) => page.data.map((e: PostDto) => createItemComponent(e)))}
          {isFetchingNextPage && <AiOutlineLoading3Quarters className="mx-auto mb-16 mt-8 h-6 w-6 animate-spin" />}
          {data.pages[0].data.length === 0 && <NoPostResultsView />}
          <div className="absolute bottom-[40rem]" ref={ref} />
        </>
      ) : (
        <>
          {Array(2)
            .fill(null)
            .map((_, key) => createLoadingItemComponent(key))}
        </>
      )}
    </div>
  );
};

export default CreatorFeed;
