import classNames from "classnames";
import Card from "components/atoms/Card";
import { HiOutlineCamera, HiOutlineEllipsisHorizontal } from "react-icons/hi2";
import DateWithIcon from "components/molecules/basics/DateWithIcon";
import { ReactNode, useEffect, useMemo } from "react";
import { Trans } from "react-i18next";
import { MediaCandidateDto, MessageContentDto } from "@neolime-gmbh/api-gateway-client";
import { ChatProductMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/ChatProductMessageContentDto";
import { MediaMessageContentDto } from "@neolime-gmbh/api-gateway-client/models/MediaMessageContentDto";
import useChatProduct from "hooks/useChatProduct.hook";
import useCurrency from "hooks/useCurrency.hook";
import CollapsableText from "components/molecules/CollapsableText";
import OneLineMediaGrid from "components/organisms/OneLineMediaGrid";

type Props = {
  date: Date;
  text?: string;
  content?: MessageContentDto;
  onOpenPopup: () => void;
  labels: ReactNode;
  className?: string;
};

const BroadcastCard = ({ date, text, content, onOpenPopup, labels, className }: Props) => {
  // TODO rework hook!
  const { chatProduct, mutateChatProduct } = useChatProduct();

  const { media, thumbnails, price } = useMemo(() => {
    if (content?.type === MessageContentDto.type.CHAT_PRODUCT) {
      return {
        media: chatProduct?.media || [],
        thumbnails: chatProduct?.thumbnails || [],
        price: chatProduct?.price.net,
      };
    } else if (content?.type === MessageContentDto.type.MEDIA) {
      const mediaContent = content as MediaMessageContentDto;
      return {
        media: mediaContent.media as MediaCandidateDto[],
        thumbnails: mediaContent.thumbnails,
        price: undefined,
      };
    } else {
      return { media: [], thumbnails: [] };
    }
  }, [content, chatProduct]);

  const { imageCount, videoCount } = useMemo(() => {
    if (media.length > 0) {
      const imageCount = media.filter((m: MediaCandidateDto) => m.type === MediaCandidateDto.type.PICTURE).length;
      const videoCount = media.filter((m: MediaCandidateDto) => m.type === MediaCandidateDto.type.VIDEO).length;

      return { imageCount, videoCount };
    }

    return { imageCount: 0, videoCount: 0 };
  }, [media]);

  useEffect(() => {
    if (content?.type === MessageContentDto.type.CHAT_PRODUCT) {
      const chatProductContent = content as ChatProductMessageContentDto;
      mutateChatProduct(chatProductContent._id);
    }
  }, []);

  return (
    <>
      <Card className={classNames("grid grid-cols-1 gap-2", className)}>
        <div className="flex justify-between">
          <DateWithIcon date={date} />
          <button onClick={onOpenPopup}>
            <HiOutlineEllipsisHorizontal className="h-6 w-6" />
          </button>
        </div>
        {labels}
        <CollapsableText text={text ?? ""} />
        {media.length > 0 && (
          <>
            <OneLineMediaGrid media={media} thumbnails={thumbnails} />
            <MediaSummary imageCount={imageCount} videoCount={videoCount} price={price} />
          </>
        )}
      </Card>
    </>
  );
};

export default BroadcastCard;

type MediaSummaryProps = {
  imageCount: number;
  videoCount: number;
  price?: number;
  className?: string;
};

const MediaSummary = ({ imageCount, videoCount, price, className }: MediaSummaryProps) => {
  const { displayCurrency } = useCurrency();

  const text = useMemo(() => {
    if (price) {
      if (imageCount > 0 && videoCount > 0) {
        return (
          <Trans
            i18nKey="chats.broadcasts.imageAndVideoSummaryWithPrice"
            values={{ imageCount, videoCount, price: displayCurrency(price) }}
          />
        );
      }
      if (imageCount > 0) {
        return (
          <Trans
            i18nKey="chats.broadcasts.imageSummaryWithPrice"
            values={{ count: imageCount, price: displayCurrency(price) }}
          />
        );
      }
      return (
        <Trans
          i18nKey="chats.broadcasts.videoSummaryWithPrice"
          values={{ count: videoCount, price: displayCurrency(price) }}
        />
      );
    }

    if (imageCount > 0 && videoCount > 0) {
      return <Trans i18nKey="chats.broadcasts.imageAndVideoSummary" values={{ imageCount, videoCount }} />;
    }
    if (imageCount > 0) {
      return <Trans i18nKey="chats.broadcasts.imageSummary" values={{ count: imageCount }} />;
    }
    return <Trans i18nKey="chats.broadcasts.videoSummary" values={{ count: videoCount }} />;
  }, [imageCount, videoCount, price]);

  return (
    <div className={classNames("flex items-center gap-1", className)}>
      <HiOutlineCamera className="h-5 w-5" />
      <div className="flex gap-1 text-sm">{text}</div>
    </div>
  );
};
