import { HiOutlineCalendarDays } from "react-icons/hi2";
import { formatSchedulingDate } from "helper/dateAndTimeHelper";

type Props = {
  date: Date;
};

const DateWithIcon = ({ date }: Props) => {
  return (
    <div className="flex items-center">
      <HiOutlineCalendarDays className="h-6 w-6" />
      <span className="ml-2 text-sm">{formatSchedulingDate(date)}</span>
    </div>
  );
};

export default DateWithIcon;
