type Props = {
  className?: string;
};

const BlockedIllustration = ({ className }: Props) => {
  return (
    <svg
      width="108"
      height="120"
      viewBox="0 0 108 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_9443_18923)">
        <path
          d="M90.6734 32.6537H90.1113V17.0404C90.1113 14.6438 89.1712 12.3453 87.4977 10.6506C85.8242 8.95596 83.5545 8.00391 81.1879 8.00391H48.5222C47.3504 8.00389 46.19 8.2376 45.1073 8.69171C44.0247 9.14582 43.041 9.81143 42.2124 10.6505C41.3837 11.4896 40.7264 12.4858 40.2779 13.5821C39.8295 14.6785 39.5987 15.8536 39.5986 17.0403V102.696C39.5986 105.093 40.5388 107.391 42.2122 109.086C43.8857 110.78 46.1554 111.732 48.5221 111.732H81.1874C83.5541 111.732 85.8238 110.78 87.4973 109.086C89.1707 107.391 90.1109 105.093 90.1109 102.696V43.7675H90.673L90.6734 32.6537Z"
          fill="#3F3D56"
        />
        <path
          d="M88.2814 16.4345V103.3C88.2811 105.117 87.5683 106.86 86.2996 108.145C85.031 109.43 83.3103 110.152 81.5159 110.153H48.1801C46.385 110.153 44.6634 109.431 43.3938 108.146C42.1242 106.861 41.4107 105.118 41.4102 103.3V16.4345C41.4107 14.6168 42.1242 12.8737 43.3937 11.5886C44.6632 10.3036 46.3848 9.5818 48.1798 9.58203H52.2236C52.0251 10.0765 51.9495 10.6128 52.0035 11.1438C52.0576 11.6748 52.2396 12.1842 52.5336 12.6272C52.8276 13.0703 53.2246 13.4334 53.6896 13.6848C54.1546 13.9361 54.6735 14.0679 55.2006 14.0685H74.206C74.7331 14.0679 75.252 13.9361 75.717 13.6848C76.1821 13.4334 76.579 13.0703 76.873 12.6272C77.167 12.1842 77.349 11.6748 77.4031 11.1438C77.4571 10.6128 77.3815 10.0765 77.183 9.58203H81.5146C83.309 9.58234 85.0297 10.3042 86.2987 11.589C87.5676 12.8737 88.2807 14.6161 88.2814 16.4332V16.4345Z"
          fill="white"
        />
        <path
          d="M64.8392 51.3045C72.5255 51.3045 78.7565 44.9946 78.7565 37.2108C78.7565 29.4271 72.5255 23.1172 64.8392 23.1172C57.1529 23.1172 50.9219 29.4271 50.9219 37.2108C50.9219 44.9946 57.1529 51.3045 64.8392 51.3045Z"
          fill="#F33800"
        />
        <path
          d="M80.018 66.47H50.3952C50.2498 66.4699 50.1103 66.4113 50.0075 66.3072C49.9047 66.203 49.8468 66.0618 49.8467 65.9146V58.4735C49.8469 58.3262 49.9047 58.185 50.0076 58.0809C50.1104 57.9767 50.2498 57.9182 50.3952 57.918H80.018C80.1634 57.9182 80.3028 57.9767 80.4057 58.0809C80.5085 58.185 80.5663 58.3262 80.5665 58.4735V65.9147C80.5663 66.062 80.5085 66.2032 80.4057 66.3073C80.3028 66.4114 80.1634 66.47 80.018 66.4702V66.47ZM50.3952 58.1401C50.3079 58.1402 50.2242 58.1753 50.1625 58.2378C50.1008 58.3003 50.0661 58.3851 50.066 58.4734V65.9147C50.0661 66.0031 50.1008 66.0878 50.1625 66.1503C50.2242 66.2128 50.3079 66.248 50.3952 66.2481H80.018C80.1053 66.248 80.189 66.2128 80.2507 66.1503C80.3124 66.0878 80.3471 66.0031 80.3472 65.9147V58.4734C80.3471 58.3851 80.3124 58.3003 80.2507 58.2378C80.189 58.1753 80.1053 58.1402 80.018 58.1401H50.3952Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 60.5977C59.455 60.5977 59.3573 60.6386 59.2853 60.7116C59.2133 60.7845 59.1729 60.8834 59.1729 60.9865C59.1729 61.0897 59.2133 61.1886 59.2853 61.2615C59.3573 61.3345 59.455 61.3754 59.5569 61.3754H77.6595C77.7613 61.3794 77.8605 61.3422 77.9352 61.2721C78.01 61.202 78.0542 61.1047 78.0581 61.0016C78.062 60.8986 78.0253 60.7981 77.956 60.7224C77.8868 60.6468 77.7907 60.602 77.689 60.5981C77.6833 60.5979 77.6777 60.5978 77.6721 60.5978L59.5569 60.5977Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 62.9297C59.455 62.9297 59.3573 62.9707 59.2853 63.0436C59.2133 63.1165 59.1729 63.2154 59.1729 63.3186C59.1729 63.4217 59.2133 63.5206 59.2853 63.5936C59.3573 63.6665 59.455 63.7075 59.5569 63.7075H77.6595C77.7613 63.7114 77.8605 63.6742 77.9352 63.6041C78.01 63.534 78.0542 63.4368 78.0581 63.3337C78.062 63.2306 78.0253 63.1302 77.956 63.0545C77.8868 62.9788 77.7907 62.9341 77.689 62.9301C77.6833 62.9299 77.6777 62.9298 77.6721 62.9298L59.5569 62.9297Z"
          fill="#E6E6E6"
        />
        <path
          d="M80.018 78.8724H50.3952C50.2498 78.8722 50.1103 78.8137 50.0075 78.7095C49.9047 78.6054 49.8468 78.4642 49.8467 78.3169V70.8758C49.8469 70.7285 49.9047 70.5874 50.0076 70.4832C50.1104 70.3791 50.2498 70.3205 50.3952 70.3203H80.018C80.1634 70.3205 80.3028 70.3791 80.4057 70.4832C80.5085 70.5874 80.5663 70.7285 80.5665 70.8758V78.3171C80.5663 78.4643 80.5084 78.6054 80.4056 78.7095C80.3028 78.8136 80.1634 78.8722 80.018 78.8724ZM50.3952 70.5424C50.3079 70.5425 50.2242 70.5777 50.1625 70.6402C50.1008 70.7027 50.0661 70.7874 50.066 70.8758V78.3171C50.0661 78.4054 50.1008 78.4902 50.1625 78.5527C50.2242 78.6152 50.3079 78.6503 50.3952 78.6504H80.018C80.1053 78.6503 80.189 78.6152 80.2507 78.5527C80.3124 78.4902 80.3471 78.4054 80.3472 78.3171V70.8758C80.3471 70.7874 80.3124 70.7027 80.2507 70.6402C80.189 70.5777 80.1053 70.5425 80.018 70.5424H50.3952Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 73.043C59.455 73.043 59.3573 73.0839 59.2853 73.1569C59.2133 73.2298 59.1729 73.3287 59.1729 73.4319C59.1729 73.535 59.2133 73.6339 59.2853 73.7068C59.3573 73.7798 59.455 73.8207 59.5569 73.8207H77.6595C77.7599 73.8202 77.8561 73.7798 77.9275 73.7083C77.9989 73.6368 78.0398 73.5398 78.0414 73.4381C78.043 73.3365 78.0053 73.2382 77.9363 73.1644C77.8672 73.0905 77.7724 73.047 77.6721 73.0431H59.5569V73.043Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 75.375C59.455 75.375 59.3573 75.416 59.2853 75.4889C59.2133 75.5618 59.1729 75.6607 59.1729 75.7639C59.1729 75.867 59.2133 75.9659 59.2853 76.0389C59.3573 76.1118 59.455 76.1528 59.5569 76.1528H77.6595C77.7599 76.1522 77.8561 76.1118 77.9275 76.0403C77.9989 75.9688 78.0398 75.8718 78.0414 75.7702C78.043 75.6685 78.0053 75.5702 77.9363 75.4964C77.8672 75.4226 77.7724 75.379 77.6721 75.3752H59.5569V75.375Z"
          fill="#E6E6E6"
        />
        <path
          d="M80.018 91.3177H50.3952C50.2498 91.3176 50.1103 91.259 50.0075 91.1548C49.9047 91.0507 49.8468 90.9095 49.8467 90.7622V83.3211C49.8469 83.1739 49.9047 83.0327 50.0076 82.9285C50.1104 82.8244 50.2498 82.7658 50.3952 82.7656H80.018C80.1634 82.7658 80.3028 82.8244 80.4057 82.9285C80.5085 83.0327 80.5663 83.1739 80.5665 83.3211V90.7624C80.5663 90.9096 80.5085 91.0508 80.4057 91.155C80.3028 91.2591 80.1634 91.3177 80.018 91.3179V91.3177ZM50.3952 82.9878C50.3079 82.9878 50.2242 83.023 50.1625 83.0855C50.1008 83.148 50.0661 83.2327 50.066 83.3211V90.7624C50.0661 90.8508 50.1008 90.9355 50.1625 90.998C50.2242 91.0605 50.3079 91.0956 50.3952 91.0957H80.018C80.1053 91.0956 80.189 91.0605 80.2507 90.998C80.3124 90.9355 80.3471 90.8508 80.3472 90.7624V83.3211C80.3471 83.2327 80.3124 83.148 80.2507 83.0855C80.189 83.023 80.1053 82.9878 80.018 82.9878H50.3952Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 85.4844C59.455 85.4844 59.3573 85.5253 59.2853 85.5983C59.2133 85.6712 59.1729 85.7701 59.1729 85.8733C59.1729 85.9764 59.2133 86.0753 59.2853 86.1482C59.3573 86.2212 59.455 86.2621 59.5569 86.2621H77.6595C77.7099 86.2641 77.7602 86.256 77.8075 86.2383C77.8548 86.2205 77.8982 86.1935 77.9352 86.1588C77.9722 86.1241 78.0021 86.0824 78.0232 86.036C78.0443 85.9896 78.0561 85.9394 78.0581 85.8884C78.06 85.8373 78.052 85.7864 78.0345 85.7385C78.017 85.6906 77.9903 85.6466 77.956 85.6092C77.9218 85.5717 77.8805 85.5414 77.8347 85.5201C77.7889 85.4987 77.7394 85.4867 77.689 85.4848C77.6833 85.4846 77.6777 85.4845 77.6721 85.4845L59.5569 85.4844Z"
          fill="#E6E6E6"
        />
        <path
          d="M59.5569 87.8203C59.455 87.8203 59.3573 87.8613 59.2853 87.9342C59.2133 88.0071 59.1729 88.1061 59.1729 88.2092C59.1729 88.3123 59.2133 88.4113 59.2853 88.4842C59.3573 88.5571 59.455 88.5981 59.5569 88.5981H77.6595C77.7099 88.6 77.7602 88.5919 77.8075 88.5742C77.8548 88.5565 77.8982 88.5295 77.9352 88.4948C77.9722 88.4601 78.0021 88.4183 78.0232 88.3719C78.0443 88.3255 78.0561 88.2753 78.0581 88.2243C78.06 88.1733 78.052 88.1223 78.0345 88.0744C78.017 88.0265 77.9903 87.9826 77.956 87.9451C77.9218 87.9076 77.8805 87.8774 77.8347 87.856C77.7889 87.8347 77.7394 87.8227 77.689 87.8207C77.6833 87.8205 77.6777 87.8204 77.6721 87.8205H59.5569L59.5569 87.8203Z"
          fill="#E6E6E6"
        />
        <path
          d="M107.344 112.004H0.902357C0.748001 112.004 0.623047 111.936 0.623047 111.852C0.623047 111.768 0.748145 111.699 0.902357 111.699H107.344C107.498 111.699 107.623 111.768 107.623 111.852C107.623 111.936 107.498 112.004 107.344 112.004Z"
          fill="#E6E6E6"
        />
        <path
          d="M102.836 106.054C102.381 106.216 101.893 106.256 101.418 106.172C100.943 106.087 100.498 105.88 100.125 105.57C99.176 104.763 98.8784 103.434 98.6362 102.203L97.9199 98.5625L99.4195 99.6082C100.498 100.36 101.601 101.136 102.348 102.226C103.094 103.315 103.42 104.802 102.82 105.98"
          fill="#E6E6E6"
        />
        <path
          d="M102.604 110.715C102.793 109.322 102.987 107.912 102.855 106.504C102.737 105.254 102.361 104.033 101.594 103.032C101.187 102.502 100.692 102.048 100.131 101.69C99.9844 101.596 99.8496 101.831 99.9953 101.924C100.966 102.545 101.717 103.463 102.14 104.545C102.607 105.747 102.682 107.058 102.601 108.336C102.552 109.109 102.449 109.876 102.346 110.643C102.337 110.677 102.342 110.714 102.36 110.745C102.377 110.776 102.406 110.799 102.439 110.81C102.473 110.819 102.51 110.814 102.541 110.797C102.572 110.779 102.594 110.749 102.604 110.715H102.604Z"
          fill="#F2F2F2"
        />
        <path
          d="M101.245 108.48C101.05 108.78 100.782 109.024 100.467 109.189C100.152 109.354 99.7997 109.434 99.4451 109.421C98.5338 109.377 97.774 108.733 97.0902 108.122L95.0674 106.313L96.4061 106.248C97.3689 106.201 98.3566 106.158 99.2742 106.459C100.192 106.759 101.037 107.483 101.205 108.444"
          fill="#E6E6E6"
        />
        <path
          d="M103.137 111.515C102.229 109.886 101.175 108.077 99.2914 107.498C98.7678 107.338 98.2187 107.281 97.6738 107.329C97.502 107.344 97.5449 107.612 97.717 107.597C98.6305 107.521 99.5421 107.765 100.299 108.289C101.027 108.791 101.593 109.488 102.073 110.228C102.367 110.681 102.63 111.154 102.893 111.625C102.977 111.776 103.223 111.667 103.137 111.515Z"
          fill="#F2F2F2"
        />
        <path
          d="M54.0911 64.4827C55.3635 64.4827 56.395 63.4381 56.395 62.1496C56.395 60.861 55.3635 59.8164 54.0911 59.8164C52.8186 59.8164 51.7871 60.861 51.7871 62.1496C51.7871 63.4381 52.8186 64.4827 54.0911 64.4827Z"
          fill="#F33800"
        />
        <path
          d="M52.9884 61.7148C52.9619 61.7148 52.9404 61.889 52.9404 62.1037C52.9404 62.3183 52.9619 62.4926 52.9884 62.4926H55.2539C55.2804 62.4961 55.3023 62.3249 55.3028 62.1102C55.3175 61.9766 55.3013 61.8412 55.2555 61.715H52.9884V61.7148Z"
          fill="#E6E6E6"
        />
        <path
          d="M54.0911 76.9319C55.3635 76.9319 56.395 75.8874 56.395 74.5988C56.395 73.3102 55.3635 72.2656 54.0911 72.2656C52.8186 72.2656 51.7871 73.3102 51.7871 74.5988C51.7871 75.8874 52.8186 76.9319 54.0911 76.9319Z"
          fill="#F33800"
        />
        <path
          d="M52.9884 74.1641C52.9619 74.1641 52.9404 74.3382 52.9404 74.5529C52.9404 74.7676 52.9619 74.9418 52.9884 74.9418H55.2539C55.2804 74.9453 55.3023 74.7741 55.3028 74.5594C55.3175 74.4258 55.3013 74.2905 55.2555 74.1642L52.9884 74.1641Z"
          fill="#E6E6E6"
        />
        <path
          d="M54.0911 89.3733C55.3635 89.3733 56.395 88.3288 56.395 87.0402C56.395 85.7516 55.3635 84.707 54.0911 84.707C52.8186 84.707 51.7871 85.7516 51.7871 87.0402C51.7871 88.3288 52.8186 89.3733 54.0911 89.3733Z"
          fill="#F33800"
        />
        <path
          d="M52.9884 86.6055C52.9619 86.6055 52.9404 86.7796 52.9404 86.9943C52.9404 87.209 52.9619 87.3832 52.9884 87.3832H55.2539C55.2804 87.3867 55.3023 87.2155 55.3028 87.0008C55.3175 86.8672 55.3013 86.7319 55.2555 86.6056L52.9884 86.6055Z"
          fill="#E6E6E6"
        />
        <path d="M61.8329 33.3424L61.0176 34.168L67.8456 41.0825L68.6609 40.2569L61.8329 33.3424Z" fill="white" />
        <path d="M68.661 34.1694L67.8457 33.3438L61.0177 40.2583L61.833 41.0839L68.661 34.1694Z" fill="white" />
        <path
          d="M13.6319 74.5755C13.8316 74.5039 14.0134 74.3889 14.1645 74.2384C14.3156 74.088 14.4322 73.9058 14.5062 73.7048C14.5803 73.5038 14.6099 73.2887 14.593 73.0749C14.5761 72.861 14.5131 72.6535 14.4084 72.4669L17.8047 68.5462L15.1535 68.1758L12.3347 71.9558C12.0369 72.1546 11.8215 72.4572 11.7292 72.8061C11.637 73.1551 11.6744 73.5262 11.8343 73.8491C11.9942 74.172 12.2655 74.4243 12.5968 74.5582C12.9281 74.6921 13.2964 74.6983 13.6319 74.5755Z"
          fill="#9F616A"
        />
        <path d="M17.728 109.323L15.3593 109.323L14.2324 100.07L17.7284 100.07L17.728 109.323Z" fill="#9F616A" />
        <path
          d="M18.3312 111.652L10.6934 111.651V111.553C10.6934 110.755 11.0066 109.989 11.5641 109.425C12.1217 108.86 12.8778 108.543 13.6662 108.543H13.6664L18.3313 108.543L18.3312 111.652Z"
          fill="#2F2E41"
        />
        <path d="M30.1268 107.317L27.8647 108.028L24.0791 99.5301L27.4179 98.4805L30.1268 107.317Z" fill="#9F616A" />
        <path
          d="M31.3854 109.355L24.091 111.649L24.0624 111.555C23.8286 110.793 23.9035 109.967 24.2707 109.261C24.6378 108.554 25.267 108.024 26.0199 107.787L26.0201 107.787L30.4753 106.387L31.3854 109.355Z"
          fill="#2F2E41"
        />
        <path d="M15.9134 66.668L12.4053 70.7367L15.1517 70.9869L15.9134 66.668Z" fill="#E6E6E6" />
        <path
          d="M14.5428 71.911C14.5428 71.911 13.3242 72.5279 13.1719 75.3045C13.0196 78.081 13.6289 85.3312 13.6289 85.3312C13.6289 85.3312 13.0197 88.5704 13.6289 91.5011C14.2381 94.4318 13.0197 105.074 13.7813 105.074C14.5428 105.074 18.5031 105.537 18.6554 105.074C18.8077 104.612 18.9601 97.6706 18.9601 97.6706C18.9601 97.6706 20.1786 94.1228 18.9601 91.9639C18.9601 91.9639 23.2147 99.4874 25.9666 105.229C26.5689 106.486 31.1454 105.075 30.3839 103.687C29.6223 102.298 27.7946 95.9742 27.7946 95.9742C27.7946 95.9742 26.4237 91.3467 23.9867 89.3415L25.2052 79.4696C25.2052 79.4696 27.7947 72.8369 26.2718 71.9114C24.7488 70.986 14.5428 71.911 14.5428 71.911Z"
          fill="#2F2E41"
        />
        <path
          d="M19.1126 46.7708C21.2998 46.7708 23.0728 44.9753 23.0728 42.7604C23.0728 40.5455 21.2998 38.75 19.1126 38.75C16.9254 38.75 15.1523 40.5455 15.1523 42.7604C15.1523 44.9753 16.9254 46.7708 19.1126 46.7708Z"
          fill="#A0616A"
        />
        <path
          d="M23.5291 47.0781L18.6058 50.0088C18.6058 50.0088 16.7207 51.0839 16.0165 52.9395C15.2659 54.9171 15.7021 57.7015 16.0165 58.3382C16.6257 59.5722 15.7609 62.6571 15.7609 62.6571L14.9994 69.7525C14.9994 69.7525 12.2577 72.3746 14.3902 72.6831C16.5226 72.9917 20.3305 72.5289 22.6153 72.6831C24.9001 72.8374 27.4894 73.146 26.7279 71.6031C25.9663 70.0603 25.0525 68.9808 25.9663 65.8965C26.6815 63.4825 26.6505 54.4565 26.6032 50.6447C26.5967 50.1212 26.4581 49.6082 26.2005 49.1543C25.9429 48.7004 25.5749 48.3208 25.1316 48.0516L23.5291 47.0781Z"
          fill="#E5E5E5"
        />
        <path
          opacity="0.1"
          d="M21.3211 51.9375L21.778 61.3466L17.3497 70.428L16.5986 70.1386L21.1682 61.655L21.3211 51.9375Z"
          fill="#0A0A0B"
        />
        <path opacity="0.1" d="M26.3472 63.3496V62.2695L21.1689 70.9077L26.3472 63.3496Z" fill="#0A0A0B" />
        <path
          d="M16.3017 39.0426L15.6475 38.7775C15.6475 38.7775 17.0167 37.2513 18.921 37.384L18.3853 36.7868C18.3853 36.7868 19.6946 36.2559 20.8851 37.6493C21.5109 38.3819 22.2348 39.243 22.6866 40.2128H23.3878L23.0952 40.8653L24.1194 41.5179L23.0681 41.4011C23.1677 41.9645 23.1336 42.5437 22.9687 43.0911L22.9975 43.6069C22.9975 43.6069 21.7784 41.6965 21.7784 41.4315V42.0951C21.7784 42.0951 21.1236 41.4979 21.1236 41.0997L20.7665 41.5643L20.5879 40.8345L18.3857 41.5643L18.7425 40.9668L17.3734 41.1658L17.9091 40.4361C17.9091 40.4361 16.3616 41.2987 16.3021 42.0287C16.2426 42.7587 15.4689 43.6876 15.4689 43.6876L15.1118 43.0239C15.1118 43.0239 14.5762 40.0379 16.3017 39.0426Z"
          fill="#2F2E41"
        />
        <path
          d="M18.8634 75.0118C19.0474 74.9055 19.2061 74.7595 19.3284 74.5843C19.4507 74.4091 19.5336 74.2089 19.5712 73.9977C19.6088 73.7866 19.6003 73.5697 19.5462 73.3622C19.492 73.1548 19.3936 72.9619 19.2579 72.7971L21.9131 68.3281L19.239 68.4402L17.1276 72.6668C16.8694 72.916 16.7104 73.2525 16.6808 73.6125C16.6512 73.9725 16.753 74.331 16.967 74.62C17.1809 74.9091 17.4921 75.1086 17.8417 75.1808C18.1912 75.253 18.5547 75.1929 18.8634 75.0118Z"
          fill="#9F616A"
        />
        <path
          d="M24.9002 49.0829L26.0425 48.8516C26.0425 48.8516 29.4696 51.3966 28.4034 56.4868C27.3372 61.5769 22.6154 69.2894 22.6154 69.2894C22.6154 69.2894 21.5489 70.6776 21.2445 70.986C20.9401 71.2944 20.3306 70.986 20.6353 71.4489C20.9399 71.9117 20.1782 72.22 20.1782 72.22C20.1782 72.22 16.8272 72.22 17.1319 70.986C17.4366 69.752 22.6154 60.9593 22.6154 60.9593L21.8538 52.7842C21.8538 52.7842 21.2443 48.7744 24.9002 49.0829Z"
          fill="#E5E5E5"
        />
      </g>
      <defs>
        <clipPath id="clip0_9443_18923">
          <rect width="107" height="104" fill="white" transform="translate(0.623047 8.00391)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlockedIllustration;
