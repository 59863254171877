import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import { create } from "zustand";

type SelectedListsState = {
  includeLists: ChatListDto[];
  excludeLists: ChatListDto[];
  addOrRemoveLists: (lists: ChatListDto[], type: "include" | "exclude") => void;
  addOrRemoveList: (list: ChatListDto, type: "include" | "exclude") => void;
  reset: () => void;
};

const useSelectedListsState = create<SelectedListsState>((set, get) => ({
  includeLists: [],
  excludeLists: [],
  addOrRemoveLists: (lists, type) => {
    lists.forEach((list) => get().addOrRemoveList(list, type));
  },
  addOrRemoveList: (list: ChatListDto, type: "include" | "exclude") => {
    const { includeLists, excludeLists } = get();
    if (type === "include") {
      if (includeLists.find((l) => l._id === list._id)) {
        set({ includeLists: includeLists.filter((l) => l._id !== list._id) });
      } else {
        set({ includeLists: [...includeLists, list] });
      }
    } else {
      if (excludeLists.find((l) => l._id === list._id)) {
        set({ excludeLists: excludeLists.filter((l) => l._id !== list._id) });
      } else {
        set({ excludeLists: [...excludeLists, list] });
      }
    }
  },
  reset: () => set({ includeLists: [], excludeLists: [] }),
}));

export default useSelectedListsState;
