import { Tab } from "@headlessui/react";
import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import MyTab from "../../components/basics/MyTab";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";
import BoughtOrders from "../../components/order/BoughtOrders";
import SoldOrders from "../../components/order/SoldOrders";
import useUserStore from "../../state/userState";
import HeaderBar from "components/layouts/HeaderBar";

const Orders = () => {
  const { t } = useTranslation();
  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const tabs = ["sold", "bought"];
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab");
  const [selectedIndex, setSelectedIndex] = useState(tabName ? tabs.indexOf(tabName) : 0);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedIndex(tab);
      setSearchParams({ tab: tabs[tab] }, { replace: true });
    },
    [setSelectedIndex, setSearchParams, tabs],
  );

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("order.my")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false}>
        <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
          {user.introduction.madeProductSale && user.introduction.madeProductPurchase && (
            <Tab.List className={"flex justify-around"}>
              <MyTab title={t("order.sales")} />
              <MyTab title={t("order.purchases")} />
            </Tab.List>
          )}

          <Tab.Panels className="px-4 md:px-0">
            {user.introduction.madeProductSale && (
              <Tab.Panel>
                <SoldOrders />
              </Tab.Panel>
            )}

            {user.introduction.madeProductPurchase && (
              <Tab.Panel>
                <BoughtOrders />
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </Layout>
  );
};

export default Orders;
