type Props = {
  className?: string;
};

const EmptyScheduledPostsIllustration = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="106"
      height="126"
      viewBox="0 0 106 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8881_89374)">
        <path
          d="M30.6169 105.652L29.5684 125.754C29.5684 125.754 73.9202 83.6031 79.4457 71.2702C84.9711 58.9373 74.5703 28.4297 74.5703 28.4297L51.4934 41.0871L30.6169 105.652Z"
          fill="#9F616A"
        />
        <path
          d="M98.6227 19.9922L105.448 20.6413C105.448 20.6413 109.024 28.4305 97.9727 29.0796L98.6227 19.9922Z"
          fill="#9F616A"
        />
        <path
          d="M98.6227 32L105.448 32.6491C105.448 32.6491 109.024 40.4383 97.9727 41.0874L98.6227 32Z"
          fill="#9F616A"
        />
        <path
          d="M98.6227 44.9805L105.448 45.6296C105.448 45.6296 109.024 53.4188 97.9727 54.0679L98.6227 44.9805Z"
          fill="#9F616A"
        />
        <path
          d="M96.6725 57.9648L103.498 58.6139C103.498 58.6139 107.073 66.4031 96.0225 67.0522L96.6725 57.9648Z"
          fill="#9F616A"
        />
        <path
          d="M101.945 23.1204H101.427V8.95341C101.427 7.87664 101.215 6.81041 100.802 5.8156C100.39 4.82079 99.7848 3.91688 99.0223 3.15549C98.2597 2.39409 97.3545 1.79012 96.3582 1.37806C95.362 0.965995 94.2942 0.753907 93.2158 0.753906H63.1569C62.0785 0.753904 61.0107 0.965989 60.0145 1.37805C59.0182 1.79011 58.1129 2.39408 57.3504 3.15547C56.5879 3.91686 55.9831 4.82076 55.5704 5.81557C55.1577 6.81038 54.9453 7.87661 54.9453 8.95338V86.6748C54.9453 87.7516 55.1577 88.8178 55.5704 89.8126C55.983 90.8075 56.5879 91.7114 57.3504 92.4728C58.1129 93.2342 59.0182 93.8381 60.0144 94.2502C61.0107 94.6623 62.0785 94.8743 63.1569 94.8743H93.2158C95.3936 94.8743 97.4823 94.0105 99.0222 92.4728C100.562 90.9351 101.427 88.8495 101.427 86.6749V33.2048H101.945V23.1204Z"
          fill="#3F3D56"
        />
        <path
          d="M99.6797 9.00955V86.6172C99.6798 87.4212 99.5213 88.2175 99.2132 88.9604C98.9051 89.7033 98.4534 90.3783 97.884 90.9469C97.3146 91.5154 96.6386 91.9665 95.8946 92.2741C95.1506 92.5818 94.3532 92.7401 93.5479 92.74H63.3437C62.5385 92.74 61.7411 92.5817 60.9971 92.274C60.2532 91.9663 59.5772 91.5153 59.0078 90.9467C58.4384 90.3782 57.9867 89.7032 57.6786 88.9603C57.3704 88.2174 57.2119 87.4212 57.2119 86.6172V9.00955C57.2119 8.20548 57.3704 7.40928 57.6786 6.6664C57.9867 5.92353 58.4384 5.24854 59.0078 4.67997C59.5772 4.11141 60.2532 3.6604 60.9971 3.35271C61.7411 3.04503 62.5385 2.88668 63.3437 2.88672H67.0094C66.8289 3.32841 66.7599 3.80764 66.8086 4.28221C66.8573 4.75678 67.0221 5.21211 67.2886 5.60808C67.555 6.00405 67.9149 6.3285 68.3365 6.55286C68.7581 6.77722 69.2285 6.89458 69.7063 6.89462H86.9274C87.405 6.89427 87.8753 6.7767 88.2967 6.55226C88.7182 6.32782 89.0779 6.00339 89.3443 5.60751C89.6107 5.21164 89.7755 4.75645 89.8244 4.28201C89.8732 3.80757 89.8045 3.32842 89.6243 2.88672H93.5479C94.3532 2.88668 95.1506 3.04503 95.8945 3.35272C96.6385 3.66041 97.3145 4.11141 97.8839 4.67998C98.4533 5.24854 98.9049 5.92353 99.213 6.66641C99.5212 7.40928 99.6798 8.20548 99.6797 9.00955Z"
          fill="#F1F1F1"
        />
        <path
          opacity="0.2"
          d="M105.412 20.5408L101.392 22.0195L101.464 22.216L105.484 20.7373L105.412 20.5408Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M105.412 32.6853L101.392 34.1641L101.464 34.3606L105.484 32.8818L105.412 32.6853Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M105.412 45.6697L101.392 47.1484L101.464 47.345L105.484 45.8662L105.412 45.6697Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M103.306 58.6557L101.349 59.5938L101.439 59.7825L103.397 58.8445L103.306 58.6557Z"
          fill="black"
        />
        <path d="M64.1218 55.4419L61.6787 60.8255L57.2119 70.6694V53.2578L64.1218 55.4419Z" fill="#9F616A" />
        <path
          d="M99.1237 59.2419L99.6794 62.7096V86.6167C99.6817 87.6737 99.4077 88.7131 98.8847 89.632L93.3023 79.8594L91.2912 59.3927L90.79 54.2812L98.3541 54.4404L99.1237 59.2419Z"
          fill="#9F616A"
        />
        <path
          d="M99.6798 58.4016C99.4113 57.5305 98.9563 56.0584 98.4341 54.4167C97.5156 51.5249 96.3915 48.1012 95.7247 46.47C94.4518 43.3562 87.9655 44.6043 87.9655 44.6043L65.7428 43.773C65.7428 43.773 64.9145 43.6641 61.188 43.1699C59.6257 42.9626 58.2646 45.1487 57.2119 47.7599V55.9788L62.3896 56.3725C62.3512 57.7864 62.3512 57.5962 62.3512 59.3489C62.3512 62.4739 63.1306 64.0364 63.5598 66.6247C64.6894 71.8489 68.0475 83.6279 68.0475 83.6279L65.6589 92.741H93.5479C94.3532 92.7411 95.1506 92.5828 95.8946 92.2751C96.6386 91.9675 97.3146 91.5165 97.884 90.9479C98.4534 90.3793 98.9051 89.7043 99.2132 88.9614C99.5213 88.2185 99.6799 87.4222 99.6798 86.6182V83.2426C97.9203 80.399 96.4754 78.9688 96.4754 78.9688L91.4865 59.3753L99.124 59.2434L99.6798 59.2329V58.4016Z"
          fill="#FFB199"
        />
        <path
          d="M83.9453 35.9083C87.952 31.9074 87.952 25.4207 83.9453 21.4198C79.9385 17.419 73.4423 17.419 69.4355 21.4199C65.4288 25.4207 65.4288 31.9074 69.4355 35.9083C73.4423 39.9091 79.9385 39.9091 83.9453 35.9083Z"
          fill="#9F616A"
        />
        <path
          d="M66.1728 20.1754C67.9529 18.329 70.1795 14.9491 76.9236 13.5299C85.257 11.7761 89.1948 18.9502 87.0933 19.0678C93.2384 20.8411 89.2909 32.9599 87.5291 31.9435C87.514 31.9334 87.4995 31.9226 87.4856 31.9111C86.5381 31.2462 85.7429 30.4042 85.1484 29.4365C84.5539 28.4688 84.1727 27.3958 84.0281 26.2829L83.6937 23.4152C83.646 25.7485 81.1433 26.0571 78.5868 25.1978C78.7379 24.6862 79.0264 24.2211 79.4238 23.8482C79.8212 23.4753 80.3138 23.2076 80.8528 23.0715C79.289 24.4463 77.2533 25.4298 74.4097 25.7431C74.7588 24.4557 75.4541 23.508 76.5426 22.9462L76.4417 22.8518C75.3999 23.6074 74.3141 24.3336 73.0614 24.6757C71.6521 25.0607 69.9634 24.8525 68.9978 23.8011C68.7378 23.511 68.5414 23.1742 68.4202 22.8105C68.6289 24.6584 67.3284 26.7677 66.3181 26.4056C65.4776 26.2499 64.9877 21.4045 66.1728 20.1754Z"
          fill="#44444B"
        />
        <path
          d="M0 116.121L36.38 74.6774L37.6801 48.0643L51.3312 25.3459L55.2316 15.6094C55.2316 15.6094 64.3323 16.583 56.8567 35.0823L53.769 44.9811C56.5523 50.9672 55.8972 57.4876 55.8972 64.0875V86.7704C55.8972 92.1115 32.5044 121.205 29.5373 125.649L0 116.121Z"
          fill="#9F616A"
        />
        <path
          opacity="0.2"
          d="M54.1613 27.2728L51.4473 25.941L51.5397 25.753L54.0472 26.9836L57.8324 17.0742L58.0283 17.149L54.1613 27.2728Z"
          fill="black"
        />
        <path
          d="M78.7334 88.9833C81.8605 88.9833 84.3955 86.4521 84.3955 83.3296C84.3955 80.2071 81.8605 77.6758 78.7334 77.6758C75.6063 77.6758 73.0713 80.2071 73.0713 83.3296C73.0713 86.4521 75.6063 88.9833 78.7334 88.9833Z"
          fill="#F33800"
        />
        <path
          d="M65.5225 87.3522C67.6314 87.3522 69.3409 85.6451 69.3409 83.5394C69.3409 81.4336 67.6314 79.7266 65.5225 79.7266C63.4137 79.7266 61.7041 81.4336 61.7041 83.5394C61.7041 85.6451 63.4137 87.3522 65.5225 87.3522Z"
          fill="white"
        />
        <path
          d="M65.5225 87.5158C64.7345 87.5158 63.9641 87.2824 63.3089 86.8453C62.6536 86.4081 62.143 85.7867 61.8414 85.0597C61.5398 84.3327 61.4609 83.5328 61.6146 82.761C61.7684 81.9892 62.1479 81.2803 62.7051 80.7239C63.2623 80.1675 63.9723 79.7886 64.7452 79.635C65.5181 79.4815 66.3192 79.5603 67.0473 79.8615C67.7754 80.1626 68.3976 80.6725 68.8355 81.3268C69.2733 81.9811 69.507 82.7503 69.507 83.5372C69.5058 84.592 69.0856 85.6033 68.3386 86.3491C67.5917 87.095 66.5789 87.5146 65.5225 87.5158ZM65.5225 79.8901C64.8001 79.8901 64.094 80.104 63.4934 80.5048C62.8927 80.9055 62.4246 81.4751 62.1481 82.1415C61.8717 82.8079 61.7994 83.5412 61.9403 84.2487C62.0812 84.9561 62.4291 85.606 62.9399 86.116C63.4507 86.6261 64.1015 86.9734 64.81 87.1141C65.5185 87.2549 66.2529 87.1826 66.9202 86.9066C67.5876 86.6306 68.1581 86.1631 68.5594 85.5634C68.9607 84.9636 69.1749 84.2585 69.1749 83.5372C69.1738 82.5703 68.7887 81.6433 68.104 80.9595C67.4192 80.2758 66.4909 79.8912 65.5225 79.8901Z"
          fill="#CBCBCB"
        />
        <path
          d="M66.3407 84.8655H62.6194C62.6147 84.8656 62.6101 84.8652 62.6055 84.8644L64.3656 81.8203C64.3763 81.8013 64.3919 81.7855 64.4108 81.7746C64.4296 81.7636 64.451 81.7578 64.4728 81.7578C64.4947 81.7578 64.5161 81.7636 64.5349 81.7746C64.5538 81.7855 64.5694 81.8013 64.5801 81.8203L65.7613 83.8632L65.8179 83.9609L66.3407 84.8655Z"
          fill="#E5E5E5"
        />
        <path
          d="M68.4131 84.8669H65.1943L65.818 83.9623L65.8629 83.8971L66.6757 82.7182C66.69 82.7002 66.7081 82.6854 66.7285 82.6747C66.749 82.6641 66.7715 82.6579 66.7946 82.6565C66.8176 82.6552 66.8407 82.6586 66.8623 82.6668C66.8839 82.6749 66.9035 82.6875 66.92 82.7037C66.9242 82.7083 66.9281 82.7131 66.9317 82.7182L68.4131 84.8669Z"
          fill="#E5E5E5"
        />
        <path
          d="M91.7354 87.3522C93.8443 87.3522 95.5538 85.6451 95.5538 83.5394C95.5538 81.4336 93.8443 79.7266 91.7354 79.7266C89.6266 79.7266 87.917 81.4336 87.917 83.5394C87.917 85.6451 89.6266 87.3522 91.7354 87.3522Z"
          fill="white"
        />
        <path
          d="M91.7354 87.5158C90.9474 87.5158 90.177 87.2824 89.5218 86.8453C88.8665 86.4081 88.3558 85.7867 88.0543 85.0597C87.7527 84.3327 87.6738 83.5328 87.8275 82.761C87.9813 81.9892 88.3608 81.2803 88.918 80.7239C89.4752 80.1675 90.1852 79.7886 90.9581 79.635C91.731 79.4815 92.5321 79.5603 93.2602 79.8615C93.9883 80.1626 94.6105 80.6725 95.0484 81.3268C95.4862 81.9811 95.7199 82.7503 95.7199 83.5372C95.7187 84.592 95.2985 85.6033 94.5515 86.3491C93.8045 87.095 92.7918 87.5146 91.7354 87.5158ZM91.7354 79.8901C91.013 79.8901 90.3069 80.104 89.7063 80.5048C89.1056 80.9055 88.6375 81.4751 88.361 82.1415C88.0846 82.8079 88.0123 83.5412 88.1532 84.2487C88.2941 84.9561 88.642 85.606 89.1528 86.116C89.6636 86.6261 90.3144 86.9734 91.0229 87.1141C91.7314 87.2549 92.4657 87.1826 93.1331 86.9066C93.8005 86.6306 94.3709 86.1631 94.7723 85.5634C95.1736 84.9636 95.3878 84.2585 95.3878 83.5372C95.3867 82.5703 95.0016 81.6433 94.3168 80.9595C93.6321 80.2758 92.7038 79.8912 91.7354 79.8901Z"
          fill="#CBCBCB"
        />
        <path
          d="M93.3133 83.1308L93.1891 83.2548V82.3572C93.1891 82.3091 93.17 82.2629 93.1359 82.2289C93.1018 82.1949 93.0556 82.1758 93.0075 82.1758H90.2828C90.2589 82.1757 90.2353 82.1803 90.2131 82.1894C90.191 82.1985 90.1709 82.2118 90.154 82.2287C90.1371 82.2455 90.1236 82.2656 90.1145 82.2876C90.1053 82.3096 90.1006 82.3333 90.1006 82.3572C90.1006 82.381 90.1053 82.4047 90.1145 82.4267C90.1236 82.4488 90.1371 82.4688 90.154 82.4856C90.1709 82.5025 90.191 82.5158 90.2131 82.5249C90.2353 82.534 90.2589 82.5386 90.2828 82.5385H92.8258V83.2548L92.7017 83.1308C92.6763 83.1054 92.644 83.0881 92.6087 83.0811C92.5735 83.0741 92.537 83.0777 92.5038 83.0914C92.4706 83.1052 92.4422 83.1284 92.4222 83.1582C92.4022 83.1881 92.3916 83.2231 92.3916 83.259V83.268C92.3916 83.3161 92.4107 83.3623 92.4448 83.3963L92.879 83.8299C92.8959 83.8467 92.9159 83.8601 92.938 83.8692C92.96 83.8783 92.9836 83.883 93.0075 83.883C93.0313 83.883 93.0549 83.8783 93.077 83.8692C93.099 83.8601 93.119 83.8467 93.1359 83.8299L93.5701 83.3963C93.6042 83.3623 93.6233 83.3161 93.6233 83.268V83.259C93.6233 83.2231 93.6127 83.1881 93.5927 83.1582C93.5728 83.1284 93.5444 83.1052 93.5112 83.0914C93.478 83.0777 93.4415 83.0741 93.4063 83.0811C93.371 83.0881 93.3387 83.1054 93.3133 83.1308Z"
          fill="#E5E5E5"
        />
        <path
          d="M93.1891 84.5359H90.6462V83.8196L90.7703 83.9436C90.7957 83.969 90.8281 83.9862 90.8633 83.9932C90.8985 84.0003 90.9351 83.9967 90.9683 83.9829C91.0015 83.9692 91.0298 83.946 91.0498 83.9161C91.0697 83.8863 91.0804 83.8512 91.0804 83.8154V83.8064C91.0804 83.7583 91.0612 83.7121 91.0272 83.6781L90.5929 83.2445C90.5761 83.2277 90.556 83.2143 90.534 83.2052C90.512 83.1961 90.4884 83.1914 90.4645 83.1914C90.4407 83.1914 90.417 83.1961 90.395 83.2052C90.373 83.2143 90.3529 83.2277 90.3361 83.2445L89.9018 83.6781C89.8678 83.7121 89.8486 83.7583 89.8486 83.8064V83.8154C89.8486 83.8513 89.8593 83.8863 89.8793 83.9162C89.8992 83.946 89.9276 83.9692 89.9608 83.983C89.994 83.9967 90.0305 84.0003 90.0658 83.9933C90.101 83.9863 90.1334 83.969 90.1588 83.9436L90.2829 83.8196V84.7172C90.2829 84.7653 90.302 84.8115 90.3361 84.8455C90.3701 84.8795 90.4163 84.8986 90.4645 84.8986H93.1891C93.213 84.8987 93.2367 84.8941 93.2588 84.885C93.2809 84.8759 93.301 84.8626 93.318 84.8457C93.3349 84.8289 93.3483 84.8088 93.3575 84.7868C93.3667 84.7647 93.3714 84.7411 93.3714 84.7172C93.3714 84.6934 93.3667 84.6697 93.3575 84.6477C93.3483 84.6256 93.3349 84.6056 93.318 84.5888C93.301 84.5719 93.2809 84.5586 93.2588 84.5495C93.2367 84.5404 93.213 84.5358 93.1891 84.5359Z"
          fill="#E5E5E5"
        />
        <path d="M68.8701 10.2969H59.5V10.7157H68.8701V10.2969Z" fill="white" />
        <path d="M83.1308 10.2969H73.7607V10.7157H83.1308V10.2969Z" fill="white" />
        <path d="M97.3906 10.2969H88.0205V10.7157H97.3906V10.2969Z" fill="white" />
        <path d="M68.8701 74.3711H59.5V74.7899H68.8701V74.3711Z" fill="white" />
        <path d="M83.1308 74.3711H73.7607V74.7899H83.1308V74.3711Z" fill="white" />
        <path d="M97.3906 74.3711H88.0205V74.7899H97.3906V74.3711Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_8881_89374">
          <rect width="106" height="125" fill="white" transform="translate(0 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyScheduledPostsIllustration;
