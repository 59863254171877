import { PublicUserDto, SubscriptionDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import useChat from "hooks/useChat.hook";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import { generateTimeTagForFans } from "../../helper/dateAndTimeHelper";
import useCurrency from "../../hooks/useCurrency.hook";
import Button from "../basics/Button";
import ProfileImage, { ProfileImageWithLink } from "../basics/ProfileImage";
import SubscribeToggleButton from "../profile/SubscribeToggleButton";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  subscription?: SubscriptionDto;
  unsubscribeFromUser: (value: PublicUserDto) => void;
  isLoading?: boolean;
};

const SubscriptionItem = ({ subscription, unsubscribeFromUser, isLoading = false }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();
  const { openChat } = useChat();

  const isExpired = useMemo(
    () => subscription?.status === "CANCELED" && Date.now() >= Date.parse(subscription?.billingPeriod),
    [subscription?.status],
  );

  const displayStatusText = useMemo(() => {
    if (!subscription) return;
    if (subscription.status === "ACTIVE") {
      return `${t("subscription.nextPayment")} ${generateTimeTagForFans(new Date(subscription.billingPeriod))}`;
    } else if (subscription.status === "CANCELED") {
      return isExpired
        ? `${t("subscription.expiredAt")} ${generateTimeTagForFans(new Date(subscription.billingPeriod))}`
        : `${t("subscription.expiringAt")} ${generateTimeTagForFans(new Date(subscription.billingPeriod))}`;
    }
    return;
  }, [subscription?.status, isExpired]);

  if (isLoading || !subscription) {
    return (
      <div className={"animate-pulse rounded-md p-3 shadow"}>
        <div className={"mb-3 flex"}>
          <div className={"pr-4"}>
            <ProfileImage className={"h-16 w-16"} isLoading />
          </div>
          <div className={"flex flex-col justify-center gap-y-2 py-1.5"}>
            <div className={"h-4 w-24 rounded-full bg-gray-200"} />
            <div className={"h-3 w-14 rounded-full bg-gray-200"} />
            <div className={"h-2 w-32 rounded-full bg-gray-200"} />
          </div>
        </div>
        <div className={"flex space-x-2"}>
          <div className={"h-10 flex-1 rounded-md bg-gray-200"} />

          <div>
            <div className={"h-10 w-10 rounded-md bg-gray-200"} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"rounded-md p-2 shadow"}>
      <div className={"mb-2 flex gap-4"}>
        <ProfileImageWithLink
          to={`/creator/${subscription?.creator?.username}`}
          url={subscription?.creator.profilePictureThumbnail?.url}
          className={"h-16 w-16"}
        />
        <div
          className={classNames("flex flex-col gap-1", {
            "justify-around": subscription.status === "ACTIVE",
            "justify-center": subscription.status !== "ACTIVE",
          })}
        >
          <StatefulLink
            to={`/creator/${subscription?.creator?.username}`}
            className={"notranslate flex items-center gap-x-1 font-semibold"}
          >
            {subscription.creator.username}
            {subscription.creator.isVerified && <VerifiedUserIcon />}
          </StatefulLink>
          {subscription.status === "ACTIVE" && (
            <span className={"text-sm"}>
              {displayCurrency(subscription.price.net)} / {t("month")}
            </span>
          )}
          <span className={"text-xs text-gray-500"}>{displayStatusText}</span>
        </div>
      </div>
      <div className={"flex space-x-2"}>
        <SubscribeToggleButton
          user={{
            ...subscription.creator,
            isSubscribed: subscription.status === "ACTIVE",
            subscriptionPrice: subscription.price,
          }}
          unsubscribeFromUser={unsubscribeFromUser}
        />
        <span>
          <Button
            onClick={async () => subscription?.creator && (await openChat(subscription?.creator._id))}
            IconBack={HiOutlineChatBubbleOvalLeft}
          />
        </span>
      </div>
    </div>
  );
};

export default SubscriptionItem;
