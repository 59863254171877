import { ScheduledBroadcastDto, ScheduledBroadcastDtoKeysetResponse } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";

const useScheduledBroadcastMessages = (loadData = true) => {
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const removeBroadcastMessage = async (messageId: string) => {
    // delete scheduled broadcast message
    // TODO could be renamed to cancel instead of delete
    await maloumClient.scheduledBroadcasts.deleteScheduledBroadcast(messageId);
    // remove from query data
    queryClient.setQueryData("scheduledBroadcastMessages", (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((message: ScheduledBroadcastDto) => message._id !== messageId),
          };
        }),
      };
    });
  };

  const fetchScheduledBroadcastMessages = async (next: any): Promise<ScheduledBroadcastDtoKeysetResponse> =>
    await maloumClient.scheduledBroadcasts.getScheduledBroadcasts(next.pageParam, 30);

  const {
    data: scheduledBroadcastMessages,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery("scheduledBroadcastMessages", fetchScheduledBroadcastMessages, {
    getPreviousPageParam: (firstPage) => firstPage.next || null,
    getNextPageParam: (lastPage) => lastPage.next || null,
    enabled: loadData,
  });

  return {
    scheduledBroadcastMessages,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeBroadcastMessage,
  };
};
export default useScheduledBroadcastMessages;
