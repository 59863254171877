import useGuestStore from "state/guestState";
import { MouseEvent, ReactNode, useCallback, useEffect, useRef } from "react";

type Props = {
  children: ReactNode;
  isGuest: boolean;
};
const GuestClickHandler = ({ children, isGuest }: Props) => {
  const showAuthPopup = useGuestStore((state) => state.showAuthPopup);
  const guestRoleRef = useRef(null);

  const showAuthPopupFn = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      showAuthPopup();
      e.stopPropagation();
      e.preventDefault();
    },
    [showAuthPopup],
  );

  useEffect(() => {
    if (isGuest) {
      const links = guestRoleRef.current?.querySelectorAll("a");
      links.forEach((link: Node) => {
        link.addEventListener("click", showAuthPopupFn);
      });
      return () => {
        links.forEach((link: Node) => {
          link.removeEventListener("click", showAuthPopupFn);
        });
      };
    }
  }, [showAuthPopupFn, isGuest, guestRoleRef.current]);

  if (!isGuest) {
    return <>{children}</>;
  }

  return (
    <div onClickCapture={showAuthPopupFn} className={"guest-role"} ref={guestRoleRef}>
      {children}
    </div>
  );
};

export default GuestClickHandler;
