import { useFirstline } from "@first-line/firstline-react";
import { PrivateUserDto, SetRoleDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import Button from "components/basics/Button";
import HeaderBar from "components/layouts/HeaderBar";
import PopUp from "components/popup/PopUp";
import UserContext from "contexts/UserContext";
import useChat from "hooks/useChat.hook";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import { useCallback, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  HiArrowRightOnRectangle,
  HiOutlineBell,
  HiOutlineChatBubbleLeftRight,
  HiOutlineCog6Tooth,
  HiOutlineShoppingBag,
  HiOutlineWallet,
} from "react-icons/hi2";
import { ReactComponent as BecomeACreatorSVG } from "svg/undraw_taking_selfie.svg";
import LegalSection from "../../components/LegalSection";
import SecondaryButton from "../../components/atoms/buttons/SecondaryButton";
import MenuItem from "../../components/basics/MenuItem";
import ProfileImage from "../../components/basics/ProfileImage";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";
import useUserStore from "../../state/userState";

const FanProfile = () => {
  const { t } = useTranslation();
  const { setRole } = useContext(UserContext);
  const { logout } = useFirstline();
  const navigate = useStatefulNavigate();
  const { openChatWithMaloumOfficial } = useChat();
  const [isBecomeCreatorPopUpOpen, setIsBecomeCreatorPopUpOpen] = useState(false);
  const [isBecomeCreatorConfirmationPopUpOpen, setIsBecomeCreatorConfirmationPopUpOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useUserStore<PrivateUserDto>((state) => state.user);

  const handleLogout = useCallback(() => logout(), []);
  const handleBecomeACreator = useCallback(async () => {
    setIsLoading(true);
    await setRole(SetRoleDto.role.CREATOR);
    setIsLoading(false);
  }, [user]);

  return (
    <Layout>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left />
          <HeaderBar.Center>
            <HeaderBar.Title>{t("myprofile")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Container>
        <div data-testid={"fan-profile-page"} className={classNames("mb-4 flex w-full items-center")}>
          <ProfileImage className={classNames("mb-3 mr-6 h-20 w-20")} />

          <h1 className="notranslate mb-3 flex flex-shrink items-center truncate font-serif text-2xl">
            {user.username}
          </h1>
        </div>

        <div className="mb-4">
          <MenuItem
            handleOnClick={() => navigate("/profile/data")}
            Icon={HiOutlineCog6Tooth}
            text={t("profileMenu.personalData")}
            data-testid={"edit-personal-data"}
            hasLeftSpacing
          />

          <MenuItem
            handleOnClick={() => navigate("/subscriptions")}
            Icon={HiOutlineWallet}
            text={t("subscription.name_plural")}
            data-testid={"my-subscriptions"}
            hasLeftSpacing
          />

          {(user.introduction.madeProductSale || user.introduction.madeProductPurchase) && (
            <MenuItem
              handleOnClick={() => navigate("/orders")}
              Icon={HiOutlineShoppingBag}
              text={t("order.name_plural")}
              data-testid={"my-orders"}
              hasLeftSpacing
            />
          )}

          <MenuItem
            handleOnClick={() => navigate("/notifications/settings")}
            Icon={HiOutlineBell}
            text={t("notifications.title")}
            data-testid={"notifications"}
            hasLeftSpacing
          />

          <MenuItem
            handleOnClick={() => openChatWithMaloumOfficial()}
            Icon={HiOutlineChatBubbleLeftRight}
            text={t("navigation.support")}
            data-testid={"support-button"}
            hasLeftSpacing
          />

          <Button
            text={t("becomeCreator.title")}
            onClick={() => setIsBecomeCreatorPopUpOpen(true)}
            variant={"secondary"}
            className={"my-4"}
          ></Button>
        </div>
      </Container>
      <Container hasGrow={false}>
        <SecondaryButton className="flex items-center justify-center gap-2" onClick={handleLogout} type="button">
          <HiArrowRightOnRectangle className="h-6 w-6" />
          <span>{t("logOut")}</span>
        </SecondaryButton>
      </Container>
      <div className={"mb-4 mt-4"}>
        <LegalSection />
      </div>

      <PopUp
        title={t("becomeCreator.title") || ""}
        isOpen={isBecomeCreatorPopUpOpen}
        onClose={() => setIsBecomeCreatorPopUpOpen(false)}
      >
        <div className={"flex flex-col items-center justify-center gap-4"}>
          <BecomeACreatorSVG width="40%" className="my-4" />

          <span className={"text-sm text-gray-500"}>{t("becomeCreator.growFanbase")}</span>
          <span className={"text-2xl font-bold text-black"}>{t("becomeCreator.noReturn")}</span>
          <div className="my-4 text-center text-sm text-gray-500">
            <div>{t("topCreators.questions.any")}</div>
            <Trans
              i18nKey="topCreators.questions.write"
              components={{
                1: <button className="underline" onClick={() => openChatWithMaloumOfficial()} />,
                2: <a href="mailto:support@maloum.com" className="underline" />,
              }}
            />
          </div>
          <Button
            onClick={() => setIsBecomeCreatorConfirmationPopUpOpen(true)}
            text={t("becomeCreator.confirm")}
            disabled={isLoading}
          />
          <Button
            variant="text-only"
            onClick={() => setIsBecomeCreatorPopUpOpen(false)}
            text={t("becomeCreator.stayFan")}
          />
        </div>

        <PopUp
          title={t("becomeCreator.title") || ""}
          isOpen={isBecomeCreatorConfirmationPopUpOpen}
          onClose={() => setIsBecomeCreatorConfirmationPopUpOpen(false)}
        >
          <div className={"flex flex-col items-center justify-center gap-4"}>
            <span className={"text-2xl font-bold text-black"}>{t("becomeCreator.noReturn")}</span>
            <Button onClick={handleBecomeACreator} text={t("becomeCreator.confirm")} disabled={isLoading} />
            <Button
              variant="text-only"
              onClick={() => {
                setIsBecomeCreatorConfirmationPopUpOpen(false);
                setIsBecomeCreatorPopUpOpen(false);
              }}
              text={t("becomeCreator.stayFan")}
            />
          </div>
        </PopUp>
      </PopUp>
    </Layout>
  );
};

export default FanProfile;
