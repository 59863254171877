import { ProductDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import ProductPreview from "components/product/ProductPreview";
import useCreatorProductFeed from "hooks/useCreatorProductFeed.hook";
import { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { HiOutlinePlusCircle, HiOutlineShoppingBag } from "react-icons/hi2";

import { useInView } from "react-intersection-observer";
import GuestClickHandler from "components/guest/GuestClickHandler";
import StatefulLink from "components/atoms/utils/StatefulLink";
import {useFirstline} from "@first-line/firstline-react";

type Props = { creator: PublicUserDto; viewedByGuest?: boolean };

const CreatorProductFeed = ({ creator, viewedByGuest = false }: Props) => {
  const { t } = useTranslation();
  // ref for loading trigger div
  const { ref, inView } = useInView();

  const { user: authUser } = useFirstline();
  const isOwnProfile = creator.username === authUser?.username;

  // useCreatorProductFeed
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, updateProduct } = useCreatorProductFeed({
    creator,
    viewedByGuest,
  });

  // create ProductItem
  const createProductComponent = useCallback(
    (item: ProductDto) => (
      <GuestClickHandler isGuest={viewedByGuest} key={item._id}>
        <ProductPreview product={item} setProduct={updateProduct} hasUser={false} />
      </GuestClickHandler>
    ),
    [updateProduct],
  );

  // loading item view
  const createLoadingProductComponent = useCallback(
    (key: number) => (
      <GuestClickHandler isGuest={viewedByGuest} key={key}>
        <ProductPreview isLoading hasUser={false} />
      </GuestClickHandler>
    ),
    [],
  );

  // empty creator product feed view
  const NoProductResultsView = useCallback(
    () =>
      isOwnProfile ? (
        <StatefulLink to="/product/create" className={"mt-8 flex flex-col items-center"}>
          <div className={"text-center"}>{t("firststore")}</div>
          <HiOutlinePlusCircle className={"mt-4 h-16 w-16 stroke-1"} />
        </StatefulLink>
      ) : (
        <div className={"flex flex-col items-center p-5"}>
          <span className={"mb-3"}>{t("noProducts")}</span>
          <HiOutlineShoppingBag className={"h-7 w-7"} />
        </div>
      ),
    [isOwnProfile],
  );

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className="relative pb-16">
      {data ? (
        <>
          <div className={"grid grid-cols-2 gap-4"}>
            {data.pages.map((page) => page.data.map((e: ProductDto) => createProductComponent(e)))}
          </div>
          {data.pages[0].data.length === 0 && <NoProductResultsView />}
          <div className="absolute bottom-[40rem]" ref={ref} />
        </>
      ) : (
        <div className={"grid grid-cols-2 gap-4"}>
          {Array(4)
            .fill(null)
            .map((_, key) => createLoadingProductComponent(key))}
        </div>
      )}
    </div>
  );
};

export default CreatorProductFeed;
