import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useState } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi2";
import BlockedInfoPopup from "./BlockedInfoPopup";
import UnblockUserPopup from "./UnblockUserPopup";
import { useTranslation } from "react-i18next";

type Props = {
  chat: ChatDto;
  className?: string;
  "data-testid"?: string;
};

const BlockedCallout = ({ chat, className, "data-testid": dataTestId }: Props) => {
  if (chat.chatPartner.isBlocked)
    return <BlockedByMeCallout chat={chat} className={className} data-testid={dataTestId} />;
  if (chat.chatPartner.hasBlocked) return <BlockedBySomeoneCallout className={className} data-testid={dataTestId} />;
  return <></>;
};

const BlockedByMeCallout = ({ chat, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <>
      <div
        className={classNames(
          "mb-6 w-full rounded-md bg-beige-400 px-3 py-2.5 text-center md:mx-4 md:w-[calc(100%-2rem)]",
          className,
        )}
        data-testid={dataTestId}
      >
        <span className="text-sm">{t("chatSettings.block.youBlocked")}</span>
        <button onClick={() => setOpenPopup(true)} className="ml-1 text-sm text-red-900">
          {t("chatSettings.block.tapToUnblock")}
        </button>
      </div>
      <UnblockUserPopup
        chatId={chat._id}
        userId={chat.chatPartner._id}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
    </>
  );
};

const BlockedBySomeoneCallout = ({ className, "data-testid": dataTestId }: Omit<Props, "chat">) => {
  const { t } = useTranslation();
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  return (
    <>
      <div
        className={classNames(
          "absolute -top-[3.5rem] left-0 flex w-full items-center justify-center gap-2 rounded-md bg-beige-400 px-3 py-2 md:mx-4 md:w-[calc(100%-2rem)]",
          className,
        )}
        data-testid={dataTestId}
      >
        <span className="text-sm">{t("chatSettings.block.youGotBlocked")}</span>
        <button onClick={() => setOpenInfoPopup(true)}>
          <HiOutlineInformationCircle className="h-6 w-6" />
        </button>
      </div>
      <BlockedInfoPopup open={openInfoPopup} setOpen={setOpenInfoPopup} />
    </>
  );
};

export default BlockedCallout;
