import BroadcastCard from "components/organisms/broadcast/BroadcastCard";
import { ChatListDto, ScheduledBroadcastDto } from "@neolime-gmbh/api-gateway-client";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import PopUp from "components/popup/PopUp";
import Button from "components/basics/Button";
import useScheduledBroadcastMessages from "hooks/useScheduledBroadcastMessages.hook";
import { HiOutlineUsers } from "react-icons/hi2";
import PillButton from "components/atoms/buttons/PillButton";
import useSelectedListsOverviewNavigation from "hooks/navigation/useSelectedListsOverviewNavigation";

type Props = {
  broadcast: ScheduledBroadcastDto;
  className?: string;
};

const ScheduledBroadcastCard = ({ broadcast, className }: Props) => {
  const { removeBroadcastMessage } = useScheduledBroadcastMessages(false);
  const [showPopup, setShowPopup] = useState(false);

  const labels = (
    <ReceiverLabel
      recipientCount={broadcast.recipientCount}
      includeLists={broadcast.includeFromLists}
      excludeLists={broadcast.excludeFromLists}
    />
  );

  return (
    <>
      <BroadcastCard
        date={new Date(broadcast.scheduledAt)}
        text={broadcast.content.text}
        content={broadcast.content}
        onOpenPopup={() => setShowPopup(true)}
        labels={labels}
        className={className}
      />
      <DeleteMessagePopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        onDelete={() => removeBroadcastMessage(broadcast._id)}
      />
    </>
  );
};

export default ScheduledBroadcastCard;

type DeleteMessagePopupProps = {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteMessagePopup = ({ show, onClose, onDelete }: DeleteMessagePopupProps) => {
  const { t } = useTranslation();
  const [showAreYouSurePopUp, setShowAreYouSurePopUp] = useState(false);

  const handleDeleteClick = () => {
    setShowAreYouSurePopUp(false);
    onClose();
    onDelete();
  };

  if (showAreYouSurePopUp)
    return (
      <PopUp isOpen onClose={() => setShowAreYouSurePopUp(false)} title={t("delete") ?? ""}>
        <div className="py-4 text-center">{t("queue.sureDeleteMessage")}</div>
        <div className="flex gap-2">
          <Button variant="secondary" text={t("cancel")} onClick={() => setShowAreYouSurePopUp(false)} />
          <Button variant="primary" text={t("delete")} onClick={handleDeleteClick} />
        </div>
      </PopUp>
    );

  return (
    <PopUp
      isOpen={show}
      onClose={onClose}
      title={t("queue.modifyMessage") ?? ""}
      options={[{ title: t("delete"), onClick: () => setShowAreYouSurePopUp(true) }]}
    />
  );
};

type ReceiverLabelProps = {
  recipientCount: number;
  includeLists: ChatListDto[];
  excludeLists: ChatListDto[];
};

const ReceiverLabel = ({ recipientCount, includeLists, excludeLists }: ReceiverLabelProps) => {
  const { t } = useTranslation();
  const navigateToSelectedListsOverview = useSelectedListsOverviewNavigation();

  const label = t("queue.memberCount", { count: recipientCount });

  const handleGoToSelection = () => {
    navigateToSelectedListsOverview({ includeLists, excludeLists });
  };

  return (
    <div>
      <PillButton onClick={handleGoToSelection}>
        <HiOutlineUsers className="h-4 w-4" /> {label}
      </PillButton>
    </div>
  );
};
