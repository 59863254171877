import classNames from "classnames";
import { useTranslation } from "react-i18next";
import useCurrency from "../../hooks/useCurrency.hook";
import Card from "../atoms/Card";
import ProductThumbnail from "../atoms/ProductThumbnail";

type Props = {
  className?: string;
  productName?: string;
  productImageUrl?: string;
  price?: {
    net: number;
    vatAmount: number;
    gross: number;
  };
  note?: string;
  isLoading?: boolean;
  isSellingUser?: boolean;
};
const OrderProductCard = ({
  className,
  productName,
  productImageUrl,
  price,
  note,
  isLoading,
  isSellingUser,
}: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  if (isLoading || !productImageUrl || !productName || !price) {
    return (
      <div className={classNames("flex items-center", className)}>
        <ProductThumbnail isLoading className="h-[5rem] w-[5rem]" imageUrl={""} altText={""} />
        <div className={"ml-3 flex flex-1 flex-col"}>
          <div className={"h-4 w-1/4 animate-pulse rounded-full bg-gray-200"}></div>
          <div className="mt-2 grid grid-flow-row grid-cols-2">
            <div className="h-2 w-1/4 animate-pulse rounded-full bg-gray-100"></div>
            <div className="h-2 w-1/4 animate-pulse rounded-full bg-gray-100"></div>

            <div className="mt-2 h-2 w-1/4 animate-pulse rounded-full bg-gray-100"></div>
            <div className="mt-2 h-2 w-1/4 animate-pulse rounded-full bg-gray-100"></div>

            <div className="mt-2 h-4 w-1/4 animate-pulse rounded-full bg-gray-200"></div>
            <div className="mt-2 flex h-4 w-1/4 animate-pulse rounded-full bg-gray-200 text-end"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Card className={className}>
      <article>
        <div className="flex items-center gap-3">
          <ProductThumbnail imageUrl={productImageUrl} altText={productName} className="h-[5rem] w-[5rem]" />
          <div className={"flex h-[5rem] grow flex-col"}>
            <h4 className={"mb-1 line-clamp-1 break-all text-base font-medium"}>{productName}</h4>
            <div className="grow" />
            <dl className="grid grid-flow-row grid-cols-2">
              {!isSellingUser && (
                <>
                  <dt className="text-xs text-gray-500">{t("checkout.subtotal")}</dt>
                  <dd className="text-end text-xs text-gray-500">{displayCurrency(price.net)}</dd>

                  <dt className="text-xs text-gray-500">{t("checkout.VAT")}</dt>
                  <dl className="text-end text-xs text-gray-500">{displayCurrency(price.vatAmount)}</dl>
                </>
              )}

              <dt>{t("checkout.total")}</dt>
              <dl className="text-end">{displayCurrency(isSellingUser ? price.net : price.gross)}</dl>
            </dl>
          </div>
        </div>
        {note && (
          <>
            <hr className="mt-2" />
            <p className="mt-2">{note}</p>
          </>
        )}
      </article>
    </Card>
  );
};

export default OrderProductCard;
