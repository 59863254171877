import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  title: string;
  value: any;
  rightSlot?: ReactNode;
  className?: string;
};

const RadioOption = ({ title, value, rightSlot, className }: Props) => {
  return (
    <RadioGroup.Option
      value={value}
      className={classNames("relative flex cursor-pointer flex-col py-2 focus:outline-none", className)}
    >
      {({ checked }) => (
        <span className="flex items-center">
          <span
            className={classNames(
              "m-2 flex h-3 w-3 items-center justify-center rounded-full ring-1 ring-offset-2 shrink-0",
              {
                "bg-red-900 ring-red-900": checked,
                "bg-white ring-gray-400": !checked,
              },
            )}
          />
          <RadioGroup.Label as="span" className={"ml-4 flex grow justify-between"}>
            <span>{title}</span>
            {rightSlot && <span>{rightSlot}</span>}
          </RadioGroup.Label>
        </span>
      )}
    </RadioGroup.Option>
  );
};

export default RadioOption;
