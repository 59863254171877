import Button from "components/basics/Button";
import { useTranslation } from "react-i18next";
import SuggestedcreatorSection from "./molecules/SuggestedCreatorsSection";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const EmptyFeed = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  return (
    <div>
      <div className="px-4 md:px-0">
        <h1 className={"mb-2 mt-6 text-base font-semibold text-gray-800 md:mt-10"}>{t("feed.yourFeed")}</h1>
        <p className="text-gray-500">{t("feed.description")}</p>
      </div>

      <SuggestedcreatorSection className="mt-5" />
      <div className="w-full px-4 md:px-0">
        <Button
          text={t("feed.startDiscovery")}
          onClick={() => navigate("/search")}
          className="mx-auto md:max-w-sm"
          data-testid="start-discovery"
        />
      </div>
    </div>
  );
};

export default EmptyFeed;
