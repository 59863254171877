import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import Layout from "components/layouts/Layout";
import { supportEmail } from "const";
import { useTranslation } from "react-i18next";
import { ReactComponent as FixingBugsSVG } from "svg/undraw_fixing_bugs.svg";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  title?: string;
  text?: string;
};
const ErrorPage = ({ title, text }: Props) => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  return (
    <Layout hideNavigationMobile={true}>
      <Container className={"mb-4 h-full overflow-y-auto text-center"}>
        <h1 className={"my-8 px-12 font-serif text-red-900"}>{t("oops")}</h1>
        <p className={"my-8 px-12 text-xl font-bold"}>{title ? title : t("somethingWentWrong")}</p>
        <p className={"px-12 text-sm"}>
          {text ? (
            text
          ) : (
            <>
              {t("tryAgain")} <br />
              <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </>
          )}
        </p>

        <div className={"mx-auto flex-1"} data-testid="error-page">
          <FixingBugsSVG className={"my-16 max-h-[300px]"} />
        </div>

        <Button onClick={() => navigate("/", { replace: true })} text={t("backToHome")} className={"my-4"} />
      </Container>
    </Layout>
  );
};

export default ErrorPage;
