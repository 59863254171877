import Card from "components/atoms/Card";
import { useTranslation } from "react-i18next";
import { HiOutlinePencil } from "react-icons/hi2";

type Props = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  iban: string;
};

const PayoutMethodCard = ({ firstName, lastName, companyName, iban }: Props) => {
  const { t } = useTranslation();

  const displayedName = [firstName, lastName].filter((item) => !!item).join(" ");
  const nameAndCompany = [displayedName, companyName].filter((item) => !!item).join(" - ");

  return (
    <Card as="dl" className="relative grid">
      <dt className="sr-only">{t("payment.cardholder")}</dt>
      <dd className="text-left">{nameAndCompany}</dd>
      <dt className="sr-only">{t("payment.iban")}</dt>
      <dd className="text-left text-sm text-gray-500">
        {t("payment.iban")}: {iban}
      </dd>
      <HiOutlinePencil className={"absolute right-3 top-2 h-5 w-5"} />
    </Card>
  );
};

export default PayoutMethodCard;
