import { VaultMediaDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useMemo } from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import useUploadStore from "state/uploadState";
import useVaultFolders from "./useVaultFolders.hook";

const useVaultMedia = (folderId: string, fanId?: string, enabled = true) => {
  const queryClient = useQueryClient();
  const { maloumClient } = useContext(MaloumClientContext);
  const { folders } = useVaultFolders("", false);
  const { uploads, removeUpload } = useUploadStore();

  const deleteMedia = async ({ mediaIds, folderId }: { mediaIds: string[]; folderId?: string }) => {
    await maloumClient.uploads.deleteUploads({ uploadIds: mediaIds, fromFolderId: folderId });
    return { mediaIds, folderId };
  };

  const deleteMediaMutation = useMutation({
    mutationFn: deleteMedia,
    onSuccess: ({ mediaIds, folderId }) => {
      // get and set new state after deletion for each folder loaded in the frontend
      folders.forEach((f) => {
        // only update the folder that was deleted from or all folders if no folderId is given
        if (folderId && f._id !== folderId) return;
        const data = queryClient.getQueryData(["vaultMedia", { folderId: f._id, fanId }]);
        if (!data) return;
        queryClient.setQueryData(["vaultMedia", { folderId: f._id, fanId }], (prev: any) => ({
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              next: page.next,
              data: page.data.filter(
                (media: VaultMediaDto) => mediaIds.findIndex((m1) => m1 === media.upload._id) === -1,
              ),
            };
          }),
        }));
      });
      // remove uploads from store
      mediaIds.forEach((id) => removeUpload(id));
    },
  });

  const getVaultMedia = async (next: any) =>
    await maloumClient.vaultFolders.loadMediaForFolder(folderId, fanId, next.pageParam, 50);

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["vaultMedia", { folderId, fanId }],
    getVaultMedia,
    {
      getPreviousPageParam: (firstPage) => firstPage.next || null,
      getNextPageParam: (lastPage) => lastPage.next || null,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: enabled,
    },
  );

  const media = useMemo(() => {
    const media = data?.pages
      .flatMap((p) => p.data)
      .filter((m: { upload: { _id: string } }) => uploads.findIndex((u) => u.id === m.upload._id) === -1);
    return media ?? [];
  }, [data]);

  return { media, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage, deleteMediaMutation };
};

export default useVaultMedia;
