import { nFormatter } from "../../helper/numberHelper";

type Props = {
  number: number;
  title: string;
};

const NumberBadge = ({ number, title }: Props) => {
  return (
    <div>
      <span>{nFormatter(number, 1)}</span>
      <p className="text-xs text-gray-500">{title}</p>
    </div>
  );
};

export default NumberBadge;
