import { PictureCandidateDto } from "@neolime-gmbh/api-gateway-client";
import MediaPopup from "components/media/MediaPopup";

type Props = {
  picture: PictureCandidateDto;
  isOpen: boolean;
  onClose: () => void;
};

const ImageFullScreenPopup = ({ picture, isOpen, onClose }: Props) => {
  return (
    <MediaPopup onClose={onClose} isOpen={isOpen}>
      <img src={picture?.url} className={"max-h-full cursor-pointer"} alt="" data-testid={"image-popup-image"} />
    </MediaPopup>
  );
};

export default ImageFullScreenPopup;
