import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineUserPlus } from "react-icons/hi2";
import UserCheckIcon from "../../assets/UserCheckIcon";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import Button from "../basics/Button";
import classNames from "classnames";

type Props = {
  user: PublicUserDto;
  setUser: (value: PublicUserDto) => void;
  hasSubscriptionPrice?: boolean;
};

const FollowToggleButton = ({ user, setUser, hasSubscriptionPrice }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const followCreator = async () => {
    const response = await maloumClient.users.follow(user._id);
    if (!(response instanceof Error)) {
      // Google Tag Manager: Follow Event
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        event: "follow",
        creator_id: user._id,
      });
      setUser({ ...user, isFollowed: true });
    }
  };

  const unFollowCreator = async () => {
    const response = await maloumClient.users.unfollow(user._id);
    if (!(response instanceof Error)) {
      setUser({ ...user, isFollowed: false });
    }
  };

  // TODO Simplify displaying of buttons
  return (
    <>
      <Button
        onClick={user.isFollowed ? unFollowCreator : followCreator}
        variant={user.isFollowed ? "secondary" : "primary"}
        text={user.isFollowed ? t("unfollow") : t("follow")}
        className={classNames({ "xxs:hidden": hasSubscriptionPrice })}
      />
      {hasSubscriptionPrice && (
        <Button
          onClick={user.isFollowed ? unFollowCreator : followCreator}
          variant={user.isFollowed ? "secondary" : "primary"}
          IconBack={user.isFollowed ? UserCheckIcon : HiOutlineUserPlus}
          className={"hidden xxs:block"}
        />
      )}
    </>
  );
};

export default FollowToggleButton;
