import LogoSymbol from "assets/logo/LogoSymbol";
import Button from "components/basics/Button";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiXMark } from "react-icons/hi2";
import useGuestStore from "state/guestState";
import {useFirstline} from "@first-line/firstline-react";

const AuthPopup = () => {
  const { t } = useTranslation();
  const { loginWithRedirect } = useFirstline();

  const authPopupIsShown = useGuestStore((state) => state.authPopupIsShown);
  const hideAuthPopup = useGuestStore((state) => state.hideAuthPopup);

  useEffect(() => {
    if (authPopupIsShown) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [authPopupIsShown]);

  if (!authPopupIsShown) return <></>;

  return (
    <div className="fixed left-0 top-0 z-[9998] flex h-screen w-screen items-center justify-center p-4">
      <div
        className="fixed left-0 top-0 z-[9998] h-full w-full bg-gray-300/70 backdrop-blur-sm"
        onClick={hideAuthPopup}
      />
      <div className="z-[9999] w-full max-w-[37.5rem] rounded-2xl bg-white px-7 pb-8 pt-5 shadow-[0_0_64px_0_rgba(0,0,0,0.2)]">
        <div className="flex items-center justify-end">
          <button onClick={hideAuthPopup} className="cursor-pointer p-1 outline-none">
            <HiXMark className="h-6 w-6 text-gray-900" />
          </button>
        </div>
        <div className="mb-6 flex flex-col items-center text-center">
          <LogoSymbol className="mb-4 w-8 fill-red-900" />
          <div className="mb-3 inline-flex items-center justify-center font-semibold">{t("signupTo")}</div>
          <span>{t("joinUs")}</span>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Button onClick={loginWithRedirect} text={t("login")} variant={"outline"} />
          <Button onClick={() => loginWithRedirect({action_hint: "signup"})} text={t("signup")} />
        </div>
      </div>
    </div>
  );
};

export default AuthPopup;
