import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import FanItem from "components/atoms/lists/FanItem";
import Button from "components/basics/Button";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useChat from "hooks/useChat.hook";
import useLikes from "hooks/useLikes.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router";
import useUserStore from "state/userState";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";

const ListItemLoading = ({ className, "data-testid": dataTestId }: { className?: string; "data-testid"?: string }) => {
  return (
    <div className={classNames("flex items-center gap-3", className)} data-testid={dataTestId}>
      <div className="h-10 w-10 animate-pulse rounded-full bg-gray-200" />
      <div className="h-4 w-20 animate-pulse rounded-md bg-gray-200" />
      <div className="grow" />
      <div className="h-10 w-20 animate-pulse rounded-md bg-gray-200" />
    </div>
  );
};

const Likes = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  // ref for loading trigger div
  const { ref, inView } = useInView();

  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { openChat } = useChat();

  // navigate to home if post id is missing in url
  if (!id) return <StatefulNavigate to="/" />;

  const { likes, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useLikes(id);

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("likes")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container className="pt-4">
        <span className="mb-4 text-xl font-semibold">{t("people-liked-post")}</span>
        <div className="relative pb-16">
          {likes.map((like) => (
            <FanItem
              userId={like.user._id}
              username={like.user.username}
              userProfilePictureUrl={like.user.profilePicture?.url}
              userProfilePictureUploadStatus={like.user.profilePicture?.uploadStatus}
              isUserVerified={like.user.isVerified}
              additionalInfo={<></>}
              actions={
                <>
                  {user._id !== like.user._id && (
                    <Button
                      variant="secondary"
                      text={t("message.title")}
                      onClick={async () => await openChat(like.user._id)}
                      data-testid={`message-with-${like.user.username}`}
                    />
                  )}
                </>
              }
              key={like.user._id}
              className="mb-4"
            />
          ))}
          {(isLoading || isFetchingNextPage) &&
            Array(15)
              .fill(null)
              .map((_, key) => <ListItemLoading key={key} className="mb-4" data-testid={`loading-item-${key}`} />)}

          <div className="absolute bottom-[40rem]" ref={ref} />
        </div>
      </Container>
    </Layout>
  );
};

export default Likes;
