import { PrivateUserDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import useUserStore from "state/userState";
import SubscribeButton from "../basics/buttons/SubscribeButton";
import UnsubscribeButton from "../basics/buttons/UnsubscribeButton";

type Props = {
  user: PublicUserDto;
  unsubscribeFromUser: (value: PublicUserDto) => void;
};

const SubscribeToggleButton = ({ user, unsubscribeFromUser }: Props) => {
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  return currentUser.subscriptions?.filter((s) => s.creator._id === user._id)[0]?.status === "ACTIVE" ? (
    <UnsubscribeButton user={user} unsubscribeFromUser={unsubscribeFromUser} />
  ) : (
    <SubscribeButton user={user} />
  );
};

export default SubscribeToggleButton;
