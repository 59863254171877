import { PrivatePriceDto, TransactionDto } from "@neolime-gmbh/api-gateway-client";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineBanknotes,
  HiOutlineCamera,
  HiOutlineCreditCard,
  HiOutlineShoppingBag,
  HiOutlineUser,
} from "react-icons/hi2";
import { formatDateWithStoredLanguageAndTime } from "../../../helper/dateAndTimeHelper";
import useCurrency from "../../../hooks/useCurrency.hook";
import classNames from "classnames";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  transaction?: TransactionDto;
  isLoading?: boolean;
  className?: string;
};

const TransactionItem = ({ transaction, isLoading = false, className }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  const transactionIcon = useMemo(() => {
    switch (transaction?.category) {
      case TransactionDto.category.SUBSCRIPTION:
        return <HiOutlineUser className={"h-6 w-6 text-gray-700"} />;
      case TransactionDto.category.PRODUCT:
        return <HiOutlineShoppingBag className={"h-6 w-6 text-gray-700"} />;
      case TransactionDto.category.TIP:
        return <HiOutlineBanknotes className={"h-6 w-6 text-gray-700"} />;
      case TransactionDto.category.CHAT_PRODUCT:
        return <HiOutlineCamera className={"h-6 w-6 text-gray-700"} />;
      default:
        return <HiOutlineCreditCard className={"h-6 w-6 text-gray-700"} />;
    }
  }, []);

  if (isLoading || !transaction) {
    return (
      <>
        <div className={classNames("mb-2 flex animate-pulse gap-x-3", className)}>
          <div className={"h-10 w-10 rounded-md bg-gray-200"} />
          <div className={"flex flex-1 flex-col justify-between"}>
            <div className={"flex justify-between"}>
              <div className={"h-4 w-20 rounded-full bg-gray-200"} />
              <div className={"h-4 w-14 rounded-full bg-gray-200"} />
            </div>

            <div className={"mb-1 flex gap-x-2 text-xs"}>
              <div className={"h-3 w-14 rounded-full bg-gray-200"} />
            </div>
          </div>
        </div>
        <div className={"mb-2 h-px bg-gray-200"} />
      </>
    );
  }

  return (
    <StatefulLink
      className={classNames("flex gap-x-3", className)}
      data-testid="transaction-item"
      to={`/chat/with/${transaction.from._id}`}
    >
      <div className={"flex h-10 w-10 items-center justify-center rounded-md bg-beige-400"}>{transactionIcon}</div>
      <div className={"flex flex-1 flex-col justify-between gap-y-1"}>
        <div className={"flex justify-between text-sm font-semibold text-gray-700"}>
          <h3 className="text-sm">
            {transaction.product ? transaction.product.name : t(`transactions.type.${transaction.category}`)}
          </h3>
          <span>{displayCurrency((transaction.price as PrivatePriceDto).payoutAmount)}</span>
        </div>

        <div className={"flex justify-between gap-1 text-xs text-gray-500"}>
          {transaction.category !== "PAYOUT" && (
            <>
              <span>
                <span>{formatDateWithStoredLanguageAndTime(new Date(transaction.createdAt))}</span>
                <span>
                  {" "}
                  {t("from")} <span className="notranslate">{`"${transaction.from?.username}"`}</span>
                </span>
              </span>

              <span>{displayCurrency(transaction.price.net)}</span>
            </>
          )}
        </div>
      </div>
    </StatefulLink>
  );
};

export default TransactionItem;
