import classNames from "classnames";
import Button from "components/basics/Button";
import { addDays, addHours, format, isAfter, isToday, startOfHour } from "date-fns";
import { de, enUS } from "date-fns/locale";
import { capitalizeFirstLetter } from "helper/stringFormatHelper";
import useScrollStopListener from "hooks/useScrollStopListener.hook";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiXMark } from "react-icons/hi2";

type DateHours = {
  date: string;
  hours: (number | string)[];
};

type Props = {
  onClose: () => void;
  "data-testid"?: string;
  className?: string;
  onSave: (date: string) => void;
  savedDate: string;
  initialDate: string | undefined;
};

const DatePickerPopup = ({ onClose, onSave, savedDate, initialDate, className, "data-testid": dataTestId }: Props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [selectedDate, setSelectedDate] = useState(0);
  const [selectedHour, setSelectedHour] = useState(0);
  // height of list element
  const elementHeight = 52; //px

  const dateContainerRef = useScrollStopListener(() => {
    if (dateContainerRef.current) {
      const scrollTop = dateContainerRef.current.scrollTop - elementHeight / 2;
      const rest = scrollTop % elementHeight;
      const index = parseInt(`${scrollTop / elementHeight + (rest > elementHeight / 2 ? 1 : 0)}`);

      if (index > -1) setSelectedDate(index);
    }
  }, 50);

  const hourContainerRef = useScrollStopListener(() => {
    if (hourContainerRef.current) {
      const scrollTop = hourContainerRef.current.scrollTop - elementHeight / 2;
      const rest = scrollTop % elementHeight;
      const index = parseInt(`${scrollTop / elementHeight + (rest > elementHeight / 2 ? 1 : 0)}`);

      if (index > -1) setSelectedHour(index);
    }
  }, 50);

  const dates = useMemo(() => {
    const dates: DateHours[] = [];
    const options = {
      locale: currentLanguage.includes("de") ? de : enUS,
    };
    let currentDate = addHours(new Date(), 1); // start from next hour
    const endDate = addDays(new Date(), 100); // We want dates for 100 days ahead

    while (isAfter(endDate, currentDate)) {
      const formattedDate = isToday(currentDate)
        ? `${capitalizeFirstLetter(t("today"))}, ${format(currentDate, "MMM dd", options)}`
        : format(currentDate, "EEE, MMM dd", options);
      let dateHours = dates.find((d) => d.date === formattedDate);

      if (!dateHours) {
        dateHours = { date: formattedDate, hours: [] };
        dates.push(dateHours);
      }

      if (isToday(currentDate) && dateHours.hours.length === 0) dateHours.hours.push(capitalizeFirstLetter(t("now")));
      dateHours.hours.push(startOfHour(currentDate).getHours());

      currentDate = addHours(currentDate, 1); // Proceed to next hour
    }

    return dates;
  }, []);

  const onSaveDate = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const tmpDate = `${dates[selectedDate].date} ${currentYear}`;

    // TODO: find better solution to support more languages
    let newDate = new Date(
      tmpDate.replace("Mär", "Mar").replace("Mai", "May").replace("Okt", "Oct").replace("Dez", "Dec"),
    );

    // Set the hour of the new Date object
    if (dates[selectedDate].hours[selectedHour] !== capitalizeFirstLetter(t("now"))) {
      newDate.setHours(dates[selectedDate].hours[selectedHour] as number);
    } else {
      newDate = now;
    }

    // handle next year
    if (newDate < now) newDate.setFullYear(now.getFullYear() + 1);

    // Get the individual components of the date
    let year = newDate.getFullYear();
    let month = (newDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    let day = newDate.getDate().toString().padStart(2, "0");
    let hours = newDate.getHours().toString().padStart(2, "0");
    let minutes = newDate.getMinutes().toString().padStart(2, "0");
    let seconds = newDate.getSeconds().toString().padStart(2, "0");

    // Assemble the date string in the desired format
    let dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    onSave(dateString);
    onClose();
  }, [selectedDate, selectedHour]);

  const selectDateFromString = useCallback(
    (dateString: string) => {
      const tmpDate = new Date(dateString);
      if (tmpDate < new Date()) {
        setSelectedDate(0);
        setSelectedHour(0);
      } else {
        const options = {
          locale: currentLanguage.includes("de") ? de : enUS,
        };
        const formattedDate = isToday(tmpDate)
          ? `${capitalizeFirstLetter(t("today"))}, ${format(tmpDate, "MMM dd", options)}`
          : format(tmpDate, "EEE, MMM dd", options);
        const dateIndex = dates.findIndex((date) => date.date === formattedDate);
        const hourIndex = dates[dateIndex]?.hours.findIndex((hour) => {
          if (tmpDate.getHours() === new Date().getHours()) return 0;
          else return hour === tmpDate.getHours();
        });
        setSelectedDate(dateIndex);
        setSelectedHour(hourIndex);
      }
    },
    [dates],
  );

  useEffect(() => {
    if (savedDate) {
      selectDateFromString(savedDate);
    }
  }, []);

  const handleResetDate = useCallback(() => {
    if (initialDate) {
      selectDateFromString(initialDate);
    } else {
      setSelectedDate(0);
      setSelectedHour(0);
    }
  }, []);

  useEffect(() => {
    if (dateContainerRef.current) dateContainerRef.current.scrollTop = elementHeight * selectedDate + elementHeight / 2;
    if (hourContainerRef.current) hourContainerRef.current.scrollTop = elementHeight * selectedHour + elementHeight / 2;
  }, [selectedDate, selectedHour]);

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <div
        className={"fixed left-0 top-0 z-[9998] h-[150vh] w-full cursor-pointer bg-slate-500/50 backdrop-blur-sm"}
        onClick={onClose}
      />
      <div className="fixed bottom-0 left-0 z-[9999] flex h-[25rem] w-full flex-col rounded-t-[2rem] bg-white p-4 md:bottom-1/2 md:left-1/2 md:max-w-xl md:-translate-x-1/2 md:translate-y-1/2 md:rounded-b-[2rem] md:p-6">
        <button className="flex w-full justify-end" onClick={onClose}>
          <HiXMark className="h-10 w-10 cursor-pointer p-2" />
        </button>
        <div className="grow" />
        <div className="relative flex h-fit gap-4">
          <div
            className="scrollbar-hide h-[15rem] grow snap-y snap-mandatory overflow-auto px-4"
            ref={dateContainerRef}
          >
            <div className="h-[7.5rem]" />
            {dates.map((e, i) => {
              return (
                <div
                  className={classNames("w-full snap-center py-3 text-center text-xl", {
                    "text-gray-900": selectedDate === i,
                    "text-gray-900/50": selectedDate !== i,
                  })}
                  key={e.date}
                >
                  {e.date}
                </div>
              );
            })}
            <div className="h-[7.5rem]" />
          </div>
          <div className="scrollbar-hide h-[15rem] grow snap-y snap-mandatory overflow-auto" ref={hourContainerRef}>
            <div className="h-[7.5rem]" />
            {dates[selectedDate].hours.map((e, i) => (
              <div
                className={classNames("w-full snap-center py-3 text-center text-xl", {
                  "text-gray-900": selectedHour === i,
                  "text-gray-900/50": selectedHour !== i,
                })}
                key={e}
              >
                {e === capitalizeFirstLetter(t("now")) ? e : `${String(e).padStart(2, "0")}:00`}
              </div>
            ))}
            <div className="h-[7.5rem]" />
          </div>
          <div className="pointer-events-none absolute left-0 top-0 h-[7.5rem] w-full bg-gradient-to-b from-white to-white/0" />
          <div className="pointer-events-none absolute bottom-0 left-0 h-[7.5rem] w-full bg-gradient-to-t from-white to-white/0" />
          <div className="absolute left-0 top-1/2 -z-[1] h-[3rem] w-full -translate-y-1/2 rounded-xl bg-gray-100" />
        </div>
        <div className="grow" />
        <div className="flex gap-2">
          <Button variant="secondary" text={t("resetDate")} onClick={handleResetDate} />
          <Button variant="primary" text={t("save")} onClick={onSaveDate} />
        </div>
      </div>
    </div>
  );
};

export default DatePickerPopup;
