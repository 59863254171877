import { useContext } from "react";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useInfiniteQuery } from "react-query";

const useBroadcastUsers = (broadcastId: string, userGroup: "receivedBy" | "viewedBy" | "boughtBy", query: string) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const fetchBroadcastUsers = async ({ pageParam }: { pageParam?: string }) => {
    return maloumClient.broadcasts.getUsersOfBroadcast(broadcastId, userGroup, pageParam, undefined, query);
  };

  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ["broadcast-users", { broadcastId, userGroup, query }],
    fetchBroadcastUsers,
    {
      getNextPageParam: (lastPage) => lastPage.next || null,
    },
  );

  return {
    users: data?.pages.flatMap((page) => page.data) || [],
    isLoading: isFetching,
    hasNextPage: hasNextPage ?? true,
    fetchNextPage,
  };
};

export default useBroadcastUsers;
