import { VaultMediaDto } from "@neolime-gmbh/api-gateway-client";
import Pill from "components/atoms/Pill";
import { useTranslation } from "react-i18next";
import ItemSentIcon from "components/atoms/icons/ItemSentIcon";
import { HiOutlineBanknotes } from "react-icons/hi2";

type Props = {
  media: VaultMediaDto;
};

const VaultMediaStatusPill = ({ media }: Props) => {
  const { t } = useTranslation();

  if (media.isSent || media.isBought) {
    return (
      <>
        <Pill
          size="sm"
          className="absolute bottom-2 left-2 opacity-90"
          colors={media.isBought ? "bg-green-700 text-white" : "bg-gray-800 text-white"}
        >
          <span className="flex items-center gap-1">
            {media.isBought ? <HiOutlineBanknotes className="h-5 w-5" /> : <ItemSentIcon className="h-5 w-5 p-0.5" />}
            <span className="hidden lg:block">{media.isBought ? t("vault.status.sold") : t("vault.status.sent")}</span>
          </span>
        </Pill>
      </>
    );
  }

  return <></>;
};

export default VaultMediaStatusPill;
