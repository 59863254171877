import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PopUp from "../popup/PopUp";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import { ProductDto } from "@neolime-gmbh/api-gateway-client";

type Props = {
  product: ProductDto;
  open: boolean;
  setOpen: (value: boolean) => void;
  setProduct?: (value: ProductDto) => void;
};
const StatusProductPopup = ({ product, setProduct, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const setActive = async () => {
    const response = await maloumClient.products.update(product._id, {
      active: true,
    });
    if (!(response instanceof Error)) {
      if (setProduct) setProduct(response);
      setOpen(false);
    }
  };

  const options = [
    {
      title: t("product.activate"),
      onClick: () => setActive(),
    },
  ];

  return <PopUp options={options} title={t("product.status.title")} isOpen={open} onClose={() => setOpen(false)} />;
};

export default StatusProductPopup;
