import { CommentDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext, useMemo } from "react";
import { QueryFunctionContext, useInfiniteQuery, useQueryClient } from "react-query";
import useUserStore from "state/userState";

const useComments = (postId: string, loadComments = true) => {
  const queryClient = useQueryClient();
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore((state) => state.user);

  const fetchNextCommentsForPost = async (query: QueryFunctionContext<string[], any>) =>
    await maloumClient.posts.getComments(postId, query?.pageParam);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["comments", postId],
    fetchNextCommentsForPost,
    {
      getNextPageParam: (lastPage) => lastPage.next,
      // only trigger load if loadComments is true
      enabled: loadComments,
    },
  );

  // move data into memo and flatMap
  const comments = useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap((p) => p.data);
  }, [data]);

  // delete comment and update data in query
  const deleteComment = async (commentId: string) => {
    await maloumClient.posts.deleteComment(postId, commentId).then(() => {
      queryClient.setQueryData(["comments", postId], (prev: any) => {
        return {
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              next: page.next,
              data: page.data.filter((comment: CommentDto) => comment._id !== commentId),
            };
          }),
        };
      });
    });
  };

  // update comment and update data in query
  const updateComment = async (commentId: string, text: string) => {
    await maloumClient.posts.editComment(postId, commentId, { text }).then((newComment) => {
      queryClient.setQueryData(["comments", postId], (prev: any) => {
        return {
          ...prev,
          pages: prev.pages.map((page: any) => {
            return {
              next: page.next,
              data: page.data.map((comment: CommentDto) =>
                comment._id === newComment._id ? { ...newComment, user: user } : comment,
              ),
            };
          }),
        };
      });
    });
  };

  // create comment and update data in query
  const createComment = async (text: string) => {
    await maloumClient.posts.postComment(postId, { text }).then((newComment) => {
      queryClient.setQueryData(["comments", postId], (prev: any) => {
        return {
          ...prev,
          pages: prev.pages.map((page: any, idx: number) => {
            return {
              next: page.next,
              data: idx === 0 ? [{ ...newComment, user: user }, ...page.data] : page.data,
            };
          }),
        };
      });
    });
  };

  return {
    comments,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    deleteComment,
    updateComment,
    createComment,
  };
};

export default useComments;
