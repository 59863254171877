import { RadioGroup } from "@headlessui/react";
import { OrderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { supportEmail } from "const";
import { Form, Formik } from "formik";
import { MouseEvent, ReactNode, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { HiOutlineClock, HiOutlineCube, HiOutlinePencil, HiOutlineTruck } from "react-icons/hi2";
import * as Yup from "yup";
import TernaryButton from "../atoms/buttons/TernaryButton";
import TextInput from "../basics/TextInput";

type Props = {
  orderStatus?: OrderDto.status;
  className?: string;
  trackingNumber?: string;
  saveTrackingNumber?: (value: string) => void;
};

const OrderStatusRadioButton = ({ orderStatus, className, trackingNumber = "", saveTrackingNumber }: Props) => {
  const { t } = useTranslation();

  const [trackingNumberState, setTrackingNumberState] = useState(trackingNumber);
  const [hasSaveButton, setHasSaveButton] = useState(trackingNumber.length === 0);

  let orderStatusText: string;
  let orderStatusIcon: ReactNode;

  switch (orderStatus) {
    case OrderDto.status.SHIPPED:
      orderStatusText = t("order.creatorStatus.inDelivery");
      orderStatusIcon = <HiOutlineTruck className="h-8 w-8" />;
      break;
    case OrderDto.status.DONE:
      orderStatusText = t("order.creatorStatus.arrived");
      orderStatusIcon = <HiOutlineCube className="h-8 w-8" />;
      break;
    case OrderDto.status.PREPARING:
    default:
      orderStatusText = t("order.creatorStatus.pending");
      orderStatusIcon = <HiOutlineClock className="h-8 w-8" />;
      break;
  }

  const handleSubmit = (values: { trackingNumber: string }) => {
    if (values.trackingNumber.length > 0 && saveTrackingNumber) {
      saveTrackingNumber(values.trackingNumber);
      setTrackingNumberState(values.trackingNumber);
      setHasSaveButton(false);
    }
  };

  const handleEdit = (e: MouseEvent<HTMLButtonElement>) => {
    setHasSaveButton(true);
    e.stopPropagation();
  };

  return (
    <RadioGroup.Option value={orderStatus}>
      {({ checked }) => (
        <div
          className={classNames(
            "flex cursor-pointer items-center rounded-md border border-gray-100 p-2 text-sm focus:outline-none xxs:text-base",
            { "border-red-900": checked, " border-gray-100": !checked },
            className,
          )}
        >
          <div className="flex-1">
            <RadioGroup.Label as="span" className={"flex grow items-center"}>
              <span
                className={classNames(
                  "m-1 flex h-3 w-3 items-center justify-center rounded-full ring-1 ring-offset-2",
                  {
                    "bg-red-900 ring-red-900": checked,
                    "bg-white ring-gray-400": !checked,
                  },
                )}
              ></span>

              <span className="ml-2 flex flex-1 items-center justify-between">
                {orderStatusText}
                {orderStatusIcon}
              </span>
            </RadioGroup.Label>
            {orderStatus === OrderDto.status.SHIPPED && (
              <>
                <div className="flex">
                  <Formik
                    initialValues={{
                      trackingNumber: trackingNumber,
                    }}
                    onSubmit={(values) => handleSubmit(values)}
                    validationSchema={Yup.object().shape({
                      trackingNumber: Yup.string()
                        .required(
                          <span>
                            <Trans i18nKey="order.provideTrackingNumber">
                              Please provide a tracking number or link. If you need help with that please contact
                              <a href={`mailto:${supportEmail}`} className="text-red-900 underline">
                                {supportEmail}
                              </a>
                            </Trans>
                          </span>,
                        )
                        .max(300, ({ max }) => t("validation.maxValue", { maxValue: max })),
                    })}
                  >
                    {({ isValid }) => (
                      <Form className="ml-8 mt-3 flex flex-1 flex-col">
                        <div className={classNames("flex flex-1", { "mr-8": hasSaveButton })}>
                          {/** TODO: Rewrite Text input component */}
                          <TextInput
                            label={`${t("order.details")} (${t("order.trackingNumberOrLink")})`}
                            variant={"underline"}
                            required
                            type="text"
                            name="trackingNumber"
                            placeholder={t("text") || ""}
                            wrapperClassName="flex-1"
                            spacing="mb-0"
                            disabled={!hasSaveButton}
                            aria-label="tracking-number"
                            className="disabled:bg-transparent disabled:text-gray-900"
                            isTouched={checked}
                          />
                          {trackingNumberState.length > 0 && !hasSaveButton && (
                            <div className="mt-5 self-start">
                              <TernaryButton
                                onClick={handleEdit}
                                variant="black"
                                type="button"
                                data-testid="edit-button"
                                disabled={!checked}
                              >
                                <HiOutlinePencil className="h-6 w-6" />
                              </TernaryButton>
                            </div>
                          )}
                        </div>
                        {hasSaveButton && (
                          <div className="mr-8 mt-5 flex justify-center">
                            <TernaryButton
                              onClick={(e) => e.stopPropagation()}
                              variant="red"
                              type="submit"
                              disabled={!isValid}
                            >
                              {t("save")}
                            </TernaryButton>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </RadioGroup.Option>
  );
};

export default OrderStatusRadioButton;
