import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import StickyBottomBar from "components/StickyBottomBar";
import { usePostHog } from "posthog-js/react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheckCircle } from "react-icons/hi2";
import useUserStore from "state/userState";
import { ReactComponent as BecomeCreatorSVG } from "svg/undraw_become_creator.svg";
import Button from "../../components/basics/Button";
import Container from "../../components/layouts/Container";
import UserContext from "../../contexts/UserContext";
import SignUpHeader from "./SignUpHeader";

type Props = {
  onBack: () => void;
  onNext: () => void;
};

const BecomeCreatorPage = ({ onBack, onNext }: Props) => {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { setRole } = useContext(UserContext);
  const userState = useUserStore<PrivateUserDto>((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectFan = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    // POSTHOG: emmit set role event
    posthog?.capture("set-role", { role: "fan" });

    // Google Tag Manager: Sign Up Fan
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "sign_up_fan",
      user: {
        email: userState.email,
        id: userState._id,
      },
    });

    await setRole("fan", true).then(() => {
      const initialCreator = localStorage.getItem("initialCreator");
      if (initialCreator) window.location.assign(`/creator/${initialCreator}`);
    });
    setIsLoading(false);
  }, [setRole, posthog, isLoading, setIsLoading]);

  const handleSelectCreator = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    // POSTHOG: emmit set role event
    posthog?.capture("set-role", { role: "creator" });

    // Google Tag Manager: Sign Up Creator
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "sign_up_creator",
      user: {
        email: userState.email,
        id: userState._id,
      },
    });
    await setRole("creator", false).then(onNext);
    setIsLoading(false);
  }, [setRole, posthog, isLoading, setIsLoading]);

  return (
    <div className="pr-safe pl-safe pb-safe full-height flex flex-1 grow flex-col md:order-2">
      <SignUpHeader onBack={onBack} />
      <Container hasStickyBottom>
        <div className={"flex flex-1 flex-col"}>
          <h1 className={"text-3xl font-semibold text-gray-900"}>{t("becomeCreator.title")}</h1>

          <ul className={"mt-2 flex flex-col gap-y-1.5"}>
            <li className={"flex items-center"}>
              <HiOutlineCheckCircle className={"mr-1.5 h-6 w-6"} />
              <span>{t("becomeCreator.start")}</span>
            </li>
            <li className={"flex items-center"}>
              <HiOutlineCheckCircle className={"mr-1.5 h-6 w-6"} />
              <span>{t("becomeCreator.engage")}</span>
            </li>
            <li className={"flex items-center"}>
              <HiOutlineCheckCircle className={"mr-1.5 h-6 w-6"} />
              <span>{t("becomeCreator.monetize")}</span>
            </li>
          </ul>
          <p className={"mt-4"}>{t("becomeCreator.earn")}</p>
          <div className="mt-2 flex grow items-center justify-center">
            <BecomeCreatorSVG className="h-fit max-h-[8.75rem] w-fit max-w-[8.75rem] md:max-h-[11.25rem] md:max-w-[11.25rem]" />
          </div>
        </div>
      </Container>
      <StickyBottomBar>
        <Button
          variant={"secondary"}
          onClick={handleSelectCreator}
          text={t("becomeCreator.title")}
          disabled={isLoading}
          data-testid="becomeCreatorButton"
        />
        <Button onClick={handleSelectFan} text={t("continueFan")} className="mt-2" disabled={isLoading} />
      </StickyBottomBar>
    </div>
  );
};

export default BecomeCreatorPage;
