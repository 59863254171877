import { useCallback, useEffect, useState } from "react";

const useShare = (link: string, text?: string) => {
  const [successfullyShared, setSuccessfullyShared] = useState(false);
  const [canShare, setCanShare] = useState(false);

  const share = useCallback(async () => {
    if (canShare) {
      await navigator.share({
        title: text,
        text: text,
        url: link,
      });
    } else {
      await copyToClipboard();
    }
    setSuccessfullyShared(true);
    setTimeout(() => setSuccessfullyShared(false), 2000);
  }, [canShare, link, text]);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(link);
  }, [link, text]);

  useEffect(() => {
    if (!navigator.share || navigator.userAgent.match("Macintosh")) {
      setCanShare(false);
    } else {
      setCanShare(true);
    }
  }, []);

  return { share, successfullyShared, canShare, copyToClipboard };
};

export default useShare;
