import TernaryButton from "components/atoms/buttons/TernaryButton";
import SuggestedCreatorCard, { SuggestedCreatorCardLoading } from "components/molecules/card/SuggestedCreatorCard";
import useTopCreators from "hooks/useTopCreators.hook";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const TopCreatorsSection = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();
  const { topCreators, isLoading } = useTopCreators();

  return (
    <div className="">
      <div className="flex items-center justify-between px-4 md:px-0">
        <div className="text-xl font-semibold">{t("topCreators.sectionHeader")}</div>
        <TernaryButton
          type="button"
          variant="red"
          onClick={() => {
            navigate("/topCreators");
          }}
        >
          {t("seeAll")}
        </TernaryButton>
      </div>
      <ScrollContainer className="flex gap-2 overflow-x-auto px-4 pb-8 md:px-0">
        {isLoading
          ? Array.from({ length: 4 }, (_, i) => (
              <SuggestedCreatorCardLoading data-testid={`top-creator-card-loading-${i}`} key={i} />
            ))
          : topCreators.map((c) => (
              <SuggestedCreatorCard
                username={c.user.username}
                isVerified={c.user.isVerified}
                backgroundImage={c.lastPost?.media[0]}
                profileImage={c.user.profilePictureThumbnail}
                rank={c.rank}
                key={c.rank}
              />
            ))}
      </ScrollContainer>
    </div>
  );
};

export default TopCreatorsSection;
