import { ChatListDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ListName from "components/atoms/lists/ListName";
import { useTranslation } from "react-i18next";
import { HiXMark } from "react-icons/hi2";
import { BaseComponent } from "types/components/component.type";

type Props = {
  list: ChatListDto;
  onClick?: (list: ChatListDto) => void;
} & BaseComponent;

const SelectedListItem = ({ list, onClick, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();

  const handleOnClick = () => onClick && onClick(list);

  return (
    <div
      className={classNames(
        "flex w-full items-center justify-between rounded-md border border-gray-100 px-3 py-2",
        className,
      )}
      data-testid={dataTestId}
    >
      <div className="text-left">
        <ListName list={list} />
        <div className="mt-0.5 text-xs text-gray-500">{t("lists.item.members", { count: list.totalMemberCount })}</div>
      </div>
      {onClick && (
        <button className="py-3 pl-3 text-gray-400" onClick={handleOnClick}>
          <HiXMark className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default SelectedListItem;
