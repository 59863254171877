import Layout from "components/layouts/Layout";
import HeaderBar from "components/layouts/HeaderBar";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import useSentBroadcasts from "hooks/chats/useSentBroadcasts.hook";
import Container from "components/layouts/Container";
import SentBroadcastCard from "components/organisms/broadcast/SentBroadcastCard";
import BroadcastCardLoading from "components/organisms/broadcast/BroadcastCardLoading";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import EmptySentBroadcasts from "components/molecules/broadcast/EmptySentBroadcasts";

const SentBroadcastsPage = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const { broadcasts, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useSentBroadcasts();

  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isLoading, isFetchingNextPage]);

  return (
    <>
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t("chats.broadcasts.title")}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right className="pr-2">
              <HeaderBar.Link to="../new">
                <HiOutlinePencilSquare className="h-6 w-6 text-gray-800" />
              </HeaderBar.Link>
            </HeaderBar.Right>
          </HeaderBar.SubPage>
        </HeaderBar>

        <Container hasPadding={false} className="py-4">
          <div className="flex grow flex-col gap-2 px-4 md:px-0">
            {broadcasts.map((broadcast) => (
              <SentBroadcastCard broadcast={broadcast} key={broadcast._id} />
            ))}
            {(isLoading || isFetchingNextPage) && (
              <>
                {Array(5)
                  .fill(null)
                  .map((_, i) => (
                    <BroadcastCardLoading key={i} />
                  ))}
              </>
            )}
            {!isLoading && !isFetchingNextPage && broadcasts.length === 0 && <EmptySentBroadcasts />}
            <div className="absolute bottom-[10rem]" ref={ref} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default SentBroadcastsPage;
