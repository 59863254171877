import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import Textarea from "components/basics/Textarea";
import Container from "components/layouts/Container";
import Layout from "components/layouts/Layout";
import StillUploadingMediaPopup from "components/media/StillUploadingMediaPopup";
import EditProfileImage from "components/profile/EditProfileImage";
import SelectItemUnderline from "components/utilities/SelectItemUnderline";
import userContext from "contexts/UserContext";
import { Form, Formik } from "formik";
import SelectGenderPage from "pages/gender/SelectGender";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUploadStore from "state/uploadState";
import useUserStore from "state/userState";
import * as Yup from "yup";
import { usePostHog } from "posthog-js/react";
import HeaderBar from "components/layouts/HeaderBar";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const EditProfilePage = () => {
  const posthog = usePostHog();
  const { updateProfile } = useContext(userContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const [bio, setBio] = useState(user.bio || "");
  const [selectedGender, setSelectedGender] = useState(user.gender?.name ? user.gender.name : null);
  const [showSelectGenderPage, setShowSelectGenderPage] = useState(false);
  const [profilePictureId, setProfilePictureId] = useState<string | null | undefined>();
  const [selectedImage, setSelectedImage] = useState<File | Blob | null>(null);
  const [mediaIsUploaded, setMediaIsUploaded] = useState(true);
  const [showStillUploadingMediaPopup, setShowStillUploadingMediaPopup] = useState(false);
  const resetUploads = useUploadStore((state) => state.resetUploads);

  const submitProfile = useCallback(
    async (values: { bio: string; profilePictureId: string }) => {
      // POSTHOG: emmit edit profile submit event
      posthog?.capture("edit-profile-submit");

      const data = { ...values };
      if (profilePictureId !== undefined) {
        data.profilePictureId = profilePictureId;
      }

      if (!mediaIsUploaded) {
        setShowStillUploadingMediaPopup(true);
        return;
      }

      const response = await updateProfile(data);

      if (!(response instanceof Error)) {
        resetUploads();
        navigate(`/creator/${user.username}`);
      }
    },
    [navigate, updateProfile, profilePictureId, selectedGender, mediaIsUploaded, posthog],
  );

  const updateGender = (gender: string | null) => {
    setSelectedGender(gender);
    setShowSelectGenderPage(false);
  };

  const handleCloseSelectGenders = useCallback(() => setShowSelectGenderPage(false), [setShowSelectGenderPage]);

  useEffect(() => {
    // POSTHOG: emmit edit profile open event
    posthog?.capture("edit-profile-open");
  }, []);

  if (showSelectGenderPage) {
    return (
      <SelectGenderPage
        initialGender={selectedGender}
        isGenderPublic={user.isGenderPublic}
        onBack={handleCloseSelectGenders}
        onUpdate={(gender: string | null) => updateGender(gender)}
      />
    );
  }

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar className="mb-2">
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.Cancel />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("editProfile.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Container hasPadding={false} className="px-4 md:px-0">
        <EditProfileImage
          imageUrl={user.profilePictureThumbnail?.url}
          title={user.username}
          userIsVerified={user.isVerified}
          setProfilePictureId={setProfilePictureId}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          mediaIsUploaded={mediaIsUploaded}
          setMediaIsUploaded={setMediaIsUploaded}
        />

        <Formik
          initialValues={{
            bio: bio,
          }}
          validationSchema={Yup.object().shape({
            bio: Yup.string(),
          })}
          onSubmit={submitProfile}
        >
          {({ isValid }) => (
            <Form className={"flex flex-1 flex-col pb-4"}>
              <div className={"mb-4 flex flex-1 flex-col md:flex-grow-0"}>
                <Textarea
                  label={t("addBio")}
                  type="text"
                  name="bio"
                  placeholder={t("addBioText") || ""}
                  maxLength={10000}
                  onChange={setBio}
                />

                <label className={"mb-1 text-sm"}>{t("gender.title")}</label>
                <SelectItemUnderline
                  value={selectedGender ? t(`gender.${selectedGender}`) : t("gender.selectYourGender")}
                  onClick={() => setShowSelectGenderPage(true)}
                  data-testid="selectGender"
                />
              </div>

              <Button
                variant={"primary"}
                disabled={!isValid}
                text={t("save")}
                type={"submit"}
                data-testid="save-button"
              />
            </Form>
          )}
        </Formik>
      </Container>
      <StillUploadingMediaPopup isOpen={showStillUploadingMediaPopup} setIsOpen={setShowStillUploadingMediaPopup} />
    </Layout>
  );
};

export default EditProfilePage;
