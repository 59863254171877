import { PostDto, PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import {useFirstline} from "@first-line/firstline-react";

type Props = {
  creator: PublicUserDto;
  viewedByGuest?: boolean;
};
const useCreatorFeed = ({ creator, viewedByGuest = false }: Props) => {
  const { user: authUser } = useFirstline();
  const { maloumClient } = useContext(MaloumClientContext);
  const queryClient = useQueryClient();

  const updatePost = (postToChange: PostDto) => {
    queryClient.setQueryData(["creator-feed", creator._id], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.map((post: PostDto) => (post._id === postToChange._id ? postToChange : post)),
          };
        }),
      };
    });
  };

  const removePost = (postId: string) => {
    queryClient.setQueryData(["creator-feed", creator._id], (prev: any) => {
      return {
        ...prev,
        pages: prev.pages.map((page: any) => {
          return {
            next: page.next,
            data: page.data.filter((post: PostDto) => post._id !== postId),
          };
        }),
      };
    });
  };

  const fetchCreatorFeed = async (next: any) => {
    return await maloumClient.posts.getByCreator(creator.username, next.pageParam, 15);
  };

  const fetchCreatorFeedGuest = async (next: any) => {
    return await maloumClient.guest.getPostsByUsername(creator.username, next.pageParam, 15);
  };

  const fetchOwnCreatorFeed = async (next: any) => {
    return await maloumClient.posts.me(next.pageParam, 15);
  };

  const splitter = async (next: any) => {
    if (!creator.isVerified) return { data: [], next: null };
    if (creator.username === authUser?.username) return fetchOwnCreatorFeed(next);
    if (viewedByGuest) return fetchCreatorFeedGuest(next);
    return fetchCreatorFeed(next);
  };

  const { data, isLoading, isError, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["creator-feed", creator._id],
    splitter,
    {
      getPreviousPageParam: (firstPage) => firstPage.next || null,
      getNextPageParam: (lastPage) => lastPage.next || null,
      staleTime: 0,
    },
  );

  return { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage, removePost, updatePost };
};

export default useCreatorFeed;
