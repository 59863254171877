import classNames from "classnames";
import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
  className?: string;
};
const Callout = ({ children, className }: Props) => {
  return (
    <div className={classNames("rounded-md bg-beige-300 p-2 text-sm", className)} data-testid="callout">
      {children}
    </div>
  );
};

export default Callout;
