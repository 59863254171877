import classNames from "classnames";
import { FaCheck } from "react-icons/fa6";

type Props = {
  isSelected: boolean;
  onClick: () => void;
  className?: string;
  "data-testid"?: string;
};

const SelectionButton = ({ isSelected, onClick, className, "data-testid": dataTestId }: Props) => {
  return (
    <button
      className={classNames("pb-3 pl-6 pr-3 pt-6 md:pl-12 md:pt-12", className)}
      data-testid={dataTestId}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      {isSelected ? (
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-red-900">
          <FaCheck className="h-2 w-2 text-white" />
        </div>
      ) : (
        <div className="h-4 w-4 rounded-full border border-gray-200 bg-white" />
      )}
    </button>
  );
};

export default SelectionButton;
