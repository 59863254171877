import Callout from "components/atoms/Callout";
import DisplayMoneyAmount from "components/atoms/DisplayMoneyAmount";
import HeaderBar from "components/layouts/HeaderBar";
import PayoutCalculationTableView from "components/molecules/table/PayoutCalculationTable";
import { getAndFormatCurrentMonth, getAndFormatFirstDayOfNextMonthDate } from "helper/dateAndTimeHelper";
import useBalance from "hooks/useBalance";
import useCurrency from "hooks/useCurrency.hook";
import { Trans, useTranslation } from "react-i18next";
import Container from "../../components/layouts/Container";
import Layout from "../../components/layouts/Layout";
import AddEditPayoutMethod from "../../components/payout/AddEditPayoutMethod";
import TransactionList from "../../components/payout/TransactionList";
import StatefulLink from "components/atoms/utils/StatefulLink";

const PayoutPage = () => {
  const { t } = useTranslation();
  const { balance, isLoading: isBalanceLoading } = useBalance();
  const { displayCurrency } = useCurrency();

  const payoutDate = getAndFormatFirstDayOfNextMonthDate();
  const currentMonth = getAndFormatCurrentMonth();

  const tableData = [
    {
      name: t("earnings.total"),
      amount: balance ? displayCurrency(balance?.net) : displayCurrency(0),
    },
    {
      name: t("earnings.platformFee"),
      amount: balance ? `− ${displayCurrency(balance?.net - balance.payoutAmount)}` : displayCurrency(0),
    },
    {
      name: t("earnings.yours"),
      amount: balance ? displayCurrency(balance?.payoutAmount) : displayCurrency(0),
    },
  ];

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("earnings.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Container>
        <DisplayMoneyAmount
          title={t("earnings.thisMonth")}
          amount={balance ? balance?.payoutAmount : 0}
          isLoading={isBalanceLoading}
          className="mt-1.5"
        />

        <Callout className="mt-2">
          <Trans i18nKey="earnings.dateOfPayout">
            Next payout of your earnings will be on {{ date: payoutDate }}. Do you have any further questions?
            <StatefulLink to="/payout/information" className="text-red-900">
              More details
            </StatefulLink>
          </Trans>
        </Callout>

        <h2 className="mb-1 mt-2 text-base font-semibold">{currentMonth}</h2>
        <PayoutCalculationTableView costArray={tableData} />

        <AddEditPayoutMethod />

        <TransactionList />
      </Container>
    </Layout>
  );
};

export default PayoutPage;
