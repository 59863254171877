import { MaloumClientProvider } from "contexts/MaloumClientContext";
import { createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import { guestRouter } from "router/guestRouter";
import Loading from "../pages/basics/Loading";
import { usePostHog } from "posthog-js/react";
import { GuestProvider } from "contexts/GuestContext";
import { useFirstline } from "@first-line/firstline-react";

const AuthorizationContext = createContext();
export default AuthorizationContext;

const queryClient = new QueryClient();

export const AuthorizationProvider = ({ children }) => {
  const posthog = usePostHog();
  const {
    isAuthenticated,
    loginWithRedirect,
    getAccessToken,
    isLoading,
    isEmailVerified,
    verifyEmail,
    user,
    doRefresh,
  } = useFirstline();

  const pathname = window.location.pathname.split("/");
  const isCreatorProfile = pathname[1] === "creator";

  if (isLoading) {
    return <Loading />;
  }

  if (isCreatorProfile && !isAuthenticated) {
    return (
      <MaloumClientProvider>
        <QueryClientProvider client={queryClient}>
          <GuestProvider>
            <RouterProvider router={guestRouter} />
          </GuestProvider>
        </QueryClientProvider>
      </MaloumClientProvider>
    );
  }

  if (!isAuthenticated) {
    const redirect = window.location.search?.split("=")?.[1];
    if (redirect === "signup") {
      loginWithRedirect({ action_hint: "signup" });
    } else {
      loginWithRedirect();
    }

    return <Loading />;
  }

  if (!isEmailVerified) {
    // POSTHOG: emmit signup event
    // as this is the closest code section to signup we emmit the signup event at this point
    posthog?.capture("signup");

    verifyEmail();
    return <Loading />;
  }

  return (
    <AuthorizationContext.Provider value={{ getAccessToken, user, doRefresh }}>
      {children}
    </AuthorizationContext.Provider>
  );
};
