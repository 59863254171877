import classNames from "classnames";
import React, { KeyboardEvent } from "react";

type Props = {
  value?: string;
  className?: string;
  placeholder?: string | null;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  maxLength?: number;
  onChange: (value: string) => void;
  onFocus?: () => void;
};

const InputField = React.forwardRef(
  ({ value, className, placeholder, required, disabled, name, maxLength, onChange, onFocus }: Props, ref) => {
    return (
      <input
        className={classNames("input-field", className)}
        type="text"
        onChange={(e) => onChange(e.target.value)}
        name={name}
        value={value}
        placeholder={placeholder !== null ? placeholder : ""}
        required={required}
        disabled={disabled}
        inputMode="numeric"
        maxLength={maxLength}
        autoComplete="off"
        // @ts-ignore
        ref={ref}
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
          if (
            !/\d/.test(event.key) &&
            event.key !== "Backspace" &&
            event.key !== "Tab" &&
            !event.metaKey &&
            !event.ctrlKey
          ) {
            event.preventDefault();
          }
        }}
        onFocus={onFocus}
      />
    );
  },
);

export default InputField;
