import * as Sentry from "@sentry/react";

import ReactDOM from "react-dom/client";
import { env } from "env";
import "i18n";
import "styles/style.css";
import App from "App";

// eslint-disable-next-line no-undef
if (typeof Node === "function" && Node.prototype) {
  // eslint-disable-next-line no-undef
  const originalRemoveChild = Node.prototype.removeChild;
  // eslint-disable-next-line no-undef
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      // eslint-disable-next-line no-undef
      if (console) {
        // eslint-disable-next-line no-undef
        console.error("Cannot remove a child from a different parent", child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  // eslint-disable-next-line no-undef
  const originalInsertBefore = Node.prototype.insertBefore;
  // eslint-disable-next-line no-undef
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // eslint-disable-next-line no-undef
      if (console) {
        // eslint-disable-next-line no-undef
        console.error("Cannot insert before a reference node from a different parent", referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

Sentry.init({
  dsn: env.VITE_SENTRY_DSN,
  enabled: ["dev", "production"].includes(env.VITE_DEPLOYMENT_PROFILES),
  environment: env.VITE_DEPLOYMENT_PROFILES,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 0.5,
  release: env.VITE_SENTRY_RELEASE,

  replaysSessionSampleRate: 0.001,
  replaysOnErrorSampleRate: 0.3,
});

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
