import classNames from "classnames";
import { useMemo } from "react";

type Props = {
  counter: number;
  displayLimit?: number;
  className?: string;
};

const UnreadMessagesBadge = ({ counter, displayLimit = 99, className }: Props) => {
  const displayedCounter = useMemo(() => (counter > displayLimit ? `${displayLimit}+` : counter), [counter]);

  if (!(counter > 0)) return <></>;
  return (
    <div
      className={classNames(
        "flex h-[1.125rem] min-w-[1.125rem] items-center justify-center rounded-full bg-red-900 px-1 py-0.5 text-xs text-white",
        className,
      )}
    >
      {displayedCounter}
    </div>
  );
};

export default UnreadMessagesBadge;
