import { PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import Callout from "components/atoms/Callout";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";
import HeaderBar from "components/layouts/HeaderBar";
import useComments from "hooks/useComments.hook";
import usePost from "hooks/usePost.hook";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePaperAirplane } from "react-icons/hi2";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import useUserStore from "state/userState";
import AutoGrowTextarea from "../components/basics/AutoGrowTextarea";
import CommentItem from "../components/comment/CommentItem";
import Container from "../components/layouts/Container";
import Layout from "../components/layouts/Layout";

const Comments = () => {
  const { t } = useTranslation();
  // post id
  const { id } = useParams();

  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const { post, isError: postLoadingError } = usePost(id ?? "");
  const userIsAllowedToComment = useMemo(
    () => post?.createdBy?._id === user?._id || !user?.isCreator || post?.createdBy.contentSettings.canCreatorsComment,
    [post, user],
  );

  // comments
  const { ref, inView } = useInView();
  const { comments, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, createComment } = useComments(id ?? "");

  // new comment
  const [commentText, setCommentText] = useState("");

  const handleChange = (value: string) => setCommentText(value);

  // create comment and scroll to top
  const handleSubmitMessage = async () => {
    // abort if commentText is empty or no postid
    if (!commentText || !commentText.trimStart() || !id) return;
    // create comment
    await createComment(commentText);
    // scroll to top
    window.scrollTo({ top: 0, behavior: "smooth" });
    // reset message
    setCommentText("");
  };

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading]);

  // navigate to error page on post loading error
  if (postLoadingError) return <StatefulNavigate to="/404" replace />;

  return (
    <Layout hideNavigationMobile>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("comment.plural")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right />
        </HeaderBar.SubPage>
      </HeaderBar>

      <Container hasPadding={false} className="">
        <div className="flex flex-col gap-2 px-4 pb-16 pt-4 md:px-0">
          {comments.map((c) => (
            <CommentItem post={post} comment={c} key={c._id} />
          ))}
          {(isLoading || isFetchingNextPage) && (
            <>
              {Array(5)
                .fill(null)
                .map((_, i) => (
                  <CommentItem isLoading key={i} />
                ))}
            </>
          )}
          {!isLoading && !isFetchingNextPage && comments.length === 0 && (
            <div className="py-8 text-center">{t("comment.empty")}</div>
          )}
          <div className="absolute bottom-[10rem]" ref={ref} />
        </div>
      </Container>
      {!isLoading && (
        <Container hasGrow={false} className="sticky bottom-0 mt-2 bg-white px-4 pb-4">
          <div className="flex w-full items-end gap-2">
            {userIsAllowedToComment ? (
              <div className="flex grow flex-wrap items-end rounded-md bg-gray-100 pr-1">
                <AutoGrowTextarea
                  placeholder={t("comment.placeholder")}
                  maxRows={4}
                  value={commentText}
                  onChange={handleChange}
                  maxLength={300}
                  className="flex w-0 grow rounded-md border-none bg-transparent px-3 py-2 text-base outline-none !ring-0 placeholder:text-gray-400 focus:outline-none"
                  onSubmit={handleSubmitMessage}
                  data-testid="message-input"
                />
                <button onClick={handleSubmitMessage} className="p-2.5" data-testid="send-message">
                  <HiOutlinePaperAirplane className="h-5 w-5" />
                </button>
              </div>
            ) : (
              <Callout className="w-full">{t("comment.notAllowed")}</Callout>
            )}
          </div>
        </Container>
      )}
    </Layout>
  );
};

export default Comments;
