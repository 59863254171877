import { ReactNode } from "react";
import useUserStore from "../state/userState";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";

type Props = {
  children: ReactNode;
};

const VerifiedRoute = ({ children }: Props) => {
  const user = useUserStore((state) => state.user);

  if (!user.isVerified) {
    return <StatefulNavigate to="/verification/start" replace={true} />;
  }
  return <>{children}</>;
};

export default VerifiedRoute;
