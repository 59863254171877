import classNames from "classnames";
import { ReactNode } from "react";
import StatefulLink from "components/atoms/utils/StatefulLink";

export type TColorVariant = "red" | "black";
export type TSizeVariant = "xs" | "base" | "lg";

type Props = {
  children: ReactNode;
  to: string;
  className?: string;
  variant?: TColorVariant;
  size?: TSizeVariant;
};

const TernaryLink = ({ variant = "black", size = "base", children, className, to }: Props) => {
  return (
    <StatefulLink
      to={to}
      className={classNames(
        "flex rounded-md border-2 border-transparent p-2 outline-none focus:border-blue-violet disabled:text-gray-300",
        {
          "text-red-900 hover:text-red-600 active:text-red-800": variant === "red",
          "text-gray-700 hover:text-gray-500 active:text-gray-800": variant === "black",
        },
        {
          "text-xs": size === "xs",
          "text-base": size === "base",
          "text-lg": size === "lg",
        },
        className,
      )}
    >
      {children}
    </StatefulLink>
  );
};
export default TernaryLink;
