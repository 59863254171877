import classNames from "classnames";
import SelectionButton from "components/atoms/vault/SelectionButton";
import UploadingMedia from "components/media/UploadingMedia";
import useVideoThumbnail from "hooks/media/useThumbnail.hook";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { BaseComponent } from "types/components/component.type";
import { TUpload } from "types/uploads.type";
import { TSelectedMedia } from "types/vault/selectedMedia.type";
import {mapUploadToSelectedMedia} from "helper/mediaHelper";

type Props = {
  upload: TUpload;
  selectedMedia: TSelectedMedia[];
  handleSelectMedia: (media: TSelectedMedia) => void;
} & BaseComponent;

const VaultMediaUpload = ({
  upload,
  selectedMedia,
  handleSelectMedia,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { state } = useLocation();
  const isSelectingMedia = state.redirectTo !== undefined;
  const allowMultipleSelect = state?.allowMultipleSelect ?? true;

  // video data
  const { thumbnail, size } = useVideoThumbnail(upload);

  const isSelected = useMemo(() => {
    if (!upload) return false;
    if (selectedMedia.findIndex((m) => m._id === upload.id) !== -1) return true;
    return false;
  }, [upload, selectedMedia]);

  const handleOnSelection = () => {
    if (!upload || !thumbnail) return;
    handleSelectMedia(mapUploadToSelectedMedia(upload, thumbnail, size));
  };

  useEffect(() => {
    if (isSelectingMedia && allowMultipleSelect && thumbnail && upload.status === "uploading") handleOnSelection();
  }, [thumbnail]);

  return (
    <div className={classNames("relative aspect-[3/4] w-full", className)} data-testid={dataTestId}>
      <UploadingMedia upload={upload} />
      <SelectionButton isSelected={isSelected} onClick={handleOnSelection} className="absolute bottom-0 right-0" />
    </div>
  );
};

export default VaultMediaUpload;
