import classNames from "classnames";
import useScheduledBroadcastMessages from "hooks/useScheduledBroadcastMessages.hook";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import Button from "components/basics/Button";
import { HiOutlinePlus } from "react-icons/hi2";
import EmptyScheduledMessagesIllustration from "components/atoms/illustration/EmptyScheduledMessagesIllustration";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import ScheduledBroadcastCard from "components/organisms/broadcast/ScheduledBroadcastCard";
import BroadcastCardLoading from "components/organisms/broadcast/BroadcastCardLoading";

type Props = {
  className?: string;
  "data-testid"?: string;
};

const ListScheduledBroadcastMessages = ({ className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const navigate = useStatefulNavigate();
  const { scheduledBroadcastMessages, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useScheduledBroadcastMessages();
  const scheduledBroadcasts = scheduledBroadcastMessages?.pages.flatMap((p) => p.data) || [];

  // load next page when threshold reached
  useEffect(() => {
    if (inView && hasNextPage && !isLoading && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isLoading]);

  return (
    <div className={classNames("flex flex-col", className)} data-testid={dataTestId}>
      <div className="relative flex grow flex-col gap-2 px-4 md:px-0">
        {scheduledBroadcasts.map((broadcast) => (
          <ScheduledBroadcastCard broadcast={broadcast} key={broadcast._id} />
        ))}
        {(isLoading || isFetchingNextPage) && (
          <>
            {Array(5)
              .fill(null)
              .map((_, i) => (
                <BroadcastCardLoading key={i} />
              ))}
          </>
        )}
        {!isLoading && !isFetchingNextPage && scheduledBroadcasts.length === 0 && (
          <div className="flex w-full grow items-center justify-center">
            <div>
              <EmptyScheduledMessagesIllustration />
              <div className="pt-6 text-center text-sm text-gray-500">{t("queue.emptyMessages")}</div>
            </div>
          </div>
        )}
        <div className="absolute bottom-[10rem]" ref={ref} />
      </div>
      {!(!isLoading && !isFetchingNextPage && scheduledBroadcasts.length === 0) && <div className="pb-16" />}
      <div className="sticky bottom-0 border-t border-t-gray-100 bg-white p-4 pb-6">
        <Button text={t("queue.scheduleMessage")} IconBack={HiOutlinePlus} onClick={() => navigate("/chat/new")} />
      </div>
    </div>
  );
};

export default ListScheduledBroadcastMessages;
