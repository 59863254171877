import PriceSettingPopup from "components/molecules/popup/PriceSettingPopup";
import MaloumClientContext from "contexts/MaloumClientContext";
import { usePostHog } from "posthog-js/react";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import useUserStore from "state/userState";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const SubscriptionPriceSettingPopup = ({ isOpen, setIsOpen }: Props) => {
  const posthog = usePostHog();
  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore((state) => state.user);
  const { t } = useTranslation();

  const changePrice = useCallback(
    async (netAmount: number) => {
      if (netAmount === user.subscriptionPrice?.net) {
        setIsOpen(false);
        return;
      }

      const response = await maloumClient.users.updateProfile({
        subscriptionNetAmount: netAmount,
      });
      if (!(response instanceof Error)) {
        // POSTHOG: emmit set subscription price event
        posthog?.capture("set-subscription-price");

        useUserStore.setState({ user: response });
        setIsOpen(false);
      } else {
        throw Error;
      }
    },
    [posthog, user],
  );

  return (
    <PriceSettingPopup
      heading={t("subscription.price.monthly")!}
      priceChangeButtonText={t("subscription.price.save")}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onPriceChange={changePrice}
      netAmount={user.subscriptionPrice?.net}
    />
  );
};

export default SubscriptionPriceSettingPopup;
