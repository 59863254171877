import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import ProfileImage, { ProfileImageWithLink } from "../basics/ProfileImage";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  user?: PublicUserDto;
  isLoading?: boolean;
};

const ProductCreator = ({ user, isLoading = false }: Props) => {
  if (isLoading || !user) {
    return (
      <div className={"flex items-center pb-2"}>
        <ProfileImage isLoading />
        <div className={"ml-2 h-3 w-3/5 animate-pulse rounded-full bg-gray-200"} />
      </div>
    );
  }

  return (
    <div className={"flex cursor-pointer items-center pb-2"}>
      <ProfileImageWithLink
        url={user.profilePictureThumbnail?.url}
        to={`/creator/${user.username}`}
        uploadStatus={user.profilePictureThumbnail?.uploadStatus}
      />
      <StatefulLink
        className={"notranslate line-clamp-1 flex items-center gap-x-1 truncate pl-2"}
        to={`/creator/${user.username}`}
      >
        <span className="line-clamp-1 truncate">{user.username}</span> {user.isVerified && <VerifiedUserIcon />}
      </StatefulLink>
    </div>
  );
};

export default ProductCreator;
