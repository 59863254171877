import { CommentDto } from "@neolime-gmbh/api-gateway-client";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../../components/basics/Button";
import Textarea from "../../components/basics/Textarea";
import PopUp from "../../components/popup/PopUp";
import useComments from "hooks/useComments.hook";

type Props = {
  postId: string;
  comment: CommentDto;
  isOpen: boolean;
  handleClose: () => void;
};

const EditCommentPopup = ({ postId, comment, isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const { updateComment } = useComments(postId, false);

  const handleUpdate = async (text: string) => {
    updateComment(comment._id, text).then(handleClose);
  };

  return (
    <PopUp isOpen={isOpen} onClose={handleClose} title={t("comment.edit.title") || ""}>
      <Formik
        initialValues={{
          text: comment.text,
        }}
        validationSchema={Yup.object().shape({
          text: Yup.string()
            .required(t("validation.required") || "")
            .max(300, ({ max }) => t("validation.max", { max: max })),
        })}
        onSubmit={(values) => {
          handleUpdate(values.text);
        }}
      >
        <Form className={"mt-4 text-left"}>
          <Textarea name="text" className={"mb-4"} maxLength={300} data-testid={"comment-textarea"} />

          <Button type={"submit"} text={t("comment.edit.save")} />
        </Form>
      </Formik>
    </PopUp>
  );
};

export default EditCommentPopup;
