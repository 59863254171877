import { useTranslation } from "react-i18next";

const useCurrency = () => {
  const { i18n } = useTranslation();

  const displayCurrency = (value: number) => {
    if (!value) return `${(0).toFixed(2)} €`;
    if (i18n.language?.includes("de")) {
      return `${value.toFixed(2).replace(".", ",")} €`;
    } else {
      return `${value.toFixed(2)} €`;
    }
  };

  return { displayCurrency };
};

export default useCurrency;
