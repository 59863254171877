import i18n from "i18n";
import { createContext, Suspense, useEffect } from "react";
import { usePostHog } from "posthog-js/react";

import Loading from "../pages/basics/Loading";
import useUserStore from "state/userState";

const StartupContext = createContext();
export default StartupContext;

/**
 * This context should be used to setup application wide stuff like analytics, general scripts, etc.
 */
export const StartupProvider = ({ children }) => {
  const posthog = usePostHog();
  const userState = useUserStore((state) => state.user);

  useEffect(() => {
    // Prevent right click on images
    const handleContextmenu = (e) => {
      if (e.target.tagName === "IMG") {
        e.preventDefault();
      }
    };

    document.documentElement.lang = i18n.language ? i18n.language : "en";
    document.addEventListener("contextmenu", handleContextmenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if (userState) {
      posthog?.identify(userState.email, { isCreator: userState.isCreator });
    }
  }, [posthog, userState]);

  return (
    <StartupContext.Provider value={null}>
      <Suspense fallback={Loading}>{children}</Suspense>
    </StartupContext.Provider>
  );
};
