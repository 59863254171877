import { CategoryDto, PrivateUserDto } from "@neolime-gmbh/api-gateway-client";
import CategorySelectionSection from "components/category/CategorySelectionSection";
import Container from "components/layouts/Container";
import HeaderBar from "components/layouts/HeaderBar";
import Layout from "components/layouts/Layout";
import useCategories from "hooks/useCategories.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import useUserStore from "state/userState";
import { toggleCategory } from "helper/categoryHelper";
import Button from "components/basics/Button";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "contexts/MaloumClientContext";
import { useMutation } from "react-query";
import SexualOrientationSection from "components/SexualOrientationSection";
import useDiscoveryStore from "state/discoveryState";
import CategorySubmissionSection from "components/category/CategorySubmissionSection";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const DiscoverWizard = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const { maloumClient } = useContext(MaloumClientContext);
  const user = useUserStore<PrivateUserDto>((state) => state.user);
  const generateRandomQuery = useDiscoveryStore((state) => state.generateRandomQuery);

  const { categories } = useCategories();
  const [selectedCategories, setSelectedCategories] = useState<CategoryDto[]>([]);

  const [selectedGenders, setSelectedGenders] = useState<string[]>(user.sexualPreferences?.map((g) => g._id) || []);

  const handleCategorySelection = useCallback(
    (c: CategoryDto) => {
      setSelectedCategories(toggleCategory(selectedCategories, c));
    },
    [selectedCategories],
  );

  const handleGenderSelection = useCallback(
    (g: string) => {
      if (selectedGenders.includes(g)) setSelectedGenders(selectedGenders.filter((_g) => _g !== g));
      else setSelectedGenders([...selectedGenders, g]);
    },
    [selectedGenders],
  );

  const updatePreferences = useCallback(async () => {
    return await maloumClient.users.updateProfile({
      categories: selectedCategories.map((c) => c._id),
      sexualPreferences: selectedGenders,
    });
  }, [selectedCategories, selectedGenders, maloumClient]);

  const updatePreferencesMutation = useMutation({ mutationFn: updatePreferences });

  const handleSubmit = useCallback(() => {
    updatePreferencesMutation.mutate(undefined, {
      onSuccess: (user: PrivateUserDto) => {
        useUserStore.setState({ user: user });
        generateRandomQuery();
        navigate("/search");
      },
    });
  }, [selectedCategories, maloumClient]);

  // if categories where loaded -> add preferences to selectedCategories and hydrate them
  // {_id: string} => CategoryDTO
  useEffect(() => {
    // when categories where loaded
    if (categories.length > 0) {
      // hydrate categories
      setSelectedCategories(categories.filter((c) => user.categories?.some((_c) => _c._id === c._id)));
    }
  }, [categories]);

  return (
    <Layout hideNavigationMobile hideVerificationButton>
      <HeaderBar>
        <HeaderBar.SubPage>
          <HeaderBar.Left>
            <HeaderBar.BackButton />
          </HeaderBar.Left>
          <HeaderBar.Center>
            <HeaderBar.Title>{t("preferences")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right></HeaderBar.Right>
        </HeaderBar.SubPage>
      </HeaderBar>
      <Container hasPadding={false} className="px-4 pt-4 md:px-0">
        <span className="text-xl font-semibold text-gray-900">{t("preferencesDesc")}</span>
        <CategorySelectionSection
          selectedCategories={selectedCategories}
          handleCategoryClick={handleCategorySelection}
          reset={() => setSelectedCategories([])}
          type="POST"
          className="mt-4"
          showAll
          minPosts={15}
        />
        <span className="mt-6 text-xl font-semibold text-gray-900">{t("sexualOrientation.heading")}</span>
        <SexualOrientationSection
          selectedGenders={selectedGenders}
          handleGenderClick={handleGenderSelection}
          className="mt-4"
        />
        <CategorySubmissionSection className="mt-6 py-4" />
        {/* to keep button on bottom even if content is not full height */}
        <div className="grow" />
        <div className="pointer-events-none fixed bottom-[74px] h-[3rem] w-full bg-gradient-to-t from-white to-white/0" />
        <div className="sticky bottom-0 mx-auto mt-12 flex w-full max-w-xl flex-col bg-white py-4">
          <Button
            variant={"primary"}
            onClick={handleSubmit}
            text={t("save")}
            disabled={updatePreferencesMutation.isLoading}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default DiscoverWizard;
