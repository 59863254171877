import { PublicUserDto, SubscriptionDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useOutgoingSubscriptions from "../../hooks/useOutgoingSubscriptions";
import Button from "../basics/Button";
import SubscriptionItem from "./SubscriptionItem";

const OutgoingSubscriptions = () => {
  const { t } = useTranslation();

  const [activeIsSelected, setActiveIsSelected] = useState(true);

  const { subscriptions: subscriptionHook, isLoading } = useOutgoingSubscriptions();

  const [subscriptions, setSubscriptions] = useState<SubscriptionDto[]>([]);

  const activeSubscriptions = useMemo(() => subscriptions.filter((item) => item.isActive), [subscriptions]);
  const inactiveSubscriptions = useMemo(() => subscriptions.filter((item) => !item.isActive), [subscriptions]);

  const NoResultsView = useCallback(
    () => (
      <div className={"py-8 text-center"}>
        {activeIsSelected ? t("subscription.noActive") : t("subscription.noExpired")}
      </div>
    ),
    [t, activeIsSelected],
  );

  const unsubscribeFromUser = useCallback(
    (user: PublicUserDto) => {
      const tmpSubscriptions = [...subscriptions];
      const index = tmpSubscriptions.findIndex((subscription) => subscription.creator._id === user._id);
      if (index !== -1) {
        tmpSubscriptions[index].status = SubscriptionDto.status.CANCELED;
        setSubscriptions(tmpSubscriptions);
      }
    },
    [subscriptions, setSubscriptions],
  );

  useEffect(() => {
    if (subscriptionHook) setSubscriptions(subscriptionHook);
  }, [subscriptionHook]);

  return (
    <div data-testid="outgoing-subscriptions">
      <div className={"mb-2 mt-5 text-xl font-semibold"}>{t("subscription.yourSubscriptions")}</div>
      <div className={"mb-3 flex gap-2"}>
        {isLoading ? (
          <>
            <div className={"h-9 flex-1 animate-pulse rounded-md bg-gray-200"} />
            <div className={"h-9 flex-1 animate-pulse rounded-md bg-gray-200"} />
          </>
        ) : (
          <>
            <Button
              variant={activeIsSelected ? "primary" : "secondary"}
              text={
                t("subscription.active", {
                  amount: activeSubscriptions.length,
                }) || ""
              }
              onClick={() => setActiveIsSelected(true)}
            />
            <Button
              variant={!activeIsSelected ? "primary" : "secondary"}
              text={
                t("subscription.expired", {
                  amount: inactiveSubscriptions.length,
                }) || ""
              }
              onClick={() => setActiveIsSelected(false)}
            />
          </>
        )}
      </div>

      <div className={"grid gap-y-3"}>
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <SubscriptionItem unsubscribeFromUser={unsubscribeFromUser} key={index} isLoading />
          ))}

        {subscriptions.length > 0 &&
          subscriptions
            .filter((item) => item.isActive === activeIsSelected)
            .map((item) => (
              <SubscriptionItem subscription={item} unsubscribeFromUser={unsubscribeFromUser} key={item.creator._id} />
            ))}

        {subscriptions.filter((item) => item.isActive === activeIsSelected).length === 0 && !isLoading && (
          <NoResultsView />
        )}
      </div>
    </div>
  );
};

export default OutgoingSubscriptions;
