import classNames from "classnames";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { HiBars3, HiChevronLeft } from "react-icons/hi2";
import useStatefulNavigate from "hooks/useStatefulNavigate";

type Props = {
  title?: ReactNode;
  hasBack?: boolean;
  hasNavigation?: boolean;
  hasCancel?: boolean;
  rightSlot?: ReactNode;
  isLoading?: boolean;
  onNavigation?: () => void;
  onCancel?: () => void;
  onBack?: () => void;
  stickyTop?: boolean;
};

const Header = ({
  hasBack = false,
  title,
  hasNavigation = true,
  onNavigation,
  hasCancel = false,
  onCancel,
  rightSlot,
  isLoading,
  onBack,
  stickyTop = false,
}: Props) => {
  const navigate = useStatefulNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={classNames("mx-auto flex w-full max-w-xl flex-shrink items-center bg-white p-4 md:px-0", {
        "sticky top-0": stickyTop,
      })}
    >
      <button
        /* TODO: we don't need to define the button length; its gonna adjust itself anyway */
        className={"flex w-20 pr-2 outline-none"}
        onClick={() => (hasCancel && onCancel ? onCancel() : onBack ? onBack() : navigate(-1))}
        type={"button"}
      >
        {hasBack && <HiChevronLeft className={"h-6 w-6"} />}
        {hasCancel && <span className="text-sm">{t("cancel")}</span>}
      </button>

      {isLoading ? (
        <div className="flex flex-1 justify-center">
          <div className="flex h-2 w-1/4 animate-pulse rounded-md bg-gray-200"></div>
        </div>
      ) : (
        <span className={"line-clamp-1 flex-1 px-3 py-1 text-center text-sm"}>{title}</span>
      )}

      <div className={"flex w-20 justify-end pl-2"}>
        {rightSlot ?? (
          <button className={"focus:outline-none"} onClick={() => (onNavigation ? onNavigation() : null)}>
            {hasNavigation && <HiBars3 className={"h-6 w-6"} />}
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
