import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import MaloumClientContext from "../../contexts/MaloumClientContext";
import Button from "../basics/Button";
import { ProfileImageWithLink } from "../basics/ProfileImage";
import PopUp from "../popup/PopUp";
import TextWithCheckMark from "../utilities/TextWithCheckMark";

type Props = {
  user: PublicUserDto;
  open: boolean;
  unsubscribeFromUser: (value: PublicUserDto) => void;
  setOpen: (value: boolean) => void;
};

const UnsubscribePopup = ({ user, unsubscribeFromUser, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);

  const unsubscribe = useCallback(async () => {
    const response = await maloumClient.users.unsubscribe(user._id);
    if (!(response instanceof Error)) {
      unsubscribeFromUser(user);
      setOpen(false);
    }
  }, [maloumClient, user, unsubscribeFromUser, setOpen]);

  const handleClosePopup = useCallback(() => setOpen(false), [setOpen]);

  return (
    <PopUp title={t("unsubscribe.text")} isOpen={open} onClose={handleClosePopup}>
      <div className={"flex justify-center py-6"}>
        <ProfileImageWithLink
          to={`/creator/${user?.username}`}
          url={user.profilePictureThumbnail?.url}
          className={"h-24 w-24"}
        />
      </div>
      <div className={"mb-4 text-left"}>
        <TextWithCheckMark
          text={t("subscription.fullAccess", {
            number: user.privatePostCount,
          })}
        />
        <TextWithCheckMark text={t("subscription.alwaysNewContent")} />
        <TextWithCheckMark text={t("subscription.cancelAtAnyTime")} />
      </div>

      <Button onClick={handleClosePopup} text={t("unsubscribe.stay")} />
      <button onClick={unsubscribe} className={"mt-1 flex w-full flex-1 justify-center py-3 text-gray-700"}>
        {t("unsubscribe.unsubscribe")}
      </button>

      <p className={"mt-3 text-center text-sm text-gray-500"}>{t("unsubscribe.explain")}</p>
    </PopUp>
  );
};

export default UnsubscribePopup;
