import Button from "components/basics/Button";
import PopUp from "components/popup/PopUp";
import { useTranslation } from "react-i18next";
import { ReactComponent as UploadingSVG } from "svg/undraw_uploading.svg";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const StillUploadingMediaPopup = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();
  return (
    <PopUp isOpen={isOpen} onClose={() => setIsOpen(false)} title={t("media.stillUploading") || ""}>
      <div className={"flex flex-col items-center justify-center gap-4"}>
        <UploadingSVG />
        <span className={"text-gray-700"}>{t("media.dontCloseWindow")}</span>
        <Button onClick={() => setIsOpen(false)} text={t("media.gotIt")} />
      </div>
    </PopUp>
  );
};

export default StillUploadingMediaPopup;
