import { ReactNode } from "react";

type Props = {
  title?: ReactNode;
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const Checkbox = ({ title, checked, name, onChange }: Props) => {
  return (
    <label className="relative flex select-none items-center">
      <input
        type="checkbox"
        checked={checked}
        name={name}
        className="mt-0.5 h-5 w-5 rounded border-gray-300 text-red-900 focus:ring-red-900"
        onChange={(e) => (onChange ? onChange(e.target.checked) : null)}
      />
      {title && <span className="ml-3 cursor-pointer">{title}</span>}
    </label>
  );
};

export default Checkbox;
