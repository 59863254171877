import { PublicPriceDto } from "@neolime-gmbh/api-gateway-client";
import useCurrency from "hooks/useCurrency.hook";
import { useTranslation } from "react-i18next";
import { HiChevronRight } from "react-icons/hi2";

type Props = {
  name: string;
  price: PublicPriceDto;
};

const ProductInfoBanner = ({ name, price }: Props) => {
  const { t } = useTranslation();
  const { displayCurrency } = useCurrency();

  return (
    <div
      data-testid={"product-info-banner"}
      className={"flex h-10 items-center justify-between gap-x-2 bg-red-900 px-5"}
    >
      <span className={"notranslate truncate font-medium text-white"}>{name}</span>
      <div className={"flex items-center text-white"}>
        <span className={"truncate font-medium"}>{t("forPrice", { price: displayCurrency(price.net) })}</span>
        <HiChevronRight className={"h-6 w-6"} />
      </div>
    </div>
  );
};

export default ProductInfoBanner;
