import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as NoResultsSVG } from "svg/undraw_no_results.svg";

type Props = {
  query: string | null;
  tab: string;
  className?: string;
  "data-testid"?: string;
};

const SearchNoResults = ({ query, tab, className, "data-testid": dataTestId = "no-results" }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "mx-auto mt-6 flex w-full flex-1 flex-col items-center justify-center gap-6 px-4 sm:max-w-[25rem]",
        className,
      )}
      data-testid={dataTestId}
    >
      <NoResultsSVG className={"max-w-xs"} />
      <p className={"text-center text-lg"}>
        <Trans i18nKey={"noResultsForIn"} values={{ query, tab }} />
      </p>
      <p className={"text-center text-gray-500"}>{t("searchOtherTabs")}</p>
    </div>
  );
};

export default SearchNoResults;
