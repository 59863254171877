import MaloumClientContext from "contexts/MaloumClientContext";
import { useContext } from "react";
import { useQuery } from "react-query";

const useReceivers = (include: string[], exclude: string[]) => {
  const { maloumClient } = useContext(MaloumClientContext);

  const getReceivers = async () => {
    if (include.length === 0) return 0;
    return maloumClient.scheduledBroadcasts.calculateReceiverCount({
      includeFromLists: include,
      excludeFromLists: exclude,
    });
  };

  const { data, isLoading } = useQuery(["include", ...include, "exclude", ...exclude], getReceivers, {
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export default useReceivers;
