import { PublicUserDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import VerifiedUserIcon from "components/atoms/icons/VerifiedUserIcon";
import useChat from "hooks/useChat.hook";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiChevronRight, HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import useUserStore from "../state/userState";
import Button from "./basics/Button";
import ProfileImage, { ProfileImageWithLink } from "./basics/ProfileImage";
import StatefulLink from "components/atoms/utils/StatefulLink";

type Props = {
  user?: PublicUserDto;
  className?: string;
};

const CreatedBy = ({ user, className }: Props) => {
  const { openChat } = useChat();
  const { t } = useTranslation();
  const authUser = useUserStore((state) => state.user);
  const isOwnProfile = useMemo(() => (user ? user.username === authUser.username : false), [user, authUser]);

  if (!user) {
    return (
      <div className={classNames("flex items-center justify-between", className)}>
        <div className={"flex flex-1 items-center"}>
          <ProfileImage className={"h-12 w-12"} isLoading />
          <div className={"flex flex-1 flex-col pl-3"}>
            <div className={"h-4 w-1/4 animate-pulse rounded-full bg-gray-200"}></div>
            <div className={"mt-2 h-4 w-1/3 animate-pulse rounded-full bg-gray-200"}></div>
          </div>
        </div>
        <div className="h-10 w-10 animate-pulse rounded bg-gray-200"></div>
      </div>
    );
  }

  return (
    <div className={classNames("flex items-center justify-between", className)}>
      {user.isCreator ? (
        <div className={"flex w-fit items-center"}>
          <ProfileImageWithLink
            className={"h-12 w-12"}
            url={user.profilePictureThumbnail?.url}
            uploadStatus={user.profilePictureThumbnail?.uploadStatus}
            to={`/creator/${user.username}`}
          />
          <div className={"pl-3"}>
            <StatefulLink to={`/creator/${user.username}`} className={"notranslate flex items-center gap-x-1 font-medium"}>
              {user.username} {user.isVerified && <VerifiedUserIcon />}
            </StatefulLink>
            <StatefulLink to={`/creator/${user.username}`} className={"flex items-center text-sm font-medium text-red-900"}>
              {t("creatorProfile")}
              <HiChevronRight className={"ml-2 h-4 w-4 stroke-2"} />
            </StatefulLink>
          </div>
        </div>
      ) : (
        // FIXME does not make sense to link to creator profile if user is no creator
        <div className={"flex w-fit items-center"}>
          <ProfileImageWithLink
            className={"h-12 w-12"}
            url={user.profilePictureThumbnail?.url}
            to={`/creator/${user.username}`}
          />
          <StatefulLink className={"notranslate pl-3 font-medium"} to={`/creator/${user.username}`}>
            {user.username}
          </StatefulLink>
        </div>
      )}

      {!isOwnProfile && (
        <div>
          <Button onClick={async () => user && (await openChat(user._id))} IconBack={HiOutlineChatBubbleOvalLeft} />
        </div>
      )}
    </div>
  );
};

export default CreatedBy;
