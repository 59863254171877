import useStatefulNavigate from "hooks/useStatefulNavigate";
import {ChatListDto} from "@neolime-gmbh/api-gateway-client";

const useSelectedListsOverviewNavigation = () => {
  const navigate = useStatefulNavigate();

  type NavigationState = {
    includeLists: ChatListDto[];
    excludeLists: ChatListDto[]
  }

  return ({ includeLists, excludeLists }: NavigationState) => {
    navigate("/lists/selected", {state: {include: includeLists, exclude: excludeLists}});
  };
}

export default useSelectedListsOverviewNavigation;