import { MediaCandidateDto, ThumbnailCandidateDto } from "@neolime-gmbh/api-gateway-client";
import Media from "components/media/Media";
import MediaRemoveButton from "components/media/MediaRemoveButton";
import ValidationError from "components/utilities/ValidationError";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi2";

interface MediaSelectionProps {
  selectedMedia?: Array<MediaCandidateDto & { _id: string; thumbnail: ThumbnailCandidateDto }>;
  showNoMediaSelectedError: boolean;
  onRemoveMedia: (mediaId: string) => void;
  handleClickMediaSelectionStart: () => void;
}

const MediaSelectionMultiple = ({
  selectedMedia,
  showNoMediaSelectedError,
  onRemoveMedia,
  handleClickMediaSelectionStart,
}: MediaSelectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="scrollbar-hide flex min-h-[9rem] gap-1 overflow-y-scroll">
        {selectedMedia &&
          selectedMedia?.length > 0 &&
          selectedMedia?.map((media) => (
            <div className="relative h-[8.5rem] w-[8.5rem] shrink-0" key={media._id}>
              <Media media={media} thumbnail={media.thumbnail} className="rounded-md" />
              <MediaRemoveButton selectedMediaId={media._id} onRemoveMedia={onRemoveMedia} data-testid="remove-media" />
            </div>
          ))}

        <button
          className="flex h-[8.5rem] min-w-[8.5rem] items-center justify-center rounded-md border border-dashed border-red-900"
          onClick={handleClickMediaSelectionStart}
        >
          <HiPlus className="h-10 w-10 fill-red-900" />
        </button>
      </div>
      {showNoMediaSelectedError && <ValidationError message={t("errorMessage.media")} hasBackground={false} />}
    </>
  );
};

export default MediaSelectionMultiple;
