import classNames from "classnames";
import { BaseComponent, ChildrenComponent } from "types/components/component.type";

type Props = {
  isSelected: boolean;
  onClick?: () => void;
} & BaseComponent &
  ChildrenComponent;

const BaseListItem = ({ isSelected, onClick, children, className, "data-testid": dataTestId }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "w-full rounded-md border border-gray-100 px-3 py-2 text-left xl:rounded-none xl:border-0 xl:border-b xl:px-2",
        { "bg-beige-400 active:bg-beige-500": isSelected, "bg-white hover:bg-beige-300 active:bg-white": !isSelected },
        className,
      )}
      data-testid={dataTestId}
    >
      {children}
    </button>
  );
};

const LoadingBaseListItem = ({ className, "data-testid": dataTestId }: BaseComponent) => {
  return (
    <div className={classNames("w-full animate-pulse rounded-md bg-gray-100", className)} data-testid={dataTestId} />
  );
};

export default BaseListItem;
export { LoadingBaseListItem };
