import { PrivateUserDto, PublicUserDto, SubscriptionDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import ButtonLink from "components/atoms/links/ButtonLink";
import useChat from "hooks/useChat.hook";
import useCreator from "hooks/useCreator.hook";
import useShare from "hooks/useShare.hook";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChatBubbleOvalLeft, HiOutlineCheckCircle } from "react-icons/hi2";
import Button from "../basics/Button";
import FollowToggleButton from "./FollowToggleButton";
import SubscribeToggleButton from "./SubscribeToggleButton";
import useUserStore from "state/userState";
import {userIsPrivate} from "helper/typesHelper";

type Props = {
  user: PublicUserDto | PrivateUserDto;
  isCurrentUser?: boolean;
};

const ProfileInfoButtons = ({ user, isCurrentUser = false }: Props) => {
  const { t } = useTranslation();
  const currentUser = useUserStore<PrivateUserDto>((state) => state.user);
  const { openChat } = useChat();
  const { updateCreatorLocally } = useCreator(user.username, isCurrentUser, false);
  const { share, successfullyShared, canShare } = useShare(`${window.location.origin}/creator/${user.username}`);

  const hasSubscriptionPrice = useMemo(
    () => user.subscriptionPrice !== undefined && user.subscriptionPrice !== null,
    [user.subscriptionPrice],
  );

  const shareText = useMemo(() => {
    return (
      <>
        <span>{canShare ? t("shareProfile") : t("copyProfileLink")}</span>{" "}
        {successfullyShared && <HiOutlineCheckCircle className={"h-5 w-5"} />}
      </>
    );
  }, [canShare, successfullyShared]);

  const unsubscribeFromUser = useCallback(
    (user: PublicUserDto) => {
      const tmpUser = { ...currentUser };
      const subscriptionIndex = tmpUser.subscriptions.findIndex(
        (subscription) => subscription.creator._id === user._id,
      );
      tmpUser.subscriptions[subscriptionIndex].status = SubscriptionDto.status.CANCELED;
      useUserStore.setState({ user: tmpUser });
    },
    [currentUser, user],
  );

  if (!user) {
    return (
      <div className={"mt-5 flex animate-pulse space-x-2"}>
        <div className={"h-12 w-full flex-1 rounded-md bg-gray-200"} />
        <div className={"h-12 w-12 rounded-md bg-gray-200"} />
        <div className={"h-12 w-12 rounded-md bg-gray-200"} />
      </div>
    );
  }

  if (userIsPrivate(user)) {
    return (
      <div className="mt-5 flex gap-2">
        <ButtonLink to="/profile/edit" variant="outline">
          {t("editProfile.title")}
        </ButtonLink>
        <Button
          variant="outline"
          text={shareText}
          onClick={() => {
            share();
          }}
        />
      </div>
    )
  }

  return (
    <>
      {hasSubscriptionPrice && (
        <div className={"mt-5 flex space-x-2"}>
          <SubscribeToggleButton user={user} unsubscribeFromUser={unsubscribeFromUser} />
          {!user.isSubscribed && (
            <div className={"hidden xxs:block"}>
              <FollowToggleButton
                user={user}
                setUser={updateCreatorLocally}
                hasSubscriptionPrice={hasSubscriptionPrice}
              />
            </div>
          )}
          <div className={"hidden xxs:block"}>
            <Button onClick={async () => user && (await openChat(user._id))} IconBack={HiOutlineChatBubbleOvalLeft} />
          </div>
        </div>
      )}
      <div
        className={classNames("flex gap-x-2", {
          "mt-2 xxs:hidden": hasSubscriptionPrice,
          "mt-5": !hasSubscriptionPrice,
        })}
      >
        {!user.isSubscribed && (
          <FollowToggleButton user={user} setUser={updateCreatorLocally} hasSubscriptionPrice={hasSubscriptionPrice} />
        )}
        <Button onClick={async () => user && (await openChat(user._id))} text={t("message.title")} />
      </div>
    </>
  );
};

export default ProfileInfoButtons;
