import { FolderDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  folder: FolderDto;
} & BaseComponent;

const FolderName = ({ folder, className, "data-testid": dataTestId }: Props) => {
  const { t } = useTranslation();
  const folderName = folder.isManaged ? t(`vault.managedFolders.${folder.name}`) : folder.name;

  return (
    <div title={folderName} className={classNames("", className)} data-testid={dataTestId}>
      {folderName}
    </div>
  );
};

export default FolderName;
