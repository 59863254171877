import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import MaloumClientContext from "contexts/MaloumClientContext";
import SocketContext from "contexts/SocketContext";
import { useContext, useEffect, useState } from "react";

const useUnreadRequests = () => {
  const socket = useContext(SocketContext);
  const { maloumClient } = useContext(MaloumClientContext);

  const [unreadRequestsCount, setUnreadRequestsCount] = useState(0);

  const addRefreshUnreadChatsEventToSocket = () => {
    socket?.on(`refresh_unread_requests`, setUnreadRequestsCount);
  };

  const updateUnreadMessages = async () => {
    const unreadRequestsCount = await maloumClient.chats.getUnreadChatsCountByInbox(ChatDto.inbox.REQUEST);
    setUnreadRequestsCount(unreadRequestsCount);
  };

  useEffect(() => {
    addRefreshUnreadChatsEventToSocket();
    updateUnreadMessages();

    return () => {
      socket?.off("refresh_unread_requests", setUnreadRequestsCount);
    };
  }, []);

  return { unreadRequestsCount };
};

export default useUnreadRequests;
