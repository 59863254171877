import { useQuery } from "react-query";
import { TUpload } from "types/uploads.type";

const useVideoThumbnail = (upload: TUpload) => {
  const generateThumbnail = async (): Promise<{ thumbnail: string; size?: { width: number; height: number } }> => {
    return new Promise((resolve, reject) => {
      if (upload.type !== "video") return resolve({ thumbnail: URL.createObjectURL(upload.media) });

      const video = document.createElement("video");
      video.src = URL.createObjectURL(upload.media);
      video.muted = true;
      video.setAttribute("playsinline", "");
      video.setAttribute("preload", "metadata");

      const captureThumbnail = async () => {
        await new Promise((resolve) => setTimeout(resolve, 100));
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        resolve({
          thumbnail: canvas.toDataURL("image/jpeg"),
          size: { width: video.videoWidth, height: video.videoHeight },
        });
      };

      video.addEventListener("seeked", () => requestAnimationFrame(captureThumbnail));

      video.currentTime = 1;
      video.onerror = reject;
    });
  };

  const { data } = useQuery(["videoThumbnail", upload.id], generateThumbnail, {
    staleTime: Infinity,
  });

  return { thumbnail: data?.thumbnail, size: data?.size ?? { width: 0, height: 0 } };
};

export default useVideoThumbnail;
