import Layout from "components/layouts/Layout";
import HeaderBar from "components/layouts/HeaderBar";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "components/layouts/Container";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import { HiOutlinePlus } from "react-icons/hi";
import BroadcastUsersList from "components/organisms/broadcast/BroadcastUsersList";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import StatefulNavigate from "components/atoms/utils/StatefulNavigate";

const SentBroadcastUserPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useStatefulNavigate();
  const broadcastId = state?.broadcastId as string | undefined;
  const userGroup = state?.userGroup as "receivedBy" | "viewedBy" | "boughtBy" | undefined;
  const userCount = state?.userCount as number | undefined;

  if (!broadcastId || !userGroup) {
    return <StatefulNavigate to="/chat/broadcasts" replace={true} />;
  }

  return (
    <>
      <Layout hideNavigationMobile>
        <HeaderBar>
          <HeaderBar.SubPage>
            <HeaderBar.Left>
              <HeaderBar.BackButton />
            </HeaderBar.Left>
            <HeaderBar.Center>
              <HeaderBar.Title>{t(`chats.broadcasts.${userGroup}`)}</HeaderBar.Title>
            </HeaderBar.Center>
            <HeaderBar.Right />
          </HeaderBar.SubPage>
        </HeaderBar>

        <Container hasPadding={false} className="py-4">
          <div className="px-4 md:px-0">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold">{t(`chats.broadcasts.${userGroup}`, { count: userCount })}</div>
              {!(userGroup === "receivedBy") && !!userCount && (
                <TernaryButton
                  variant="red"
                  size="sm"
                  className="px-0 py-0.5"
                  onClick={() =>
                    navigate("/lists/add/member", { state: { broadcastUserToAdd: { broadcastId, userGroup } } })
                  }
                >
                  <div>{t(`chats.broadcasts.${userGroup}ToList`)}</div>
                  <HiOutlinePlus className="ml-1 h-5 w-5" />
                </TernaryButton>
              )}
            </div>
            <BroadcastUsersList broadcastId={broadcastId} userGroup={userGroup} />
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default SentBroadcastUserPage;
