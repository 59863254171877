import BlockedIllustration from "components/atoms/illustration/BlockedIllustration";
import TernaryButton from "components/atoms/buttons/TernaryButton";
import PopupBottom from "components/molecules/PopupBottom";
import useChat from "hooks/useChat.hook";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (v: boolean) => void;
};

const BlockedInfoPopup = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { openChatWithMaloumOfficial } = useChat();

  return (
    <PopupBottom open={open} setOpen={setOpen}>
      <BlockedIllustration className="mx-auto my-2" />
      <div className="mt-4 text-center text-xl font-semibold">{t("chatSettings.block.infoHeader")}</div>
      <div className="mx-auto my-4 grid w-fit max-w-xl grid-cols-1 gap-3 px-6 text-sm text-gray-500">
        <div className="text-center text-sm">{t("chatSettings.block.infoText")}</div>
      </div>
      <div className="my-4 text-center text-xs text-gray-500">
        <div>{t("topCreators.questions.any")}</div>
        <Trans
          i18nKey="topCreators.questions.write"
          components={{
            1: <button className="underline" onClick={() => openChatWithMaloumOfficial()} />,
            2: <a href="mailto:support@maloum.com" className="underline" />,
          }}
        />
      </div>
      <TernaryButton variant="red" className="w-full text-center" onClick={() => setOpen(false)}>
        <div className="grow text-center">{t("gotIt")}</div>
      </TernaryButton>
    </PopupBottom>
  );
};

export default BlockedInfoPopup;
