import { BaseComponent } from "types/components/component.type";

type Props = BaseComponent;

const EmptyFolderSelectionIllustration = ({ className, "data-testid": dataTestId }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="135"
      height="106"
      viewBox="0 0 777.69685 613.28514"
      className={className}
      data-testid={dataTestId}
    >
      <path
        d="M352.94583,215.69156a10.05577,10.05577,0,0,0-8.543,12.83638l-29.07453,20.7762,17.33071,6.66935,24.95268-20.75192a10.11027,10.11027,0,0,0-4.66584-19.53Z"
        transform="translate(-211.15157 -143.35743)"
        fill="#ffb8b8" />
      <path
        d="M350.24195,246.986l-36.06422,36.28719a29.89622,29.89622,0,0,1-18.41948,8.62953l-44.5634,4.066a12.27993,12.27993,0,0,1-9.11541-2.8646,11.99558,11.99558,0,0,1,5.29328-20.95052l45.52648-9.69376,41.44849-30.5531a3.79287,3.79287,0,0,1,3.76933.0281l11.27183,9.23372a3.70275,3.70275,0,0,1,1.86773,3.88642A3.618,3.618,0,0,1,350.24195,246.986Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M782.628,266.48724a12.13173,12.13173,0,0,0-6.71771-12.199L551.46863,144.59115a12.0995,12.0995,0,0,0-16.18578,5.55125l-.00411.00842L451.09862,322.3844a12.12136,12.12136,0,0,0,5.55968,16.18989L681.09991,448.27138a12.12137,12.12137,0,0,0,16.1899-5.55968l84.18011-172.23357A12.01171,12.01171,0,0,0,782.628,266.48724Z"
        transform="translate(-211.15157 -143.35743)" fill="#f2f2f2" />
      <path
        d="M651.14179,648.76059H359.91726a13.84469,13.84469,0,0,1-13.8291-13.8291V239.582a13.84469,13.84469,0,0,1,13.8291-13.8291H651.14179a13.84469,13.84469,0,0,1,13.8291,13.8291v395.3495A13.84469,13.84469,0,0,1,651.14179,648.76059Z"
        transform="translate(-211.15157 -143.35743)" fill="#fff" />
      <circle cx="226.0083" cy="181.28514" r="19" fill="#e6e6e6" />
      <path
        d="M408.20138,376.57177a54.43119,54.43119,0,0,0,8.39893,3.8706l.51318.17774A54.76653,54.76653,0,0,0,435.32,383.71239c1.24756,0,2.44922-.04345,3.572-.12842a54.13483,54.13483,0,0,0,8.12305-1.12011c1.46826-.3208,2.93188-.70459,4.34814-1.13916.34253-.10108.65967-.2002,1.00684-.30908a55.18285,55.18285,0,0,0,16.052-8.31788,24.80532,24.80532,0,0,0-1.279-2.14892,27.42455,27.42455,0,0,0-22.98315-12.40625h-10a27.516,27.516,0,0,0-25.0254,16.08789A24.98384,24.98384,0,0,0,408.20138,376.57177Z"
        transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M443.60748,381.99078a3.61841,3.61841,0,0,0-1.7801.6245,53.17031,53.17031,0,0,0,7.97082-1.10638Z"
            transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M438.31173,273.08257a55.56576,55.56576,0,0,0-27.72021,103.72,54.6388,54.6388,0,0,0,8.83007,4.11005l.52.18a55.25847,55.25847,0,0,0,18.37012,3.12c1.21,0,2.41992-.04,3.60986-.13a54.65,54.65,0,0,0,8.19-1.13c1.51025-.33,2.99023-.72,4.39013-1.15.33985-.1.65967-.2,1.00977-.31a55.57115,55.57115,0,0,0-17.19971-108.41Zm32.23975,97.19a52.68148,52.68148,0,0,1-15.94971,8.41c-.31006.1-.63037.2-.9502.3-1.33007.41-2.73.76995-4.16992,1.07995a51.23077,51.23077,0,0,1-7.75,1.08c-1.12988.08-2.28027.12-3.41992.12a52.5441,52.5441,0,0,1-17.39014-2.95l-.48974-.17a51.23572,51.23572,0,0,1-8.76026-4.12,52.57628,52.57628,0,1,1,58.87989-3.75Z"
        transform="translate(-211.15157 -143.35743)" fill="#ccc" />
      <path d="M364.67642,494.92223" transform="translate(-211.15157 -143.35743)" fill="#f2f2f2" />
      <path
        d="M651.14179,648.76059H359.91726a13.84469,13.84469,0,0,1-13.8291-13.8291V239.582a13.84469,13.84469,0,0,1,13.8291-13.8291H651.14179a13.84469,13.84469,0,0,1,13.8291,13.8291v395.3495A13.84469,13.84469,0,0,1,651.14179,648.76059ZM359.91726,227.37984A12.216,12.216,0,0,0,347.71512,239.582v395.3495a12.216,12.216,0,0,0,12.20214,12.20215H651.14179a12.216,12.216,0,0,0,12.20215-12.20215V239.582a12.216,12.216,0,0,0-12.20215-12.20215Z"
        transform="translate(-211.15157 -143.35743)" fill="#ccc" />
      <path d="M594.7642,328.43312H512.35768a6.51929,6.51929,0,1,1,0-13.03858H594.7642a6.51929,6.51929,0,1,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M621.65628,350.43573H512.35768a6.51929,6.51929,0,1,1,0-13.03858h109.2986a6.51929,6.51929,0,0,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M594.64422,434.82487H389.40277a6.51929,6.51929,0,1,1,0-13.03858H594.64422a6.51929,6.51929,0,1,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M621.5363,456.82748H389.40277a6.51929,6.51929,0,1,1,0-13.03858H621.5363a6.51929,6.51929,0,0,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M594.64422,507.39792H389.40277a6.51929,6.51929,0,1,1,0-13.03858H594.64422a6.51929,6.51929,0,1,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M621.5363,529.40053H389.40277a6.51929,6.51929,0,0,1,0-13.03858H621.5363a6.51929,6.51929,0,1,1,0,13.03858Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M594.64422,579.971H389.40277a6.51929,6.51929,0,0,1,0-13.03859H594.64422a6.51929,6.51929,0,1,1,0,13.03859Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path d="M621.5363,601.97358H389.40277a6.51929,6.51929,0,0,1,0-13.03859H621.5363a6.5193,6.5193,0,0,1,0,13.03859Z"
            transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <path
        d="M772.628,379.48724a12.13173,12.13173,0,0,0-6.71771-12.199L541.46863,257.59115a12.0995,12.0995,0,0,0-16.18578,5.55125l-.00411.00842L441.09862,435.3844a12.12136,12.12136,0,0,0,5.55968,16.18989L671.09991,561.27138a12.12137,12.12137,0,0,0,16.1899-5.55968l84.18011-172.23357A12.01171,12.01171,0,0,0,772.628,379.48724Z"
        transform="translate(-211.15157 -143.35743)" fill="#fff" />
      <path
        d="M772.628,379.48724a12.13173,12.13173,0,0,0-6.71771-12.199L541.46863,257.59115a12.0995,12.0995,0,0,0-16.18578,5.55125l-.00411.00842L441.09862,435.3844a12.12136,12.12136,0,0,0,5.55968,16.18989L671.09991,561.27138a12.12137,12.12137,0,0,0,16.1899-5.55968l84.18011-172.23357A12.01171,12.01171,0,0,0,772.628,379.48724ZM686.01043,555.0864a10.69317,10.69317,0,0,1-14.28521,4.9056L447.2836,450.29492a10.69321,10.69321,0,0,1-4.90557-14.28521l84.18011-172.23357a10.69317,10.69317,0,0,1,14.28518-4.90559L765.28494,368.56764a10.69321,10.69321,0,0,1,4.90561,14.28519Z"
        transform="translate(-211.15157 -143.35743)" fill="#3f3d56" />
      <path
        d="M748.14785,387.3187,689.36749,507.58406a3.369,3.369,0,0,1-4.50563,1.549l-.00011,0-204.16-99.78435a2.83892,2.83892,0,0,1-.41747-.24582,3.367,3.367,0,0,1-1.13054-4.26189l58.78038-120.26542a3.369,3.369,0,0,1,4.50569-1.54893h0L746.59984,382.811a3.369,3.369,0,0,1,1.549,4.50565l0,.00009Z"
        transform="translate(-211.15157 -143.35743)" fill="#e6e6e6" />
      <circle cx="357.75864" cy="208.28689" r="29.56668" fill="#f33900" />
      <path
        d="M612.20158,474.21733,481.88833,410.526a2.95392,2.95392,0,0,1-.46807-.2766l113.80964-76.6327a4.80317,4.80317,0,0,1,7.51178,3.67142l6.34775,91.85777.30612,4.39658Z"
        transform="translate(-211.15157 -143.35743)" fill="#3f3d56" />
      <polygon
        points="401.05 330.86 356.558 309.114 393.905 288.064 396.594 286.548 397.938 285.789 398.244 290.185 401.05 330.86"
        opacity="0.2" />
      <path
        d="M684.75906,509.6802,572.0488,454.59244l37.34677-21.05,2.68877-1.51719L660.75207,404.592c3.19024-1.7977,7.76143.22749,8.79816,3.667a5.71792,5.71792,0,0,1,.16006.71138Z"
        transform="translate(-211.15157 -143.35743)" fill="#3f3d56" />
      <polygon points="125.844 376.82 138.103 376.819 143.936 329.531 125.841 329.532 125.844 376.82" fill="#ffb8b8" />
      <path d="M333.86788,516.1748l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53051.00146Z"
            transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <polygon points="30.551 369.014 43.253 371.498 58.881 323.688 40.135 320.021 30.551 369.014" fill="#ffb8b8" />
      <path
        d="M239.27428,507.59042l25.01436,4.89306.001.0002a16.24294,16.24294,0,0,1,12.82227,19.05981l-.10135.518L236.05472,524.05Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M354.8316,357.05617a10.05575,10.05575,0,0,0-14.13593-6.15884l-25.5542-24.97923L311.61562,344.15l24.80726,20.92555a10.11027,10.11027,0,0,0,18.40872-8.01938Z"
        transform="translate(-211.15157 -143.35743)" fill="#ffb8b8" />
      <circle cx="48.87092" cy="60.56582" r="24.56103" fill="#ffb8b8" />
      <path
        d="M254.865,228.86026c1.30495.571,3.97731-9.82732,2.78024-11.90707-1.78024-3.09293-1.675-3.07071-2.85681-5.117s-1.44623-4.84712.08417-6.64761,5.072-1.56163,5.77042.69581c-.4493-4.2878,3.79189-7.73454,7.993-8.70313s8.63244-.36723,12.85668-1.22917c4.90243-1.00032,10.00316-5.10972,8.04719-10.5007a7.5932,7.5932,0,0,0-1.48106-2.43408c-2.25992-2.54094-5.42117-3.62594-8.512-4.675-6.43006-2.18246-13.036-4.39233-19.82212-4.15141a28.7977,28.7977,0,0,0-25.44625,18.60924,26.156,26.156,0,0,0-1.08344,4.02534c-2.32924,12.52423,4.94368,24.87794,16.75623,29.64715Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M248.83468,363.78417a3.75449,3.75449,0,0,1-1.77783-3.7168l-11.083-54.90136-11.23437-42.13477h.00024A21.07382,21.07382,0,0,1,235.291,238.94042l7.427-7.02734,16.20239,6.98047,7.699,7.82812a44.02687,44.02687,0,0,1,22.99316,27.26074L301.9609,316.2373,330.47823,358.664a3.77282,3.77282,0,0,1-.36938,4.67969C326.14157,367.61132,248.83468,363.78417,248.83468,363.78417Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M323.54806,359.88378l-42.05078-29.13965A29.89622,29.89622,0,0,1,269.77047,314.124L257.9504,270.96483a12.28,12.28,0,0,1,1.22119-9.47656,11.99558,11.99558,0,0,1,21.5542,1.53613l17.52954,43.12012,37.3501,35.44629a3.79288,3.79288,0,0,1,.63355,3.71582l-7.11328,12.7168a3.70279,3.70279,0,0,1-3.49854,2.52051A3.618,3.618,0,0,1,323.54806,359.88378Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M236.188,505.05272a3.74374,3.74374,0,0,1-.44751-3.42285l19.45362-70.71972,1.438-69.29493,65.48633-4.49121.31787-.02148,19.72412,35.749a91.97727,91.97727,0,0,1,11.10035,36.23535l6.5,71.2295a3.71631,3.71631,0,0,1-3.25074,4.06445l-19.7456,1.87793a3.67409,3.67409,0,0,1-4.07886-3.11133l-8.01807-72.38281-28.84961-48.74512-9.68408,51.31836L266.071,504.207a3.7189,3.7189,0,0,1-3.45825,2.42578l-23.40015.00977A3.63923,3.63923,0,0,1,236.188,505.05272Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M737.61617,737.64257H244.15157a33.03734,33.03734,0,0,1-33-33v-271a33.03734,33.03734,0,0,1,33-33h60.50269a32.92486,32.92486,0,0,1,22.19507,8.57959l65.61157,46.85107a23.18228,23.18228,0,0,0,15.65869,6.06934H740.68014A33.00029,33.00029,0,0,1,773.67672,495.625l-3.064,209.5A33.13847,33.13847,0,0,1,737.61617,737.64257Z"
        transform="translate(-211.15157 -143.35743)" fill="#fff" />
      <path
        d="M733.23214,721.64257H247.071a15.93751,15.93751,0,0,1-15.91944-15.9195V430.292A15.93754,15.93754,0,0,1,247.071,414.37252a14.09636,14.09636,0,0,1,14.08056,14.08056v258.27A14.9364,14.9364,0,0,0,276.071,701.64251H731.5903a10.12538,10.12538,0,0,1,9.91528,7.5874,9.72,9.72,0,0,1-4.30689,11.18457A7.59512,7.59512,0,0,1,733.23214,721.64257Z"
        transform="translate(-211.15157 -143.35743)" fill="#f2f2f2" />
      <path
        d="M494.57588,589.36994l-1.28-1.28a1.37,1.37,0,0,1-.06356-1.9364q.0307-.03286.06356-.0636l11.23-11.24a1.37,1.37,0,0,1,1.93644-.0636q.03282.03076.06356.0636l11.23,11.23a1.39,1.39,0,0,1,.03454,1.96546l-.03454.03454-1.28,1.28a1.39,1.39,0,0,1-1.96546.03455l-.03454-.03455-6.64-7v16.61a1.37994,1.37994,0,0,1-1.36993,1.38995l-.01007.00006h-1.85a1.39,1.39,0,0,1-1.39-1.39h0V582.38l-6.64,7a1.39,1.39,0,0,1-1.9656.02472Z"
        transform="translate(-211.15157 -143.35743)" fill="#f33900" />
      <path
        d="M505.50591,564.64a22.79,22.79,0,1,1-22.79,22.79h0a22.81,22.81,0,0,1,22.79-22.79m0-3a25.79,25.79,0,1,0,25.79,25.79h0A25.79,25.79,0,0,0,505.50591,561.64Z"
        transform="translate(-211.15157 -143.35743)" fill="#3f3d56" />
      <path
        d="M737.61617,737.64257H244.15157a33.03734,33.03734,0,0,1-33-33v-271a33.03734,33.03734,0,0,1,33-33h60.50269a32.92486,32.92486,0,0,1,22.19507,8.57959l65.61157,46.85107a23.18228,23.18228,0,0,0,15.65869,6.06934H740.68014A33.00029,33.00029,0,0,1,773.67672,495.625l-3.064,209.5A33.13847,33.13847,0,0,1,737.61617,737.64257Zm-493.4646-335a31.03529,31.03529,0,0,0-31,31v271a31.03529,31.03529,0,0,0,31,31h493.4646a31.12957,31.12957,0,0,0,30.99659-30.54688l3.06421-209.5a31,31,0,0,0-30.99683-31.45312H408.11959a25.17915,25.17915,0,0,1-16.96216-6.55127l-65.61132-46.85108a30.93108,30.93108,0,0,0-20.89185-8.09765Z"
        transform="translate(-211.15157 -143.35743)" fill="#3f3d56" />
      <polygon points="568.285 599.561 556.025 599.561 552.193 552.273 568.287 552.274 568.285 599.561" fill="#ffb8b8" />
      <path d="M782.56291,754.803l-39.53051-.00147v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
            transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <polygon points="705.285 599.561 693.025 599.561 687.193 552.273 705.287 552.274 705.285 599.561" fill="#ffb8b8" />
      <path d="M919.56291,754.803l-39.53051-.00147v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
            transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M876.1253,596.73307a11.29143,11.29143,0,0,0,.29141-14.3014,9.29926,9.29926,0,0,0-1.071-1.09138l9.234-34.09151-15.39037,5.10584-6.4092,31.5911a11.37213,11.37213,0,0,0,1.01721,12.44942A7.89882,7.89882,0,0,0,876.1253,596.73307Z"
        transform="translate(-211.15157 -143.35743)" fill="#ffb8b8" />
      <path
        d="M875.53292,575.76171a3.90859,3.90859,0,0,1-.57641-.043l-8.01953-1.20947a4.34336,4.34336,0,0,1-3.0127-2.0874,5.60344,5.60344,0,0,1-.70313-3.87256l10.00538-55.88184-6.78565-35.90137c-1.30835-6.90918,2.06665-13.8872,7.85108-16.23193a10.72756,10.72756,0,0,1,10.49316,1.34424,14.31655,14.31655,0,0,1,5.62134,10.20849l4.80249,45.77637-.05738.19922L879.69479,572.311A4.47572,4.47572,0,0,1,875.53292,575.76171Z"
        transform="translate(-211.15157 -143.35743)" fill="#f33900" />
      <circle cx="662.01317" cy="272.83111" r="24.56103" fill="#ffb8b8" />
      <path
        d="M860.58956,530.35888a4.97146,4.97146,0,0,1-1.78906-.333l-38.82056-14.93164a4.98327,4.98327,0,0,1-3.16137-5.32764l1.55908-11.69287a26.50127,26.50127,0,0,1,6.24975-23.59033l.94532-1.04,11.48535-20.94385a12.25972,12.25972,0,0,1,16.73071-4.80761l20.28223,11.334a12.238,12.238,0,0,1,5.65014,6.82519,37.406,37.406,0,0,1-.5725,25.19629L865.26193,527.1538A5.03505,5.03505,0,0,1,860.58956,530.35888Z"
        transform="translate(-211.15157 -143.35743)" fill="#f33900" />
      <path
        d="M758.85177,731.3081l-.05273-.01026a5.0008,5.0008,0,0,1-3.92164-6.05957l29.74854-130.03955a350.67931,350.67931,0,0,1,20.102-60.146l12.65625-28.85644,47.22608,21.56006,6.74291,12.33252a120.69737,120.69737,0,0,1,7.97632,43.02246l47.98658,140.63183a4.99969,4.99969,0,0,1-3.90967,6.54639l-26.269-.29687a4.04456,4.04456,0,0,1-.751.06347,4.96678,4.96678,0,0,1-4.62134-3.13476l-54.85425-142.186a1.0056,1.0056,0,0,0-.96191-.61963.97054.97054,0,0,0-.90283.65674l-52.04761,142.894a4.97557,4.97557,0,0,1-5.58789,3.21Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path
        d="M794.76536,571.39216a10.05576,10.05576,0,0,0,5.36511-14.45584l23.523-26.90067-18.39942-2.50893-19.517,25.93a10.11027,10.11027,0,0,0,9.02832,17.93546Z"
        transform="translate(-211.15157 -143.35743)" fill="#ffb8b8" />
      <path
        d="M805.854,552.44189a5.00894,5.00894,0,0,1-2.34766-.58594l-8.27783-4.415a5.00071,5.00071,0,0,1-1.86865-7.09131l30.57422-48.17725,5.30151-36.35644a14.03461,14.03461,0,1,1,27.52148,5.356l-10.946,44.811-36.06323,44.60449A4.98359,4.98359,0,0,1,805.854,552.44189Z"
        transform="translate(-211.15157 -143.35743)" fill="#f33900" />
      <path
        d="M851.0572,395.92355c2.841-9.31634,19.25481-13.47286,32.89789-9.60742s23.44419,14.0001,25.72575,23.90877c5.05239,21.9422-21.30043,39.75058-16.525,61.69533,3.15948,14.51885,21.43586,28.24626,42.02041,31.56157-22.68517,4.74615-50.37341.57437-71.41-10.75935-13.09259-7.05379-24.07717-17.69856-23.91119-28.43533.10049-6.501,4.23589-12.24949,9.29313-17.21585s11.11108-9.38126,15.75221-14.56516,7.85664-11.391,6.50229-17.97171-8.23545-13.4434-17.4938-15.35808Z"
        transform="translate(-211.15157 -143.35743)" fill="#2f2e41" />
      <path d="M987.84843,756.64257h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-211.15157 -143.35743)"
            fill="#ccc" />
    </svg>
  );
};

export default EmptyFolderSelectionIllustration;