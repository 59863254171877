import { AutomatedMessageDto } from "@neolime-gmbh/api-gateway-client";
import classNames from "classnames";
import HeaderBar from "components/layouts/HeaderBar";
import AutomatedMessagesList from "components/organisms/automated-messages/AutomatedMessagesList";
import { useTranslation } from "react-i18next";
import { BaseComponent } from "types/components/component.type";

type Props = {
  selectedType?: AutomatedMessageDto.type;
  onTypeSelection: (type: AutomatedMessageDto.type) => void;
};

const AutomatedMessages = ({ selectedType, onTypeSelection }: Props) => {
  const { t } = useTranslation();

  const TitleAndInfo = ({ className }: BaseComponent) => (
    <div className={classNames("flex flex-col gap-2", className)}>
      <span className="text-xl font-semibold">{t("automatedMessages.title")}</span>
      <span className="text-sm">{t("automatedMessages.info")}</span>
    </div>
  );

  return (
    <>
      <HeaderBar alwaysShowDividerXL>
        <HeaderBar.SubPage className="xl:mb-3">
          <HeaderBar.Left className="flex-grow xl:ml-0">
            <TitleAndInfo className="hidden pt-1.5 xl:flex" />
            <HeaderBar.BackButton className="xl:hidden" />
          </HeaderBar.Left>
          <HeaderBar.Center className="xl:hidden">
            <HeaderBar.Title>{t("automatedMessages.title")}</HeaderBar.Title>
          </HeaderBar.Center>
          <HeaderBar.Right className="mr-0 md:-mr-4 xl:hidden" />
        </HeaderBar.SubPage>
      </HeaderBar>
      <div className="max-md:mt-4 max-md:px-4">
        <TitleAndInfo className="mb-3 mt-4 xl:hidden" />
        <AutomatedMessagesList selectedType={selectedType} onTypeSelection={onTypeSelection} />
      </div>
    </>
  );
};

export default AutomatedMessages;
