const LoadingChatItem = () => {
  return (
    <div className="animate-pulse">
      <div className="flex items-center gap-6 py-4">
        <div className="h-12 w-12 shrink-0 rounded-full bg-gray-100" />
        <div className="grow">
          <div className="flex items-center">
            <div className="h-4 w-20 rounded-md bg-gray-100" />
            <div className="grow" />
            <div className="h-3 w-20 rounded-md bg-gray-100" />
          </div>
          <div className="mt-0.5 flex items-center">
            <div className="h-4 w-full rounded-md bg-gray-100" />
          </div>
        </div>
      </div>
      <div className="ml-[74px] h-[2px] w-[calc(100%-74px)] bg-gray-100" />
    </div>
  );
};

export default LoadingChatItem;
