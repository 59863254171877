import { ChatDto } from "@neolime-gmbh/api-gateway-client";
import Button from "components/basics/Button";
import MaloumClientContext from "contexts/MaloumClientContext";
import useChatItem from "hooks/useChatItem.hook";
import useQueryManipulator from "hooks/useQueryManipulator";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

type Props = { userId: string; chat: ChatDto };

const ChatRequestActions = ({ userId, chat }: Props) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { invalidateChat } = useQueryManipulator();
  const { moveChatToPrimary } = useChatItem(chat._id);

  const handleBlockUser = useMutation(() => maloumClient.users.blockUsers({ userIds: [userId] }), {
    onSuccess: () => {
      invalidateChat(chat._id);
    },
  });

  const handleAcceptRequest = useMutation(moveChatToPrimary);

  return (
    <div className="sticky bottom-0 z-[51] mx-auto flex w-full max-w-xl flex-col gap-2 bg-white py-2">
      <Button
        disabled={handleAcceptRequest.isLoading}
        text={t("chats.request.accept") as string}
        onClick={() => handleAcceptRequest.mutate()}
      />
      <Button
        disabled={handleBlockUser.isLoading}
        variant="outline"
        text={t("chatSettings.block.blockUser") as string}
        onClick={() => handleBlockUser.mutate()}
      />
    </div>
  );
};

export default ChatRequestActions;
