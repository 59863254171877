import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineChevronRight } from "react-icons/hi2";
import FloatingAction from "../FloatingAction";
import Button from "./Button";
import useStatefulNavigate from "hooks/useStatefulNavigate";

const FloatingVerificationButton = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  const handleOnClick = useCallback(() => navigate("/verification/start"), [navigate]);

  return (
    <FloatingAction title={t("verification.startNow")} data-testid="floatingVerificationButton">
      <Button
        text={t("verification.verifyAccountNow")}
        IconBack={HiOutlineChevronRight}
        className="!w-fit shrink-0"
        onClick={handleOnClick}
      />
    </FloatingAction>
  );
};

export default FloatingVerificationButton;
