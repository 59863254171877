import classNames from "classnames";
import { ReactNode } from "react";
import StatefulLink from "components/atoms/utils/StatefulLink";

type TVariants = "primary" | "secondary" | "outline";
// TODO: rework during refactoring; currently build similar to button
type Props = {
  to: string;
  children: ReactNode;
  className?: string;
  "data-testid"?: string;
  variant?: TVariants;
};

const ButtonLink = ({ to, children, className, variant = "primary", "data-testid": dataTestId }: Props) => (
  <StatefulLink
    data-testid={dataTestId}
    to={to}
    className={classNames(
      "flex h-fit w-full select-none items-center justify-center gap-x-2 truncate rounded-md p-3 font-medium leading-none transition-all disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-700",
      className,
      {
        "bg-red-900 text-white hover:bg-red-600 active:bg-red-800": variant === "primary",
        "bg-beige-400 text-gray-900": variant === "secondary",
        "border border-red-900 text-red-900 hover:border-red-600 hover:text-red-600 active:border-red-800 active:text-red-800":
          variant === "outline",
      },
    )}
  >
    {children}
  </StatefulLink>
);

export default ButtonLink;
