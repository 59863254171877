import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../basics/Button";
import PopUp from "../popup/PopUp";
import SubscriptionPriceSettingPopup from "./SubscriptionPriceSettingPopup";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};
const ExplanationPricePopup = ({ isOpen, setIsOpen }: Props) => {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setIsOpen(false);
    setShowPopup(true);
  };

  return (
    <>
      <PopUp title={t("subscription.price.setSub")} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className={"py-3 text-left"}>{t("subscription.price.explanation")}</div>
        <Button text={t("subscription.price.set")} onClick={openPopup} />
      </PopUp>
      <SubscriptionPriceSettingPopup isOpen={showPopup} setIsOpen={setShowPopup} />
    </>
  );
};

export default ExplanationPricePopup;
