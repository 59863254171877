import classNames from "classnames";

type Props = {
  name: string;
  amount: string;
  className?: string;
  "data-testid"?: string;
};

const CostCalculationTableViewRow = ({ name, amount, className, "data-testid": dataTestId }: Props) => {
  return (
    <div className={classNames("flex justify-between text-xs", className)} data-testid={dataTestId}>
      <dt>{name}</dt>
      <dd>{amount}</dd>
    </div>
  );
};
export default CostCalculationTableViewRow;
