import classNames from "classnames";

type Props = {
  currStep: number;
  maxStep: number;
  className?: string;
  "data-testid"?: string;
};

const CheckoutStepper = ({ currStep, maxStep, className, "data-testid": dataTestId }: Props) => {
  const redWidth = Math.ceil((currStep / (maxStep + 1)) * 100) + "%";

  return (
    <div className={classNames("relative z-10 flex", className)} data-testid={dataTestId}>
      <div className={"h-0.5 bg-red-900"} style={{ width: redWidth }} />
      <div className={"h-0.5 flex-1 bg-gray-200"} />

      {Array.from({ length: maxStep }).map((_, index) => {
        const left = `${Math.round(((index + 1) * 100) / (maxStep + 1))}%`;

        return (
          <div
            key={index}
            style={{ left: left }}
            className={classNames("absolute h-4 w-4 -translate-y-1/2 rounded-full", {
              "bg-gray-200": currStep <= index,
              "bg-red-900": currStep >= index + 1,
            })}
          />
        );
      })}
    </div>
  );
};

export default CheckoutStepper;
