import classNames from "classnames";
import Button from "components/basics/Button";
import MaloumClientContext from "contexts/MaloumClientContext";
import useDebounceQuery from "hooks/common/useDebounceQuery.hook";
import useMembers from "hooks/lists/members/useMembers.hook";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineCheck } from "react-icons/hi2";
import { useMutation, useQueryClient } from "react-query";
import { BaseComponent } from "types/components/component.type";
import RelatedUsersList from "components/organisms/RelatedUsersList";

type Props = {
  listId: string;
  isManaged: boolean;
  selectedMembers?: string[];
  handleSelectMember?: (memberId: string) => void;
} & BaseComponent;

const MembersList = ({
  listId,
  isManaged,
  selectedMembers,
  handleSelectMember,
  className,
  "data-testid": dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const { query, debounceQuery, isTyping, setDebounceQuery } = useDebounceQuery("memberQuery");
  const { members, isLoading, fetchNextPage, hasNextPage } = useMembers(listId, query, true, !!handleSelectMember);

  const renderAction = (userId: string) => {
    if (isManaged) return <></>;
    if (handleSelectMember && selectedMembers)
      return (
        <SelectMember
          userId={userId}
          handleSelection={handleSelectMember}
          selected={selectedMembers.includes(userId)}
        />
      );
    else return <RemoveMember userId={userId} query={query} listId={listId} />;
  };

  const triggerFetchNextPage = useCallback(() => {
    if (hasNextPage && !isLoading) fetchNextPage();
  }, [isLoading, hasNextPage]);

  return (
    <div className={classNames("", className)} data-testid={dataTestId}>
      <RelatedUsersList
        users={members}
        searchQuery={debounceQuery}
        onSearchQueryChange={setDebounceQuery}
        searchPlaceholder={t("lists.members.search") ?? ""}
        isTyping={isTyping}
        fetchNextPage={triggerFetchNextPage}
        isLoading={isLoading ? isLoading && hasNextPage : false}
        renderAction={renderAction}
      />
    </div>
  );
};

export default MembersList;

type RemoveMemberProps = { userId: string; query: string; listId: string };

const RemoveMember = ({ userId, query, listId }: RemoveMemberProps) => {
  const { t } = useTranslation();
  const { maloumClient } = useContext(MaloumClientContext);
  const { removeMemberFromQuery } = useMembers(listId, query, false);
  const queryClient = useQueryClient();

  const removeUserMutation = useMutation(
    async (memberId: string) => await maloumClient.chatLists.updateMembersById(listId, { remove: [memberId] }),
  );

  const handleRemoveMember = () => {
    removeUserMutation.mutate(userId, {
      onSuccess: () => {
        removeMemberFromQuery(userId);
        queryClient.invalidateQueries(["list", listId]);
      },
    });
  };
  return (
    <div className="w-fit">
      <Button
        text={t("lists.members.remove")}
        className="w-fit"
        onClick={handleRemoveMember}
        variant="secondary"
        disabled={removeUserMutation.isLoading}
      />
    </div>
  );
};

type SelectMemberProps = {
  userId: string;
  selected: boolean;
  handleSelection: (listId: string) => void;
};

const SelectMember = ({ userId, selected, handleSelection }: SelectMemberProps) => {
  const handleClick = () => handleSelection(userId);

  return (
    <button className="flex grow items-center justify-end" onClick={handleClick}>
      <div
        className={classNames("flex h-5 w-5 items-center justify-center rounded-[4px] border-[1.5px]", {
          "border-red-900 bg-red-900": selected,
          "border-gray-400": !selected,
        })}
      >
        {selected && <HiOutlineCheck className="stroke-[3px] text-white" />}
      </div>
    </button>
  );
};
