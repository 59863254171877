import Container from "components/layouts/Container";
import Header from "components/layouts/Header";
import Layout from "components/layouts/Layout";
import StickyBottomBar from "components/StickyBottomBar";
import { useTranslation } from "react-i18next";
import useStatefulNavigate from "hooks/useStatefulNavigate";
import StatefulLink from "components/atoms/utils/StatefulLink";

const VerificationInfo = () => {
  const { t } = useTranslation();
  const navigate = useStatefulNavigate();

  return (
    <Layout hasNavigation={false} hideNavigationMobile hideVerificationButton hasStickyBottom>
      <Header
        hasBack={true}
        hasNavigation={false}
        onBack={() => navigate("/verification/start")}
        title={t("verification.moreInfo")}
      />
      <Container hasStickyBottom>
        <h1
          className={"mt-3 text-center font-serif text-3xl text-red-900 xxs:text-4xl"}
          data-testid="verificationInfoPage"
        >
          {t("verification.moreInfo")}
        </h1>
        <div className="mt-6 grid gap-y-3">
          <article>
            <h2 className="text-base font-semibold">{t("verification.info.whyVerificationsHeader")}</h2>
            <p>{t("verification.info.whyVerificationsText")}</p>
          </article>

          <article>
            <h2 className="text-base font-semibold">{t("verification.info.howVerificationsHeader")}</h2>
            <p>{t("verification.info.howVerificationsText")}</p>
          </article>

          <article>
            <h2 className="text-base font-semibold">{t("verification.info.whoSeesDataHeader")}</h2>
            <p>{t("verification.info.whoSeesDataText")}</p>
          </article>
        </div>
      </Container>
      <StickyBottomBar>
        <StatefulLink
          to="/verification/start"
          className="flex items-center justify-center gap-x-2 truncate rounded-md bg-red-900 p-2 font-medium text-white transition-all hover:bg-red-600 active:bg-red-800"
        >
          {t("verification.info.gotIt")}
        </StatefulLink>
      </StickyBottomBar>
    </Layout>
  );
};

export default VerificationInfo;
