// Legal topics
export const termsAndConditionsLink = "https://www.maloum.com/terms";
export const termsForCreatorsLink = "https://www.maloum.com/creator-terms";
export const privacyPolicyLink = "https://www.maloum.com/privacy";
export const imprintLink = "https://www.maloum.com/imprint";
export const dmcaLink = "https://www.maloum.com/dmca";
export const recallPolicyLink = "https://www.maloum.com/recall-policy";
export const recordKeepingLink = "https://www.maloum.com/record-keeping";
export const epochLink = "https://epoch.com";

// Emails
export const supportEmail = "support@maloum.com";

//Small business
export const smallBusinessAustria = "https://www.usp.gv.at/steuern-finanzen/umsatzsteuer/kleinunternehmen.html";
export const smallBusinessGermany = "https://www.kleinunternehmer.de/";
