import LogoSymbol from "../../assets/logo/LogoSymbol";

const Loading = () => {
  return (
    <div
      data-testid="loading-page"
      className={"full-height flex w-full items-center justify-center overflow-hidden bg-white"}
    >
      <LogoSymbol className={"w-16 animate-bounce fill-red-900"} />
    </div>
  );
};

export default Loading;
